.modal {

  &-error {
    color: #E71D36;
    margin-bottom: 14px;
    margin-top: 6px;
  }

  &-form {
    flex-direction: column;

    fieldset {
      border: 1px solid #EBEBEB;
      background-color: #FBFBFB;
      flex-direction: column;
      border-radius: 4px;
      padding: 18px;
      display: flex;
      width: 100%;

      legend {
        margin: 0 0 0 -16px;
        text-align: center;
        font-weight: bold;
        padding: 0 15px;
        font-size: 14px;
        width: auto;
        border: 0;
      }

      + fieldset {
        margin-top: 25px;
      }

      .edit-company-address {
        flex-direction: row;
        flex-wrap: nowrap;
        display: flex;

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }

        &-preview,
        &-notice {
          justify-content: flex-start;
          flex-direction: column;
          padding: 16px;
          flex: 0 1 50%;
          display: flex;
          width: 50%;

          @media screen and (max-width: 600px) {
            width: 100%;
            flex: 1;
          }

        }

        &-preview {
          margin-right: 18px;
          text-align: center;

          p {
            margin-bottom: 0;
          }

          @media screen and (max-width: 600px) {
            order: 2;
          }

        }

        &-notice {
          background-color: #F7F2D2;
          border: 1px solid #F7E199;
          border-radius: 4px;
          @media screen and (max-width: 600px) {
            margin-bottom: 16px;
            order: 1;
          }
        }

      }

    }

    &-field {
      flex-direction: row;
      margin-right: -8px;
      margin-left: -8px;
      flex-wrap: nowrap;
      display: flex;

      + .modal-form-field {
        margin-top: 16px;
      }

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;

        .modal-form-label {
          width: 100%;

          + .modal-form-label {
            margin-top: 16px;
          }

        }

      }

    }

    &-checkboxes {
      flex-direction: row;
      margin-bottom: -5px;
      flex-wrap: wrap;
      display: flex;
      width: 100%;
    }

    &-checkbox {
      text-transform: capitalize;
      flex-direction: row;
      padding-right: 8px;
      flex: 0 1 33.333%;
      flex-wrap: nowrap;
      font-weight: 600;
      display: flex;

      input {
        margin-right: 5px;
      }

    }

    &-label {
      align-items: flex-start;
      flex-direction: column;
      display: flex;
      margin: 0 8px;
      flex: 1;

      @media screen and (max-width: 600px) {
        min-width: calc(100% - 16px);
      }

      strong {
        margin-bottom: 5px;
      }

      small {
        margin-bottom: 12px;
        margin-top: -6px;
        font-weight: 500;
        font-size: 12px;
        color: #888;
      }

    }

    &-placeholder {
      line-height: var(--input-height);
      height: var(--input-height);
      position: absolute;
      font-weight: 400;
      padding: 0 15px;
      color: #CACACA;
      z-index: 1;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }

    &-input {
      flex-direction: row;
      align-items: center;
      position: relative;
      overflow: hidden;
      cursor: text;
      width: 100%;

      &:focus-within {
        border-color: #5C9EAD;
      }

      &[aria-invalid] {
        border-color: #E71D36;
      }

      .fa {
        line-height: var(--input-height);
        border-right: 1px solid #CCC;
        width: var(--input-height);
        background-color: #f5f5f5;
        text-align: center;
        font-size: 16px;
        color: #858585;
        z-index: 1;
      }

      input,
      select,
      textarea {
        height: var(--input-height);
        padding: 10px 15px;
        font-weight: 500;
        max-width: 100%;
        flex: 1 0 auto;
        outline: none;
        border: 0;

        &:not(:placeholder-shown) {
          + .modal-form-placeholder {
            display: none;
          }
        }

      }

      &.select {
        cursor: pointer;

        select {
          padding-left: 16px;
          margin-left: -1px;
          appearance: none;
          cursor: pointer;
        }

        &::before {
          content: "\f107";
          font: normal normal normal 14px/1 FontAwesome;
          height: var(--input-height);
          width: var(--input-height);
          justify-content: center;
          pointer-events: none;
          align-items: center;
          position: absolute;
          font-size: 20px;
          display: flex;
          right: 0;
        }

      }

      textarea {
        max-height: calc(var(--input-height) * 5);
        min-height: calc(var(--input-height) * 3);
        height: calc(var(--input-height) * 3);
        margin-right: -15px;
        line-height: 1.55;
        resize: vertical;
      }

      &.search-input {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 3px;

        span {
          background-color: #EFEFEF;
          pointer-events: none;
          border-radius: 100px;
          display: inline-flex;
          align-items: center;
          font-weight: 600;
          font-size: 12px;
          padding: 0 12px;
          height: 32px;
          margin: 2px;

          a {
            margin: 0 4px 0 -2px;
            pointer-events: all;
            text-align: center;
            line-height: 16px;
            font-size: 16px;
            color: #AAAAAA;
            display: block;
            outline: none;
            height: 16px;
            width: 16px;
            padding: 0;
            border: 0;

            &:hover,
            &:focus-within {
              color: red !important;
            }

          }

        }

        input {
          padding-left: 8px;
          height: 32px;
          margin: 2px;
          flex: 1;
        }

      }

    }

    &-panel {
      flex-direction: column;
      display: flex;
      width: 100%;
    }

  }

}