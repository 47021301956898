// ==========================================================
// 320 and Up by Phillip
// ==========================================================

/* External Fonts ======================================== */

@font-face {
  font-family: 'league_gothicregular';
  src: url('../fonts/leaguegothic-regular-webfont.woff2') format('woff2'),
  url('../fonts/leaguegothic-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: .5;
  cursor: text;
}

/* Page =================================================== */
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: lighten($baseTextColor, 75%);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  width: 100%;
  font-family: $baseFont;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: $baseTextColor;
  font-weight: $baseFontWeight;
  min-height: 100%;
  overflow-x: hidden;
  background: #f1f1f1;
  position: relative;
  float: left;
  display: block;
}

.vue-app {
  min-height: 100vh;
  float: left;
  width: 100%;
  background-color: #fafafa;
}

.headerBody {
  text-align: center;
  font-size: 1.6rem;
}

.container-fluid {
  max-width: 1865px;

  h1 {
    display: inline-block;
  }
}

/* Common Components =================================================== */
/* -- Select2 positioning issues -- */
.select2-container--open .select2-dropdown--above {
  margin-top: 18px !important;
}

/* -- Profile Icon -- */
.profile-icon-big {
  width: 56px;
  height: 56px;
  float: left;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* -- Home Black Strip -- */
.strip-black {
  background: #3a3a39; /* Old browsers */
  color: $white;
  padding: 25px 15px;
  @include fullwidth();

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      box-sizing: border-box;
      padding: 0 35px;
      vertical-align: middle;

      &:first-child {
        border-right: 1px solid #666;
      }

      a {
        color: $white;
        display: block;
        position: relative;
        font-size: inherit;
        text-transform: uppercase;
        margin: 0;

        &:hover {
          color: #f69422 !important;
        }

        .profile-icon-over {
          position: absolute;
          top: 50px;

          > img {
            width: 25px;
            margin: -7px 0 0 15px;
            position: absolute;
            left: 0;
          }

          .icon-overlay {
            width: 198px;
            height: 88px;
            background: url($imgDir + 'bg-pattern.png') center center no-repeat;
            background-size: cover;
            padding: 10px;
            display: none;
            opacity: 0;
            @include transition(all .3s ease);
            margin-top: 20px;

            img {
              float: left;
              margin: 15px 5px 0 0;
            }

            h3 {
              color: $darkBlack;
              font-weight: 600;
              font-style: italic;
              font-size: 1.52rem;
              margin-top: 17px;

              span {
                display: block;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
        }

        &:hover .icon-overlay {
          display: block;
          opacity: 1;
        }
      }

      a.btn-log-in {
        padding: 17px 20px 17px 0;
      }

      .profile-icon-big {
        float: none;
        display: inline-block;
        vertical-align: middle;
      }
    }

    li + li {
      text-align: right;
    }

    li:first-child {
      padding-left: 0;
    }

    li:last-child {
      padding-right: 0;
    }
  }

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    position: relative;

    i {
      margin-right: 10px;
    }

    .block-terms {
      right: -25px;
      position: absolute;
      top: 0;
      width: 20px;
      color: #fff;

      &:before {
        margin-left: -5px;
      }

      i {
        font-size: 1.7rem;
      }

      .info-terms {
        text-transform: initial;
        width: 240px;
        right: -25px;
      }
    }
  }

  h3 {
    font-size: 1.4rem;
    margin: 3px 0 5px 0;
    font-weight: 500;
  }
}

.alert-success span {
  font-size: 2.5rem;
  color: #f99839;
}

.shop-container {
  width: 100%;
  margin: 0;
  padding: 0 0 0 30px;
  max-width: 1900px;
  margin: 0 auto;
}

.shop-filter-bs-offset {
  width: calc(83% - 75px);
}


/* -- Breadcrumb CSS -- */
.mid-inner .breadcrumb {
  margin-bottom: 0;
  background: transparent;
  font-weight: 600;
  font-size: 1.6rem;
  color: $lightBlack;
  padding: 10px 30px;

  a {
    color: $lightBlack;
    font-weight: 500;

    &.viewAllBtn {
      font-size: 1.2rem;
      color: #cc6909;
      cursor: pointer;
    }
  }

  span {
    display: inline-block;
    margin: 0 7px;
    color: $darkenGrey;
  }

  a.btn-print {
    float: right;
    margin-left: 15px;

    i {
      margin-right: 5px;
    }
  }
}


.block-frequently {
  h3 {
    margin-bottom: 20px;
  }

  .icon-math {
    @include flexbox();
    -webkit-align-items: center;
    align-items: center;
    font-size: 4.5rem;
    width: 46px;
    font-weight: bold;
    padding-left: 3px;
  }

  .product-in {
    width: 185px;
    margin: 0 15px 15px 0;

    p {
      margin-bottom: 0;
    }
  }

  .product-box {
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .thumb-view-gallery {
    float: left;
    width: 220px;
    margin-right: 15px;
    margin-bottom: 15px;

    a {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      span {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .7);
        color: $white;
        font-size: 1.6rem;
        @include flexbox();
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        @include transition(all .3s ease);
      }

      &:hover span {
        background: rgba($linkColorHover, .8);
      }
    }
  }

  .frequently-subtotal {
    width: 175px;
    @include flexbox();
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: right;

    p {
      margin-top: 0;
      font-size: 1.6rem;
    }
  }
}

.wrap-knowledge-base {
  @include fullwidth();
  display: flex;
  flex-wrap: wrap;

  .knowledge-base {
    @include fullwidth();
    margin-top: 10px;

    .search-in {
      input {
        border: 3px solid $lightBorderColor;
        width: 100%;
        height: 60px;
        background: transparent;
        padding-left: 50px;
        font-size: 1.8rem;
      }
    }
  }
}

// Product Support Section Styling =======================================*/
.knowledge-base-tabs {
  @include fullwidth();

  .wrap-nav-tabs {
    @include fullwidth();
    text-align: center;
  }

  .nav-tabs {
    border: none;

    li, li > a, li.active a, li.active a:focus, li.active a:active, li.active {
      color: #222;
      outline: 0;
      border-radius: 0;
      font-weight: 700;
      font-size: 16px;
      margin-right: 0px;

      &:hover {
        color: #222 !important;
        background-color: $white;
        margin-right: 0px;
      }
    }

    li:hover, li > a:hover {
      background-color: #dcdcdc;
    }

    .fa {
      margin-right: 8px;
      font-size: 16px;
    }

    .badge {
      transition: background-color 200ms ease-in-out;
      text-transform: uppercase;
      background-color: #999;
      letter-spacing: 0.5px;
      border-radius: 3px;
      line-height: 20px;
      font-weight: 600;
      margin-left: 5px;
      font-size: 11px;
      padding: 0 8px;
      color: white;
    }

    li.active {
      .badge {
        background-color: #333;
      }
    }
  }

  h3 {
    font-weight: bold;
  }

  .text-center > h3 {
    margin-bottom: 20px;
  }

  .tab-content {
    @include fullwidth();
    background-color: $white;
    padding: 50px;
    border: 1px solid #ddd;

    .active {
      display: grid;
    }

    .btn-alt-outline {
      width: fit-content;
      margin: 0 auto;
    }

    .alert {
      &.success {
        color: $darkGreen;
        margin: 0;
        padding: 0;
        margin-top: -25px;
      }

      &.error {
        color: $darkRed;
        margin: 0;
        padding: 0;
        margin-top: -25px;
      }
    }
  }

  .qa-heading {
    display: grid;
  }

  .panel-group {
    margin-bottom: 0;
    display: grid;

    .panel-heading {
      width: 100%;
      padding: 30px 15px 0 15px;
      margin: auto;
    }

    .panel-heading + .panel-collapse > .panel-body {
      border-top: none;
    }

    .panel {
      border-top: 1px solid #ddd;
      margin-bottom: 25px;
      box-shadow: none;
      border-radius: 0;

      .btn-link {
        color: inherit;
      }

      .panel-collapse, .collapse {
        padding: 0 15px;
      }
    }

    .panel:first-of-type {
      border-top: none;
    }

    .qa-label {
      margin: 0;
    }

    .qa-question, .qa-answer {
      word-break:break-word;
      color: #000;
      user-select: text;
      -moz-user-select: text;
      -khtml-user-select: text;
      -webkit-user-select: text;
      -ms-user-select: text;

      &:hover {
        color: #000 !important;
      }

      .row {
        margin-bottom: 10px;
      }

      .qa-content {
        font-weight: 400;

        p {
          margin: 5px 0 10px 0;

          img {
            max-width: 75%;
            margin: 0 auto;
            padding: 4px;
            line-height: 1.42857143;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 4px;
            display: inline-block;
            height: auto;
          }
        }

        .qa-author {
          color: #757575;
          font-size: 1.3rem;
          font-style: italic;
        }
      }

      .qa-count {
        float: right;
        text-transform: uppercase;
        background-color: #333;
        letter-spacing: .5px;
        border-radius: 3px;
        line-height: 20px;
        font-weight: 600;
        font-size: 10px;
        padding: 0 8px;
        color: $white;
        width: auto;
        display: inline-flex;

        span {
          color: $white;
        }
      }

      .qa-date {
        font-size: 1.10rem;
        color: #757575;
        font-weight: 400;
        float: right;
        margin: 0;
        text-align: right;
      }

    }

    .panel-body {
      padding: 0;

      .qa-reply {
        word-break:break-word;
        font-weight: 400;
      }
    }
  }

  .qa-info-q, .qa-info-a {
    margin-top: 20px;
    @include fullwidth();

    h2 {
      font-size: 1.5rem;
      text-transform: none;

      p {
        margin: 0;
      }
    }

    h6 {
      margin-bottom: 0px;
      margin-top: 10px;
      text-transform: none;
    }

    h3 {
      display: inline-block;

      + a {
        float: right;
        margin-top: 15px;

        + hr {
          margin: 10px 0 0;
          border-top: 1px solid;
        }
      }

      + hr {
        margin: 10px 0 0;
        border-top: 1px solid;
      }
    }
  }

  .qa-info-a {
    text-align: left;
    margin-top: 10px;
    margin-left: 20px;

    strong {
      width: 100%;
      float: left;

      span {
        color: $orange;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }

  .qa-info-q.border-top {
    border-top: 1px solid $lightBorderColor;
    padding-top: 20px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 1024px) {
    img {
      height: auto;
      width: 100%;
    }
  }
}

// MIXING CALCULATOR =====================================*/
.calc-heading {
  text-transform: none;
  color: #222222;
  font-weight: normal;

  sup {
    top: -0.02;
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
}

.mixing-calculator {
  background-color: #fff;
  padding: 40px;

  .select2 {
    float: none;
    box-shadow: 0 6px 6px -5px;
    color: #22222229;
    margin-bottom: 10px;
  }

  .btn {
    border-radius: 0px;
    width: 49%;
  }

  .product-container {
    border: 1px solid #39393914;
    background-color: #fff;
    padding: 32px 15px;
    box-shadow: 0 3px 6px -5px;
    color: #22222229;
    text-align: center;
    transition: all .25s ease;
    min-width: 215px;

    h3 {
      margin: 10px 0 5px 0;
      font-size: 1.6rem;
      font-weight: 600;
      color: #222222;
    }

    p {
      color: #222222;
    }

    .fa {
      font-size: 9.5em;
    }

    img {
      width: 100px;
      height: auto;
    }
  }

  .product-container:hover {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0 3px 6px 0px;
  }

  .calc-container {
    height: auto;
    display: grid;
    grid-gap: 10px;
    grid-template-areas:
      'step1 step2 cat-weight'
      'step1 step2 total-weight'
      'error error error';

    .step-one {
      grid-area: step1;
      padding: 25px;
      border: 1px solid #39393914;
      background-color: #f1f1f1;
      min-height: 341px;

      span {
        border-radius: 0px !important;
      }

      h2 {
        margin: 0 0 15px 0;
        text-align: center;
      }
    }

    .step-two {
      grid-area: step2;
      padding: 25px;
      border: 1px solid #39393914;
      background-color: #f1f1f1;

      button[disabled] {
        pointer-events: none;
      }

      label {
        font-weight: 400;
      }

      input {
        border-radius: 0px;
      }

      h2 {
        margin: 0 0 15px 0;
        text-align: center;
      }
    }

    .catalyst-weight {
      grid-area: cat-weight;
      padding: 25px;
      border: 1px solid #39393914;
      background-color: #f1f1f1;
      text-align: center;
    }

    .total-weight {
      grid-area: total-weight;
      padding: 25px;
      border: 1px solid #39393914;
      background-color: #f1f1f1;
      text-align: center;
    }

    .error-msg {
      grid-area: error;
      padding: 25px;
      text-align: center;
      border: 1px solid #a94442;
    }

    .result-label {
      font-size: 45px;
      font-weight: 700;
      margin: 0 auto;
      color: #222222;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mixing-calculator-notes {
    div:first-of-type {
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
      min-width: 100%;
    }

    ol, ul {
      list-style-position: inside;
      text-indent: -1em;
      padding-left: 1em;
      margin-bottom: 20px;
    }

    hr {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    h2 {
      margin-bottom: 10px;
      color: #f69521;
      font-family: Teko,sans-serif;
    }
  }
}

// MIXING CALCULATOR MOBILE
@media(max-width: 991px) {
  .calc-heading {
    padding: 15px 0;
  }

  .mixing-calculator {
    padding: 10px !important;

    .btn {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .calc-container {
    grid-template-areas:
      'step1'
      'step2'
      'error'
      'cat-weight'
      'total-weight' !important;
  }

  .step-two {
    display: block !important;
  }

  .product-container {
    padding: 15px;
  }
}

// DOCUMENTS PAGE ===================================*/
.boxes-documents {
  .col-md-2, .col-xs-12, .col-sm-12 {
    &:hover {
      .favorite-doc, .favorite-article {
        visibility: visible;
        opacity: .6;
        background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
        background-size: 165px auto;
      }
    }

    .favorite-doc {
      position: absolute;
      z-index: 3;
      left: 20px;
      top: 0;
      cursor: pointer;
      width: 29px;
      height: 55px;
      visibility: hidden;
      opacity: 0;
      transition: visibility .3s, opacity .3s;
      background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
      background-size: 165px auto;

      &.document-highlight {
        visibility: visible;
        opacity: 1;
        background: url($imgDir + 'arrow-bookmark.svg') -138px 0 no-repeat;
        background-size: 166px auto;
        left: 21px;
      }
    }

    .favorite-article {
      position: absolute;
      z-index: 3;
      right: 15px;
      top: -30px;
      cursor: pointer;
      width: 29px;
      height: 55px;
      visibility: hidden;
      opacity: 0;
      transition: visibility .3s, opacity .3s;
      background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
      background-size: 165px auto;

      &.article-highlight {
        visibility: visible;
        opacity: 1;
        background: url($imgDir + 'arrow-bookmark.svg') -138px 0 no-repeat;
        background-size: 166px auto;
        right: 15px;
      }
    }
  }

  .shadow-box .favorite-article {
    right: 30px;
    top: -30px;
  }

  .document-item {

    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    width: 100%;

    .favorite-doc-true,
    .favorite-doc-false {
      transition: color .3s;
      margin-top: 0.15rem;
      font-size: 2.5rem;
      cursor: pointer;
      min-width: 4rem;
      flex: 0 1 4rem;
    }

    .favorite-doc-false {
      color: #888888;
      &:hover {
        color: #7ED323;
      }
    }

    .favorite-doc-true {
      color: #7ED323;
    }

    > a {
      flex: 0 1 auto;
    }

    + .document-item {
      border-top: 1px solid #EEEEEE;
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }

  }

  a {
    position: relative;
    line-height: 1.365;
    display: inline-block;
    color: $darkBlack;

    &:hover {
      color: $orange !important;
    }

    &.mobileLink {
      display: none;
    }

    > strong,
    > small {
      display: block;
    }

    > small {
      color: #888888;
    }

  }


  .documents-collapse-content {
    pointer-events: none;
    padding: 35px 25px;
    text-align: left;
    overflow: hidden;
    max-height: 0;
    display: none;
    float: left;
    height: 0;
    &.open {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      background-color: #FFFFFF;
      max-height: 10000000px;
      pointer-events: all;
      display: block;
      height: auto;
    }
  }
}


// KNOWLEDGE BASE =====================================*/
.search-container {
  .wrap-video {
    h2 {
      text-align: center;
    }

    #loader {
      display: inline-block;
      width: 120px;
      height: 120px;
      padding: 0;
      background: white;
      border: solid 2px #F5A623;
      border-radius: 60px;
      font-size: 16px;
      color: #F5A623;
      line-height: 120px;
      outline: none;
      font-weight: bold;
      margin: 15px auto 0;
      -webkit-transition: all 0.1s ease-out;
      transition: all 0.1s ease-out;
      text-align: center;
      margin-left: 15px;
    }

    .boxes-documents {
      margin-bottom: 20px;

      .documentList {
        height: auto;
        padding: 20px 10px 12px;
        background-color: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

        .documentLink {
          min-height: auto;
          margin: 0;
          text-align: left;

          hr {
            margin: 15px 0 10px;
          }

          a {
            color: $linkColor;

            &:hover {
              color: $linkColorHover !important;
            }
          }
        }

        a {
          img {
            width: 75%;
          }
        }
      }
    }

    h6 {
      span {
        border: 1px solid;
        padding: 1px 3px;
        line-height: 1.8rem;
        border-radius: 4px;
      }
    }

    .videoList {
      .col-xs-12 {
        margin-bottom: 0;
      }
    }
  }

  .filter-box {
    width: 380px;
    height: 43px;
    margin: 35px auto 0;
    float: none;

    li {
      width: 50%;
      display: inline-block;

      a {
        display: inline-block;
        width: 100%;

        &.disabled {
          pointer-events: none;
          opacity: .4;
          background-color: #f7f7f7;
        }
      }
    }
  }
}

.wrap-hero {
  padding-top: 0 !important;

  .hero-large {
    @include fullwidth();
    background: url('/img/CERAKOTE BLACK RAIN HERO.jpg') center center no-repeat;
    background-size: cover;
    height: 375px;
    position: relative;
    color: $white;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, .3);
    }

    .container {
      text-align: center;
      height: 100%;
      @include flexbox();
      -webkit-align-items: center;
      align-items: center;

      h1 {
        font-size: 3.6rem;
        color: $white;
        margin: 0 0 10px 0;

        sup {
          top: -0.8em;
          font-size: 50%;
        }
      }

      p {
        font-size: 1.8rem;
        margin-bottom: 40px;
        letter-spacing: 1px;
        padding: 0 16%;
      }

      form {
        background-color: $white;
        opacity: .8;
        border-radius: 6px;
      }

      input[type="text"] {
        border: 2px solid $lightBorderColor;
        width: 100%;
        height: 55px;
        background: $white;
        padding-left: 50px;
        font-size: 1.8rem;
        border-radius: 6px;
        color: #222;
        font-weight: 600;
      }
    }
  }
}

.strip-knowledge-base {
  @include fullwidth();
  padding: 55px 0;

  .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .col-sm-4 + .col-sm-4 {
    border-left: 1px solid $borderColor;
  }

  .col-sm-4 {
    text-align: center;
    padding: 0 60px;

    i {
      font-size: 4.5rem;
    }

    h2 {
      font-size: 2.4rem;
      margin: 20px 0;
      font-weight: 600;
    }

    p {
      font-size: 1.8rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.knowledge-base-bottom {
  padding-top: 20px;
  clear: both;
  background: #e6e8e8;

  .kb-section {
    background-color: #fff;
    margin: 10px;
    border-radius: 6px;
    display: inline-block;
    background: #fff;
    padding: 1.5em;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0,0.1);

    a {
      font-size: 16px;
    }
  }

  ul {
    margin-left: 20px;

    li {
      padding: 5px 0;
      list-style: decimal;
    }
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }
}

// Styling for article lists =======================================*/
ul.article-list {
  @include list-nostyle();
  font-weight: 400;
  margin: 0 auto;
  width: 55%;


  li {
    display: grid;
    text-align: left;
  }

  a {
    padding: 6px 0;
    display: block;
    width: fit-content;
  }

  a.see-all {
    text-decoration: underline;
  }

  .kb-search {
    flex-direction: row;
    padding: 12px 0 12px 0;
    z-index: 1;

    div {
      transition: border-color .2s ease-in-out;
      border: 1px solid #ccc;
      background-color: #fff;
      flex-direction: row;
      display: flex;

      &:focus-within {
        border-color: #5C9EAD;
      }

      input {
        padding-left: 12px;
        flex: 1;
        border: 0;
        font-weight: 400;

        &:focus {
          outline: none;
        }
      }

      button {
        align-items: center;
        font-size: 18px;
        color: #F69422;
        display: flex;
        width: 40px;
        background-color: transparent;
        border: 0;
        flex-direction: row;
        border-radius: 4px;
        padding: 0 12px;
        height: 40px;

        i {
          margin-right: 6px;
          font-size: 18px;
        }
      }
    }
  }
}

.wrap-hero + footer {
  border-bottom: 1px solid $white;
}


// COATING COMBOS =======================================*/
.coating-combo {
  margin-top: 20px;
  padding: 0 0 10px 0;

  .col-sm-4 {
    margin: 0 !important;
  }

  .product-in {
    margin: 0;

    p {
      margin-bottom: 0;
    }
  }

  &:nth-child(even) {
    background: rgba($darkGrey, .5);
  }

  .coating-combo-in {
    @include fullwidth();
    @include flexbox();

    > div {
      @include flexbox();
      -webkit-align-items: center;
      align-items: center;
      height: 100%;

      &.col-md-2 {
        margin-top: 100px;
      }

      .flex-wrap {
        width: 100%;

        > div {
          position: relative;
        }
      }

      h3, span {
        font-size: 1.8rem;
        color: $lightenBlack;
        margin: 0 0 5px 0;
        display: block;
        line-height: 125%;
      }

      .product-box {
        h3, span {
          margin: 0;
        }
      }

      span {
        margin-bottom: 23px;
        cursor: pointer;
        text-align: center;

        strong {
          font-style: italic;
          font-size: 1.3rem;
        }
      }

      span.icon-theme {
        width: 33px;
        height: 33px;
        background: url($imgDir + 'popular-themes.png') left -32px no-repeat;
        background-size: 30px;
        margin: 0 auto 15px;
      }

      span.icon-blends {
        width: 33px;
        height: 30px;
        background: url($imgDir + 'popular-blends.png') left -30px no-repeat;
        background-size: 30px;
        margin: 0 auto 15px;
      }

      span.comboMath {
        bottom: 0;
        position: absolute;
        font-size: 30px;
        right: -9px;
      }
    }

    .product-box {
      margin: 20px 10px 10px 10px;
      padding-bottom: 10px;
      height: 210px;

      &.gallery-box {
        a {
          margin-top: 2px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          img {
            min-width: 100%;
            min-height: 100%;
            margin: 0;
            flex-shrink: 0;
            object-fit: cover;
          }
        }
      }
    }

    .col-sm-2 {
      width: 20%;
    }

    .col-sm-8 {
      width: 60%;
    }
  }
}

.filter-wrap + h3 {
  clear: both;
  @include fullwidth();
  margin-top: 50px;
}

.coating-combo + .coating-combo {
  margin-top: 0;
}


// MODAL POPUP =======================================*/
#galleryReviewModal, #removeAllProductsModal, #applicatorReviewModal, #videoUploadModal {
  --color-cerakote-gray: #3A3A39;
  --color-cerakote-orange: #F69322;
  --input-height: 42px;
  pointer-events: none;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;

  label {
    font-weight: normal;
  }

  .modal-dialog {
    width: 660px;
  }

  .modal-content {
    background-color: white;
    pointer-events: all;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: none;
    color: #333;
    padding: 0;
    border: 0;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      max-height: 100%;
      display: flex;
    }

  }

  .modal-header {
    flex-direction: column;
    position: sticky;
    display: flex;
    z-index: 10;
    padding: 0;
    top: 0;

    &-content {
      background-color: var(--color-cerakote-gray);
      flex-direction: row;
      align-items: center;
      padding: 20px 24px;
      flex-wrap: nowrap;
      display: flex;
      z-index: 1;
    }

    &-title {
      flex-direction: column;
      display: flex;
      flex: 1;

      h2 {
        text-transform: uppercase;
        letter-spacing: 0.125px;
        font-weight: 700;
        font-size: 15px;
        color: #FFF;
        margin: 0;
      }

      small {
        margin-bottom: 0;
        margin-top: 4px;
        font-size: 12px;
        color: #BABABA;
      }

    }

    &-close {
      transition: color 200ms ease-in-out;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      padding: 0 8px;
      line-height: 1;
      display: flex;
      outline: none;
      color: #AAA;
      border: 0;

      &:hover,
      &:focus-within {
        color: #FFF;
      }

    }

  }

  .modal-body {
    padding: 24px;

    section {
      margin-bottom: 0.8rem;
    }

    .input-label {
      font-size: 1.1rem;
      text-transform: uppercase;
      color: #777;
    }

    .file-input {
      position: absolute;
      visibility: hidden;
    }

    ul.file-list {
      font-family: arial;
      list-style: none;
      padding: 0;
    }

    ul.file-list li {
      margin: 10px;
      outline: 1px solid #333;
      padding: 5px;
      border-radius: 3px;
      display: inline-block;
    }

    .form-group {
      margin-bottom: 0;
    }

    @media screen and (max-width: 600px) {
      flex: 1;
    }

  }

  .modal-footer {
    box-shadow: 0 -12px 16px 0px white;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB;
    background-color: #FBFBFB;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px;
    position: sticky;
    display: flex;
    z-index: 10;
    bottom: 0;

    @media screen and (max-width: 600px) {
      flex-flow: wrap;

      label {
        flex: auto;
        text-align: left;
      }
    }

    &-actions {
      justify-content: flex-end;
      flex-direction: row;
      flex-wrap: nowrap;
      display: flex;
      flex: 1;
    }

    &-save,
    &-cancel {
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      padding: 0 20px;
      display: flex;
      height: 42px;
      border: 0;

      @media screen and (max-width: 600px) {
        flex: 1;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.65;
      }

      .fa {
        margin-right: 6px;
        font-size: 18px;
      }

    }

    &-save {
      background-color: #F69322;
      margin-left: 16px;
      color: #FFF;
    }

  }

  .video-preview {
    background-color: #EBEBEB;
    padding-bottom: 56.25%;
    margin-bottom: 25px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;

    &-placeholder {
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      font-size: 60px;
      color: #AAA;
      left: 50%;
      top: 50%;
    }

    iframe {
      border-radius: 4px;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      left: 0;
      top: 0;
    }
  }
}



.modal {
  .modal-dialog {
    margin-top: 50px;
    width: 800px;
  }

  .modal-content {
    float: left;
    color: $white;
    width: 100%;
    border-radius: 0;
    padding: 20px;
    background: #3a3a39;
    margin-bottom: 50px;
  }

  .modal-header {
    border: 0;
    padding: 10px 15px 5px 15px;
  }

  .modal-footer {
    border: 0;
  }

  h2 {
    text-transform: none;
    font-weight: bold;
    font-size: 2.4rem;
  }

  input, select, textarea {
    border-radius: 0;
    background: $white !important;
    font-weight: 600;
  }

  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #e6e6e6 !important;
    color: #9c9a99;
    opacity: .75;
  }

  #accordionUploader,
  #accordionEditor {
    .form-group.keyword-group,
    .form-group.color-group,
    .form-group.story-group {
      background: #e6e8e8;
      padding: 10px;
    }

    .form-group {
      > a {
        color: #73859d;
        width: 100%;
        display: block;

        .hashtag {
          background: url($imgDir + 'hash.svg') center no-repeat;
          background-size: 14px auto;
          width: 14px;
          height: 14px;
          vertical-align: sub;
          display: inline-block;
        }
      }

      #collapseKeywords,
      #collapseEditKeywords {
        padding-bottom: 15px;

        p {
          margin: 0 0 13px 0;
          color: #73859d;
        }

        .col-sm-6 {
          margin-bottom: 18px;

          i {
            position: absolute;
            top: 13px;
            right: 30px;
            pointer-events: none;
            color: #72859d;
          }
        }

        .col-sm-12 {
          i {
            position: absolute;
            top: 13px;
            right: 30px;
            pointer-events: none;
            color: #72859d;
          }
        }
      }

      #collapseColors,
      #collapseEditColors {
        padding-bottom: 15px;

        span.colorItem {
          margin-top: 4px;
          margin-bottom: 4px;
          border: none;
          border-radius: 26px;

          padding: 5px 10px;
        }

        i {
          position: absolute;
          top: 13px;
          right: 30px;
          pointer-events: none;
          color: #72859d;
        }
      }

      #collapseStory {
        padding-bottom: 15px;
      }
    }
  }

  .form-group {
    @include fullwidth();
    margin-bottom: 18px;
    position: relative;

    .colorItem {
      padding: 1px 6px;
      background: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      margin-right: 5px;
      margin-bottom: 5px;
      display: inline-block;

      a {
        cursor: pointer;
        color: #999;
        font-weight: bold;
        font-size: 1.1rem;

        &:hover {
          color: #333;
        }
      }
    }

    .keywordItem {
      padding: 1px 6px;
      background: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      margin-right: 5px;
      margin-bottom: 5px;
      display: inline-block;

      a {
        cursor: pointer;
        color: $lightBlack;

        &:hover {
          color: $darkBlack;
        }
      }
    }
  }

  .btn-orange {
    display: inline-block;
    width: auto;
    font-size: 1.8rem;
    padding-left: 30px;
    padding-right: 30px;
    color: $white !important;
    border-radius: 4px;

    &:hover {
      background: $orangeLight !important;
    }
  }

  .btn-light-green {
    display: inline-block;
    width: auto;
    font-size: 1.8rem;
    padding-left: 30px;
    padding-right: 30px;
    color: $white !important;
    border-radius: 4px;
  }

  button.close.close-popup {
    background: $darkBlack;
    opacity: 1;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 3px solid $white;
    top: -46px;
    font-size: 2.8rem;
    left: 54px;
    position: relative;
    line-height: 100%;
    padding: 0;
    @include box-shadow(0 0 5px rgba(0, 0, 0, .6));
    outline: none;
    color: $white;
    text-shadow: none;
    @include transition(all .3s ease);

    &:hover {
      background: $orange;
    }
  }

  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #ccc 1px;
    outline: 0;
    height: auto;
    min-height: inherit;
    border-radius: 0;
  }

  .select2-container .select2-search--inline .select2-search__field {
    padding: 8px 7px;
    margin: 0;
    height: 38px;
    width: 100% !important;
  }

  .select2-container--default .select2-selection--multiple {
    border: 1px solid #ccc;
    border-radius: 0;
    min-height: inherit;
    height: auto;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__rendered,
  .select2-container--default.select2-container--focus .select2-selection--multiple,
  .select2-container--default .select2-selection--multiple {
    float: left;
    width: 100%;
  }

  .select2-container {
    .select2-search--inline {
      width: 100%;
    }

    .select2-selection__choice + .select2-search--inline {
      width: auto;
    }
  }

  .select2-container--focus.select2-container {
    .select2-selection__choice + .select2-search--inline {
      width: auto;
    }
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 4px;
    margin-bottom: 4px;
    border: none;
    border-radius: 26px;
    padding: 5px 10px;
  }
}

.form-group {
  .keywords-help {
    margin-bottom: 5px;

    p {
      margin-bottom: 0;
      font-weight: 600;

      span {
        background: $lightenGrey;
        padding: 4px 12px 5px 12px;
        border-radius: 15px;
      }
    }
  }
}

.upload-file {
  @include fullwidth();

  .custom-file-upload {
    background: $white;
    padding: 13px 12px 12px 12px;
    cursor: pointer;
    border-radius: 0;
    color: #73859d;
    border: 1px solid #cccccc;
    float: left;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 5px 0 15px 0;

    &:hover {
      background: #f7f7f7;
    }
  }

  input[type="file"] {
    display: none;
  }

  span#file-name {
    float: left;
    margin: 10px 0 0 0;
    color: $darkGrey;
    width: 100%;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: inherit;
  }
}

.upload-file-edit {
  @include fullwidth();

  .custom-file-upload-edit {
    background: $white;
    padding: 13px 12px 12px 12px;
    cursor: pointer;
    border-radius: 0;
    color: #73859d;
    border: 1px solid #cccccc;
    float: left;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 5px 0 15px 0;

    &:hover {
      background: #f7f7f7;
    }
  }

  input[type="file"] {
    display: none;
  }

  span#file-name {
    float: left;
    margin: 10px 0 0 0;
    color: $darkGrey;
    width: 100%;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: inherit;
  }
}

#modelSuccess {
  .modal-dialog {
    max-width: 475px;

    .modal-header {
      img {
        width: 170px;
      }

      button.close {
        margin-top: -2px;
        font-size: 3rem;
        outline: none;
      }
    }

    .modal-body {
      h4 {
        font-size: 3rem;
        color: $black;
        margin-bottom: 15px;
        line-height: 1.2;
      }

      p {
        color: $black;
        padding-right: 50px;
        line-height: 1.6;
        font-size: 1.6rem;
        margin-bottom: 25px;
      }
    }
  }
}

// PRODUCT DETAIL =====================================*/
.laser-optimized-section {
  display: inline-flex;

  .well {
    padding: 10px;
    margin-bottom: 0;

    .media-heading {
      padding: 0;
    }

    .media-body {
      p {
        margin-bottom: 0;

        small {
          font-style: italic;
        }
      }
    }
  }
}

.info-disclaimer {
  color: #1788e4;
  cursor: pointer;
  margin-top: 15px;
  font-weight: 500;

  .info-text {
    padding: 7px 13px;
    border-radius: 4px;
    overflow-y: auto;
    background: #d2d2d2;
    border: 1px solid #bbbbbb4f;
    color: #131313;
    position: absolute;
    z-index: 10;
    display: none;
    margin-right: 20px;
    font-weight: 500;

    a {
      color: #1788e4;
    }
  }

  &:hover .info-text {
    display: block;
  }
}


// ADD TO CART =====================================*/
.add-to-cart {
  background: $white;
  @include box-shadow(0 3px 10px rgba(0, 0, 0, 0.4));
  padding: 25px 0;
  position: fixed;
  display: block;
  width: 100%;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(all .3s ease);

  h2 {
    font-family: $leagueGothicRegular;
    font-weight: normal;
    color: $darkBlack;
    margin-top: 0;
    font-size: 36px;

    span {
      color: $orange;
    }
  }

  .col-sm-6 {
    img {
      max-width: 110px;
      margin: 3px 0;
    }

    p {
      font-size: 1.8rem;
      margin-bottom: 0;

      span {
        display: inline;

        em {
          font-style: normal;
          color: $orange;
          font-weight: 600;
        }

        span {
          font-size: 1.4rem;
        }

        input {
          width: inherit;
          margin: 5px auto;
        }

        #quantityEntered {
          width: 66px;
          display: inline;
        }
      }

      > input {
        width: 200px;
        margin: 10px auto;
      }
    }
  }

  .col-sm-6 + .col-sm-6 {
    border-left: 1px solid $borderColor;

    p {
      text-transform: uppercase;
      margin: 30px 17% 35px 17%;
    }

    .btn {
      padding: 15px 25px 14px 25px;
      margin: 0 5px;
      min-width: 200px;
    }
  }

  .container .text-center {
    @include flexbox();
  }

  .close-cart {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 3.5rem;
    color: $lightenBlack;
    background: transparent;
    border: 0;
    line-height: 100%;
    outline: none;
    font-weight: bold;
    padding: 10px;
    z-index: 1055;
    @include transition(all .3s ease);

    &:hover {
      color: $linkColorHover;
    }
  }
}

#vue-home {
  .add-to-cart, .show-cart-popup {
    top: 130px;
    z-index: 50;
  }
}

.show-cart-popup {
  opacity: 1;
  visibility: visible;
  @include transition(all .3s ease);
}


// GALLERY FULL WIDTH =================================*/
.gallery-full {
  .flex-wrap {
    display: block;
    text-align: center;

    > .col-sm-4,
    > .col-sm-3 {
      width: 370px;
      float: none;
      display: inline-block;
      padding: 0 6px;
      margin-bottom: 2px;

      .thumbnail-box {
        padding: 0;
        width: 358px;
        height: 358px;
        overflow: hidden;

        .wrap-img {
          height: 100%;
          z-index: 0;
        }

        .info-thumbnail {
          text-align: left;
          width: 100%;
          height: 50%;
          position: absolute;
          bottom: -50%;
          background: $white;
          padding-top: 15px;
          @include transition (all .3s ease);

          h3 {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .row {
            bottom: 15px;

            .col-sm-4 {
              img {
                width: 100%;
              }
            }
          }
        }

        .arrow-up {
          visibility: hidden;
          opacity: 0;
          display: block;
          position: absolute;
          bottom: 20px;
          left: 50%;
          width: 58px;
          height: 58px;
          margin-left: -29px;
          text-indent: -9999px;
          transition: visibility .3s, opacity .3s;
          background: url($imgDir + 'arrow-bookmark.svg') 0 0 no-repeat;
          background-size: 182px auto;
          transform: rotate(180deg);
          z-index: 2;
        }

        &:hover .arrow-up {
          visibility: visible;
          opacity: 1;
        }

        .arrow-up.selected {
          background: url($imgDir + 'arrow-bookmark.svg') 0 0 no-repeat;
          background-size: 182px auto;
          visibility: visible !important;
          top: 20px !important;
          transform: rotate(0);
        }

        .bookmark-project {
          position: absolute;
          z-index: 3;
          left: 20px;
          top: 0;
          cursor: pointer;
          width: 29px;
          height: 55px;
          visibility: hidden;
          opacity: 0;
          transition: visibility .3s, opacity .3s;
          background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
          background-size: 165px auto;
        }

        &:hover .bookmark-project {
          visibility: visible;
          opacity: .6;
          background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
          background-size: 165px auto;
        }

        .bookmark-project.project-highlight {
          visibility: visible;
          opacity: 1;
          background: url($imgDir + 'arrow-bookmark.svg') -138px 0 no-repeat;
          background-size: 166px auto;
          left: 21px;
        }
      }

      .box-on-touch {
        .arrow-up {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.gallery-common {
  .flex-wrap > .col-sm-4 {
    width: 33.33333333%;
    float: left;
    padding: 0 15px;
    margin-bottom: 24px;

    .thumbnail-box {
      width: 360px;
      height: 360px;
    }
  }
}

.data-table {
  .table-fill {
    background: white;
    border-radius: 6px;
    border-collapse: separate;
    width: 100%;
    animation: float 5s infinite;
    border: solid 1px #ccc;

    th {
      color: #222;
      background: #ccc;
      border-bottom: 3px solid #222;
      border-right: 1px solid #ccc;
      font-size: 16px;
      font-weight: 600;
      padding: 10px;
      text-align: left;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      vertical-align: middle;
    }

    th:first-child {
      border-top-left-radius: 6px;
    }

    th:last-child {
      border-top-right-radius: 6px;
      border-right: none;
    }

    tr {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: #222;
      font-size: 16px;
      font-weight: normal;
      text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
    }

    tr:hover td {
      background: #ffb5003b;
      color: #222;
    }

    tr:first-child {
      border-top: none;
    }

    tr:nth-child(odd) td {
      background: #ebebeb;
    }

    tr:nth-child(odd):hover td {
      background: #ffb5003b;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }

    td {
      background: #ffffff;
      padding: 10px;
      text-align: left;
      vertical-align: middle;
      font-weight: 300;
      font-size: 14px;
      text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
      border-right: 1px solid #c1c3d1;
    }

    td:last-child {
      border-right: 0px;
    }

    th.text-left {
      text-align: left;
    }

    th.text-center {
      text-align: center;
    }

    th.text-right {
      text-align: right;
    }

    td.text-left {
      text-align: left;
    }

    td.text-center {
      text-align: center;
    }

    td.text-right {
      text-align: right;
    }
  }
}

.related-creations {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  @media screen and (min-width: 568px) {
    flex-direction: row;
  }

  .creations {
    padding: 15px 15px 0 15px;
    margin-top: 10px;
    background-color: $white;
    box-sizing: border-box;
    float: left;
    width: 100%;
  }
}

a.related-creation {
  max-width: calc(100% - 18px);
  transition: all 0.3s ease;
  border: 1px solid #ccc;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 13px 13px 0;
  overflow: hidden;
  padding: 0;
  flex: 1;
  display: inline-flex;
  width: inherit;
  @media screen and (min-width: 568px) {
    max-width: calc(50% - 18px);
    flex: 0 1 50%;
  }
  @media screen and (min-width: 1031px) {
    max-width: calc(25% - 18px);
    flex: 0 1 25%;
  }
  .related-creation-thumbnail {
    background-position: center center;
    background-image: var(--thumbnail);
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: block;
    height: auto;
    opacity: 1;
    width: 100%;
    &::before {
      content: "";
      padding-bottom: 100%;
      display: block;
      width: 100%;
    }
  }
  .related-creation-cta {
    color: $buttonBackground;
    font-weight: 600;
    padding: 13px 0;
    display: block;
    width: 100%;
    background-color: $white;
    z-index: 1;
  }
  &:hover,
  &:focus-within {
    .related-creation-thumbnail {
      opacity: 0.825;
      transform: scale(1.1);
    }
  }
}

.creation-full {
  @extend .gallery-full;

  .product-wrap {

    .flex-wrap {
      > .col-sm-4,
      > .col-sm-3 {
        width: 25%;
        min-width: 350px;
        max-width: 460px;

        .creation-thumbnail-box {
          @extend .thumbnail-box;
          width: auto;
          height: 460px;
          padding: 0 !important;

          .wrap-img {
            height: 100% !important;
          }

          &.mix-box {
            .arrow-up {
              background: #fff;
              background-size: 182px auto;
              visibility: visible !important;
              opacity: 1;
              border-top-left-radius: 50%;
              border-top-right-radius: 50%;
              z-index: 0;
              color: $darkBlack;
              top: unset !important;
              text-indent: unset;
              -webkit-transform: rotate(0);
              transform: rotate(0);
              transition: bottom .3s;
              bottom: 0;
              width: 85px;
              height: 80px;
              font-size: 1.5rem;
              line-height: 1.5rem;
              left: calc(50% - 15px);
              padding-top: 10px;

              &:hover {
                color: $orange !important;
              }

              i {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
              }

              &.selected {
                background: #fff;
                background-size: 182px auto;
                visibility: visible !important;
                bottom: 170px;
                opacity: 1;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                z-index: 0;
                color: $darkBlack;
                top: unset !important;
                text-indent: unset;
                transition: bottom .3s;

                i {
                  -webkit-transform: rotate(0);
                  transform: rotate(0);
                }
              }
            }
          }

          .info-thumbnail {
            bottom: -170px;
            height: auto;
            padding-top: 0 !important;
            padding-bottom: 170px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;

            h3 {
              padding: 10px 0 8px 0;
              margin: 0;
              text-align: center;
              background: #3a3a39;
              color: #e6e8e8;
            }

            > a {
              width: 100%;
              position: absolute;
              bottom: 0px;
              margin: 0px;
              padding: 5px !important;
              background: #f78e1a;
              color: #fff;

              &:hover {
                color: #fff !important;
                background:#ffa400;
              }
            }

            .row {
              position: relative;
              bottom: 0;
              margin: 0 !important;
              width: 100% !important;

              &.mix-creation {
                bottom: 30px;
                flex-wrap: unset;
                width: 100% !important;
                white-space: nowrap;

                .col-sm-4 {
                  padding: 0 10px;
                  height: auto;

                  strong {
                    display: block;
                    width: 100px;
                    white-space: initial;
                    margin: auto;
                    font-size: 1.1rem;
                  }
                }
              }

              .col-sm-2 {
                + .col-sm-4 {
                  border-left: none !important;
                }
              }

              .col-sm-4 {
                padding: 0 10px;
                font-size: 1.25rem;
                position: relative;
                height: 160px;

                + .col-sm-4 {
                  border: none;

                  .step-plus {
                    position: absolute;
                    left: -1.2rem;
                    font-size: 2rem;
                    bottom: calc(50% - 10px);
                    border: 1px solid #dedede;
                    border-radius: 50%;
                    background: #fff;
                    padding: 0 5px;
                    line-height: 20px;
                  }

                  .step-mix {
                    position: absolute;
                    left: -3.2rem;
                    bottom: calc(50% - 10px);
                    border-radius: 50%;
                    background: #fff;
                    padding: 0 5px;

                    .step-mix {
                      position: absolute;
                      left: -3.2rem;
                      bottom: calc(50% - 10px);
                      border-radius: 50%;
                      background: #fff;
                      padding: 0 5px;

                      i {
                        line-height: 45px;
                        font-size: 25px;
                      }
                    }
                  }

                  &:nth-child(2) {
                    border-left: 1px solid #dedede;
                    border-right: 1px solid #dedede;
                  }

                  &.twoColorCreation {
                    border-right: none !important;
                  }

                  &.creation-details {
                    padding-top: 30px;
                    color: $darkenBlack;
                    margin: 0;
                  }

                  i {
                    line-height: 60px;
                    font-size: 30px;
                  }

                  > div {
                    display: block;
                    min-height: 60px;
                    padding-bottom: 5px;
                  }

                  img {
                    height: 60px;
                    width: auto;
                    line-height: 60px;
                  }

                  span {
                    font-size: 1.1rem;
                  }
                }

                &:nth-child(2) {
                  border-left: 1px solid #dedede;
                  border-right: 1px solid #dedede;
                }

                &:nth-child(4) {
                  border-left: 1px solid #dedede;
                }

                &.creation-details {
                  padding-top: 30px;
                  color: $darkenBlack;
                  margin: 0;
                }

                i {
                  line-height: 60px;
                  font-size: 30px;
                }

                > div {
                  display: block;
                  min-height: 60px;
                  padding-bottom: 5px;
                }

                img {
                  height: 60px;
                  width: auto;
                  line-height: 60px;
                }

                span {
                  font-size: 1.1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

#colorSwatch {
  p {
    margin-bottom: 0;
  }
}

//Old Browser Version==============================================*/
#oldBrowserModal {
  font-family: $sourceSansPro;

  .modal-dialog {
    width: 800px;

    .wrap-browser-message {
      background-image: url('/img/No_Pain.jpg?impolicy=full');
      background-size: cover;
      height: 500px;
      width: 800px;
      display: block;
      color: $white;
      padding: 150px 80px;

      h2 {
        margin: 0;
        font-weight: normal;
        line-height: 25px;

        span {
          text-transform: capitalize;
        }
      }

      .browserLinks {
        width: 500px;

        a {
          font-size: 20px;
          color: $white;
          cursor: pointer;
          padding-bottom: 15px;
          padding-right: 25px;
          display: block;
          float: left;

          img {
            height: 50px;
          }
        }
      }
    }
  }
}

// Cookies are disabled==============================================*/
.cookiesDisabled {
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: #3d3d3b;
  color: white;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3);
  bottom: 0;
  text-align: center;
  height: 100px;
  padding-top: 15px;

  p {
    margin: 0;
  }
}

// LOG IN ==============================================*/
.login-signup {
  font-family: $sourceSansPro;

  .modal-dialog {
    width: 550px;
    height: 1000px;
  }

  .modal-content {
    background: #3a3a39;
    padding: 30px 40px 40px 40px;
  }

  .btn-orange {
    display: block;
    width: 100%;
  }

  .text-center > img {
    width: 175px;
    max-width: 100%;
  }

  button.close {
    border: 0;
    background: transparent;
    box-shadow: none;
    color: $white;
    top: -30px;
    font-size: 4rem;
    left: 46px;
    opacity: 1;
    text-shadow: 0 1px 0 #0a0b09;

    &:hover {
      color: $linkColorHover;
      background: transparent;
    }
  }

  .modal-body {
    @include fullwidth();

    h2, h3 {
      float: left;
      font-weight: normal;
      position: relative;
      z-index: 2;
    }

    h2 {
      font-size: 2.6rem;
      margin-top: 0;
    }

    h3 {
      font-size: 2rem;
      text-align: right;
      margin-top: 15px;
      float: right;
    }

    .title-group {
      span {
        display: block;
        font-size: 1.7rem;
        font-weight: bold;
      }
    }

    .signUpForNewsletter {
      margin-top: 5px;
    }

    .btn {
      line-height: 1.6rem;
      font-size: 1.6rem;
      font-weight: normal;
      padding: 13px 20px;
      text-transform: none;

      i {
        margin-left: 10px;
        font-size: 1.4rem;
      }

      &.btn-signup {
        line-height: 1.6rem;
        background: transparent;
        border-color: #777;
        color: #fff;
        margin-top: 18px;

        span {
          font-weight: bold;
        }

        &:hover {
          color: #fff !important;
        }
      }
    }

    .btns-sign-in {
      &.mobile-sign-in {
        display: none
      }

      .btn {
        width: 48%;
        float: left;
      }

      .btn + .btn {
        float: right;
        margin-top: 0;
      }

      .btn-google:hover {
        background-color: darken($darkOrange, 7%);
      }

      .btn-google:active, .btn-google:focus {
        background-color: $darkOrange !important;
      }
    }

    input[type="email"], input[type="username"], input[type="tel"], input[type="password"], input[type="text"], select {
      height: 50px;
      font-size: 1.4rem;
      border-radius: 3px;
      font-weight: normal;
    }

    .country-code {
      height: 38px;
    }

    p {
      margin: 0 25px;
    }

    .forgot-password {
      text-align: center;
      margin-top: 5px;

      a {
        font-size: 1.6rem;
      }
    }
  }

  .divider-sign-in {
    display: block;
    margin: 20px 0 20px 0;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    position: relative;
    @include fullwidth();

    span {
      display: inline-block;
      padding: 0 35px;
      background: #3a3a39;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $lightBorderColor;
      top: 49%;
      left: 0;
      z-index: 0;
    }
  }
}

.wrap-sign-up {
  @include fullwidth();
}

.signup-country {
  @extend .login-signup;

  .modal-content {
    padding: 0 20px;
  }

  .signup-company-field {
    height: 38px !important;
    padding-left: 30px;
  }

  .modal-body .form-group #hasCompany {
    height: 34px !important;
  }

  #signupCompany {
    padding-left: 30px;
  }
}

#notificationPopup {
  background-color: transparent !important;

  .modal-dialog {
    height: auto !important;

    .modal-content {
      padding: 0px;
      height: auto !important;
      border: 1px solid #999;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

      .modal-header,
      .modal-body,
      .modal-footer {
        padding: 0px;

      }
    }
  }
}

.wrap-business-fields {
  .circle-steps {
    display: block;
    text-align: center;
    margin-bottom: 25px;

    > span {
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      background: $darkGrey;
      margin: 0 5px;
      border-radius: 50%;
      cursor: pointer;

      &:before {
        width: 9px;
        height: 9px;
        background: $orange;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 50%;
        content: '';
        display: none;
      }
    }
  }

  .form-group.text-center {
    margin-bottom: 0;
  }

  .styled-select:before {
    top: 18px;
    pointer-events: none;
  }
}

.fields-step1 {
  display: none;

  .circle-steps {
    > span:first-child:before {
      display: block;
    }
  }
}

.fields-step2 {
  display: none;

  .circle-steps {
    > span:nth-child(2):before {
      display: block;
    }
  }
}

.fields-step3 {
  display: none;

  .circle-steps {
    > span:last-child:before {
      display: block;
    }
  }

  p {
    clear: both;
    padding-top: 15px;
  }
}

#login {
  .visit-your-page {
    padding: 60px 15px 15px 15px;
    text-align: center;
    @include fullwidth();
    display: none;

    .img-page {
      width: 100px;
      height: 100px;
      margin: 0 0 40px 0;
      border-radius: 50%;
      background: $green;
      display: inline-block;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    div + p {
      margin-bottom: 50px;
    }
  }
}

.styled-checkbox {
  position: relative;
  margin: 0 0 10px 10px;
  float: left;
  width: 50%;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
    box-sizing: border-box;
    padding: 0;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 40px;
    padding-top: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 140%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    font-size: 1.6rem;
  }

  input[type="checkbox"] + label:before,
  input[type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    z-index: 0;
    background: $darkenDarkBlack;
    border: 1px solid rgba($white, .2);
    border-radius: 3px;
    transition: .2s;
  }

  input[type="checkbox"] + label:before {
    border-radius: 50%;
  }

  input[type="checkbox"]:checked + label:after {
    top: 4px;
    left: 3px;
    width: 10px;
    height: 19px;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid $white;
    border-bottom: 3px solid $white;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    background: transparent;
  }
}

.wrap-checkbox {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 16px;

  label.styled-checkbox2 {
    cursor: pointer;
    float: none;
    width: auto;
    position: relative;

    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
      opacity: 0;
      width: 0;
      height: 0;
      box-sizing: border-box;
      padding: 0;
      background: rgba(0, 0, 0, .2);
      border: 1px solid rgba(255, 255, 255, .7);
    }

    .lever {
      content: "";
      display: inline-block;
      position: relative;
      width: 40px;
      height: 15px;
      background-color: transparent;
      background-color: $darkGrey;
      border-radius: 15px;
      transition: background 0.3s ease;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -ms-user-select: none;

      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: $lightGrey;
        border-radius: 21px;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
        left: 0;
        top: -3px;
        transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
      }
    }

    input[type=checkbox]:checked + .lever:after {
      left: 20px;
    }

    input[type=checkbox]:checked + .lever {
      background-color: rgba($orange, .5);
    }
  }

  label.styled-checkbox2 + em {
    font-style: normal;
    color: $orange;
    padding-left: 10px;
  }

}


// Videos Page =====================================*/
#vue-videos {
  .wrap-hero {
    .breadcrumb {
      padding: 30px 30px 8px;
    }

    .videoPlayer {
      width: 100%;
      max-height: 611px;
      height: 0;
      @include transition(all .5s ease);

      &.open {
        height: 611px;
        margin-bottom: 25px;
      }

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}

#videoPlayerModal {
  padding-right: 0px !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    padding: 0px;
    margin: 0px;
    height: 100%;
    text-align: center;
    background-color: #222;
  }

  .modal-header {
    display: none;
  }

  .modal-body {
    padding: 0px;
    top: calc(50% - 350px);

    button.close.close-popup {
      top: -50px;
      right: 10px;
      left: auto;
      position: absolute;
    }

    .save-video {
      opacity: 1;
      height: 38px;
      border-radius: 50%;
      top: -50px;
      left: 10px;
      font-size: 2.8rem;
      float: left;
      position: absolute;
      line-height: 100%;
      color: white;

      b {
        font-size: 1.8rem;
        padding: 10px;
        font-weight: 400;
      }
    }
  }

  .modal-footer {
    display: none;
  }
}

.wrap-video {
  .col-sm-9 {
    padding-right: 0;
    border-right: 1px solid $borderColor;
  }

  h2 {
    margin: 20px 0 10px;
  }

  .videoList, .documentList, .articleList {
    .col-xs-12 {
      text-align: center;
      margin-bottom: 20px;
      min-height: 190px;

      .vidTitle {
        font-weight: 600;
        font-size: 1.3rem;
        width: 100%;
        display: block;
        text-align: left;
        min-height: 40px;
      }

      .vidDate {
        color: #777;
        width: 100%;
        text-align: left;
        font-size: 1.2rem;
        display: block;
      }
    }

    a {
      background-color: #e6e8e8;
      height: 100%;
      width: 100%;
      display: block;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .videoPlay {
        opacity: .9;
        font-size: 50px;
        color: $darkenBlack;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        @include transition(all .1s ease);
      }
    }

    a:hover {
      img {
        opacity: .3;
      }

      .videoPlay {
        opacity: 1;
        color: $red;
      }
    }

    button {
      background: none;
      border: none;
      color: $linkColor;
      font-size: 1.2rem;
      width: 100%;

      i {
        color: #7ED323;
        font-size: 15px;
      }

      &:hover {
        i {
          color: #7ED323;
        }
      }
    }

    #loader {
      display: inline-block;
      width: 120px;
      height: 120px;
      padding: 0;
      background: white;
      border: solid 2px #F5A623;
      border-radius: 60px;
      font-size: 16px;
      color: #F5A623;
      line-height: 120px;
      outline: none;
      font-weight: bold;
      margin: 15px auto 0;
      -webkit-transition: all 0.1s ease-out;
      transition: all 0.1s ease-out;
      text-align: center;
      margin-left: 15px;
    }

    &.article-details {
      float: left;

      button {
        vertical-align: text-top;
        font-size: 1.5rem;
        padding-top: 5px;
      }
    }
  }

  .articleList {
    float: left;
    margin-bottom: 30px;
    width: 100%;

    a {
      background-color: $white;
      height: 100%;
      width: 100%;
      display: block;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    a:hover {
      img {
        opacity: .3;
      }

      .videoPlay {
        opacity: 1;
        color: $red;
      }
    }

    button {
      background: none;
      border: none;
      color: $linkColor;
      font-size: 1.2rem;
      width: 100%;

      i {
        color: #7ED323;
        font-size: 15px;
      }

      &:hover {
        i {
          color: #7ED323;
        }
      }
    }

    #loader {
      display: inline-block;
      width: 120px;
      height: 120px;
      padding: 0;
      background: white;
      border: solid 2px #F5A623;
      border-radius: 60px;
      font-size: 16px;
      color: #F5A623;
      line-height: 120px;
      outline: none;
      font-weight: bold;
      margin: 15px auto 0;
      -webkit-transition: all 0.1s ease-out;
      transition: all 0.1s ease-out;
      text-align: center;
    }
  }

  .article-list {
    li {
      position: relative;

      button {
        width: initial;
        background: none;
        border: 0;
        position: absolute;
        left: -25px;
        top: 5px;
        font-size: 1.4rem;
        color: $orange;
      }
    }
  }
}

// Contact Us Page =====================================*/
.ul-cta {
  text-decoration: underline;
}

.contact-container {

  .panel-sapcing {
    margin-bottom: 10px;
  }
  .panel-container {
    display: flex;
    flex-wrap: wrap;

    .pannel-inner {
      height: 100%;
      position: relative;
      min-height: 200px;

      .panel-body {
        margin-bottom: 15px;
      }
      .text-center {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-bottom: 15px;
      }
    }
  }
}


// News Page =====================================*/
.wrap-news {
  margin-top: 35px;

  .news-box {
    margin-bottom: 25px;
  }

  .news-content {
    padding-left: 45px;
  }

  .news-image + .news-content {
    padding-left: 0;
  }

}


// Find Applicator ===============================*/

#applicator-map-no-results {
  margin-top: 24px;
}

.applicator-map-overlay {
  #applicator-map-sidebar-filters, #applicator-map-sidebar-results {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    opacity: 0;

    label, button, a {
      pointer-events: none;
    }

    @media only screen and (max-width: 1024px) {
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  #applicator-map-sidebar-filters {
    overflow: hidden !important;
  }

  #applicator-map-list {
    height: 100%;

    .result-placeholder {
      height: 100%;

      .bg-image {
        background-image: url($imgDir + 'applicator-result-placeholder.jpg');
      }
    }
  }

  #applicator-map-view {
    height: 100%;

    .map-placeholder.landing {
      background-color: none;
    }
    .map-placeholder {
      height: 100%;
    }
    .bg-image {
      background-image: url($imgDir + 'applicator-map-placeholder.jpg');
      background-repeat: no-repeat;
      transform: translateX(-40%);
      overflow: hidden;
      background-size: cover;
      z-index: -1;
      width: 145vw;
    }
  }


  .bg-image {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position:absolute;
  }

  @keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translate(-50%, -200%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .bg-text {
    background-color: rgba(255, 255, 255, 1);
    color: #3a3a39;
    box-shadow: 0 10px 20px rgba(black, 0.09), 0 6px 6px rgba(black, 0.13);
    font-weight: bold;
    border: 2px solid #2196f3;
    position: sticky;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    width: 100%;
    padding: 20px;
    text-align: center;
    max-width: 1200px;
    animation: 1s ease-in 0s 1 slideInFromTop;
    border-radius: 4px;
    min-height:185px;


    h1 {
      color: #393939;
      font-size: 5rem;
      font-family: 'Teko';
    }

    p {
      padding-top: 15px;
      color: #2196f3;
      font-size: 2rem;
      border-top: 2px solid;
      margin: 0;
      font-family: 'Roboto';
    }

    p.initial {
      color: #393939;
      font-family: inherit;
      margin: 10px;
      border-top:none;
      padding-top:0px;
    }
  }
}

.mobile-toggle-map {
  display: none;
}

#about-us {
  background-color: rgb(15, 15, 15);

  .breadcrumb {
    color: rgb(221, 221, 221);
  }
}

.mid-inner {
  &.applicator {
    .breadcrumb {
      padding: 10px 30px;
    }

    .map-how-to {
      text-align: right;
      padding-right: 35px;

      .btn-orange {
        background: transparent;
        border-radius: 0;
        border: 1px solid #f69422;
        border-bottom-width: 6px;
        color: #f69422 !important;
        padding: 5px 20px;

        &:hover {
          background: #f69422;
          color: #fff !important;
        }
      }
    }
  }
}

.noURL {
  opacity: .3;
  pointer-events: none;
  cursor: default;

  &:hover span {
    color: $darkenGrey !important;
  }

  &:hover {
    color: $darkBlack !important;
  }
}

.wrap-flex {
  @include flexbox();
}

// SVG Checkmark ============================================*/
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $green;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px $color--green;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $green;
  }
}

.msgblock {
  float: left;
  width: 100%;
  clear: both;

  &.invalidCredentials {
    margin-left: -55px;
    width: calc(100% + 110px);
    padding: 15px 55px;
    background: #f69521;
    border-radius: 0;

    i {
      float: left;
      font-size: 40px;
      width: 40px;
    }

    span {
      float: left;
      width: calc(100% - 40px);
    }
  }
}

.has-error .form-control {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

.has-success .form-control {
  border-color: #3c763d !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

// Creating Combos
.creation-dropdown {
  float: left;
  width: 350px;
  margin: 35px 0 15px 0;
  position: relative;

  > ul, > div {
    list-style: none;
    margin: 0;
    padding: 10px;
    font-size: 2rem;
    font-weight: 500;
    background: $lightenGrey;
    @include box-shadow(0 2px 5px rgba($black, .5));
    border-radius: 3px 3px 3px 3px;

    li {
      padding: 10px;
      cursor: pointer;
      color: $darkenGrey;
      @include transition(all .3s ease);

      &:last-child {
        border-top: 1px solid $borderColor;
        margin-top: 15px;
        color: $darkBlack;
      }

      span {
        float: left;
        margin: 5px 10px 0 0;
      }

      span.icon-creation {
        width: 23px;
        height: 18px;
        background: url($imgDir + 'all-creations.png') left top no-repeat;
      }

      span.icon-theme {
        width: 23px;
        height: 23px;
        background: url($imgDir + 'popular-themes.png') left top no-repeat;
      }

      span.icon-blends {
        width: 23px;
        height: 20px;
        background: url($imgDir + 'popular-blends.png') left top no-repeat;
      }

      span.icon-time {
        width: 23px;
        height: 14px;
        background: url($imgDir + 'check.png') left top no-repeat;
        margin-top: 7px;
      }

      &:hover {
        color: $darkBlack;

        span.icon-creation {
          background: url($imgDir + 'all-creations.png') left -18px no-repeat;
        }

        span.icon-theme {
          background: url($imgDir + 'popular-themes.png') left -26px no-repeat;
        }

        span.icon-blends {
          background: url($imgDir + 'popular-blends.png') left -20px no-repeat;
        }
      }
    }
  }

  > ul {
    display: none;
    position: absolute;
    z-index: 11;
    width: 100%;
  }

  > div {
    cursor: pointer;
    padding: 12px 20px 10px 20px;

    span {
      float: left;
      margin: 5px 10px 0 0;
    }

    span.icon-creation {
      width: 23px;
      height: 18px;
      background: url($imgDir + 'all-creations.png') left -18px no-repeat;
    }
  }

  &:hover > ul {
    display: block;
    border-radius: 0 0 5px 5px;
  }
}

// Cart Section Styling
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Firefox */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Webkit */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* IE */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Opera */
.cart-checkout {
  @include flexbox();

  .col-md-9 {
    h2 {
      padding-top: 40px;
      clear: both;
    }

    > div:first-child h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .selected-address {
    margin-left: 0;
    margin-right: 0;
    position: relative;

    .cvv {
      display: inline;
      width: 60px;
      margin-left: 10px;
    }
  }

  .address-toggle {
    position: absolute;
    right: -5px;
    bottom: 5px;
    text-align: right;
    cursor: pointer;
    color: $linkColor;

    &:hover {
      color: $linkColorHover;
    }

    i {
      color: #cccccc;
      font-size: 2.5rem;
      vertical-align: sub;
    }
  }

  .same-as-shipping {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .shipping-terms {
    font-size: 1.3rem;
  }

  .phone-number {
    width: 252px;
    margin-top: 7px;
  }

  .account-info-box {
    padding-bottom: 18px;

    input[type="email"].form-control,
    input[type="text"].form-control,
    select.form-control {
      background: #fff;
    }
  }

  .account-info-group {
    margin: 10px 0;
  }

  .signup-company-field {
    height: 38px !important;
  }

  .checkbox-label {
    font-weight: normal;
  }

  .filtering-addresses {
    text-align: center;
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 15px;
  }

}

.req-text {
  font-size: 1.6rem;
}

.opa {
  opacity: 0.3;
}

.opa-checkout {
  opacity: 0.6;
}

.loading-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: #000;
  overflow-x: hidden;

  span {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #fff;
    display: block;
    position: fixed;
    z-index: 1000;
    width: 100%;
    text-align: center;
    top: calc(50% - 60px);

    i {
      font-size: 50px;
    }
  }
}

.radio-button {
  background-color: $radioDefault;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
  padding: 3px 3px 2px 3px;
  color: $radioDefault;
}

.checkout-check {
  color: $successColor;
}

.checkout-times,
.error-text {
  color: $errorColor;
}

.block-comment-payment {
  padding-top: 40px;
  clear: both;
}

.checkout-main {
  background-color:#fff;

  .shadow-box {
    background: #f9f9f9;
  }

  h2 {
    border-bottom: 1px solid #222222;
  }
}

.cart-sidebar {
  padding: 0px;
}

.order-container {
  width: 50%;
  margin: 0 auto;
}

.submitOrder {
  width: 100%;
}

.wrap-cart {
  margin: 20px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  .cart-items, .cart-summary {
    position: sticky;
    top: 155px;
  }

  .blink {
    padding-top: 10px;
    font-size: 1.6rem;
    color: #323232;
    font-weight: 500;
    -moz-transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    animation: blink normal 1s infinite ease-in-out;
  }

  .cart-content, .cart-sidebar {
    padding: 20px;
  }

  .cart-sidebar {
    .subtotal-price {
      background: $darkBlack;
      color: $white;
      text-align: center;
      padding: 12px 15px;
      margin: 12px 0;
    }

    .no-items {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 5px;
      position: absolute;
    }

    .styled-select {
      float: none;
      display: block;
    }

    .form-wrap {
      margin-top: 10px;

      label {
        font-weight: normal;
      }
    }

    .get-estimate {
      margin: 10px 0;
      text-align: center;
      display: block;

      .col-xs-6 {
        font-size: 1.6rem;
        font-weight: bold;
        padding-bottom: 10px;

        > span {
          font-size: 1.4rem;
          display: block;
          position: relative;

          > p {
            position: absolute;
            margin: 0;
            bottom: 0;
            display: block;
            width: 100%;
          }
        }

        &:first-child {
          padding-right: 3px;
        }

        &:last-child {
          padding-left: 3px;
        }
      }

      .col-xs-12 {
        font-size: 1.6rem;
        font-weight: bold;
        padding-bottom: 10px;

        > span {
          font-size: 1.4rem;
          display: block;
          position: relative;

          > p {
            position: absolute;
            margin: 0;
            bottom: 0;
            display: block;
            width: 100%;
          }
        }
      }

      .estimate-info {
        @include flexbox();
        margin-bottom: 15px;

        .shipping-option {
          width: 50%;
          float: left;
          text-align: left;
          color: $darkBlack;
          font-size: 1.3rem;
          font-weight: 500;

          span {
            font-weight: 600;
          }
        }
        .shipping-price {
          float: right;
          width: 50%;
          text-align: right;
          font-size: 1.3rem;
          font-weight: 500;
        }
      }
    }
  }

  .cart-content {
    padding-top: 25px;

    > .col-sm-6 {
      padding-right: 60px;

      p {
        margin-bottom: 5px;
      }
    }

    > .col-sm-6 + .col-sm-6 {
      border-left: 1px solid $borderColor;
      padding: 0 15px 0 60px;

      .form-group + .form-group {
        margin-top: 35px;
      }
    }

    h4 {
      margin-bottom: 20px;
    }

    label {
      font-weight: 500;
      margin: 0;
    }

    .cart-content.shipping-address {
      padding: 25px 0 0;
    }

    .remove-cart-item {
      width: 20px;
      height: 20px;
      display: block;
      border: 1px solid $lightenBlack;
      border-radius: 50%;
      text-align: center;
      padding: 0;

      span {
        line-height: 17px;
        vertical-align: text-top;
        color: $lightenBlack;
      }

      &:hover {
        border-color: $errorColor;

        span {
          color: $errorColor;
        }
      }
    }
  }

  .cart-content.checkout {
    @include flexbox();
  }

  .cart-content.shipping-address {
    h2 {
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid $borderColor;
    }

    h2 + .row {
      margin-bottom: 50px;
    }
  }

  .shipping-title {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .checkout-sidebar {
    //Checkout Sidebar Styling
    .top-rounded {
      border-radius: 4px 4px 0 0;
    }
    .order-section {
      padding: 10px;

      p {
        margin: 5px 0;
      }

      h5 {
        margin: 0 0 10px 0;
      }

      .order-label {
        font-size: 1.5rem;
        font-weight: 600;
        display: inline-block;
        width: 45%;
      }

      .amount {
        font-size: 1.5rem;
        font-weight: 600;
        float: right;
      }

      .total {
        color: #f69422;
        font-weight: 700;
        margin-top: 5px;
      }
    }
  }

    .order-note {
      font-size: 12px;
      font-style: italic;
      padding-left: 8px;
    }

    h6 {
      font-style: italic;
      background-color: #3a3a39;
      padding: 10px;
      font-size: 14px;
      color: #fff;
    }

    .shipping-disclaimer {
      font-size: 1.2rem;
    }

    .order-summary-promo-separator {
      margin-top: 12px;
      margin-bottom: 10px;
    }

    .side-summary {
      font-size: 1.2rem;
      margin-bottom: 20px;

      div:first-child {
        font-weight: bold;
      }
    }

  .wrap-subtotal {
    position: relative;
    margin: 0 0 35px 0;
  }
}

.block-terms {
  position: relative;
  color: $orange;
  padding-bottom: 10px;

  .info-terms {
    font-size: 1.2rem;
    padding: 7px 13px;
    border-radius: 4px;
    max-height: 125px;
    overflow-y: auto;
    background: $darkBlack;
    color: $white;
    @include box-shadow(0 0 5px rgba($black, .3));
    position: absolute;
    margin-top: 5px;
    z-index: 10;
    display: none;
  }

  .link-terms {
    cursor: pointer;
    position: relative;

    span {
      font-size: 1.2rem;
      color: $darkBlack;
    }
  }

  &:hover .info-terms {
    display: block;
  }

  &:before, &:after {
    bottom: 0px;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    display: none;
  }

  &:before {
    border-color: rgba($darkBlack, 0);
    border-bottom-color: $darkBlack;
    border-width: 10px;
    margin-left: -10px;
  }

  &:hover:before, &:hover:after {
    display: block;
  }
}

.help-text {
  text-align: left;
  font-style: normal;

  .info-terms {
    min-width: 250px;
    left: -148px;
    line-height: 1.6;
    margin-top: -3px;
  }

  &:before {
    margin-left: 0;
  }
}

.shipping-options {

  &.col-xs-12,
  .col-xs-12,
  .col-sm-12 {
    padding: 0;

    hr {
      border-color: #d8d8d8;
    }
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    padding: 15px 0 0 0;
    margin: 0;
  }

  .best-value {
    color: #f99839;
  }
}


.method-wrap {
  margin-bottom: 30px;
  @include flexbox();

  .method-box {
    width: 150px;
    text-align: center;
    border: 1px solid $borderColor;
    margin: 0 10px 10px 0;
    cursor: pointer;

    .method-info {
      padding: 20px 0px 0px 0px;
      font-weight: bold;
      width: 100%;

      strong {
        display: block;
        font-size: 3rem;
      }
    }

    .use-info {
      text-transform: uppercase;
      font-size: 1.4rem;
      background: $darkenGrey;
      color: $white;
      padding: 8px 6px 7px 6px;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .use-info.selected {
      text-transform: uppercase;
      background: $orange;
      color: $white;
      font-size: 1.4rem;
      width: 100%;
      display: none;
    }

    &:first-child {
      @include flexbox();
      // align-items: center;
      justify-content: center;
      cursor: default;
    }

    &.method-box-click {
      cursor: pointer !important;
      height: 161px;
      position: relative;
    }

    &.swatch-box {
      cursor: pointer !important;
      height: 161px;
      position: relative;
    }
  }

  .swatch-box {
    &:first-child {
      display: block !important;
    }
  }
}

.method-box.method-orange {
  span {
    font-size: 1.3rem;
    font-weight: normal;
  }

  border: 1px solid $orange;

  .use-info {
    display: none;
  }

  .use-info.selected {
    display: block;
    padding: 8px 6px 7px 6px;
  }
}

.table-responsive {
  margin-bottom: 35px;

  .main-table.table {
    width: 100%;
    margin: 0;
    @include box-shadow(0 1px 0 rgba($black, .1));

    th {
      font-size: 1.6rem;
      background: $darkBlack;
      color: $white;
      font-weight: normal;
      border-right: 1px solid $lightenGrey;
      vertical-align: middle;
    }

    th, td {
      padding: 10px;
      text-align: center;
      display: table-cell;

      img {
        max-width: 50px;
      }

      input[type="text"] {
        max-width: 60px;
        display: inline-block;
        background: $white;
        margin-right: 5px;
        text-align: center;
      }

      &:first-child {
        text-align: left;
      }

      &:last-child {
        border-right: 0;
      }
    }

    td {
      border-right: 1px solid $white !important;
      border-top: 1px solid $white !important;
      vertical-align: middle;

      &:nth-of-type(2) {
        border-right: 0 !important;
      }

      &:nth-of-type(3) {
        text-align: left;
        padding-left: 0;
      }

      a {
        color: $errorColor;
        font-size: 1.6rem;

        &.productURL {
          color: $lightBlack;

          &:hover {
            color: $linkColorHover
          }
        }
      }

      .stock {
        color: $orange;
        font-size: 1.3rem;
      }

      .stock-out {
        @extend .stock;
        color: $errorColor;
      }

      .kitItem {
        font-size: 1.1rem;
        font-style: italic;
        padding-left: 9px;
        display: block;
      }

    }

    tr:nth-child(odd) {
      background: $lightGrey !important;
    }

    tr:nth-child(even) {
      background: $lightenGrey !important;
    }
  }
}

.main-table.table.table-common {
  @include box-shadow(0 0 1px rgba($black, .1));

  td {
    padding: 15px;

    &:first-child {
      border-right: 1px solid $white !important;
      width: 225px;
    }

    &:nth-of-type(2) {
      text-align: left;
      padding-left: 15px;
    }

    a {
      color: $blue;
    }
  }
}

.add-product {
  max-width: 400px;
}

.add-coupon {
  max-width: 400px;
}

/*-- Collection Pages -- */
.collections-bottom {
  padding: 40px 0;
}

.collections {
  background-color: #fff;
  padding: 15px 15px 0 15px;

  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;

    .collection-container {
      margin: 5px;
      flex-grow: 1;
      flex-basis: 48%;
      border: 1px solid #e6e6e6;
      padding: 15px;

      .image-container {
        overflow: hidden;

        img {
          width: 100%;
          transition: transform .2s;
        }
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

/* -- Common Content Pages styling -- */
.content-page {
  .breadcrumb {
    padding-left: 50px;
  }

  h1 {
    text-transform: none;
    color: $darkBlack;
    font-weight: normal;

    sup {
      top: -0.8em;
      font-size: 50%;
    }

    strong {
      font-weight: 600;
    }
  }

  div.col-sm-12 {
    background: $white;
    padding: 30px 50px;
    float: left;
    width: 100%;
  }

  p {
    margin: 6px 0 25px 0;
    color: $lightenBlack;
    font-weight: 300;
    line-height: 1.8;
    font-size: 1.6rem;
    @include fullwidth();

    span {
      color: $darkBlack;
    }

    a {
      color: $orange !important;

      &:hover {
        color: rgba($orange, .7) !important;
      }

      strong {
        font-weight: 600;
      }
    }
  }

  h2 {
    margin: 15px 0 0 0;
    @include fullwidth();
  }
}

.wrap-testimonials {
  .breadcrumb {
    padding-left: 0;
  }

  h1 {
    padding-left: 0;

    sup {
      top: -0.8em;
      font-size: 50%;
    }
  }

  .testimonial-box {
    @include fullwidth();
    background: $white;
    padding: 15px 20px 10px 20px;
    margin-bottom: 30px;
    border-radius: 6px;

    .blockquote {
      display: inline-block;
      font-size: 1.25rem;
      border-left: 0.5rem solid #3E6B85;
      color: #6c757d;
      font-style: italic;
      line-height: 1.6;
      padding-top: 4.6875rem;
      position: relative;

      &:before {
        font-size: 6.875rem;
        color: #3E6B85;
        content: "\201C";
        font-family: "Noto Serif", "Georgia", "Times New Roman", "Times", serif;
        font-size: 5.5rem;
        height: 3.75rem;
        left: 0.9375rem;
        line-height: 1;
        max-width: 3.75rem;
        position: absolute;
        text-align: inherit;
        top: 0.9375rem;
        width: 100%;
      }

      &:after {
        content: "";
      }

      .blockquote-footer {
        font-size: inherit;
        color: #343a40;
        display: flex;
        background: $white;
        font-style: normal;
        padding: 0;
        text-align: left;

        &:before {
          content: "— ";
        }

        a {
          display: contents;
        }
      }

      p {
        margin: 0 0 12px 0;
      }
    }
  }
}

// Distributor Pages Styling
.distributor-top {
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  background: $orange;
  color: $white;
  font-weight: bold;
  text-shadow: 0 0 1px rgba($black, .5);
  @include box-shadow(0 1px 1px rgba($black, .6));
  margin-bottom: 15px;
  font-size: 36px;
}

.distributor-content {
  h2 {
    text-align: center;
    background: #ddd;
    margin: 5px -10px 0 5px;
    font-family: "league_gothicregular", sans-serif;
    font-size: 3.2rem;
    border-radius: 0;
    font-weight: normal;
    letter-spacing: .5px;
    padding: 16px 15px 12px 15px;
    color: $lightenBlack;
    font-size: 33px;
    text-transform: none;
  }

  .distributor-box {
    @include fullwidth();
    min-height: 165px;
    padding: 20px 10px;

    h3 {
      font-family: "league_gothicregular", sans-serif;
      font-size: 3.2rem;
      border-radius: 0;
      font-weight: normal;
      letter-spacing: .5px;
      color: $lightenBlack;
      font-size: 33px;
      margin-top: 25px;
    }

    hr {
      margin: 7px 0 10px;
    }

    .col-sm-4 {
      text-align: center;

      img {
        width: 100%;
        max-width: 150px;
      }
    }

    .payment-processed {
      padding: 0;
    }
  }

  .col-md-6 {
    padding-bottom: 45px;
    float: left;
  }

  .col-md-6 + .col-md-6 {
    .distributor-box {
      padding-left: 0;
    }

    h2 {
      background: #ddd;
      margin: 5px 5px 0 -10px;
      border-radius: 0;
      font-size: 33px;
      color: $lightenBlack;
      text-transform: none;
    }
  }
}

.form-question {
  display: none;
  margin-top: 25px;

  textarea, input[type="text"], input[type="tel"] {
    background: $lighterGrey;
  }

  label {
    margin-right: 35px;
  }

  .group-btn {

    .btn {
      text-transform: none;
    }

    #btnCancel {
      background: transparent;
      color: $lightBlack;

      &:hover {
        color: $darkBlack;
      }
    }
  }
}

/* -- Token Find Applicator -- */
.token-wrap {
  padding: 30px 20px 0 20px;
  margin: 20px 0;
  background: $white;

  .token-applicator {
    width: 275px;
    float: left;
    border: 1px solid $borderColor;
    padding: 5px;
    position: relative;
    margin: 0 35px 30px 0;
    min-height: 82px;

    img {
      height: 70px;
      float: right;
      margin-left: 10px;
      max-width: 125px;
    }

    span {
      padding: 5px 10px 5px 5px;
      display: inherit;
    }

    .delete-item {
      top: -12px;
      right: -13px;
    }

    a {
      display: block;
      color: $darkBlack;
      min-height: 68px;

      &:hover i {
        color: $linkColorHover;
      }
    }

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }

  .add-token {
    i {
      float: left;
      color: $darkGrey;
      font-size: 6.5rem;
      font-weight: 300;
      width: 90px;
      line-height: 100%;
      text-align: center;
      transition: all .3s ease;
    }
  }

  .heading-applicator {
    margin-top: 10px;
  }
}

.heading-applicator {
  width: 190px;
  float: left;
  margin: 0;
  line-height: 1.3;

  span {
    font-size: 4.5rem;
    float: left;
    margin-right: 15px;
    font-family: "league_gothicregular", sans-serif;
    line-height: 100%;
    font-weight: normal;
  }
}

#vue-like-project {
  .heading-applicator {
    width: 100%;
  }
}

.sticky-wrap {
  background: $white;
  @include box-shadow(0 -2px 4px rgba($black, .2));
  padding: 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .btn {
    position: absolute;
    left: 50%;
    padding: 13px 75px;
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: -150px;
  }
}

#vue-like-project {
  .sticky-wrap {
    height: 0;
    padding: 0;
    transition: all .3s ease;

    &.showStep3 {
      padding: 30px;
      height: inherit;
    }
  }
}

.img-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
  padding: 10px;
  width: 160px;
  height: 160px;
  float: left;
  position: relative;
  overflow: hidden;
  margin: 0 15px 15px 0;

  .delete-image {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
    border: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    box-sizing: border-box;
    padding: 2px 0 0 1px;
    color: #fff;
    background: red;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

/* -- Order History -- */
.latest-purchased {
  padding: 15px 25px;
  background: $white;

  .row {
    display: flex;
    margin: 0;
  }
  .order-history-details {
    display: inline-block;
  }
  .order-history-details-btn {
    float: right;
  }

  hr {
    margin: 10px 0;
  }

  .order-history-container {
    display: flex;
    padding-right: 20px;
    color: #222222;

    .order-history-product-image {
      float: left;
      padding-right: 20px;

      img {
        width: 75px;
        height: auto;
      }
    }

    .order-history-product-arrow {
      margin: auto;
      float: right;
      margin-right: 0;
    }
  }
}

/* -- Search box updated style -- */
.search-box {
  .select2-container {
    width: calc(100% - 75px) !important;
    min-height: 50px;
    float: left;

    > .selection {
      width: 100%;
      height: 100%;
      float: left;
    }
  }

  .select2-dropdown {
    border: 1px solid $darkGrey;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
    position: relative;
    margin-top: 8px;

    &:before, &:after {
      bottom: 100%;
      left: 5%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ccc;
      border-width: 10px;
      margin-left: -10px;
    }

    &:after {
      border-color: rgba(202, 202, 202, 0);
      border-bottom-color: #fff;
      border-width: 9px;
      margin-left: -9px;
    }
  }

  .select2-container--default.select2-container--focus .select2-selection--multiple,
  .select2-container--default .select2-selection--multiple {
    border: 1px solid $darkGrey;
    background: $white;
    float: left;
    width: 100%;
    min-height: 50px;
    padding: 0 0 0 20px;
    font-size: 1.8rem;
    outline: none;
    border-radius: 25px 0 0 25px !important;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 6px 0;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 0;
    float: right;
    margin-left: 8px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 3px 10px 3px 12px;
    font-size: 1.3rem;
    border-radius: 15px;
  }

  .select2-results__option {
    padding: 0;
    background: transparent !important;
  }

  .select2-results__option.select2-results__message,
  .select2-results__option.loading-results {
    padding: 10px 10px 10px 20px;
    color: $orange;
    text-align: left;
  }

  .select2-results__option > span {
    padding: 7px 6px 7px 32px;
    display: block;
    text-align: left;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eee !important;
    color: $darkBlack;
  }

  .select2-results__option > span.applicator {
    background: url($imgDir + 'applicator.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.location {
    background: url($imgDir + 'location.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'location_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.bookmarked {
    background: url($imgDir + 'location.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'location_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.hashtag {
    background: url($imgDir + 'hash.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'hash_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.color_category {
    background: url($imgDir + 'color.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'color_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.personal {
    background: url($imgDir + 'applicator.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-results__option > span.owned {
    background: url($imgDir + 'applicator.svg') 15px center no-repeat;
    background-size: 13px auto;

    &:hover {
      background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
      background-size: 13px auto;
      color: $white !important;
    }
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.applicator {
    background: url($imgDir + 'applicator.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 14px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.location {
    background: url($imgDir + 'location.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 12px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.bookmarked {
    background: url($imgDir + 'location.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 12px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.hashtag {
    background: url($imgDir + 'hash.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 12px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.color_category {
    background: url($imgDir + 'color.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 12px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.personal {
    background: url($imgDir + 'applicator.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 14px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice span.owned {
    background: url($imgDir + 'applicator.svg') 0 center no-repeat;
    background-size: 10px auto;
    padding-left: 14px;
  }

}


/* -- Updated Global Search Last -- */
.search-box {
  position: relative;

  &.gallery-search .global-search > .tokchi-token {
    font-size: 1.3rem;
    background: rgba(0, 0, 0, .1);
    padding: 5px 12px;
    border-radius: 15px;
    margin: 0 5px 5px 0;

    > div {
      border-bottom: none;
    }
  }

  .global-search {
    border: 1px solid #cccccc;
    background: white;
    float: left;
    width: calc(100% - 75px);
    min-height: 51px;
    height: 100%;
    padding: 11px 15px 0 20px;
    font-size: 1.6rem;
    outline: none;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    text-align: left;
    position: relative;

    > .tokchi-token {
      display: inline-block;

      &.hide-token {
        display: none;
      }

      &.missing-result > div {
        border: none;
        text-decoration: line-through;
      }

      &:last-child div {
        margin-right: 5px;
      }

      div.applicator {
        background: url($imgDir + 'applicator.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 14px;
      }

      > div {
        display: inline-block;
        border-bottom: 1px solid #d8d8d8;

        > span {
          cursor: pointer;
          display: inline-block;
          margin-left: 5px;
          color: $orange;
        }
      }

      > div.location {
        background: url($imgDir + 'location.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 12px;
      }

      > div.bookmarked {
        background: url($imgDir + 'location.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 12px;
      }

      > div.hashtag {
        background: url($imgDir + 'hash.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 12px;
      }

      > div.color_category {
        background: url($imgDir + 'color.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 12px;
      }

      > div.personal {
        background: url($imgDir + 'applicator.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 14px;
      }

      > div.owned {
        background: url($imgDir + 'applicator.svg') 0 center no-repeat;
        background-size: 10px auto;
        padding-left: 14px;
      }
    }

  }

  ul.tokchi-dropdown {
    border: 1px solid $darkGrey;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    margin-top: 8px;
    list-style: none;
    width: 100%;
    left: 0 !important;
    // 	    top: 53px !important;
    background: white;
    z-index: 11;

    // .tokchi-dropdown-item {
    //   text-transform: lowercase;
    // }

    &:before, &:after {
      bottom: 100%;
      left: 5%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ccc;
      border-width: 10px;
      margin-left: -10px;
    }

    &:after {
      border-color: rgba(202, 202, 202, 0);
      border-bottom-color: #fff;
      border-width: 9px;
      margin-left: -9px;
    }

    > li {
      padding: 7px 6px 7px 41px;

      &.color-match {
        padding-left: 10px;
      }

      display: block;
      text-align: left;
      cursor: pointer;

      span {
        display: block;
        font-size: 1.1rem;
        opacity: .6;
      }
    }

    .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background-color: #eee !important;
      color: $darkBlack;
    }

    > li.applicator {
      background: url($imgDir + 'applicator.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.location {
      background: url($imgDir + 'location.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'location_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.bookmarked {
      background: url($imgDir + 'location.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'location_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.hashtag {
      background: url($imgDir + 'hash.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'hash_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.hashtag.color-match {
      background: none;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.color_category {
      background: url($imgDir + 'color.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'color_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.personal {
      background: url($imgDir + 'applicator.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }

    > li.owned {
      background: url($imgDir + 'applicator.svg') 15px center no-repeat;
      background-size: 13px auto;

      &.tokchi-dropdown-item-selected {
        background: $orange url($imgDir + 'applicator_white.svg') 15px center no-repeat;
        background-size: 13px auto;
        color: $white !important;
      }
    }
  }
}

/* -- Gallery Upload Keyword Search -- */
.select2-dropdown {
  li.select2-results__option {
    div {
      padding: 2px 6px 2px 35px;
      display: block;
      text-align: left;
      cursor: pointer;

      &.hashtag {
        background: url($imgDir + 'hash.svg') 15px center no-repeat;
        background-size: 13px auto;
      }

      span {
        display: inline-block;
        font-size: 1.1rem;
        opacity: .6;
      }
    }

    &.select2-results__option--highlighted {
      div.hashtag {
        background: url($imgDir + 'hash_white.svg') 15px center no-repeat;
        background-size: 13px auto;
      }
    }
  }
}

/* -- Gallery Filter Top Searches CSS -- */
.wrap-top-searches {
  margin-top: 15px;
  text-align: center;
  float: left;
  width: 100%;
  display: none;

  a {
    color: $white;
    display: inline-block;
    margin: 5px 2px;
    padding: 5px 12px;
    background: $lightBlack;
    font-size: 1.3rem;
    border-radius: 20px;
  }

  &.show {
    display: block
  }
}

.radius-wrap {
  float: left;
  display: none;
}

.searchable {
  .dropdown-menu {
    li a:hover {
      color: $white !important;
    }
  }
}

.quote-modal {
  .modal-body, .modal-footer {
    @include fullwidth();
  }

  ul.attached-projects {
    margin-left: 18px;
  }
}

.countdown-wrapper + .distributor-content,
.countdown-wrapper + .distributor-top {
  margin-top: 30px;
  border-radius: 0
}

.distributor-details {
  padding: 35px 35px 15px 35px;
}

[v-cloak] > * {
  display: none
}

[v-cloak]::before {
  content: "loading..."
}
