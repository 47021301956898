// ==========================================================
// 320 and Up by Phillip
// ==========================================================

// Home Banner (Animate objects on mouse move) ======================================== */

.banner {
  padding: 0;
  background: #eee;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  .item {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.spinner {
        height: 500px;
        object-fit: contain;
        background-color: #fff;
      }
    }
    .carousel-caption {
      right: inherit;
      left: inherit;
      text-align: left;
      width: inherit;
      text-shadow: none;
      top: 0;
      bottom: 0;
      padding: 0;
      @include flexbox();
      -webkit-align-items: center;
      align-items: center;
      .caption-inner {
        width: 47%;
        padding: 15px;
        background-color: rgba(0,0,0, .75);

        &.center {
          text-align:center !important;
          width:100%;
          padding: 15px 0;
        }
        .mobile-captionBtn {display:none}
        .promo-item-title {
          font-size: 1.8rem;
        }
      }
      h1 {
        color: $white;
        font-weight: 300;
        line-height: 1.3;
        margin: 0 0 15px 0;
      }
      h2 {
        color: white;
          font-weight: 300;
          line-height: 1.3;
          margin: 0 0 15px 0;
          font-size: 36px;
      }
      p {
        font-weight: 300;
        padding-right: 5%;
      }
      &.center {
        p {padding-right:0}
      }
      .btn-bordered {
        border: 2px solid $white;
        color: $white;
        text-transform: uppercase;
        font-weight: 300;
        margin: 5px 0 0 0;
        font-size: 1.3rem;
        letter-spacing: 1px;
        padding: 12px 30px 11px 30px;
        &:hover {
          background: $white;
          color: $darkenBlack !important;
        }
      }
    }
  }
  .carousel-indicators li {
    width: 14px;
    height: 14px;
    margin: 0 5px;
    border-radius: 50%;
    border: 1px solid rgba($white, .6);
    position: relative;
  }
  .carousel-indicators li.active {
    background: transparent;
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      background: rgba($white, .6);
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
  .carousel-control {
    background: transparent;
    width: 5%;
    display: none;
  }
  .carousel-indicators {
    left: 0;
    bottom: 0;
    padding: 10px;
    margin: 0;
    width: 100%;
    z-index: 5;
    background-color: #00000075;
  }
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
  visibility: hidden;
}

.prx {
  position: absolute;
  color: #FFF;
  font-size:1.5em;
  overflow: hidden
}

// Account Page Accordion =================================================== */
.wrap-accordion {
  @include fullwidth();
  clear: both;
  margin-top: 10px;
  h5.accordion-label {
    color: $orange;
    font-size: 1.6rem;
    float: left;
    width: 100px;
    margin-top: 30px;
  }
  .shadow-box > input[type="radio"] {
    float: left;
    box-shadow: none;
    border: 0;
    opacity: 0;
    position: absolute;
    height: 45px;
    width: 85%;
    cursor: pointer;
    z-index: 50;
    &:checked + .accordion-head {
      border: 2px solid #f99839;
      min-height: 45px;
    }
  }

  .shadow-box {
    > input[type="checkbox"] {
      float: left;
      box-shadow: none;
      border: 0;
      opacity: 0;
      position: absolute;
      height: 40px;
      width: 100%;
      cursor: pointer;
      z-index: 50;

    }

    .tall {
      height: 62px !important;
    }

    .x-tall {
      height:85px;
    }

    &.selected {
      .radio-button {
        color: $radioSelected;
        border-color: #1788e4;
      }
    }
  }
  .addressSearch {padding-bottom: 5px;}
}

.payment-add-form,
.address-form {
  &.selected {
    border: 2px solid #1788e4;
  }

  .has-error {
    border-color: #a94442 !important;
  }
}

.has-error-glow {
  box-shadow: 0 0 8px red !important;
  border-radius: 5px;
}

.save-to-account {
  margin-bottom: 15px !important;
  margin-right: 5px !important;
}

a.shadow-box {
  padding: 15px 25px;
  margin-top: 0;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
}

a.shadow-box + .row,
div.shadow-box + .row {
  margin: 0;
  > .col-sm-12 {
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 15px 25px;
    margin-top: 2px;

    .col-sm-12 {
      label {
        margin-bottom: 15px
      }
    }
  }
}

#choose-payment-option {
  + .card {
    .paymentOptionForm {
      .missing-options {
        border-radius: 4px;
        background: white;
        padding: 15px 25px;
      }
    }
  }
}

.accordion-in {
  float: left;
  width: 100%;
  .shadow-box {
    margin-bottom: 5px;
    font-size: 1.6rem;
    color: $darkenBlack;
    font-weight: 600;
    position: relative;
    @include fullwidth();
    .col-sm-12 {
      font-weight: 500;
      h3 { font-size: 1.6rem; }
      > div {padding-bottom:10px}
    }
  }
}

.accordion-head {
  position: relative;
  @include fullwidth();
  padding: 0px 25px 10px 0;
  div img, div span { float: left; }
  div img {
    margin-right: 10px;
    width:40px;
    margin-top:8px;
  }
  div span {
    margin-top: 10px;

    .rate-span {
      float: none;
      font-weight: 500;

      &.discounted {
        text-decoration: line-through;
        opacity: .6;
      }
    }

    &.btn-purple {
      margin: 10px auto;
      display: block;
      float: none;
      width: 250px;
    }

    .best-rate-span {
      float: none;
      font-weight: 700;
      background-color: transparent;
      text-align: left;
      color: $orange;
      text-transform: uppercase;
      font-size: 1.5rem;
      letter-spacing: 1px;

      &.discounted {
        color: #5bb75b;
      }
    }
  }
  .radio-select {
    font-size: 1.2rem;
    position: absolute;
    top: 3px;
    left: 5px;
    color: $darkGrey;
    width: 35px;
    height: 35px;
    text-align: center;
    padding-top: 10px;
    padding-right: 1px;
    > i.fa-angle-down { display: none; }
  }
  a > i.fa-angle-down {
    display: none;
  }
  a > i.fa-angle-up {
    display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: right;
      padding-right: 20px;
      padding-top: 10px;
      font-size: 20px;
      z-index: 9;
  }
  > a.collapsed > i.fa-angle-up { display: none; }
  a.collapsed > i.fa-angle-down {
    display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: right;
      padding-right: 20px;
      padding-top: 10px;
      font-size: 20px;
      z-index: 9;
  }
  &.cc-option {
    padding: 0px 25px 0px 0;
    min-height:40px;
  }
}

.cart-checkout {
  .accordion-head {
    padding-right: 0;
  }
}

#accordionShipping {
  .accordion-head { margin-bottom: 0; padding-bottom: 0;}
  div span { margin-bottom: 10px; }
}

.radio-container {
  width: 0;
}

.address-name {
  margin-right: 15px;
}

.address-line {
  font-weight: normal;
  white-space: pre;
}

// Swiper Slider Product Detail =================================================== */
#productCarousel {
  float: left;
  width: calc(62.8% - 110px);

  .carousel-inner {
    height: 100%;
    overflow: hidden;
  }

  .product-images {
    border-radius: 4px;
  }

  .item {
    text-align: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      display: inline-block;
    }

    img:first-child {
      object-fit: cover;
      min-height: 475px;
      max-height: 475px;
      height: 100%;
      width: 100%;
    }

    .item-in {
      width: 100%;
      height:100%;
      display: grid;

      .fa-search-minus, .fa-search-plus {
          color: $darkenBlack;
          font-size: 3rem;
          position: absolute;
          bottom: 25px;
          left: 25px;
          z-index: 999;
          font-weight: normal;

          span {
            font-family: "Open Sans", sans-serif;
            font-size: 1.6rem;
            text-transform: uppercase;
            vertical-align: middle;
            padding-left: 5px;
          }
        }

        .fa-search-minus { display: none; }
    }
  }
}

// Swiper Slider Gallery Detail =================================================== */
#creationCarousel {
  img:first-child {
    object-fit: contain !important;
  }
}

#galleryCarousel,
#creationCarousel {
  float: left;
  width: 68%;
  height: auto;

  .carousel-inner {
    height: auto;
    overflow: hidden;
    border-radius: 4px 2px 0 0;
  }

  .item {
    text-align: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      display: inline-block;
    }

    img:first-child {
      min-height: 575px;
      max-height: 575px;
      object-fit: cover;
    }

    .item-in {
      width: 100%;
      display: grid;
    }
  }
}

#slider-thumbs {
  margin-top: 10px;
  @include fullwidth();

  .product-media {
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  }

  ul {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 12px;
    justify-items: center;
  }

  .list-inline {
    margin: auto;
    width: auto;
  }

  li {
    padding: 5px;
    z-index: 1;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    @include box-shadow(0 0 3px rgba(0,0,0,.1));

    .overlay {
      position: absolute;
      top: 30%;
      color: #222;
      line-height: 30px;
      text-shadow: 0px 0px 8px #f99839;
      z-index: 3;
      left: 30%;
      font-size: 2.5rem;
    }

    img {
      z-index: 2;
      width: 65px;
      height: 65px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  li.selected {
    @include transition(all .1s ease);
    outline: 2px solid rgba(249,151,0,1);
  }
}

// Image Zoom on click ===================================== */
.zoom {
  display:inline-block;
  position: relative;
  &:after {
    content:'';
    display:block;
    width:33px;
    height:33px;
    position:absolute;
    top:0;
    right:0;
  }
  img {
    display: block;
  }
  img::selection { background-color: transparent; }
}