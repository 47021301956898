// Styles necessary for vue-instant (currently components/colormatch/Search.vue)

.sbx-facebook {
  display: inline-block;
  position: relative;
  width: 450px;
  height: 27px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 13px;
}
.sbx-facebook__wrapper {
  width: 100%;
  height: 100%;
}
.sbx-facebook__input {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0px #CCCCCC;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 62px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-facebook__input-placeholder {
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0px #CCCCCC;
  background: #FFFFFF;
  padding: 0;
  padding-right: 62px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-facebook__input::-webkit-search-decoration,
.sbx-facebook__input::-webkit-search-cancel-button,
.sbx-facebook__input::-webkit-search-results-button,
.sbx-facebook__input::-webkit-search-results-decoration {
  display: none;
}
.sbx-facebook__input:hover {
  box-shadow: inset 0 0 0 0px #b3b3b3;
}
.sbx-facebook__input:focus,
.sbx-facebook__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 0px #3E82F7;
  background: rgba(255, 255, 255, 0)
}
.sbx-facebook__input::-webkit-input-placeholder {
  color: #AAAAAA;
}
.sbx-facebook__input::-moz-placeholder {
  color: #AAAAAA;
}
.sbx-facebook__input:-ms-input-placeholder {
  color: #AAAAAA;
}
.sbx-facebook__input::placeholder {
  color: #AAAAAA;
}
.sbx-facebook__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #f6f7f8;
  padding: 0;
  width: 35px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sbx-facebook__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.sbx-facebook__submit:hover,
.sbx-facebook__submit:active {
  cursor: pointer;
}
.sbx-facebook__submit:focus {
  outline: 0;
}
.sbx-facebook__submit svg {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  fill: #3C5BA2;
}
.sbx-facebook__reset {
  display: none;
  position: absolute;
  top: 3px;
  right: 41px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}
.sbx-facebook__reset:focus {
  outline: 0;
}
.sbx-facebook__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}
.sbx-facebook__input:valid~.sbx-facebook__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}
@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}


/* amazon*/
.sbx-amazon {
  display: inline-block;
  position: relative;
  width: 600px;
  height: 39px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}
.sbx-amazon__wrapper {
  width: 100%;
  height: 100%;
}
.sbx-amazon__input {
  display: inline-block;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #FFFFFF;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 75px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-amazon__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #FFFFFF;
  background: #FFFFFF;
  padding: 0;
  padding-right: 75px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-amazon__input::-webkit-search-decoration,
.sbx-amazon__input::-webkit-search-cancel-button,
.sbx-amazon__input::-webkit-search-results-button,
.sbx-amazon__input::-webkit-search-results-decoration {
  display: none;
}
.sbx-amazon__input:hover {
  box-shadow: inset 0 0 0 1px #e6e6e6;
}
.sbx-amazon__input:focus,
.sbx-amazon__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #FFBF58;
  background: rgba(255, 255, 255, 0)
}
.sbx-amazon__input::-webkit-input-placeholder {
  color: #AAAAAA;
}
.sbx-amazon__input::-moz-placeholder {
  color: #AAAAAA;
}
.sbx-amazon__input:-ms-input-placeholder {
  color: #AAAAAA;
}
.sbx-amazon__input::placeholder {
  color: #AAAAAA;
}
.sbx-amazon__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #ffbf58;
  padding: 0;
  width: 47px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sbx-amazon__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.sbx-amazon__submit:hover,
.sbx-amazon__submit:active {
  cursor: pointer;
}
.sbx-amazon__submit:focus {
  outline: 0;
}
.sbx-amazon__submit svg {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  fill: #202F40;
}
.sbx-amazon__reset {
  display: none;
  position: absolute;
  top: 9px;
  right: 54px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}
.sbx-amazon__reset:focus {
  outline: 0;
}
.sbx-amazon__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}
.sbx-amazon__input:valid~.sbx-amazon__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}
@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}


/*  google */
.sbx-google {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 41px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}
.sbx-google__wrapper {
  width: 100%;
  height: 100%;
}
.sbx-google__input {
  display: inline-block;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #CCCCCC;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 77px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-google__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #CCCCCC;
  background: #FFFFFF;
  padding: 0;
  padding-right: 77px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-google__input::-webkit-search-decoration,
.sbx-google__input::-webkit-search-cancel-button,
.sbx-google__input::-webkit-search-results-button,
.sbx-google__input::-webkit-search-results-decoration {
  display: none;
}
.sbx-google__input:hover {
  box-shadow: inset 0 0 0 1px #b3b3b3;
}
.sbx-google__input:focus,
.sbx-google__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #3E82F7;
  background: rgba(255, 255, 255, 0)
}
.sbx-google__input::-webkit-input-placeholder {
  color: #AAAAAA;
}
.sbx-google__input::-moz-placeholder {
  color: #AAAAAA;
}
.sbx-google__input:-ms-input-placeholder {
  color: #AAAAAA;
}
.sbx-google__input::placeholder {
  color: #AAAAAA;
}
.sbx-google__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #3e82f7;
  padding: 0;
  width: 49px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sbx-google__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.sbx-google__submit:hover,
.sbx-google__submit:active {
  cursor: pointer;
}
.sbx-google__submit:focus {
  outline: 0;
}
.sbx-google__submit svg {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  fill: #FFFFFF;
}
.sbx-google__reset {
  display: none;
  position: absolute;
  top: 10px;
  right: 56px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}
.sbx-google__reset:focus {
  outline: 0;
}
.sbx-google__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}
.sbx-google__input:valid~.sbx-google__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}
@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}


/* twitter */
.sbx-twitter {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 33px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 12px;
}
.sbx-twitter__wrapper {
  width: 100%;
  height: 100%;
}
.sbx-twitter__input {
  display: inline-block;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 17px;
  box-shadow: inset 0 0 0 1px #E1E8ED;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 52px;
  padding-left: 16px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-twitter__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 17px;
  box-shadow: inset 0 0 0 1px #E1E8ED;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 52px;
  padding-left: 16px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-twitter__input::-webkit-search-decoration,
.sbx-twitter__input::-webkit-search-cancel-button,
.sbx-twitter__input::-webkit-search-results-button,
.sbx-twitter__input::-webkit-search-results-decoration {
  display: none;
}
.sbx-twitter__input:hover {
  box-shadow: inset 0 0 0 1px #c1d0da;
}
.sbx-twitter__input:focus,
.sbx-twitter__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #D6DEE3;
  background: rgba(255, 255, 255, 0)
}
.sbx-twitter__input::-webkit-input-placeholder {
  color: #9AAEB5;
}
.sbx-twitter__input::-moz-placeholder {
  color: #9AAEB5;
}
.sbx-twitter__input:-ms-input-placeholder {
  color: #9AAEB5;
}
.sbx-twitter__input::placeholder {
  color: #9AAEB5;
}
.sbx-twitter__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 16px 16px 0;
  background-color: rgba(62, 130, 247, 0);
  padding: 0;
  width: 33px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sbx-twitter__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.sbx-twitter__submit:hover,
.sbx-twitter__submit:active {
  cursor: pointer;
}
.sbx-twitter__submit:focus {
  outline: 0;
}
.sbx-twitter__submit svg {
  width: 13px;
  height: 13px;
  vertical-align: middle;
  fill: #657580;
}
.sbx-twitter__reset {
  display: none;
  position: absolute;
  top: 7px;
  right: 33px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}
.sbx-twitter__reset:focus {
  outline: 0;
}
.sbx-twitter__reset svg {
  display: block;
  margin: 4px;
  width: 11px;
  height: 11px;
}
.sbx-twitter__input:valid~.sbx-twitter__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}
@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}


/* spotify */
.sbx-spotify {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 25px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 12px;
}
.sbx-spotify__wrapper {
  width: 100%;
  height: 100%;
}
.sbx-spotify__input {
  display: inline-block;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 13px;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-spotify__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 0;
  border-radius: 13px;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: #FFFFFF;
  padding: 0;
  padding-right: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sbx-spotify__input::-webkit-search-decoration,
.sbx-spotify__input::-webkit-search-cancel-button,
.sbx-spotify__input::-webkit-search-results-button,
.sbx-spotify__input::-webkit-search-results-decoration {
  display: none;
}
.sbx-spotify__input:hover {
  box-shadow: inset 0 0 0 0px #e6e6e6;
}
.sbx-spotify__input:focus,
.sbx-spotify__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: rgba(255, 255, 255, 0)
}
.sbx-spotify__input::-webkit-input-placeholder {
  color: #AAAAAA;
}
.sbx-spotify__input::-moz-placeholder {
  color: #AAAAAA;
}
.sbx-spotify__input:-ms-input-placeholder {
  color: #AAAAAA;
}
.sbx-spotify__input::placeholder {
  color: #AAAAAA;
}
.sbx-spotify__submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 12px 0 0 12px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 25px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sbx-spotify__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.sbx-spotify__submit:hover,
.sbx-spotify__submit:active {
  cursor: pointer;
}
.sbx-spotify__submit:focus {
  outline: 0;
}
.sbx-spotify__submit svg {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  fill: #222222;
}
.sbx-spotify__reset {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}
.sbx-spotify__reset:focus {
  outline: 0;
}
.sbx-spotify__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}
.sbx-spotify__input:valid~.sbx-spotify__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}
@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.vue-instant__suggestions {
  position: absolute;
  left: 0;
  top: 110%;
  margin: 0;
  background-color: #fff;
  border: 1px solid #D3DCE6;
  width: 100%;
  padding: 6px 0;
  z-index: 10;
  border-radius: 2px;
  max-height: 280px;
  box-sizing: border-box;
  overflow: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .12);
  margin-top: 3px;
  text-align: left;
}
.vue-instant__suggestions li {
  list-style: none;
  line-height: 36px;
  padding: 0 10px;
  margin: 0;
  cursor: pointer;
  color: #475669;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vue-instant__suggestions li.highlighted__spotify {
  background-color: black;
  color: #fff;
}
.vue-instant__suggestions li.highlighted__twitter {
  background-color: #20a0ff;
  color: #fff;
}
.vue-instant__suggestions li.highlighted__google {
  background-color: #EEEEEE;
  color: black;
}
.vue-instant__suggestions li.highlighted__facebook {
  background-color: #3e5da0;
  color: #fff;
}
.vue-instant__suggestions li.highlighted__amazon {
  background-color: #232F3E;
  color: #fff;
}
.el-input-group__append {
  border: 0px !important;
}
.sbx-custom__input {
  position: absolute;
  left: 0 !important;
  background: rgba(255, 255, 255, 0) !important;
}
