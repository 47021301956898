// ==========================================================
// 320 and Up by Phillip Pradeep [ITH]
// ==========================================================

/* Mid Section CSS ========================================*/
.mid-section {
  padding: 20px 0;
  @include fullwidth();
}

.col {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  min-height: 1px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.styled-select {
  .form-control {
    padding-right: 42px;
  }
}

.product-wrap {
  @include fullwidth();
  clear: both;

  h2 {
    margin-bottom: 5px;
  }

  h2>a>i {
    font-size: 1.1rem;
    font-weight: 400;
  }
}

.flex-wrap {
  @include flexbox();
  //margin-right: -15px;

  &.col-xs-4 {
    //min-height: 200px;
    margin: 20px 0;
  }

  &.col-sm-4 {
    //min-height: 200px;
    margin: 20px 0;
  }

  &.col-sm-3 {
    //min-height: 250px;
    margin: 20px 0;
  }

  &.col-sm-5 {
    //min-height: 200px;
    margin: 20px 0;
  }

  &.col-sm-6 {
    //min-height: 250px;
    margin: 20px 0;
  }

  &.col-sm-9 {
    //min-height: 250px;
    margin: 20px 0;
  }

  .col-sm-4 {
    margin: 10px 0 18px 0;
  }

  .shadow-box {
    hr {
      margin: 10px 0;
    }
  }
}



%loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #d7d7d7;
  border-color: #d7d7d7;

  &::placeholder {
    color: transparent;
  }
}

@keyframes loading-skeleton {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

.loading-skeleton {
  pointer-events: none;
  width: 100%;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  .img-placeholder,
  label,
  img,
  span,
  .form-control {
    @extend %loading-skeleton;
  }
}

.loading-skeleton::before {
  background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      transparent);
  background: linear-gradient(90deg,
      transparent,
      var(--gradient-color),
      transparent);
  animation: loading-skeleton 1.5s linear .5s infinite;
}

#loader {
  display: block;
  width: 120px;
  height: 120px;
  padding: 0;
  background: white;
  border: solid 2px #F5A623;
  border-radius: 60px;
  font-size: 16px;
  color: #F5A623;
  line-height: 120px;
  outline: none;
  font-weight: bold;
  margin: 15px auto 0;
  transition: all 0.1s ease-out;
  text-align: center;

  span {
    opacity: 1;
    -webkit-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
  }
}

.home-no-results {
  color: gray;
  padding-left: 20px;
}

.shop-no-results {
  color: gray;
}

.gallery-no-results {
  color: gray;
}

.color-match-box {
  >.shopProduct {
    display: block;
    height: 100%;

    &.shopProduct {
      height: 100%;
    }

    i {
      margin-right: 10px;
    }

    h3,
    p {
      color: $darkBlack !important;
      height: auto;
      margin: 0;
      transition: opacity 0.3s ease-in, all .1s ease-out;
    }
  }

  >.creation {
    display: block;
    height: 100%;

    i {
      margin-right: 10px;
    }
  }
}

// No results or items found

.no-results,
.no-more {
  margin: 100px 0;
  display: inline-block;
  color: #ccc;

  i {
    font-size: 40px;
  }

  h4 {
    text-transform: unset;
    margin-bottom: 20px;
  }
}

// BEGIN: shop product styles

.shop-products {

  .collection-info {
    margin-top: 10px;
    background-color: $white;
    padding: 15px;
    display: inline-block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    color: $lightBlack;
  }
}

.product-cards {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: calc(1vmin);
}

.cards-center {
  justify-content: center;
}

.product-card {
  transition: ease-in-out .3s;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  border-radius: 4px;

  .product-btn {
    width: 100%;
  }

  .product-card-btn {
    position: absolute;
    padding: 16px;
    width: 100%;
    opacity: 0;
    top: 15%;
    transition: ease-in-out 0.3s;
    z-index: 2;

    a {
      font-size: 1.2rem;
      color: #3E6B85;
    }

    button {
      margin-top: 8px;
      font-size: 1.2rem;
    }
  }

  .img-effect {
    transition: ease-in-out 0.2s;
    margin: 0 auto;
    display: grid;
    max-height: 250px;
    height: 175px;
    width: 100%;
    object-fit: contain;
  }

  a {
    color: #222222;
  }

  &:hover {
    box-shadow: 0px 10px 8px -8px rgba(0, 0, 0, 0.1);

    .img-effect {
      transform: scale(1.05, 1.05);
      opacity: 0.3;
      z-index: 0;
    }

    .product-card-details {
      background-color: $white;
      position: relative;
    }

    .product-sku,
    .product-title,
    .product-price,
    .product-creation {
      color: #222222;
    }

    .product-card-btn {
      opacity: 1;
    }
  }
}

// card component styling

.product-card-details {
  z-index: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px 12px 12px 12px;
  transition: .3s ease-in-out;
  display: inline-block;
  width: 100%;

  .product-sku {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 600;
    float: left;
  }

  .product-title {
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    text-transform: uppercase;
    height: 34px;
    font-weight: 400;
    font-family: Oswald, 'Open Sans', Roboto;
    letter-spacing: 1px;
  }

  .product-price {
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #222222;
    float: right;
  }

  .product-creation {
    margin: 10px 0 0 0;
    padding: 3px;
    text-align: center;
    border: 2px solid #393939;
    display: inline-block;
    width: 100%;
  }

  h3 {
    font-size: 16px;
  }
}

// END: product card styles

.product-box {
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  width: 177px;
  position: relative;
  margin-right: 15px;
  display: flex;
  flex-direction: column;

  img {
    margin: 15px 0;
    max-width: 90%;
    max-height: 90px;
    width: auto;
  }

  h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: 1.4rem;
  }

  >div a:last-child {
    text-transform: uppercase;
    color: $lightBlack;
    display: block;
    padding: 10px 0 10px 0;
    font-weight: 600;
    cursor: pointer;
  }

  p {
    margin: 0 0 10px 0;
    font-size: 1.2rem;
  }

  >a {
    margin-bottom: 16px;
    display: block;
    height: 100%;

    &.shopProduct {
      flex: 1;
    }

    i {
      margin-right: 10px;
    }

    h3,
    p {
      color: $darkBlack !important;
      height: auto;
      margin: 0;
      transition: opacity 0.3s ease-in, all .1s ease-out;
    }

    .hoverShow {
      opacity: 0;
      height: 0;
      display: block;
      transition: all 0.5s ease-in .2s, all 0s ease-out;
    }

    &:hover {

      h3,
      p {
        color: $orange !important;
      }
    }
  }

  div:first-child {
    >a {
      display: block;
      height: 100%;

      i {
        margin-right: 10px;
      }

      h3,
      p {
        color: $darkBlack !important;
        margin: 0;
        height: auto;
        transition: opacity 0.3s ease-in, all .1s ease-out;
      }

      .hoverShow {
        opacity: 0;
        height: 0;
        display: block;
        transition: all 0.5s ease-in .2s, all 0s ease-out;
      }

      &:hover {

        h3,
        p {
          color: $orange !important;
          opacity: 0;
          height: 0;
          margin: 0;
        }

        .hoverShow {
          opacity: 1;
          height: 54px;
        }
      }
    }
  }
}

// Hardcoded Width for Alternatives section
.product-box.alternativesWidth {
  width: 177px;
}

#informationModal {
  ul {
    padding-left: 20px;
  }
}

#vue-gallery-details {
  .product-wrap {
    .product-box {

      .addSwatch {

        &:hover {
          color: $orange !important;
        }

        &.disabled {
          pointer-events: none;
          cursor: default;
          opacity: .5;
        }
      }
    }
  }
}

.delete-item {
  background: $red;
  color: $white;
  cursor: pointer;
  @include rounded(50%);
  width: 26px;
  height: 26px;
  text-align: center;
  position: absolute;
  top: -6px;
  right: -6px;
  border: 0;
  line-height: 100%;
  outline: none;

  &:before,
  &:after {
    width: 2px;
    height: 14px;
    background: $white;
    position: absolute;
    content: '';
    top: 6px;
    left: 12px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.shadow-box {
  background: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

  &.extra-padding {
    padding: 30px;
  }

  &.spacing-top {
    margin-top: 15px;
  }

  h6 {
    margin-top: 0;
  }
}

.orders {
  .row {
    display: flex;
    margin: 0;
  }

  .shadow-box {
    table {
      width: 100%;
      margin: 15px 0;

      th {
        padding: 15px 0 5px
      }

      img {
        width: 60px
      }

      h5 {
        display: inline-block;
        color: $darkBlack;
      }

      .kitItem {
        font-size: 1.1rem;
        font-style: italic;
        padding-left: 9px;
        display: block;
      }

      .border-bottom {
        border-bottom: 1px solid #ddd;
      }

      .discountAmount {
        padding: 15px 0 5px;
      }
    }
  }
}

#vue-order-print {
  .col-xs-6 {
    font-size: 12px;

    table {
      display: block;
      padding: 20px;
      border: 2px solid #222;

      tbody {
        width: 100%;
        display: block;

        tr {
          width: 100%;
          display: block;

          td {
            width: 49%;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }
  }

  .col-xs-12 {
    table {
      width: 100%;
    }

    th {
      background-color: #ddd !important;
    }

    table,
    th {
      border: 1px solid #222;
      padding: 5px 10px;
      font-size: 12px;
    }

    td {
      padding: 5px 10px;
      font-size: 12px;
    }

    .table1 {
      margin-bottom: 10px;

      tr,
      td {
        border: 1px solid #222;
        padding: 5px 10px;
        font-size: 12px;
      }
    }

  }
}

.thumbnail-box {
  padding: 7px;
  @include fullwidth();
  position: relative;

  h3 {
    text-transform: none;
    font-size: 2rem;
    margin: 5px 0 8px 0;

    a {
      color: $darkBlack;
    }

    .icon-wrap {
      float: right;
      margin-top: -5px;
    }
  }

  .wrap-img {
    width: 100%;
    height: 245px;
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;

      .projectStatus {
        position: absolute;
        top: 15px;
        left: 15px;
        color: $white;
        font-weight: 600;
        font-size: 1.8rem;
        text-shadow: 0 0 2px black;

        &.denied {
          color: $darkRed
        }

        &.approved {
          color: $green
        }
      }
    }

    >.icon-delete {
      position: absolute;
      right: 20px;
      top: 10px;
      color: $white;
      display: inline-block;
      font-size: 2.5rem;
      cursor: pointer;
      @include transition(all .3s ease);

      &:hover {
        color: $linkColorHover;
      }
    }

    >.icon-star {
      position: absolute;
      left: 0px;
      bottom: 0px;
      border-top: 1px solid $darkGrey;
      color: $darkBlack;
      font-size: 1.4rem;
      line-height: 3rem;
      cursor: pointer;
      width: 100%;
      background-color: $white;
      @include transition(all .3s ease);

      i {
        text-shadow: 0 0 2px black;
        color: $white;
      }

      &:hover i {
        color: $linkColorHover;
      }
    }

    >.icon-pencil {
      position: absolute;
      left: 0px;
      bottom: 31px;
      border-top: 1px solid $darkGrey;
      color: $darkBlack;
      font-size: 1.4rem;
      line-height: 3rem;
      cursor: pointer;
      width: 100%;
      background-color: $white;
      @include transition(all .3s ease);

      i {
        text-shadow: 0 0 2px black;
        color: $white;
      }

      &:hover i {
        color: $linkColorHover;
      }
    }

    >.icon-comment {
      position: absolute;
      left: 0px;
      bottom: 62px;
      border-top: 1px solid $darkGrey;
      color: $darkBlack;
      font-size: 1.4rem;
      line-height: 3rem;
      cursor: pointer;
      width: 100%;
      background-color: $white;
      @include transition(all .3s ease);

      i {
        text-shadow: 0 0 2px black;
        color: $white;
      }

      &:hover i {
        color: $linkColorHover;
      }
    }

    .icon-disabled {
      color: #c4c4c4;
      pointer-events: none;
      opacity: .3;

      &:hover {
        color: #c4c4c4;
        cursor: default;
      }
    }

    .starred {
      color: $orange;

      i {
        color: $orange;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info-thumbnail {
    padding: 10px 15px 55px 15px;

    .row {
      position: absolute;
      bottom: 10px;
      margin: 0 15px;
      left: 0;
      width: inherit;
      @include flexbox();
      width: calc(100% - 30px);

      >div {
        margin: 0;
      }
    }

    p {
      margin: 0 0 10px 0;
    }

    .col-sm-4 {
      margin-top: 5px;

      a {
        color: $lightBlack;
        display: inline-block;
        padding: 0px;

        span {
          display: block;
          font-size: 1.1rem;
          color: #999;
          line-height: 100%;
          margin-top: 5px;
          @include transition(all .3s ease);
        }

        &:hover span {
          color: $linkColorHover;
        }
      }
    }

    .col-sm-4+.col-sm-4 {
      border-left: 1px solid $lightenGrey;
    }
  }
}

.item-status {
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  position: absolute;
  left: 6px;
  top: 6px;
  color: #fff;
  font-weight: 600;
  cursor: default;
}

.status-green {
  background: $green;
}

.status-yellow {
  background: $yellow;
}

.status-blue {
  background: $darkBlue;
}

.status-red {
  background: $red;
}

/* -- News Box CSS -- */
.news-box {

  img {
    width: 100%;
  }

  p {
    line-height: 1.8;
    color: $lightenBlack;
  }

  h6 {
    margin-top: 0;
  }
}

/* -- Hero styles for homepage marketing banner -- */
#marketing-banner {
  a img {
    width: 100%;
    height: auto;
  }
}

/* -- Hero styles for TV commercial (8-16-2024) -- */
.hero-cta {
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
}

.hero-sub-cta {
  font-size: 2rem;
  margin: 0;
}

@mixin hero-background($image-url) {
  background-image:
    linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%),
    url($image-url);
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center center;
}

#discover {
  @include hero-background('/img/hero-discover.jpg');
}

#launch {
  @include hero-background('/img/hero-launch.jpg');
}

#connect {
  @include hero-background('/img/hero-connect.jpg');
}

@media (max-width: 768px) {
  @mixin hero-background($image-url) {
    background-size: contain;
    background-position: top center;
  }

  #discover,
  #launch,
  #connect {
    height: 300px;
  }
}

#homepage-video {

  .video-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      position: absolute;
      opacity: 0.9;
    }

    img {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  #homepage-video .video-cta {
    img {
      background: transparent;
      height: 150px;
    }
  }
}

/* -- Shop Advertisement Banner -- */
.shopBanner {
  >div {
    overflow: hidden;
    display: block;
    margin: 10px 0 0px;
    text-align: center;
    cursor: default;

    span {
      color: $lightenBlack;
      font-size: 1.6rem;
    }
  }
}

.marketing-banner {
  float: left;
  width: inherit;
  display: contents;

  &-img {
    background-image: var(--mobile-image);
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-size: cover;
    height: 275px;
    width: 100%;
    float: left;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    @media screen and (min-width: 767px) {
      background-image: var(--tablet-image);
    }

    @media screen and (min-width: 991px) {
      background-image: var(--desktop-image);
    }
  }
}

@media (max-width: 991px) {
  .marketing-banner {
    padding: 0 !important;
  }
}

/* -- Search Box CSS -- */
.search-main {
  text-align: center;
  margin-bottom: 15px;
}

.strip-black+.mid-section .search-main {
  margin-top: 25px;
}

.search-box {
  width: 50%;
  display: inline-block;

  input[type="text"] {
    border: 1px solid $darkGrey;
    background: $white;
    float: left;
    width: calc(100% - 75px);
    height: 50px;
    padding: 0 15px 0 20px;
    font-size: 1.8rem;
    outline: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .btn-search,
  .btn-filter {
    background: $darkGrey;
    color: $white;
    width: 75px;
    text-align: center;
    float: left;
    border-radius: 0 4px 4px 0;
    border: 0;
    height: 51px;
    font-size: 2.2rem;
    outline: none;
    @include transition(all .3s ease);

    &:hover,
    &:active,
    &:focus {
      background: $orange;
      color: $white;
    }
  }

  .btn-filter {
    border-radius: 4px 0 0 4px;
    background: $white;
    color: $lightBlack;
    width: 50px;
    border: 1px solid $darkGrey;
    border-right: 0;

    i {
      margin-left: 3px;
    }
  }
}

.search-box-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid #bdbdbd;
  transition: border-color 200ms ease-in-out;
  background-color: #fff;
  border-radius: 4px;

  &:focus-within {
    border-color: #333;
  }

  .search-box-shop {
    width: auto;
    flex: 1;
    padding: 8px;
    margin: auto;

    input {
      font-weight: normal;
      appearance: none;
      float: none;
      background: transparent;
      border: none;
      border-radius: 0;
      min-height: auto;
      width: 100%;
      padding: 5px 5px 5px 0;
      outline: none;

      &::placeholder {
        color: currentColor;
      }
    }
  }

  button {
    background: transparent;
    border: none;
    width: 40px;
  }
}

#filterVisibility.collapse,
.toggle-shop-filters {
  display: none;

  .side-box {
    .collapse {
      margin-top: 10px;
    }
  }
}

.mobile-only,
.small-screen {
  display: none;
}


/* Mid Section Inner CSS ===================*/

.mid-inner {
  min-height: -webkit-fill-available;
  background-color: $lighterGrey;
  margin-bottom: 3%;
  @include fullwidth();

  >.viewAllBtn {
    color: #cc6909;
    cursor: pointer;
  }
}

.document-page {
  margin-top: 65px;
}

.has-sub-menu {
  .mobile-shop-categories {
    margin-top: 0;

    h5 {
      position: absolute;
      top: 3px;
      left: 10px;
    }

    select {
      padding-left: 80px;
    }
  }
}


/* -- Filter CSS -- */
.filter-wrap {
  @include fullwidth();
}

.filter-box {
  background: $white;
  border-radius: 4px;
  @include fullwidth();
  border: 1px solid $darkGrey;
  padding: 0;

  li {
    float: left;
    width: 20%;
    text-align: center;
    list-style: none;
    font-size: 1.8rem;

    &:first-child>a {
      border-radius: 4px 0 0 4px;
    }

    &:last-child>a {
      border-radius: 0 4px 4px 0;
    }

    button.btn-project-upload {
      color: #4b4f54;
      font-weight: 600;
      font-size: 18px;
      margin: 0;
      margin-top: 2px;
    }

    a {
      color: $lightenBlack;
      font-weight: 600;
      display: block;
      padding: 8px 10px;

      >img {
        display: inline-block;
        margin-right: 10px;
      }

      >span {
        display: inline-block;
        padding-left: 25px;
        line-height: 100%;
      }

      >span.icon-newest {
        background: url($imgDir + 'clock.png') left 1px no-repeat;
      }

      >span.icon-top-search {
        background: url($imgDir + 'seo-target.png') left 1px no-repeat;
      }

      >span.icon-trending {
        background: url($imgDir + 'trending.png') left 0 no-repeat;
        padding-bottom: 1px;
      }

      >span.icon-upload-photo {
        background: url($imgDir + 'upload-photo.png') left 1px no-repeat;
        padding-bottom: 1px;
      }

      &:hover,
      &:active {
        background: $lightBlack;
        color: $white !important;

        >span.icon-newest {
          background: url($imgDir + 'clock.png') left -16px no-repeat;
        }

        >span.icon-top-search {
          background: url($imgDir + 'seo-target.png') left -16px no-repeat;
        }

        >span.icon-upload-photo {
          background: url($imgDir + 'upload-photo.png') left -16px no-repeat;
        }

        >span.icon-trending {
          background: url($imgDir + 'trending.png') left -19px no-repeat;
        }
      }
    }
  }

  li.active {
    a>span.icon-newest {
      background: url($imgDir + 'clock.png') left -16px no-repeat;
    }

    a>span.icon-top-search {
      background: url($imgDir + 'seo-target.png') left -16px no-repeat;
    }

    a>span.icon-trending {
      background: url($imgDir + 'trending.png') left -19px no-repeat;
    }

    a>span.icon-upload-photo {
      background: url($imgDir + 'upload-photo.png') left -16px no-repeat;
    }
  }

  li+li {
    border-left: 1px solid $darkGrey;
  }

  li.active a {
    background: $lightBlack;
    color: $white !important;
  }
}

.filter-options {
  padding: 8px 0 0 0;
  text-align: center;
  clear: both;

  div {
    display: inline-block;
    width: 61px;

    a {
      display: inline-block;
      margin: 10px 0;
      color: $darkenGrey;
      font-weight: 600;
      text-align: center;
      position: relative;

      &:hover,
      &.active {
        color: $darkBlack !important;
      }

      &.active {
        i.fa-check {
          opacity: 1;
        }
      }

      img {
        display: inline-block;
        margin-bottom: 8px;
        @include box-shadow(3px 1px 6px rgba(0, 0, 0, .5));
      }

      span {
        display: block;
      }

      i.fa-check {
        color: $white;
        position: absolute;
        top: 7px;
        margin-left: 9px;
        opacity: 0;
        text-shadow: 0px 0px 1px $black;
        @include transition(all .3s ease);
      }

      &.active i.fa-check {
        opacity: 1;
      }
    }

    &:active {
      color: $darkenGrey;
    }
  }

  a.color-all {
    img {
      width: 30px;
    }
  }
}

label[for="sortNew"],
label[for="sortTrending"] {
  font-weight: normal;
}

$color-filters: (
  red: #B74051,
  orange: #F38A1C,
  yellow: #F8EE49,
  green: #1BA00F,
  blue: #2456F0,
  purple: #9E49E9,
  pink: #D929A9,
  black: #363636,
  white: #F8F8F8,
  silver: #C5C5C5,
  grey: #7B7B7B,
  bronze: #F0A755,
  gold: #F5CC3F,
  tan: #BFA371,
  brown: #625B3F,
  chrome: #DCDEDB
);

.color-filters {
  margin: 15px 0;

  span {
    font-size: 1.3em;
    font-weight: 700;
    color: #222222;
    font-family: "Open Sans", sans-serif;
    padding: 3px 0;
  }

  &-list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 540px;

    li {
      flex: 0 1 calc(16.66666666666667% - 2px);
      position: relative;
      padding-bottom: 0;
      margin: 0 1px;
      line-height: 1;
      max-width: 65px;

      &::before {
        content: attr(aria-label);
        position: absolute;
        z-index: 9;
        pointer-events: none;
        transition: all 150ms 200ms ease-in-out;
        white-space: nowrap;

        top: 100%;
        left: 50%;
        transform: translate3d(-50%, 12px, 0);

        padding: 10px;
        border-radius: 10px;
        background: #000;
        color: #fff;
        text-align: center;

        opacity: 0;
      }

      &:hover::before {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
      }

      input[type=checkbox] {
        justify-content: center;
        display: inline-flex;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 100%;
        position: relative;
        width: 100%;
        height: 0;
        border: 1px solid transparent;
        appearance: none;
        cursor: pointer;
        outline: none;
        margin: 0;
        transition: transform 100ms ease-in-out;

        @each $color, $value in $color-filters {
          &[value*=#{$color}] {
            background-image: linear-gradient($value, darken($value, 10));
            background-color: $value;
          }
        }

        &[value*=white],
        &[value*=clear] {
          border-color: #cacaca;
        }

        &[value*=clear] {
          background-image: linear-gradient(rgba(black, 0.125) 25%, transparent 100%);
          background-color: #EEE;
          overflow: hidden;

          &::before {
            content: "";
            background-color: rgba(white, 0.125);
            transform-origin: top right;
            transform: rotate(45deg);
            position: absolute;
            height: 150%;
            width: 100%;
            top: 0;
            left: 8px;
          }
        }

        &::before {
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: "FontAwesome";
          display: inline-block;
          font-variant: normal;
          text-rendering: auto;
          font-weight: 900;
          content: '\f00c';
          transition: transform 200ms ease-in-out;
          color: #fff;
          transform: scale(0);
          line-height: 0;
          text-shadow: 0 0 3px rgba(black, 0.75);
          margin: -1px 0 0 -6px;
          position: absolute;
          left: 50%;
          top: 50%;
        }

        &:hover {
          transform: scale(.95);
        }

        &:checked {
          &::before {
            transform: scale(1);
          }
        }
      }
    }
  }
}

.shop-filters {
  .filter-options {
    display: block;
  }
}

.shop-filter-box {
  li {
    width: 16.66666666666667%;
    position: relative;

    span {
      font-size: 1.3rem;
      position: absolute;
      left: 25px;
      bottom: 10px;
      color: #f78f1c;
      font-weight: bold;
      font-style: italic;
    }
  }
}

.filter-options-gallery {
  display: none;

  &.show {
    display: block
  }
}

/* -- Shop Series Cards -- */

#vue-shop {

  .popular-searches,
  .series-card {
    .shadow-box {
      padding: 0 15px;
    }

    button,
    a.btn-orange {
      background: transparent;
      border-radius: 0;
      border: 1px solid #f69422;
      border-bottom-width: 6px;
      color: #f69422 !important;

      &:hover {
        background: #f69422;
        color: #fff !important;
      }
    }
  }

  .popular-searches {
    text-align: center;
    padding: 10px 0 20px 0;

    h3 {
      font-family: 'Teko', sans-serif;
      font-size: 26px;
      line-height: 25px;
      display: block;
      color: #f69422;
      padding-bottom: 15px;
      letter-spacing: 0.1em;
      font-weight: normal;
    }

    .search-links {
      &:first-child {
        margin-left: 12.5%;
      }

      a {
        font-family: 'Teko', sans-serif;
        font-size: 23px;
        line-height: 20px;
        display: block;
        padding-bottom: 15px;
        color: #393939;
        letter-spacing: 0.05em;
        text-align: center;
        cursor: pointer;

        &:hover {
          color: #FD920E !important;
        }
      }
    }
  }

  .series-card {
    margin-top: 5%;

    h1 {
      font-size: 1.6rem;
      padding: 10px 0;
      color: #fff;
      margin: 0;
    }

    h2 {
      font-size: 1.5rem;
      display: block;
      border-bottom: 2px solid #f69422;
      margin: 0 auto 10px auto;
      width: 110px;
      padding-top: 10px;
      padding-bottom: 2px;
    }

    .series-card-header {
      min-height: 38px;
      text-align: center;
      background: #393939;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .series-card-content {
      .series-card-key-features {
        min-height: 332px;
        text-align: center;
        background: #FFFFFF;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 4px;

        ul {
          list-style-type: circle;
          padding: 0 20px 20px;
          height: 220px;

          li {
            text-align: left;
            font-size: 1.4rem;
          }
        }
      }

      .series-card-image {
        padding: 0;

        img {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 0;
        }

        .med-series-img,
        .sml-series-img,
        .mbl-series-img {
          display: none;
        }
      }
    }
  }
}

/* -- Filtered Products -- */
.sidebar {
  border-radius: 4px;

  h2 {
    margin-left: 30px;
  }

  h3 {
    padding: 0 0 14px 0;
    margin: 0;
    font-weight: bold;
    font-size: 1.8rem;
    color: $lightBlack;
  }

  h2+p {
    margin: 0 0 15px 30px;
  }

  .shadow-box {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 26px;
    margin-bottom: 15px;

    h2,
    h2+p {
      margin-left: 0;
      text-transform: none;
    }

    .photo-in {
      margin-left: auto;
      margin-right: auto;

      label {
        height: inherit;
      }
    }

    address {
      color: $blue;
      font-weight: 600;
      margin-bottom: 5px;
    }

    a {
      color: $lightBlack;
      display: inline-block;
      padding: 0 15px;
      margin-top: 10px;

      span {
        font-weight: 600;
        display: block;
        font-size: 1.3rem;
        color: $darkenGrey;
        line-height: 100%;
        margin-top: 10px;
        @include transition(all .3s ease);
      }

      i {
        font-size: 1.8rem;
      }

      &:hover span {
        color: $linkColorHover !important;
      }
    }

    .my-account-country {
      color: $linkColor;
      margin: 0;
    }
  }
}

.products-filtered {
  float: left;
  width: calc(100% + 30px);
  display: flex;

  &.kits {
    margin-top: 0;
  }

  >.back-to-top {
    background: none;
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 70px;
    height: 70px;
    z-index: 100;
    display: none;
    text-decoration: none;
    color: $orange;
    background-color: transparent;
    font-size: 70px;
    line-height: 68px;
    padding: 0 5px;

    &:hover {
      opacity: .6;
    }
  }

  .sidebar {
    width: 19%;
    background-color: $white;
    padding: 15px;
    margin: 0 15px 0 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    min-width: 250px;
    height: max-content;
  }

  .side-box {
    ul {
      .collapse {
        margin-top: 10px;
      }
    }
  }
}

.product-count {
  float: right;
  font-size: 14px;
  color: #7B7B7B;
}

.filter-list-title {
  overflow: auto;
}

.filters:hover {
  color: #F78F1B;
  transition: color .2s ease;
}

.filter-selected {
  color: #F78F1B;
  font-weight: 800 !important;
  margin-left: 5px;
  transition: margin-left .2s cubic-bezier(1, 0, 1, 1);
}

.clear-filters {
  width: 100%;
  margin-top: 15px;
  text-transform: inherit;
}

#filterOptions {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    width: 100%;

    li {
      cursor: pointer;
      padding: 10px 0;
      position: relative;
      font-size: 1.3em;

      span {
        background: $lightGrey;
        font-size: 1.2rem;
        line-height: 1.6;
        margin: 10px -10px -8px -10px;
        padding: 10px 14px 12px 16px;
        position: relative;
        color: $darkenGrey;
        font-weight: 300;
        display: none;

        &::after {
          bottom: 100%;
          left: 25px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba($lightGrey, 0);
          border-bottom-color: $lightGrey;
          border-width: 6px;
          margin-left: -6px;
        }
      }

      &:hover span {
        display: block;
      }

      i {
        float: right;
        margin-top: 6px;
      }

      i.fa-plus {
        display: none;
      }

      i.fa-angle-down {
        display: none;
      }

      ul {
        position: relative;
        z-index: 2;
        padding: 5px 0;
      }

      li {
        padding: 3px 0px 3px 10px;
        font-size: 16px;
        font-weight: 500;

        div {
          display: inline-block;

          p {
            margin: 0;
            font-size: 1.1rem;
            line-height: 1.2rem;
            color: $darkenGrey;
          }
        }

        input {
          margin-left: 20px;
          margin-top: -1px !important;
        }

        em {
          float: right;
          background: $white;
          margin-top: 11px;

          &.mColorChecks {
            margin-top: 4px;
          }
        }
      }
    }

    li div.collapsed i.fa-angle-up {
      display: none;
    }

    li div.collapsed i.fa-angle-down {
      display: block;
    }

    li.active {
      font-weight: bold;

      i {
        opacity: 1;
      }
    }
  }

  @media only screen and (min-width: 1031px) {
    display: block !important;
  }
}

.gallery-full {
  >.back-to-top {
    background: none;
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 20px;
    width: 70px;
    height: 70px;
    z-index: 100;
    display: none;
    text-decoration: none;
    color: $orange;
    background-color: transparent;
    font-size: 70px;
    line-height: 68px;
    padding: 0 5px;

    &:hover {
      opacity: .6;
    }
  }
}

.side-box {
  background: $white;
  padding: 10px 15px 15px 15px;
  margin-bottom: 20px;
}

.latest-purchased {
  margin-top: 20px;
}

/* -- Filtered Right Content CSS -- */
.content-right {
  .product-wrap {

    .product-box {
      margin: 10px !important;
      width: 10%;
      min-height: 231px;
      min-width: 190px;

      &.swatch-box {
        padding: 5px;
        min-height: 254px;
        margin-top: 0px;
        margin-bottom: 20px;

        .addSwatch {

          &:hover {
            color: $orange !important;
          }

          &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: .5;
          }
        }

        .swatchToCart {
          &.disabled {
            pointer-events: none;
            cursor: default;
            opacity: .5;
          }
        }
      }
    }
  }

  h4 {
    padding: 0 0 0px 5px;
    margin: 0;
    font-weight: bold;
    font-size: 1.8rem;
    color: #393939;
    text-align: left;
    text-transform: capitalize;

    .styled-select {
      width: 150px;
      float: none;
      height: 33px;
      display: inline-block;

      &:before {
        top: 2px;
        pointer-events: none;
      }

      select.form-control {
        margin-top: -6px;
        height: 34px;
      }
    }
  }
}

.product-in {
  background: $white;
  @include fullwidth();
  padding: 25px 10px 30px 10px;
  text-align: center;
  margin-top: 32px;

  h4 {
    text-transform: none;
    margin: 20px 0 0 0;
  }

  a {
    color: $lightBlack;
  }

  img {
    max-width: 100%;
  }
}


/* Profile Page ========================================*/
.my-page {
  .qualifiedApplication {
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 2px 1px 6px -2px #222;
    background: #3a3a39;
    position: absolute;
    padding: 10px 25px;
    border-radius: 4px;
    color: $darkBlack;
    display: block;
    left: 50%;
    top: 50%;

    img {
      margin-left: -15px;
      height: 45px;
    }

    span {
      font-size: 17px;
      margin: 0;
      font-weight: 500;
      color: #fff;
      display: inline-block;
    }

    &:hover {
      color: $orange !important;
    }
  }
}

.profile-top {
  margin-top: 30px;
  padding-bottom: 15px;
  position: relative;

  .block-map {
    @include box-shadow(0 0 5px rgba($black, .5));
  }

  .disabled {
    pointer-events: none;
    opacity: .4;
  }

  ul {
    li {
      list-style: none;
      padding-top: 5px;

      .checkout-check {
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}

.show-all {
  cursor: pointer;
  color: #1788e4;
  text-transform: none;
  font-size: 1.4rem;
  margin-left: 25px;
}

.project-reviews {
  background-color: #fff;
  margin: 15px 0;

  .col-md-2 {
    padding: 0px;
    padding-right: 15px;

    img {
      width: 100%;
    }
  }

  .col-md-10 {
    padding-left: 0px;

    >div {
      margin-top: 15px;
      padding-left: 15px;
    }
  }

  a {
    color: initial;
  }

  .stars {
    float: none;
    height: 14px;
    width: 100%;
  }

  .fa-star {
    float: left;
    padding-right: 4px;
  }
}

.photo-profile {
  position: relative;
  display: inline-block;

  .photo-in {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 5px;

    img {
      width: 100%;
      height: 101%;
      object-fit: cover;
      margin-top: -1px;
    }
  }

  .qualified-profile,
  .trend-profile,
  .distributor-profile,
  .yellow-check,
  .coming-soon-profile,
  .robotics-profile,
  .high-volume-profile,
  .multi-cam-profile {
    position: absolute;
    bottom: -20px;
    left: 42px;
  }

  .trend-profile {
    bottom: -5px;
    left: 90px;
    width: 34px;
  }

  .distributor-profile {
    width: 34px;
    bottom: -5px;
    left: 7px;
  }

  .qualified-profile {
    width: 34px;
    left: 48px;
  }

  .robotics-profile {
    width: 34px;
    bottom: 30px;
    left: -15px;
  }

  .coming-soon-profile {
    bottom: 30px;
    left: 115px;
    width: 34px;
  }

  .high-volume-profile {
    bottom: 75px;
    left: 115px;
    width: 34px;
  }

  .multi-cam-profile {
    width: 34px;
    bottom: 75px;
    left: -15px;
  }

  .yellow-check {
    bottom: 0;
    left: 86px;
  }
}

#vue-my-page {
  .photo-profile {
    .photo-in {
      width: 100%;
      height: auto;
    }
  }
}

.info-profile {
  // 	padding-right: 40px;
  padding: 0 20px 0px 0px;

  .info-details {
    margin-bottom: 20px;
  }

  h2 {
    text-transform: none;
    font-weight: bold;
    margin-top: 5px;

    a {
      float: right;
    }
  }

  address {
    color: $blue;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .aboutUs {
    margin-bottom: 15px;
  }

  .row {
    .col-sm-12 {
      overflow: hidden;

      >.closePhoneLink {
        position: absolute;
        right: 20px;
        bottom: 4px;
        padding: 6px 10px;
        z-index: 10;
        opacity: 0;
        width: 0px;
        @include transition(all .3s ease);

        &.open {
          width: initial;
          opacity: 1;
        }
      }

      >.phoneLink {
        position: absolute;
        width: 0px;
        background-color: #ddd;
        z-index: 1;
        height: 100%;
        text-align: center;
        padding-top: 10px;
        opacity: 0;
        left: 15px;
        @include transition(all .3s ease);

        &.open {
          width: calc(100% - 30px);
          opacity: 1;
          z-index: 9;
        }
      }
    }
  }

  .col-sm-3+.col-sm-3 {
    border-left: 1px solid $darkGrey;
  }

  .col-sm-3 a {
    color: $lightBlack;
    display: inline-block;
    padding: 0;
    width: 100%;
    height: 100%;

    i {
      font-size: 1.8rem;
    }

    span {
      font-weight: 600;
      display: block;
      font-size: 1.3rem;
      color: $darkenGrey;
      line-height: 100%;
      margin-top: 5px;
      @include transition(all .3s ease);
    }

    &.noURL {
      opacity: .3;
      pointer-events: none;
      cursor: default;

      &:hover span {
        color: $darkenGrey !important;
      }

      &:hover {
        color: $darkBlack !important;
      }
    }

    &:hover span {
      color: $linkColorHover !important;
    }
  }

  .appInfoContactBtn {
    width: 20% !important;
    padding: 0;
    float: left;
    cursor: pointer;
    z-index: 8;
  }
}

.badges-profile {
  .col-xs-3 {
    font-size: 1.3rem;
    text-align: center;

    img {
      width: 45px;
      display: block;
      margin: auto;
    }
  }
}

.category-profile {
  ul {
    padding-left: 30px;
  }
}

.stars {
  color: #f99839;
  margin: 5px 0;
  position: relative;

  a {
    color: #f99839 !important;
    margin: 5px 0;
  }

  >.stars-tooltip {
    @include tooltip(-24px, 20%);
    width: 330px;
    top: 30px;
    z-index: 9;
  }

  >.stars-tooltip-center {
    @include tooltip(10px, 20%);
    width: 330px;
    top: 30px;
    z-index: 9;

    &::before {
      left: 50%;
    }
  }

  &:hover {

    .stars-tooltip {
      visibility: visible;
      opacity: 1;
    }

    .stars-tooltip-center {
      visibility: visible;
      opacity: 1;
    }
  }
}


/* Location Page ======================================*/
.location-wrap {
  margin-top: 50px;
  width: 75%;

  h2 {
    text-transform: none;
    color: $lightBlack;
    @include transition(all .3s ease);
  }

  h3 {
    text-transform: none;
    padding-bottom: 30px;
    color: $lightBlack;
    @include transition(all .3s ease);
  }

  .col-sm-12 h2 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px 0;
  }

  .info-block {
    width: 75%;
    margin: 0 auto;
    text-align: left;
    padding: 0 15px;

    p {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }

  .shadow-box {
    margin-bottom: 30px;

    svg {
      margin-top: 35px;
      padding: 15px;
    }
  }
}


/* Wishlist Page =======================================*/
.heading-bordered {
  margin-top: 20px;
  @include fullwidth();

  h2 {
    position: relative;

    >span {
      background: $lighterGrey;
      display: inline-block;
      position: relative;
      z-index: 2;
      padding-right: 15px;
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      height: 1px;
      background: $darkGrey;
      width: 100%;
      left: 0;
      top: 48%;
      z-index: 1;
    }
  }
}

.heading-bordered+.row .flex-wrap .product-box {
  width: 177px;
  margin-right: 15px;
}

#checkoutAlertModal {
  .modal-dialog {
    width: 400px;
    text-align: center;

    .modal h2 {
      padding-left: 30px;
    }

    h5 {
      line-height: 1.8rem;
    }

    .modal-footer {
      text-align: center;

      button {
        padding: 8px 25px;
      }
    }
  }
}

#qualifiedApplication {
  .modal-title {
    text-align: center;
  }

  .modal-content {
    padding-bottom: 0;
  }

  .form-group {
    @include fullwidth();
    margin-bottom: 5px;
    position: relative;
    min-height: 93px;

    label {
      padding-top: 9px;
      font-weight: 400;
      display: inline;
      position: absolute;
      bottom: 42px;
    }

    .form-control {
      background: $white;
      font-weight: 600;
      height: 42px;
      bottom: 0;
      position: absolute;
    }
  }

  .sidebar {
    h2 {
      margin-left: 0px;
    }
  }

  .btn-orange {
    margin-top: 20px;
  }
}


/* Your Account Page ====================================*/
.my-account {
  display: grid;
  padding-top: 20px;
  padding-bottom: 0px;

  .sidebar+.content-right {

    .form-group .col-sm-9>div+div {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }

  .form-group {
    @include fullwidth();

    >div {
      padding-left: 30px;
    }

    label {
      padding-top: 9px;
      font-weight: 600;

      span {
        font-size: 1.2rem;
        color: $darkenGrey
      }
    }

    .form-control {
      background: $white;
      font-weight: 600;
      height: 42px;

      &.aboutUsSection {
        height: 125px
      }
    }

    input+a {
      margin: 10px 0 15px 12px;
      display: block;
      font-weight: 600;
    }

    input+.row {
      margin-top: 15px;
    }
  }

  #qualifiedApplication {
    .modal-content {
      padding-bottom: 0;
    }

    .form-group {
      @include fullwidth();
      margin-bottom: 5px;
      position: relative;
      min-height: 93px;

      label {
        padding-top: 9px;
        font-weight: 400;
        display: inline;
        position: absolute;
        bottom: 42px;
      }

      .form-control {
        background: $white;
        font-weight: 600;
        height: 42px;
        bottom: 0;
        position: absolute;
      }
    }

    .sidebar {
      h2 {
        margin-left: 0px;
      }
    }

    .btn-orange {
      margin-top: 20px;
    }
  }

  .field-add-phone {
    display: none;
  }

  &.addressSection {
    >div {
      width: 100%
    }

    h5 {
      margin: 0 0 5px 0;
      width: 100%;

      input {
        width: 200px;
        display: inline-block;
        float: right;
        margin-top: -12px;
        margin-right: 15px;
      }

      a {
        float: right;
        width: 160px;
        text-align: right;
      }
    }
  }

  >.payment-options {
    .row {
      label {
        font-weight: 600
      }

      .form-group {
        &.col-sm-6 {
          width: 50%
        }

        &.col-sm-4 {
          width: 33.33333333%;

          label {
            padding-top: 0;
          }
        }
      }
    }
  }

  .remove-payment-opt {
    font-size: 1.9rem;
    color: rgba(255, 0, 0, .65);
    position: absolute;
    right: 15px;
    top: 7px;
    text-align: right;
    padding: 0 5px;
    cursor: pointer;
  }

  .delete-phone-parent {
    position: relative;
  }

  .delete-phone {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;

    i {
      font-size: 1.9rem;
      color: rgba(255, 0, 0, 0.65);
    }
  }

  .primary-phone {
    position: absolute;
    top: 12px;
    right: 40px;
    cursor: pointer;

    i {
      font-size: 1.8rem;

      &.fa-star {
        color: $orange
      }
    }
  }
}

.heading-account h5 a {
  float: right;
}


/* Equipment Page ======================================== */
.wrap-gallery {
  margin-bottom: 25px;
}

.thumbnail-gallery {
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  height: 0;

  a {
    display: block;
    position: absolute;
    color: $lightBlack;
    width: 100%;
    height: 100%;

    .thumbnail-info {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 5px 15px 15px 15px;
      z-index: 2;
      background: $white;
      transition: background .2s;
      text-align: center;

      p {
        margin-bottom: 0;
        font-size: 1.3rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }

    &:hover {
      color: $lightBlack !important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    .thumbnail-info {
      background: rgba($white, .8);
      transition: background .2s;
    }

    z-index: 10;
  }
}

#loadMore {
  width: 120px;
  height: 120px;
  background: $lighterGrey;
  border-radius: 50%;
  display: inline-block;
  color: $yellowDark;
  font-size: 1.2rem;
  border: 3px solid $yellowDark;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 20px;

  >span {
    display: inline-block;
    margin-top: 50px;
  }

  &:hover {
    background: $yellowDark;
    color: $white !important;
  }
}


/* Color Match ========================================= */
.shop-search-label {
  padding-left: 25%;
  font-size: 18px;
  line-height: 24px;
  color: #4B4F54;
  font-weight: bold;
  font-family: "Open Sans";
  margin-top: 10px;
}


/* Product Detail ====================================== */
.countdown-wrapper {
  display: table;
  width: 100%;
  line-height: 30px;
  margin: 5px 0;

  >div {
    display: table-cell;
    line-height: 30px;
  }

  .stripe-left {
    text-align: right;

    .gradient {
      display: inline-block;
      width: 100%;
      max-width: 220px;
      height: 3px;
      background: linear-gradient(to right, rgb(242, 242, 242), rgb(255, 151, 0));
    }
  }

  .stripe-right {
    .gradient {
      display: inline-block;
      width: 100%;
      max-width: 220px;
      height: 3px;
      background: linear-gradient(to left, rgb(242, 242, 242), rgb(255, 151, 0));
    }
  }

  .middle {
    width: 320px;
  }

  .countdown {
    width: 100%;
    line-height: 30px;
    z-index: 300;
    text-align: center;

    p {
      display: inline-block;
      vertical-align: middle;
      line-height: 30px;
      font-family: $leagueGothicRegular;
      color: white;
      margin: 3px 0px 0px;
      color: $lightenBlack;
      text-transform: uppercase;
      font-size: 2.8rem;

      span {
        color: $orange;
      }
    }

    .question {
      color: $orange;
      display: inline-block;
      margin: 0 0 0 5px;
      font-size: 1.6rem;
      cursor: pointer;
    }
  }

  .shipping-rates {
    width: 100%;
    line-height: 30px;
    z-index: 300;
    text-align: center;
    padding: 15px;
    background: #fff;
    float: left;

    .container-fluid {
      width: fit-content;
    }

    h2 {
      font-size: 37px;
      letter-spacing: .5px;
      word-spacing: 2px;
      font-family: "league_gothicregular", sans-serif;
      font-weight: normal;
      text-transform: none;
      color: $lightenBlack;
      margin-top: 5px;
    }

    h6 {
      margin: 5px auto;
      width: fit-content;
    }

    p {
      vertical-align: middle;
      line-height: 30px;
      font-family: $leagueGothicRegular;
      color: $lightenBlack;
      text-transform: uppercase;
      font-size: 2.2rem;
      width: fit-content;
      margin: 3px auto;
    }

    >div {
      div {
        width: 215px;
        margin: auto;
        text-align: left;
      }
    }

    img {
      width: 75px;
      display: block;
      margin: 0 auto;
    }
  }
}

.wrap-details {
  background: $white;
  @include fullwidth();
  @include flexbox();
  position: relative;
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.info-product-left {
  width: 45%;

  .resources {
    float: left;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    z-index: 1;
    background: $white;

    ul {
      @extend .ul-no-styled;
      height: 100%;

      li {
        display: inline-block;
        width: 13%;

        a {
          display: block;
          text-align: center;
          padding-top: 30px;
          font-weight: 600;
          color: $lightBlack;
          min-height: 100px;
          height: auto;

          &:hover {
            color: $orange !important;
          }

          &[disabled] {
            cursor: not-allowed;
            opacity: .3;
          }

          >i {
            font-size: 2rem;
          }

          >span {
            display: block;
            line-height: 125%;
            font-size: 1.1rem;

            em {
              display: block;
              font-style: normal;
            }
          }
        }
      }
    }
  }

  #productCarousel {
    width: 100%;

    iframe {
      width: 100% !important;
    }
  }
}

.info-product-right {
  float: left;
  width: 55%;
  background: $white;
  padding: 30px 35px 30px 35px;
  position: relative;

  .info-product-title {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;

    h1 {
      flex: 0 1 auto;
    }

    .btn-wishlist {
      justify-self: flex-end;
      white-space: nowrap;
      margin-left: 24px;
      flex: 0 1 auto;
    }
  }

  .product-box {
    .mix-by-weight {
      position: absolute;
      top: 13px;
      left: 120px;
      color: #979797;
      font-size: 1.3rem;
    }
  }

  h1 {
    text-transform: none;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0px;
    color: inherit;
  }

  h2,
  h3,
  h2>a {
    text-transform: none;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0px;
    line-height: 2.2rem;
  }

  h3 {
    font-size: 1.4rem;
    margin-top: 20px;

    span {
      font-weight: normal;
    }
  }

  h4 {
    font-size: 1.4rem;
    text-align: left;
    margin: 0;
    padding: 15px 20px;
    color: #979797;

    a {
      display: block;
      float: right;

      i {
        font-size: 18px;
        vertical-align: text-bottom;
      }
    }
  }

  h1+h3,
  h1+a+h3,
  h2+h3 {
    margin-top: 0;
  }

  .btn-color-match {
    float: right;
  }

  .stock {
    color: $orange;
    font-size: 1.6rem;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .stock-out {
    @extend .stock;
    color: $errorColor;
  }

  .stock-notification-input {
    display: inline-block;
    width: 65%;
    margin-top: 5px;
    margin-right: 2px;
  }

  .stock-notification-submit {
    padding: 7px 20px;
    margin-bottom: 4px;
  }

  .stock-notification-success {
    padding: 10px;
    margin-bottom: 10px;
  }

  .stock-notification-error {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;

    th {
      background-color: #bdbdbd;
      border: 1px solid #222;
      padding: 7px 15px;
    }

    td {
      border: 1px solid #222;
      padding: 7px 15px;
    }
  }
}

.info-product-right-mobile {
  float: left;
  width: 38%;
  background: $lighterGrey;
  padding: 30px 35px 30px 35px;
  position: relative;

  h1 {
    text-transform: none;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0px;
    color: inherit;
  }

  h2,
  h3,
  h2>a {
    text-transform: none;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0px;
    line-height: 2.2rem;
  }

  h3 {
    font-size: 1.4rem;
    margin-top: 20px;

    span {
      font-weight: normal;
    }
  }

  h4 {
    font-size: 1.4rem;
    text-align: left;
    margin: 0;
    padding: 10px 20px;
    color: #979797;

    a {
      float: right;
    }
  }

  h2+h3 {
    margin-top: 0;
  }

  h1+h3 {
    margin-top: 0;
  }

  .btn-wishlist {
    float: right;
    margin-top: -15px;
  }
}

.add-cart {
  float: left;
  width: 100%;
  position: static;

  input[type="text"] {
    background: $white;
    border-radius: 4px;
  }

  input[type="text"].field-unit {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,e2e2e2+100 */
    background: #fcfcfc;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fcfcfc 0%, #e2e2e2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fcfcfc 0%, #e2e2e2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fcfcfc 0%, #e2e2e2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#e2e2e2', GradientType=0);
    /* IE6-9 */

  }

  .col-sm-12 .col-xs-3 {
    height: 40px;
    position: relative;

    label {
      position: absolute;
      bottom: 0px;
      margin: 0px;
    }
  }

  .col-sm-12 .col-sm-4 {
    color: $darkGrey;
    padding: 0 30px;
    margin: 0px 0 25px 0;

    strong {
      display: block;
      color: $darkBlack;
    }
  }

  .col-sm-12 .col-sm-4+.col-sm-4 {
    border-left: 1px solid $darkGrey;
  }

  .product-total {
    margin-top: 25px;

    div>div {
      color: $darkGrey;

      strong {
        display: inline-block;
        color: $darkBlack;
        margin-right: 10px;
        font-size: 1.7rem;
      }
    }
  }

  .productSelect+.select2-container .select2-selection--single {
    height: 36px;

    .select2-selection__rendered {
      line-height: 36px;
    }
  }
}

.link-save {
  float: right;
  color: $darkenGrey;
  font-weight: 600;
  border-bottom: 2px solid $darkenGrey;

  &:hover {
    border-bottom: 2px solid $orange;
  }
}

// International Pages ==================================*/
.main-container {

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 28px;
  }

  h2,
  h3,
  h4,
  h5 {
    text-transform: none;
    color: #3a3a39;
  }

  .row-spacing {
    padding: 3px 0;
  }

  .jumbotron {
    margin: 0;
    padding: 70px 0;
    background-color: transparent;

    .caption-inner {

      p {
        font-size: 22px;
      }
    }
  }

  .info {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 24px;

    h5 {
      margin: 0;
    }
  }

  .banner-cta {
    position: absolute;
    top: 170px;
    width: 100%;

    .banner-cta-content {
      width: 55%;
      margin: 0 auto;
      max-width: 1170px;

      .banner-cta-container {
        width: 55%;
        padding: 25px;
        background-color: #3a3a39d6;
        border-radius: 4px;
        position: absolute;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .30);
        max-width: 1170px;

        p {
          font-weight: 200;
          font-size: 22px;
          color: #fff;
        }

        h1 {
          color: #fff;
        }
      }
    }
  }

  .img-banner {
    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }

  .thumbnail {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .30);
    border: none;
    padding: 15px;
    width: 100%;
    display: inline-block;

    .icon-img {
      padding: 15px;

      svg {
        height: 75px;
      }
    }

    img {
      padding: 20px;
    }
  }

  .thumbnail-height {
    display: flex;

    .col-md-4 {
      display: flex;
    }
  }

  .shipping-block {
    padding: 30px 0;
    background-color: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, .30);

    .row-height {
      margin: 10px 0 30px 0;
      display: inline-flex;

      .country-icon {
        margin: 0 20px;

        svg {
          max-height: 83px;
        }
      }
    }

    p {
      margin: 0;
    }

    .row {
      a {
        margin: 0 auto;
        width: max-content;
      }
    }
  }
}

// Gallery Project Review ==================================*/
.review-form {
  .name-highlight {
    color: $orange;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .review-info-group {
    padding-top: 20px;

    .stars {
      a {
        padding-right: 3px;
      }
    }
  }

  .row {
    margin: 0 auto 15px;
  }

  .fa-star {
    cursor: pointer;
    font-size: 2rem;
  }
}

// Gallery Details =========================================*/
.collection-list,
.creation-detail-top {
  h1 {
    text-transform: none;
    color: $lightBlack;
    margin: 5px 0 10px 0;
  }

  p {
    a {
      padding: 1px 8px;
      margin: 2px 2px 3px 0;
      border-radius: 4px;
      line-height: 20px;
      display: inline-block;
      font-weight: 400;
      font-size: 13px;
      text-transform: inherit;
    }
  }
}

.stars .star-grey {
  color: $darkGrey !important;
}

.wrap-gallery-details {
  background: transparent;
  box-shadow: none;
  margin-bottom: 10px;

  .info-product-right {
    padding: 0;
    margin: 0;
    background: transparent;
    width: 30.5%;
    text-align: left;
    display: grid;
    height: auto;

    >a.btn-blue {
      width: 100%;
      text-align: left;
      font-weight: 400;
      font-size: 12px;

      span {
        font-size: 16px;
        font-weight: 600;
      }

      i:first-child {
        float: left;
        font-size: 30px;
        padding: 15px 10px 0 0;
      }

      i {
        float: right;
        font-size: 18px;
        margin-top: -10px;
      }
    }

    .product-box {
      padding: 0px 0px 0px 0px !important;
      display: inline-block;
      width: 100%;
      margin-top: 0px;

      .color-details {
        padding: 0 20px;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
      }

      .applicator-info {
        padding: 0 20px 20px 20px;
      }

      h2 {
        padding: 5px 0 0;
      }

      h4 {
        margin-top: 15px;
      }

      div {
        >a {
          border-top: none;
        }
      }

      &:hover {
        h4 {
          a {
            color: $linkColorHover !important;
          }
        }
      }

      table {
        margin: 0;
        height: calc(100% - 45px);

        td {
          vertical-align: middle;
          padding: 4px;
          border: none;

          a {
            color: $lightBlack;

            &:hover {
              color: $linkColorHover !important;
            }

            img {
              margin: 0;
              max-height: 50px;
              margin-left: 5px;
            }

            h3 {
              margin-top: 10px;
            }

            p {
              margin: 0 0 5px 0;
            }
          }

          &.seeGallery {
            a {
              padding: 5px 9px;
              display: block;
              max-width: 110px;
              float: right;
              margin-right: 15px;
              font-size: 13px;

              &:hover {
                color: $white !important;
              }
            }
          }
        }
      }
    }

    .shadow-box .photo-in {
      margin-left: auto;
      margin-right: auto;
      width: 65px;
      height: 65px;
    }

    address {
      color: $blue;
      margin-bottom: 10px;
    }

    .applicator-info {
      padding: 20px 20px 25px 20px;
      text-align: center;

      div {
        >a {
          img {
            width: auto;
          }
        }
      }

      h4 {
        padding: 0;
      }

      h2 {
        line-height: 1.65rem;
      }

      h3 {
        margin-top: 15px;
      }

      address {
        margin: 0;
      }

      p {
        margin: 0;
      }

      .stars {
        a {
          color: #3982c4;
          margin: 5px 0;
        }
      }

      >.row {
        .col-sm-12 {
          .col-xs-3 {
            z-index: 8;
          }

          overflow: hidden;

          >.closePhoneLink {
            position: absolute;
            right: 20px;
            bottom: 3px;
            padding: 8px 10px;
            z-index: 10;
            opacity: 0;
            width: 0px;
            @include transition(all .3s ease);

            &.open {
              width: initial;
              opacity: 1;
            }
          }

          >.phoneLink {
            position: absolute;
            width: 0px;
            background-color: #ddd;
            z-index: 1;
            height: 100%;
            text-align: center;
            padding-top: 15px;
            opacity: 0;
            left: 15px;
            @include transition(all .3s ease);

            &.open {
              width: calc(100% - 30px);
              opacity: 1;
              z-index: 9;
            }
          }
        }
      }

      a {
        color: $darkBlack;
        display: inline-block;
        padding: 0;
        margin-top: 10px;
        height: 100%;
        margin-left: 0;
        cursor: pointer;

        i {
          font-size: 1.8rem;
          vertical-align: text-bottom;
        }

        span {
          font-weight: 600;
          display: block;
          font-size: 1rem;
          color: $darkenGrey;
          line-height: 100%;
          margin-top: 10px;
          @include transition(all .3s ease);
        }

        &:hover span {
          color: $linkColorHover !important;
        }

        &.noURL {
          opacity: .3;
          pointer-events: none;
          cursor: default;

          &:hover span {
            color: $darkenGrey !important;
          }

          &:hover {
            color: $darkBlack !important;
          }
        }
      }

      .col-sm-4+.col-sm-4 {
        border-left: 1px solid $borderColor;
      }
    }
  }

  .gallery-media {
    border-radius: 4px 4px 0 0 !important;
  }

  .share {
    background-color: transparent;
    z-index: 1;

    ul {
      li {
        opacity: .6;
        color: #222222;
        background-color: $white;
        border-radius: 4px;

        &#pinBtn {
          a {
            width: 100%;
            height: 100%;
          }

          i {
            transition: all 0.3s ease;
            color: $darkBlack;
          }

          &:hover {
            i {
              color: $linkColorHover !important;
            }
          }
        }
      }
    }
  }
}

.pinned-projects {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);

  a>img {
    margin: 0;
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
  }
}

.project-tools {
  margin-top: 30px;

  .project-action {
    margin-top: 20px;
  }
}

#galleryCarousel {
  @extend #productCarousel;
  background: #fff;
  margin-right: 1.5%;
  display: inline-block;
  border-radius: 4px;

  #slider-thumbs {
    width: auto;
    margin: 10px;
  }

  .bookmark-project {
    position: absolute;
    z-index: 3;
    right: 20px;
    float: right;
    top: 0;
    cursor: pointer;
    width: 29px;
    height: 55px;
    opacity: .4;
    transition: visibility .3s, opacity .3s;
    background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
    background-size: 165px auto;
  }

  &:hover .bookmark-project {
    visibility: visible;
    opacity: .6;
    background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
    background-size: 165px auto;
  }

  .bookmark-project.project-highlight {
    visibility: visible;
    opacity: 1;
    background: url($imgDir + 'arrow-bookmark.svg') -138px 0 no-repeat;
    background-size: 166px auto;
    left: 21px;
  }

  .item {
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    .item-in {
      position: relative;
      height: 100%;
      width: 100%;

      i+img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
      }

      .fa-search-minus,
      .fa-search-plus {
        color: $white;
        font-size: 3rem;
        position: absolute;
        bottom: 25px;
        left: 25px;
        z-index: 999;
        font-weight: normal;

        span {
          font-family: "Open Sans", sans-serif;
          font-size: 1.6rem;
          text-transform: uppercase;
          vertical-align: middle;
          padding-left: 5px;
        }
      }

      .fa-search-minus {
        display: none;
      }

      .icon-bookmark {
        a {
          color: $lightGreen;
        }

        position: absolute;
        top: -5px;
        left: 20px;
        z-index: 99;

        .fa-bookmark,
        .fa-star {
          float: left;
        }

        .fa-bookmark {
          font-size: 5rem;
        }

        .fa-star {
          color: $white;
          margin: 14px 0 0 -24px;
        }
      }
    }

    .item-in.item-in-zoom {
      .fa-search-minus {
        display: block;
      }

      .fa-search-plus {
        display: none;
      }
    }
  }
}

#vue-creation-details {
  @extend #vue-gallery-details;

  .mixology-colors-used-mobile {
    display: none;
  }

  .product-wrap {
    .product-box {
      min-height: 210px;
    }

    .creation-color-step {
      position: relative;
      font-size: 1.8rem;
      color: #4b4f54;
      margin: 0 15px 0 0;
      display: block;
      line-height: 125%;

      strong {
        font-style: italic;
        font-size: 1.3rem;
      }

      strong {
        font-style: italic;
        font-size: 1.3rem;
      }
    }

    .creation-color-step-mobile {
      @extend .creation-color-step;
      display: none;
    }
  }


  #creationCarousel {
    @extend #galleryCarousel;

    margin-left: 0;
    width: 58.5%;
  }

  .creation-share {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 10px;

    ul {
      list-style: none;

      li {
        text-transform: uppercase;
        color: $darkBlack !important;
        display: inline-block;
        margin: 10px 8px;
        width: 60px;
        height: 60px;
        background: $white;

        &#pinBtn {
          position: relative;
          margin-right: 20px;

          a {
            opacity: 0;
            width: 100%;
            height: 100%;
          }

          i {
            position: absolute;
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            font-size: 2.5rem;
          }
        }

        &:first-child {
          margin-left: 0px;
        }

        a {
          display: block;
          text-align: center;
          padding: 17px 10px 15px 10px;
          font-weight: 600;
          color: #393939;

          i {
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  .wrap-creation-details {
    @extend .wrap-gallery-details;

    min-height: 540px;
    height: 100%;

    .info-product-right {
      width: 40%;

      .creation-custom-panel {
        width: 100%;
      }

      .side-box {
        height: auto;
        margin-bottom: 0;

        address {
          margin: 0 0 10px 0;
        }
      }

      .product-box {
        height: auto;
        min-height: 120px;

        .creation-all-colors {
          width: 100%;
          margin: 0 0 15px;
        }

        .creation-color-container {
          padding-bottom: 15px;

          .col-xs-2,
          .col-xs-3,
          .col-xs-4 {
            padding-left: 10px;
            padding-right: 10px;

            &:nth-child(2) {
              border-left: 1px solid #dedede;
              border-right: 1px solid #dedede;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              border-left: 1px solid #dedede;
            }

            &:not(:first-child)::before {
              content: "+";
              font-size: 2rem;
              position: absolute;
              left: -10px;
              bottom: calc(50% - 15px);
              border: 1px solid #dedede;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              line-height: 19px;
              background-color: #fff;
            }
          }

          .creation-color-step-container {
            text-align: center;
            margin-top: 10px;

            .creation-color-step {
              font-size: 1.3rem;
            }

            .creation-color-coat {
              font-size: 1.1rem;
              font-weight: bold;
            }
          }
        }

        .creation-color-container {
          padding-bottom: 15px;

          .col-xs-3 {
            &:nth-child(2) {
              border-left: 1px solid #dedede;
              border-right: 1px solid #dedede;
            }

            &:nth-child(4) {
              border-left: 1px solid #dedede;
            }
          }

          .creation-color-mix-step-container {
            text-align: center;
            margin-top: 10px;

            .step-mix {
              position: absolute;
              left: -2.1rem;
              bottom: calc(50% - 10px);
              border-radius: 50%;
              background: #fff;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

  .creation-panel-description {
    display: block;
    margin-bottom: 3%;
  }

  .creation-custom-panel {
    display: inline-block;
  }
}


.info-product-right-mobile {
  display: none;
  float: left;
  padding: 0;
  background: transparent;
  width: 100%;
  text-align: center;
  margin-top: 10px;

  .shadow-box .photo-in {
    margin-left: auto;
    margin-right: auto;
  }

  address {
    color: $blue;
    margin-bottom: 10px;
  }

  .shadow-box {
    padding: 5px 20px 25px 20px;
    height: 85%;

    .stars {
      a {
        color: #f99839;
        margin: 5px 0;
      }
    }

    a {
      color: $darkBlack;
      display: inline-block;
      padding: 0;
      margin-top: 10px;
      height: 100%;

      i {
        font-size: 1.8rem;
      }

      span {
        font-weight: 600;
        display: block;
        font-size: 1rem;
        color: $darkenGrey;
        line-height: 100%;
        margin-top: 10px;
        @include transition(all .3s ease);
      }

      &:hover span {
        color: $linkColorHover !important;
      }

      &.noURL {
        opacity: .3;
        pointer-events: none;
        cursor: default;

        &:hover span {
          color: $darkenGrey !important;
        }

        &:hover {
          color: $darkBlack !important;
        }
      }
    }

    .col-sm-4+.col-sm-4 {
      border-left: 1px solid $borderColor;
    }
  }

  .side-box {
    padding: 20px 20px 25px 20px;
    height: calc(100% - 262px);
    text-align: center;

    h2 {
      line-height: 1.65rem;
    }

    h3 {
      margin-top: 15px;
    }

    address {
      margin: 0;
    }

    p {
      margin: 0;
    }

    .stars {
      a {
        color: #f99839;
        margin: 5px 0;
      }
    }

    >.row {
      .col-sm-12 {
        .col-xs-3 {
          z-index: 8;
        }

        overflow: hidden;

        >.closePhoneLink {
          position: absolute;
          right: 20px;
          bottom: 3px;
          padding: 8px 10px;
          z-index: 10;
          opacity: 0;
          width: 0px;
          @include transition(all .3s ease);

          &.open {
            width: initial;
            opacity: 1;
          }
        }

        >.phoneLink {
          position: absolute;
          width: 0;
          background-color: #ddd;
          z-index: 1;
          height: 100%;
          text-align: center;
          padding-top: 15px;
          opacity: 0;
          left: 15px;
          @include transition(all .3s ease);

          &.open {
            width: calc(100% - 30px);
            opacity: 1;
            z-index: 9;
          }
        }
      }
    }
  }
}

.heading-bordered.mobile-header {
  display: none
}

.wrap-gallery-details+#slider-thumbs+.heading-bordered+.product-wrap,
.wrap-creation-details+#slider-thumbs+.heading-bordered+.product-wrap {
  margin-top: 0;
}

.share {
  @extend .resources;
  background: $white;
  box-shadow: none;
  width: 75px;
  height: 100%;
  margin: 0;
  position: absolute;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      text-transform: uppercase;
      color: $darkBlack !important;

      a {
        display: block;
        text-align: center;
        padding: 14px 12px;
        font-weight: 600;
        color: #393939;
      }

      span {
        font-size: 1.6rem;
      }

      &:hover a {
        background: transparent;
        color: $linkColorHover !important;
      }

      &:first-child {
        letter-spacing: 1px;
        margin: 10px 8px;
        font-size: 2rem;
        font-family: "league_gothicregular", sans-serif;
        text-align: center;
      }
    }

    li+li {
      border-top: 0;
      margin: 10px 8px;
      float: left;
      width: calc(100% - 16px);
      box-sizing: border-box;
      position: relative;
    }
  }

  &.articles {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    padding: 10px 50px !important;
    border-radius: 6px;
    border: 1px solid #ddd;

    ul {
      li {
        display: inline-block;
        width: 75px;

        a {
          padding: 17px 10px 15px 10px;
        }

        &:last-child a {
          padding: 17px 10px 15px 10px;
        }

        &:first-child {
          margin-top: 0px;
          padding-left: 0;
          text-align: left;
        }
      }

      li+li {
        width: 75px;
        float: none;
      }
    }
  }
}

.content-page {
  margin-bottom: 3%;
  border-radius: 4px;

  .article-body {
    padding: 35px;
    border-radius: 4px;
    border: 1px solid #ddd;

    &.ql-editor {
      ol {
        float: left;

        :before {
          float: left;
        }
      }

      ul {
        float: left;

        :before {
          float: left;
        }
      }
    }

    p {
      margin: 0px
    }

    img {
      max-height: 300px;
      max-width: -moz-available;
      max-width: -webkit-stretch;
      border-radius: 4px;
    }
  }

  .privacy-policy,
  .terms-and-conditions {
    font-size: 16px;
    font-weight: 300;
    color: #494D52;

    p {
      float: none;
    }

    a,
    a:visited,
    a:focus {
      color: #3982c4 !important;
    }

    a:hover {
      color: rgba(249, 152, 57, 0.7) !important;
    }

    h2 {
      float: none;
    }

    h4 {
      margin-top: 25px;
      font-size: 1.5rem;
    }

    ol {
      margin: 10px 0 20px 25px;

      ol {
        margin-left: 35px;
        list-style-type: lower-alpha;
      }
    }

    ol:not(.policies-tac-toc) {
      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    ol+h2 {
      padding-top: 5px;
    }

    .policies-table {
      thead {
        background: #ccc;

        td {
          padding: 10px;
          border: 1px solid #ababab;
          vertical-align: top;
          font-weight: 600;
        }
      }

      tbody {
        td {
          padding: 10px;
          border: 1px solid #ababab;
          vertical-align: top;

          ul {
            padding-left: 20px;

            &.description-list {
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}