@charset "UTF-8";
/* Variables =================================================== */
@font-face {
  font-family: "HelveticaNeueLTStdBdIt";
  src: url("../fonts/HelveticaNeueLTStdBdIt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStdBdIt.woff") format("woff"), url("../fonts/HelveticaNeueLTStdBdIt.ttf") format("truetype"), url("../fonts/HelveticaNeueLTStdBdIt.svg#HelveticaNeueLTStdBdIt") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans Regular";
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans Bold";
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans ExtraBold";
  font-weight: 900;
  src: url("../fonts/OpenSans-ExtraBold.ttf") format("truetype"), url("../fonts/OpenSans-ExtraBold.woff") format("woff"), url("../fonts/OpenSans-ExtraBold.woff2") format("woff2");
}
/* Mixins =================================================== */ /* Typography =================================================== */
h1, h2, h3, h4, h5, h6, h9 {
  letter-spacing: -0.05em;
  text-transform: uppercase;
  margin: 15px 0 5px 0;
  font-weight: 600;
}

h1 {
  color: #393939;
}

h2, h9 {
  font-size: 2rem;
}
h2 > a, h9 > a {
  text-transform: none;
  font-size: 1.4rem;
  margin-left: 25px;
}

h3 {
  font-size: 2rem;
  text-transform: none;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  text-transform: none;
}

h6 {
  color: rgb(153, 153, 153);
  font-size: 1.2rem;
}

h2 + h5 {
  margin-top: 0;
}

a {
  color: rgb(23, 136, 228);
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  text-decoration: none;
  outline: none;
  color: #126cb6 !important;
}
a:active, a:focus {
  text-decoration: none;
  outline: none;
}

.btn-facebook,
.btn-google,
.btn-blue,
.btn-black,
.btn-grey,
.btn-orange,
.btn-purple,
.btn-light-green {
  font-weight: 600;
  color: #ffffff !important;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-blue,
.btn-black,
.btn-orange,
.btn-light-green {
  text-transform: uppercase;
  padding: 11px 20px 10px 20px;
}

.btn-dark-grey {
  background: rgb(153, 153, 153);
  color: #ffffff !important;
}

.btn-light-green {
  background: #13ae47;
}
.btn-light-green:hover {
  background: #10973e;
  color: #ffffff !important;
}

.btn-orange {
  background: #f69422;
  color: #ffffff !important;
}
.btn-orange:hover {
  background: #ab5f07;
  color: #ffffff !important;
}

.btn-grey {
  background: rgb(235, 235, 235);
  color: #323232;
}
.btn-grey:hover {
  background: rgb(204, 204, 204);
  color: #323232 !important;
}

.btn-black {
  background: #393939;
}
.btn-black:hover {
  background: #000000;
  color: #ffffff !important;
}

.btn-blue {
  background: rgb(23, 136, 228);
}
.btn-blue:hover {
  background: rgb(35, 82, 124);
  color: #ffffff !important;
}

.btn-purple {
  background: #8a2be2;
}
.btn-purple:hover {
  background: #4b0082;
  color: #ffffff !important;
}

.btn-facebook {
  background: rgb(54, 83, 149);
}
.btn-facebook:hover {
  background: rgb(35, 82, 124);
  color: #ffffff !important;
}

.btn-google {
  background: rgb(221, 75, 57);
}
.btn-google:hover {
  background: #ab5f07;
  color: #ffffff !important;
}

.wrap-btn {
  margin: 40px 0 0px 0;
}

.wrap-btn .btn + .btn {
  margin-left: 10px;
}

.btn-bordered {
  display: inline-block;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #222222;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 0;
  font-size: 1.6rem;
}
.btn-bordered:hover {
  border: 1px solid #f69422;
}

.btn-like-project {
  display: block;
  padding: 5px 20px 8px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  border-radius: 4px;
  background: #13ae47;
}
.btn-like-project span {
  font-weight: normal;
  display: block;
  font-size: 1.5rem;
}
.btn-like-project:hover, .btn-like-project:active, .btn-like-project:focus {
  background: #10973e;
  color: #fff !important;
}

input[type=text].form-control,
input[type=email].form-control,
input[type=password].form-control,
input[type=number].form-control,
input[type=file].form-control,
input[type=tel].form-control,
select.form-control,
textarea.form-control {
  box-shadow: none;
  outline: none;
  border: 1px solid rgb(204, 204, 204);
  height: 38px;
  box-shadow: none;
}
input[type=text].form-control:hover, input[type=text].form-control:active, input[type=text].form-control:focus,
input[type=email].form-control:hover,
input[type=email].form-control:active,
input[type=email].form-control:focus,
input[type=password].form-control:hover,
input[type=password].form-control:active,
input[type=password].form-control:focus,
input[type=number].form-control:hover,
input[type=number].form-control:active,
input[type=number].form-control:focus,
input[type=file].form-control:hover,
input[type=file].form-control:active,
input[type=file].form-control:focus,
input[type=tel].form-control:hover,
input[type=tel].form-control:active,
input[type=tel].form-control:focus,
select.form-control:hover,
select.form-control:active,
select.form-control:focus,
textarea.form-control:hover,
textarea.form-control:active,
textarea.form-control:focus {
  outline: none;
  box-shadow: none !important;
}
input[type=text].form-control#variantID,
input[type=email].form-control#variantID,
input[type=password].form-control#variantID,
input[type=number].form-control#variantID,
input[type=file].form-control#variantID,
input[type=tel].form-control#variantID,
select.form-control#variantID,
textarea.form-control#variantID {
  background-color: #fff;
  border-radius: 0;
}

textarea.form-control {
  height: 125px;
  resize: none;
}
textarea.form-control.commentSection {
  height: 80px;
}

/* -- Select Field Customized -- */
.styled-select {
  position: relative;
  float: left;
  width: 100%;
}

.styled-select::before {
  font: normal normal normal 14px/1 FontAwesome;
  pointer-events: none !important;
  align-items: center;
  position: absolute;
  color: #393939;
  display: flex;
  height: 100%;
  right: 15px;
  z-index: 1;
  top: 0;
}
.styled-select.icon-angle::before {
  content: "\f107";
}
.styled-select.icon-search::before {
  content: "\f002";
  color: #72859d;
}

.truncated-text {
  --max-lines: 5;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Elements =================================================== */
p {
  margin: 5px 0 20px 0;
}

p + ul, p + ol {
  margin: 0px 0 20px 25px;
}

.ul-no-styled, .info-product-left .resources ul, .info-product-left .share ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

select + i.fa-angle-down {
  pointer-events: none !important;
}

.btn-block {
  margin-bottom: 5px;
}
.btn-block + .btn-block {
  margin-top: 0;
}

.btn.phone-number .phone-number-cta,
.btn.phone-number .phone-number-display {
  transition: opacity 200ms ease-in-out;
  justify-content: center;
  align-items: center;
  display: flex;
}
.btn.phone-number .phone-number-cta {
  opacity: 1;
}
.btn.phone-number .phone-number-display {
  position: absolute;
  opacity: 0;
}
.btn.phone-number:hover .phone-number-cta, .btn.phone-number:focus-within .phone-number-cta {
  opacity: 0;
}
.btn.phone-number:hover .phone-number-display, .btn.phone-number:focus-within .phone-number-display {
  opacity: 1;
}

header {
  position: sticky;
  z-index: 100;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  width: 100%;
  top: 0;
}

/* Nav Main =============================================== */
header .notificationMessage {
  height: 0;
  background-color: #ffb500;
}
header .notificationMessage.showMessages {
  height: auto;
  padding: 5px;
  transition: all 0.3s ease;
}
header .notificationMessage.showMessages a {
  text-align: center;
  display: block;
  font-weight: bold;
  color: #222222;
}
header .notificationMessage.showMessages span {
  text-align: center;
  display: block;
  font-weight: bold;
  color: #222222;
}
header nav {
  background: #3a3a39 !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  min-height: inherit !important;
}
header .navbar-collapse {
  padding: 0;
}
header .navbar-nav.nav-main {
  text-align: center;
  width: 100%;
  height: 40px;
  border-top: 1px solid #cccccc;
}
header .navbar-nav.nav-main > li {
  float: none;
  display: inline-block;
  position: relative;
}
header .navbar-nav.nav-main > li.sub-menu-dropdown {
  min-width: 175px;
}
header .navbar-nav.nav-main > li.sub-menu-dropdown:not(:first-child) {
  border-left: 1px solid #cccccc;
}
header .navbar-nav.nav-main > li > a {
  text-transform: uppercase;
  color: #ffffff !important;
  font-family: "HelveticaNeueLTStdBdIt";
  font-size: 2rem;
  padding: 10px 20px;
  position: relative;
}
header .navbar-nav.nav-main > li > a:not(.sub-menu-text):hover, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):active, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):focus {
  color: #f69422 !important;
  background: transparent !important;
}
header .navbar-nav.nav-main > li > a:not(.sub-menu-text):hover:before, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):active:before, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):focus:before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 24px;
  height: 25px;
  width: 4px;
  -moz-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  -o-transform: skew(-15deg, 0deg);
  -ms-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
  background: #ffffff;
}
header .navbar-nav.nav-main > li > a:not(.sub-menu-text):hover:before, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):active:before, header .navbar-nav.nav-main > li > a:not(.sub-menu-text):focus:before {
  left: 10px;
}
header .navbar-nav.nav-main > li > a.sub-menu-text {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
  text-transform: none;
  padding: 8px 18px;
}
header .navbar-nav.nav-main > li.active > a.sub-menu-text {
  background-color: transparent;
  color: #F78F1B !important;
  box-shadow: inset 0px -4px 0 #F78F1B;
}
header .navbar-nav.nav-main > li:not(.active) > a.sub-menu-text:hover {
  color: #F78F1B !important;
  box-shadow: inset 0px -4px 0 #ffffff;
}
header .navbar-nav.nav-main > li.active > a:not(.sub-menu-text) {
  color: #F78F1B !important;
  background-color: transparent;
}
header .navbar-nav.nav-main > li:hover > a:not(.sub-menu-text) {
  color: #f69422 !important;
  background: transparent !important;
}
header .navbar-nav.nav-main > li:hover > a:not(.sub-menu-text):before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 24px;
  height: 25px;
  width: 4px;
  -moz-transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  -o-transform: skew(-15deg, 0deg);
  -ms-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
  background: #ffffff;
}
header .navbar-nav.nav-main > li:hover > a:not(.sub-menu-text):not(.sub-menu-text):before {
  left: 10px;
}
header .navbar-nav.nav-main .dropdown-menu {
  top: 98%;
  padding: 0px 0;
  margin: 0;
  text-align: center;
  border: 0;
  border-radius: 0;
  background: #3a3a39 !important;
  -webkit-box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
}
header .navbar-nav.nav-main .dropdown-menu > li {
  max-width: 425px;
  display: inline-block;
  position: static;
  vertical-align: top;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 35px 5px;
  text-align: left;
}
header .navbar-nav.nav-main .dropdown-menu > li > a {
  position: static;
  vertical-align: top;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  text-align: right;
  padding: 0 0 15px 0;
  color: #ffffff;
}
header .navbar-nav.nav-main .dropdown-menu > li > a:hover {
  color: #f69422 !important;
}
header .navbar-nav.nav-main .dropdown-menu > li > a > img {
  max-width: 100%;
}
header .navbar-nav.nav-main .dropdown-menu > li > a > span > div {
  float: right;
  font-size: 1.6rem;
  font-weight: normal;
  color: #393939;
  text-transform: none;
}
header .navbar-nav.nav-main .dropdown-menu > li > span {
  display: block;
  padding-bottom: 15px;
}
header .navbar-nav.nav-main .dropdown-menu > li > span > a {
  float: right;
  font-size: 1.6rem;
  font-weight: normal;
  color: #393939;
  text-transform: none;
}
header .navbar-nav.nav-main .dropdown-menu > li > span > div {
  float: right;
  font-size: 1.6rem;
  font-weight: normal;
  color: #393939;
  text-transform: none;
}
header .navbar-nav.nav-main .dropdown-menu > li > input + span {
  margin-top: 20px;
  padding-bottom: 10px;
}
header .navbar-nav.nav-main .dropdown-menu > li > input + span + a {
  text-transform: none;
  font-size: 1.6rem;
  text-align: left;
  font-weight: normal;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul#trendsetters a img {
  border-radius: 50%;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul li {
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  padding-bottom: 6px;
  float: left;
  clear: both;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul a {
  color: #393939;
  display: block;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul a img {
  float: left;
  margin: 0 8px 0 0;
  max-width: 30px;
}
header .navbar-nav.nav-main .dropdown-menu > li > ul a strong {
  font-weight: normal;
  text-transform: uppercase;
  padding-top: 3px;
}
header .navbar-nav.nav-main .dropdown-menu > li a:hover, header .navbar-nav.nav-main .dropdown-menu > li a:active, header .navbar-nav.nav-main .dropdown-menu > li a:focus {
  background: transparent;
  color: #f69422;
  text-decoration: none;
}
header .navbar-nav.nav-main .dropdown-menu > li + li:before {
  content: "";
  position: absolute;
  width: 1px;
  background: rgb(235, 235, 235);
  top: 15px;
  height: calc(100% - 30px);
  bottom: 0;
  margin-left: -35px;
}
header .phone-top {
  text-align: center;
  position: absolute;
  top: 4px;
  z-index: 11;
  width: 100%;
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 600;
}

.phone-top-mob {
  position: static;
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  font-style: italic;
}

/* Header Right Nav ======================================== */
header .navbar-right {
  padding-right: 5px;
  margin: 0;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
}
header .navbar-right i.fa-shopping-cart, header .navbar-right .profile-icon {
  font-size: 2rem;
  float: left;
  margin: 0 8px 0 0;
}
header .navbar-right .profile-icon-small {
  height: 25px;
}
header .navbar-right > li {
  position: relative !important;
}
header .navbar-right > li > a {
  box-sizing: border-box;
  float: left;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.3rem !important;
  padding: 25px 12px 12px 12px !important;
  color: #ffffff !important;
  text-transform: uppercase;
}
header .navbar-right > li > a > span {
  float: left;
  margin-top: 1px;
}
header .navbar-right > li > a:hover {
  color: #f69422 !important;
}
header .navbar-right > li:nth-of-type(2) {
  border-top: 0;
}
header .navbar-right > li:nth-of-type(2):before {
  content: "";
  position: absolute;
  width: 1px;
  background: rgb(153, 153, 153);
  right: 0;
  top: 20px;
  bottom: 20px;
  height: 30px;
}
header .navbar-right > li:first-child {
  position: relative;
}
header .navbar-right > li:first-child:before {
  content: "";
  position: absolute;
  width: 1px;
  background: rgb(153, 153, 153);
  right: 0;
  top: 20px;
  bottom: 20px;
  height: 30px;
}
header .navbar-right > li:hover > a {
  color: #f69422 !important;
}
header .navbar-right li.active a {
  color: #f69422 !important;
  background: transparent !important;
}
header .navbar-right li:last-child > a {
  padding-left: 0 !important;
}
header .navbar-right li:last-child > a img {
  margin: 4px 0;
}
header .navbar-right .dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  width: 300px;
}
header .navbar-right .dropdown-menu li {
  font-size: 1.6rem;
  display: block;
  margin: 0 15px;
  font-weight: bold;
}
header .navbar-right .dropdown-menu li h2 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 10px 0 0 0;
  line-height: 130%;
}
header .navbar-right .dropdown-menu li h2 span {
  display: block;
  font-size: 1.3rem;
}
header .navbar-right .dropdown-menu li h4 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 10px 0 0 0;
  line-height: 130%;
}
header .navbar-right .dropdown-menu li h4 span {
  display: block;
  font-size: 1.3rem;
}
header .navbar-right .dropdown-menu li:last-child {
  border-top: 0;
  background: rgb(235, 235, 235);
  margin-top: 10px;
  margin-bottom: 5px;
}
header .navbar-right .dropdown-menu li a {
  padding: 8px 10px;
  font-weight: 600;
}
header .navbar-right .dropdown-menu li a:hover, header .navbar-right .dropdown-menu li a:active, header .navbar-right .dropdown-menu li a:focus {
  background: transparent;
  color: #f69422;
}
header .navbar-right .dropdown-menu li + li {
  text-align: center;
  border-top: 1px solid rgb(235, 235, 235);
}
header .navbar-right .dropdown-menu li:nth-of-type(2) {
  border-top: 0;
}
header .navbar-right .dropdown-menu:after, header .navbar-right .dropdown-menu:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
header .navbar-right .dropdown-menu:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ddd;
  border-width: 10px;
  margin-left: -10px;
}
header .navbar-right .dropdown-menu:after {
  border-color: rgba(202, 202, 202, 0);
  border-bottom-color: #fff;
  border-width: 9px;
  margin-left: -9px;
}
header .navbar-right .dropdown-menu .nav-select-country {
  padding: 0;
}

/* -- Nav Brand/Toggle Button CSS in Responsive -- */
header nav .navbar-header {
  width: 100%;
}
header nav .navbar-header .search-main {
  text-align: left;
  margin-top: 13px;
}
header nav .navbar-header .search-main .search-box {
  width: 400px;
}
header nav .navbar-header .search-main .search-box .global-search {
  min-height: 45px;
}
header nav .navbar-header .search-main .search-box input[type=text] {
  width: calc(100% - 50px);
  height: 45px;
  font-size: 1.6rem;
}
header nav .navbar-header .search-main .search-box .btn-search {
  width: 50px;
  height: 45px;
}
header nav .navbar-brand {
  height: auto;
  margin: 10px 20px;
  padding: 0;
}
header nav .navbar-brand img {
  height: 50px;
}
header nav .navbar-toggle {
  border: 3px solid transparent;
  border-color: #ffffff !important;
  border-radius: 0;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 40px;
  padding: 9px 6px;
}
header nav .navbar-toggle:hover, header nav .navbar-toggle:active, header nav .navbar-toggle:focus {
  background-color: transparent !important;
}
header nav .navbar-toggle span.icon-bar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  width: 26px;
  height: 3px;
}
header nav .navbar-toggle.open-menu span.icon-top {
  transform: rotate(45deg);
  top: 4px;
}
header nav .navbar-toggle.open-menu span.icon-mid {
  display: none;
}
header nav .navbar-toggle.open-menu span.icon-bottom {
  transform: rotate(-45deg);
  top: -3px;
}

/* Nav Mobile ======================================== */
.mobileNotificationMessage {
  height: 0;
  background-color: #ffb500;
  text-align: center;
  font-weight: bold;
  display: block;
  width: 100%;
  top: 0;
  z-index: 1039;
}
.mobileNotificationMessage.showMessages {
  height: auto;
  padding: 5px;
  transition: all 0.3s ease;
}
.mobileNotificationMessage.showMessages a {
  color: #222222;
}

.mobile-head-wrapper {
  position: sticky;
  z-index: 1039;
  display: none;
  top: 0;
}

.mobile-head {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 25px;
  background: #3a3a39;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
}
.mobile-head a img {
  display: inline-block;
  width: 128px;
}

.link-cart-mob,
.nav-toggle-mob {
  float: right;
  font-size: 2.5rem;
  position: absolute;
  right: 30px;
  top: 16px;
  color: #ffffff;
}
.link-cart-mob:hover, .link-cart-mob:active, .link-cart-mob:focus,
.nav-toggle-mob:hover,
.nav-toggle-mob:active,
.nav-toggle-mob:focus {
  color: #f69422;
}

.nav-toggle-mob {
  right: inherit;
  left: 30px;
  color: #ffffff;
}

.mobile-nav {
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: fixed;
  z-index: 1040;
  height: 100%;
  top: 0;
  left: -100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: none;
}

.mobile-nav-left {
  width: 75px;
  height: 100%;
  float: left;
  background: rgba(0, 0, 0, 0.7);
}

.mobile-nav-right {
  background: #3a3a39;
  width: 100%;
  margin-left: -75px;
  padding: 30px 40px;
  float: left;
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.mobile-nav-right button + a {
  display: inline-block;
}
.mobile-nav-right button + a img {
  width: 163px;
}
.mobile-nav-right > .mobile-menu {
  width: 100%;
  height: 90%;
  position: relative;
}
.mobile-nav-right > .mobile-menu .main-menu {
  margin-left: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mobile-nav-right > .mobile-menu .main-menu.hide-main-menu {
  margin-left: -300px;
}
.mobile-nav-right > .mobile-menu .sub-menu {
  position: absolute;
  top: 0px;
  left: 1100px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mobile-nav-right > .mobile-menu .sub-menu.show-sub-menu {
  left: 0px;
}
.mobile-nav-right ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-nav-right ul li {
  display: block;
}
.mobile-nav-right ul a {
  width: 100%;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "league_gothicregular", sans-serif;
  font-size: 2.8rem;
  padding: 15px 0 15px 0;
  display: inline-block;
  line-height: 100%;
  letter-spacing: 1px;
}
.mobile-nav-right ul a:hover, .mobile-nav-right ul a:active, .mobile-nav-right ul a:focus {
  color: #f69422;
}

.mobile-nav-footer {
  box-sizing: border-box;
  float: left;
  width: 100%;
  border-top: 1px solid rgb(204, 204, 204);
  text-align: center;
  padding-top: 30px;
}
.mobile-nav-footer > span {
  float: none;
  display: inline-block;
  margin-right: 0;
  height: auto;
  border-radius: 0;
  width: 64px;
}
.mobile-nav-footer > span img {
  height: auto;
}
.mobile-nav-footer h2 {
  display: block;
  color: #ffffff;
}
.mobile-nav-footer h2 span {
  display: block;
  text-transform: none;
  font-size: 1.5rem;
  margin-top: 3px;
}
.mobile-nav-footer a {
  margin-top: 10px;
  display: inline-block;
}
.mobile-nav-footer a img {
  width: 40px;
}

.close-mobile-nav {
  border: 0;
  background: transparent;
  color: #ffffff;
  right: 30px;
  top: 15px;
  outline: none;
  font-size: 3rem;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
}
.close-mobile-nav:hover, .close-mobile-nav:active, .close-mobile-nav:focus {
  outline: none;
  color: #f69422;
}

header nav .link-dealer-mob {
  display: none;
}

.cart-count {
  display: inline-block;
  text-align: center;
  vertical-align: text-bottom;
  background-color: rgb(10, 11, 9);
  color: #ffffff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  border-radius: 30px;
  margin-left: 5px;
}

.banner {
  padding: 0;
  background: #eee;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.banner .item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
}
.banner .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner .item img.spinner {
  height: 500px;
  object-fit: contain;
  background-color: #fff;
}
.banner .item .carousel-caption {
  right: inherit;
  left: inherit;
  text-align: left;
  width: inherit;
  text-shadow: none;
  top: 0;
  bottom: 0;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.banner .item .carousel-caption .caption-inner {
  width: 47%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.75);
}
.banner .item .carousel-caption .caption-inner.center {
  text-align: center !important;
  width: 100%;
  padding: 15px 0;
}
.banner .item .carousel-caption .caption-inner .mobile-captionBtn {
  display: none;
}
.banner .item .carousel-caption .caption-inner .promo-item-title {
  font-size: 1.8rem;
}
.banner .item .carousel-caption h1 {
  color: #ffffff;
  font-weight: 300;
  line-height: 1.3;
  margin: 0 0 15px 0;
}
.banner .item .carousel-caption h2 {
  color: white;
  font-weight: 300;
  line-height: 1.3;
  margin: 0 0 15px 0;
  font-size: 36px;
}
.banner .item .carousel-caption p {
  font-weight: 300;
  padding-right: 5%;
}
.banner .item .carousel-caption.center p {
  padding-right: 0;
}
.banner .item .carousel-caption .btn-bordered {
  border: 2px solid #ffffff;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 300;
  margin: 5px 0 0 0;
  font-size: 1.3rem;
  letter-spacing: 1px;
  padding: 12px 30px 11px 30px;
}
.banner .item .carousel-caption .btn-bordered:hover {
  background: #ffffff;
  color: #323232 !important;
}
.banner .carousel-indicators li {
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  position: relative;
}
.banner .carousel-indicators li.active {
  background: transparent;
}
.banner .carousel-indicators li.active:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: rgba(255, 255, 255, 0.6);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.banner .carousel-control {
  background: transparent;
  width: 5%;
  display: none;
}
.banner .carousel-indicators {
  left: 0;
  bottom: 0;
  padding: 10px;
  margin: 0;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4588235294);
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
  visibility: hidden;
}

.prx {
  position: absolute;
  color: #FFF;
  font-size: 1.5em;
  overflow: hidden;
}

.wrap-accordion {
  box-sizing: border-box;
  float: left;
  width: 100%;
  clear: both;
  margin-top: 10px;
}
.wrap-accordion h5.accordion-label {
  color: #f69422;
  font-size: 1.6rem;
  float: left;
  width: 100px;
  margin-top: 30px;
}
.wrap-accordion .shadow-box > input[type=radio] {
  float: left;
  box-shadow: none;
  border: 0;
  opacity: 0;
  position: absolute;
  height: 45px;
  width: 85%;
  cursor: pointer;
  z-index: 50;
}
.wrap-accordion .shadow-box > input[type=radio]:checked + .accordion-head {
  border: 2px solid #f99839;
  min-height: 45px;
}
.wrap-accordion .shadow-box > input[type=checkbox] {
  float: left;
  box-shadow: none;
  border: 0;
  opacity: 0;
  position: absolute;
  height: 40px;
  width: 100%;
  cursor: pointer;
  z-index: 50;
}
.wrap-accordion .shadow-box .tall {
  height: 62px !important;
}
.wrap-accordion .shadow-box .x-tall {
  height: 85px;
}
.wrap-accordion .shadow-box.selected .radio-button {
  color: rgb(23, 136, 228);
  border-color: #1788e4;
}
.wrap-accordion .addressSearch {
  padding-bottom: 5px;
}

.payment-add-form.selected,
.address-form.selected {
  border: 2px solid #1788e4;
}
.payment-add-form .has-error,
.address-form .has-error {
  border-color: #a94442 !important;
}

.has-error-glow {
  box-shadow: 0 0 8px red !important;
  border-radius: 5px;
}

.save-to-account {
  margin-bottom: 15px !important;
  margin-right: 5px !important;
}

a.shadow-box {
  padding: 15px 25px;
  margin-top: 0;
  display: block;
  float: left;
  width: 100%;
  text-align: center;
}

a.shadow-box + .row,
div.shadow-box + .row {
  margin: 0;
}
a.shadow-box + .row > .col-sm-12,
div.shadow-box + .row > .col-sm-12 {
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  padding: 15px 25px;
  margin-top: 2px;
}
a.shadow-box + .row > .col-sm-12 .col-sm-12 label,
div.shadow-box + .row > .col-sm-12 .col-sm-12 label {
  margin-bottom: 15px;
}

#choose-payment-option + .card .paymentOptionForm .missing-options {
  border-radius: 4px;
  background: white;
  padding: 15px 25px;
}

.accordion-in {
  float: left;
  width: 100%;
}
.accordion-in .shadow-box {
  margin-bottom: 5px;
  font-size: 1.6rem;
  color: #323232;
  font-weight: 600;
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.accordion-in .shadow-box .col-sm-12 {
  font-weight: 500;
}
.accordion-in .shadow-box .col-sm-12 h3 {
  font-size: 1.6rem;
}
.accordion-in .shadow-box .col-sm-12 > div {
  padding-bottom: 10px;
}

.accordion-head {
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 100%;
  padding: 0px 25px 10px 0;
}
.accordion-head div img, .accordion-head div span {
  float: left;
}
.accordion-head div img {
  margin-right: 10px;
  width: 40px;
  margin-top: 8px;
}
.accordion-head div span {
  margin-top: 10px;
}
.accordion-head div span .rate-span {
  float: none;
  font-weight: 500;
}
.accordion-head div span .rate-span.discounted {
  text-decoration: line-through;
  opacity: 0.6;
}
.accordion-head div span.btn-purple {
  margin: 10px auto;
  display: block;
  float: none;
  width: 250px;
}
.accordion-head div span .best-rate-span {
  float: none;
  font-weight: 700;
  background-color: transparent;
  text-align: left;
  color: #f69422;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.accordion-head div span .best-rate-span.discounted {
  color: #5bb75b;
}
.accordion-head .radio-select {
  font-size: 1.2rem;
  position: absolute;
  top: 3px;
  left: 5px;
  color: rgb(204, 204, 204);
  width: 35px;
  height: 35px;
  text-align: center;
  padding-top: 10px;
  padding-right: 1px;
}
.accordion-head .radio-select > i.fa-angle-down {
  display: none;
}
.accordion-head a > i.fa-angle-down {
  display: none;
}
.accordion-head a > i.fa-angle-up {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-right: 20px;
  padding-top: 10px;
  font-size: 20px;
  z-index: 9;
}
.accordion-head > a.collapsed > i.fa-angle-up {
  display: none;
}
.accordion-head a.collapsed > i.fa-angle-down {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: right;
  padding-right: 20px;
  padding-top: 10px;
  font-size: 20px;
  z-index: 9;
}
.accordion-head.cc-option {
  padding: 0px 25px 0px 0;
  min-height: 40px;
}

.cart-checkout .accordion-head {
  padding-right: 0;
}

#accordionShipping .accordion-head {
  margin-bottom: 0;
  padding-bottom: 0;
}
#accordionShipping div span {
  margin-bottom: 10px;
}

.radio-container {
  width: 0;
}

.address-name {
  margin-right: 15px;
}

.address-line {
  font-weight: normal;
  white-space: pre;
}

#productCarousel, #galleryCarousel, #vue-creation-details #creationCarousel {
  float: left;
  width: calc(62.8% - 110px);
}
#productCarousel .carousel-inner, #galleryCarousel .carousel-inner, #vue-creation-details #creationCarousel .carousel-inner {
  height: 100%;
  overflow: hidden;
}
#productCarousel .product-images, #galleryCarousel .product-images, #vue-creation-details #creationCarousel .product-images {
  border-radius: 4px;
}
#productCarousel .item, #galleryCarousel .item, #vue-creation-details #creationCarousel .item {
  text-align: center;
  width: 100%;
  height: 100%;
}
#productCarousel .item img, #galleryCarousel .item img, #vue-creation-details #creationCarousel .item img {
  width: 100%;
  display: inline-block;
}
#productCarousel .item img:first-child, #galleryCarousel .item img:first-child, #vue-creation-details #creationCarousel .item img:first-child {
  object-fit: cover;
  min-height: 475px;
  max-height: 475px;
  height: 100%;
  width: 100%;
}
#productCarousel .item .item-in, #galleryCarousel .item .item-in, #vue-creation-details #creationCarousel .item .item-in {
  width: 100%;
  height: 100%;
  display: grid;
}
#productCarousel .item .item-in .fa-search-minus, #galleryCarousel .item .item-in .fa-search-minus, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus, #productCarousel .item .item-in .fa-search-plus, #galleryCarousel .item .item-in .fa-search-plus, #vue-creation-details #creationCarousel .item .item-in .fa-search-plus {
  color: #323232;
  font-size: 3rem;
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 999;
  font-weight: normal;
}
#productCarousel .item .item-in .fa-search-minus span, #galleryCarousel .item .item-in .fa-search-minus span, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus span, #productCarousel .item .item-in .fa-search-plus span, #galleryCarousel .item .item-in .fa-search-plus span, #vue-creation-details #creationCarousel .item .item-in .fa-search-plus span {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
  vertical-align: middle;
  padding-left: 5px;
}
#productCarousel .item .item-in .fa-search-minus, #galleryCarousel .item .item-in .fa-search-minus, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus {
  display: none;
}

#creationCarousel img:first-child {
  object-fit: contain !important;
}

#galleryCarousel, #vue-creation-details #creationCarousel,
#creationCarousel {
  float: left;
  width: 68%;
  height: auto;
}
#galleryCarousel .carousel-inner, #vue-creation-details #creationCarousel .carousel-inner,
#creationCarousel .carousel-inner {
  height: auto;
  overflow: hidden;
  border-radius: 4px 2px 0 0;
}
#galleryCarousel .item, #vue-creation-details #creationCarousel .item,
#creationCarousel .item {
  text-align: center;
  width: 100%;
  height: 100%;
}
#galleryCarousel .item img, #vue-creation-details #creationCarousel .item img,
#creationCarousel .item img {
  width: 100%;
  display: inline-block;
}
#galleryCarousel .item img:first-child, #vue-creation-details #creationCarousel .item img:first-child,
#creationCarousel .item img:first-child {
  min-height: 575px;
  max-height: 575px;
  object-fit: cover;
}
#galleryCarousel .item .item-in, #vue-creation-details #creationCarousel .item .item-in,
#creationCarousel .item .item-in {
  width: 100%;
  display: grid;
}

#slider-thumbs {
  margin-top: 10px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
#slider-thumbs .product-media {
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
}
#slider-thumbs ul {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 12px;
  justify-items: center;
}
#slider-thumbs .list-inline {
  margin: auto;
  width: auto;
}
#slider-thumbs li {
  padding: 5px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
#slider-thumbs li .overlay {
  position: absolute;
  top: 30%;
  color: #222;
  line-height: 30px;
  text-shadow: 0px 0px 8px #f99839;
  z-index: 3;
  left: 30%;
  font-size: 2.5rem;
}
#slider-thumbs li img {
  z-index: 2;
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 4px;
}
#slider-thumbs li.selected {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  outline: 2px solid rgb(249, 151, 0);
}

.zoom {
  display: inline-block;
  position: relative;
}
.zoom:after {
  content: "";
  display: block;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  right: 0;
}
.zoom img {
  display: block;
}
.zoom img::selection {
  background-color: transparent;
}

/* External Fonts ======================================== */
@font-face {
  font-family: "league_gothicregular";
  src: url("../fonts/leaguegothic-regular-webfont.woff2") format("woff2"), url("../fonts/leaguegothic-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: 0.5;
  cursor: text;
}

/* Page =================================================== */
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: #e1e1e1;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1.45;
  color: #222222;
  font-weight: 500;
  min-height: 100%;
  overflow-x: hidden;
  background: #f1f1f1;
  position: relative;
  float: left;
  display: block;
}

.vue-app {
  min-height: 100vh;
  float: left;
  width: 100%;
  background-color: #fafafa;
}

.headerBody {
  text-align: center;
  font-size: 1.6rem;
}

.container-fluid {
  max-width: 1865px;
}
.container-fluid h1 {
  display: inline-block;
}

/* Common Components =================================================== */
/* -- Select2 positioning issues -- */
.select2-container--open .select2-dropdown--above {
  margin-top: 18px !important;
}

/* -- Profile Icon -- */
.profile-icon-big {
  width: 56px;
  height: 56px;
  float: left;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-icon-big > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -- Home Black Strip -- */
.strip-black {
  background: #3a3a39; /* Old browsers */
  color: #ffffff;
  padding: 25px 15px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.strip-black ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.strip-black ul li {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 35px;
  vertical-align: middle;
}
.strip-black ul li:first-child {
  border-right: 1px solid #666;
}
.strip-black ul li a {
  color: #ffffff;
  display: block;
  position: relative;
  font-size: inherit;
  text-transform: uppercase;
  margin: 0;
}
.strip-black ul li a:hover {
  color: #f69422 !important;
}
.strip-black ul li a .profile-icon-over {
  position: absolute;
  top: 50px;
}
.strip-black ul li a .profile-icon-over > img {
  width: 25px;
  margin: -7px 0 0 15px;
  position: absolute;
  left: 0;
}
.strip-black ul li a .profile-icon-over .icon-overlay {
  width: 198px;
  height: 88px;
  background: url("../img/bg-pattern.png") center center no-repeat;
  background-size: cover;
  padding: 10px;
  display: none;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 20px;
}
.strip-black ul li a .profile-icon-over .icon-overlay img {
  float: left;
  margin: 15px 5px 0 0;
}
.strip-black ul li a .profile-icon-over .icon-overlay h3 {
  color: #222222;
  font-weight: 600;
  font-style: italic;
  font-size: 1.52rem;
  margin-top: 17px;
}
.strip-black ul li a .profile-icon-over .icon-overlay h3 span {
  display: block;
  font-style: normal;
  font-weight: 500;
}
.strip-black ul li a:hover .icon-overlay {
  display: block;
  opacity: 1;
}
.strip-black ul li a.btn-log-in {
  padding: 17px 20px 17px 0;
}
.strip-black ul li .profile-icon-big {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.strip-black ul li + li {
  text-align: right;
}
.strip-black ul li:first-child {
  padding-left: 0;
}
.strip-black ul li:last-child {
  padding-right: 0;
}
.strip-black h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  position: relative;
}
.strip-black h2 i {
  margin-right: 10px;
}
.strip-black h2 .block-terms {
  right: -25px;
  position: absolute;
  top: 0;
  width: 20px;
  color: #fff;
}
.strip-black h2 .block-terms:before {
  margin-left: -5px;
}
.strip-black h2 .block-terms i {
  font-size: 1.7rem;
}
.strip-black h2 .block-terms .info-terms {
  text-transform: initial;
  width: 240px;
  right: -25px;
}
.strip-black h3 {
  font-size: 1.4rem;
  margin: 3px 0 5px 0;
  font-weight: 500;
}

.alert-success span {
  font-size: 2.5rem;
  color: #f99839;
}

.shop-container {
  width: 100%;
  margin: 0;
  padding: 0 0 0 30px;
  max-width: 1900px;
  margin: 0 auto;
}

.shop-filter-bs-offset {
  width: calc(83% - 75px);
}

/* -- Breadcrumb CSS -- */
.mid-inner .breadcrumb {
  margin-bottom: 0;
  background: transparent;
  font-weight: 600;
  font-size: 1.6rem;
  color: #393939;
  padding: 10px 30px;
}
.mid-inner .breadcrumb a {
  color: #393939;
  font-weight: 500;
}
.mid-inner .breadcrumb a.viewAllBtn {
  font-size: 1.2rem;
  color: #cc6909;
  cursor: pointer;
}
.mid-inner .breadcrumb span {
  display: inline-block;
  margin: 0 7px;
  color: rgb(153, 153, 153);
}
.mid-inner .breadcrumb a.btn-print {
  float: right;
  margin-left: 15px;
}
.mid-inner .breadcrumb a.btn-print i {
  margin-right: 5px;
}

.block-frequently h3 {
  margin-bottom: 20px;
}
.block-frequently .icon-math {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  font-size: 4.5rem;
  width: 46px;
  font-weight: bold;
  padding-left: 3px;
}
.block-frequently .product-in {
  width: 185px;
  margin: 0 15px 15px 0;
}
.block-frequently .product-in p {
  margin-bottom: 0;
}
.block-frequently .product-box {
  padding-bottom: 15px;
  margin-top: 0;
  margin-bottom: 15px;
}
.block-frequently .thumb-view-gallery {
  float: left;
  width: 220px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.block-frequently .thumb-view-gallery a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.block-frequently .thumb-view-gallery a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-frequently .thumb-view-gallery a span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 1.6rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.block-frequently .thumb-view-gallery a:hover span {
  background: rgba(18, 108, 182, 0.8);
}
.block-frequently .frequently-subtotal {
  width: 175px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: right;
}
.block-frequently .frequently-subtotal p {
  margin-top: 0;
  font-size: 1.6rem;
}

.wrap-knowledge-base {
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.wrap-knowledge-base .knowledge-base {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-top: 10px;
}
.wrap-knowledge-base .knowledge-base .search-in input {
  border: 3px solid rgb(235, 235, 235);
  width: 100%;
  height: 60px;
  background: transparent;
  padding-left: 50px;
  font-size: 1.8rem;
}

.knowledge-base-tabs {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.knowledge-base-tabs .wrap-nav-tabs {
  box-sizing: border-box;
  float: left;
  width: 100%;
  text-align: center;
}
.knowledge-base-tabs .nav-tabs {
  border: none;
}
.knowledge-base-tabs .nav-tabs li, .knowledge-base-tabs .nav-tabs li > a, .knowledge-base-tabs .nav-tabs li.active a, .knowledge-base-tabs .nav-tabs li.active a:focus, .knowledge-base-tabs .nav-tabs li.active a:active, .knowledge-base-tabs .nav-tabs li.active {
  color: #222;
  outline: 0;
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;
  margin-right: 0px;
}
.knowledge-base-tabs .nav-tabs li:hover, .knowledge-base-tabs .nav-tabs li > a:hover, .knowledge-base-tabs .nav-tabs li.active a:hover, .knowledge-base-tabs .nav-tabs li.active a:focus:hover, .knowledge-base-tabs .nav-tabs li.active a:active:hover, .knowledge-base-tabs .nav-tabs li.active:hover {
  color: #222 !important;
  background-color: #ffffff;
  margin-right: 0px;
}
.knowledge-base-tabs .nav-tabs li:hover, .knowledge-base-tabs .nav-tabs li > a:hover {
  background-color: #dcdcdc;
}
.knowledge-base-tabs .nav-tabs .fa {
  margin-right: 8px;
  font-size: 16px;
}
.knowledge-base-tabs .nav-tabs .badge {
  transition: background-color 200ms ease-in-out;
  text-transform: uppercase;
  background-color: #999;
  letter-spacing: 0.5px;
  border-radius: 3px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 11px;
  padding: 0 8px;
  color: white;
}
.knowledge-base-tabs .nav-tabs li.active .badge {
  background-color: #333;
}
.knowledge-base-tabs h3 {
  font-weight: bold;
}
.knowledge-base-tabs .text-center > h3 {
  margin-bottom: 20px;
}
.knowledge-base-tabs .tab-content {
  box-sizing: border-box;
  float: left;
  width: 100%;
  background-color: #ffffff;
  padding: 50px;
  border: 1px solid #ddd;
}
.knowledge-base-tabs .tab-content .active {
  display: grid;
}
.knowledge-base-tabs .tab-content .btn-alt-outline {
  width: fit-content;
  margin: 0 auto;
}
.knowledge-base-tabs .tab-content .alert.success {
  color: #10973e;
  margin: 0;
  padding: 0;
  margin-top: -25px;
}
.knowledge-base-tabs .tab-content .alert.error {
  color: rgb(208, 1, 27);
  margin: 0;
  padding: 0;
  margin-top: -25px;
}
.knowledge-base-tabs .qa-heading {
  display: grid;
}
.knowledge-base-tabs .panel-group {
  margin-bottom: 0;
  display: grid;
}
.knowledge-base-tabs .panel-group .panel-heading {
  width: 100%;
  padding: 30px 15px 0 15px;
  margin: auto;
}
.knowledge-base-tabs .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}
.knowledge-base-tabs .panel-group .panel {
  border-top: 1px solid #ddd;
  margin-bottom: 25px;
  box-shadow: none;
  border-radius: 0;
}
.knowledge-base-tabs .panel-group .panel .btn-link {
  color: inherit;
}
.knowledge-base-tabs .panel-group .panel .panel-collapse, .knowledge-base-tabs .panel-group .panel .collapse {
  padding: 0 15px;
}
.knowledge-base-tabs .panel-group .panel:first-of-type {
  border-top: none;
}
.knowledge-base-tabs .panel-group .qa-label {
  margin: 0;
}
.knowledge-base-tabs .panel-group .qa-question, .knowledge-base-tabs .panel-group .qa-answer {
  word-break: break-word;
  color: #000;
  user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.knowledge-base-tabs .panel-group .qa-question:hover, .knowledge-base-tabs .panel-group .qa-answer:hover {
  color: #000 !important;
}
.knowledge-base-tabs .panel-group .qa-question .row, .knowledge-base-tabs .panel-group .qa-answer .row {
  margin-bottom: 10px;
}
.knowledge-base-tabs .panel-group .qa-question .qa-content, .knowledge-base-tabs .panel-group .qa-answer .qa-content {
  font-weight: 400;
}
.knowledge-base-tabs .panel-group .qa-question .qa-content p, .knowledge-base-tabs .panel-group .qa-answer .qa-content p {
  margin: 5px 0 10px 0;
}
.knowledge-base-tabs .panel-group .qa-question .qa-content p img, .knowledge-base-tabs .panel-group .qa-answer .qa-content p img {
  max-width: 75%;
  margin: 0 auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: auto;
}
.knowledge-base-tabs .panel-group .qa-question .qa-content .qa-author, .knowledge-base-tabs .panel-group .qa-answer .qa-content .qa-author {
  color: #757575;
  font-size: 1.3rem;
  font-style: italic;
}
.knowledge-base-tabs .panel-group .qa-question .qa-count, .knowledge-base-tabs .panel-group .qa-answer .qa-count {
  float: right;
  text-transform: uppercase;
  background-color: #333;
  letter-spacing: 0.5px;
  border-radius: 3px;
  line-height: 20px;
  font-weight: 600;
  font-size: 10px;
  padding: 0 8px;
  color: #ffffff;
  width: auto;
  display: inline-flex;
}
.knowledge-base-tabs .panel-group .qa-question .qa-count span, .knowledge-base-tabs .panel-group .qa-answer .qa-count span {
  color: #ffffff;
}
.knowledge-base-tabs .panel-group .qa-question .qa-date, .knowledge-base-tabs .panel-group .qa-answer .qa-date {
  font-size: 1.1rem;
  color: #757575;
  font-weight: 400;
  float: right;
  margin: 0;
  text-align: right;
}
.knowledge-base-tabs .panel-group .panel-body {
  padding: 0;
}
.knowledge-base-tabs .panel-group .panel-body .qa-reply {
  word-break: break-word;
  font-weight: 400;
}
.knowledge-base-tabs .qa-info-q, .knowledge-base-tabs .qa-info-a {
  margin-top: 20px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.knowledge-base-tabs .qa-info-q h2, .knowledge-base-tabs .qa-info-a h2 {
  font-size: 1.5rem;
  text-transform: none;
}
.knowledge-base-tabs .qa-info-q h2 p, .knowledge-base-tabs .qa-info-a h2 p {
  margin: 0;
}
.knowledge-base-tabs .qa-info-q h6, .knowledge-base-tabs .qa-info-a h6 {
  margin-bottom: 0px;
  margin-top: 10px;
  text-transform: none;
}
.knowledge-base-tabs .qa-info-q h3, .knowledge-base-tabs .qa-info-a h3 {
  display: inline-block;
}
.knowledge-base-tabs .qa-info-q h3 + a, .knowledge-base-tabs .qa-info-a h3 + a {
  float: right;
  margin-top: 15px;
}
.knowledge-base-tabs .qa-info-q h3 + a + hr, .knowledge-base-tabs .qa-info-a h3 + a + hr {
  margin: 10px 0 0;
  border-top: 1px solid;
}
.knowledge-base-tabs .qa-info-q h3 + hr, .knowledge-base-tabs .qa-info-a h3 + hr {
  margin: 10px 0 0;
  border-top: 1px solid;
}
.knowledge-base-tabs .qa-info-a {
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
}
.knowledge-base-tabs .qa-info-a strong {
  width: 100%;
  float: left;
}
.knowledge-base-tabs .qa-info-a strong span {
  color: #f69422;
  font-size: 1.6rem;
  font-weight: 600;
}
.knowledge-base-tabs .qa-info-q.border-top {
  border-top: 1px solid rgb(235, 235, 235);
  padding-top: 20px;
  margin-top: 15px;
}
@media only screen and (max-width: 1024px) {
  .knowledge-base-tabs img {
    height: auto;
    width: 100%;
  }
}

.calc-heading {
  text-transform: none;
  color: #222222;
  font-weight: normal;
}
.calc-heading sup {
  top: -0.02;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
}

.mixing-calculator {
  background-color: #fff;
  padding: 40px;
}
.mixing-calculator .select2 {
  float: none;
  box-shadow: 0 6px 6px -5px;
  color: rgba(34, 34, 34, 0.1607843137);
  margin-bottom: 10px;
}
.mixing-calculator .btn {
  border-radius: 0px;
  width: 49%;
}
.mixing-calculator .product-container {
  border: 1px solid rgba(57, 57, 57, 0.0784313725);
  background-color: #fff;
  padding: 32px 15px;
  box-shadow: 0 3px 6px -5px;
  color: rgba(34, 34, 34, 0.1607843137);
  text-align: center;
  transition: all 0.25s ease;
  min-width: 215px;
}
.mixing-calculator .product-container h3 {
  margin: 10px 0 5px 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: #222222;
}
.mixing-calculator .product-container p {
  color: #222222;
}
.mixing-calculator .product-container .fa {
  font-size: 9.5em;
}
.mixing-calculator .product-container img {
  width: 100px;
  height: auto;
}
.mixing-calculator .product-container:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  box-shadow: 0 3px 6px 0px;
}
.mixing-calculator .calc-container {
  height: auto;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "step1 step2 cat-weight" "step1 step2 total-weight" "error error error";
}
.mixing-calculator .calc-container .step-one {
  grid-area: step1;
  padding: 25px;
  border: 1px solid rgba(57, 57, 57, 0.0784313725);
  background-color: #f1f1f1;
  min-height: 341px;
}
.mixing-calculator .calc-container .step-one span {
  border-radius: 0px !important;
}
.mixing-calculator .calc-container .step-one h2 {
  margin: 0 0 15px 0;
  text-align: center;
}
.mixing-calculator .calc-container .step-two {
  grid-area: step2;
  padding: 25px;
  border: 1px solid rgba(57, 57, 57, 0.0784313725);
  background-color: #f1f1f1;
}
.mixing-calculator .calc-container .step-two button[disabled] {
  pointer-events: none;
}
.mixing-calculator .calc-container .step-two label {
  font-weight: 400;
}
.mixing-calculator .calc-container .step-two input {
  border-radius: 0px;
}
.mixing-calculator .calc-container .step-two h2 {
  margin: 0 0 15px 0;
  text-align: center;
}
.mixing-calculator .calc-container .catalyst-weight {
  grid-area: cat-weight;
  padding: 25px;
  border: 1px solid rgba(57, 57, 57, 0.0784313725);
  background-color: #f1f1f1;
  text-align: center;
}
.mixing-calculator .calc-container .total-weight {
  grid-area: total-weight;
  padding: 25px;
  border: 1px solid rgba(57, 57, 57, 0.0784313725);
  background-color: #f1f1f1;
  text-align: center;
}
.mixing-calculator .calc-container .error-msg {
  grid-area: error;
  padding: 25px;
  text-align: center;
  border: 1px solid #a94442;
}
.mixing-calculator .calc-container .result-label {
  font-size: 45px;
  font-weight: 700;
  margin: 0 auto;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mixing-calculator .mixing-calculator-notes div:first-of-type {
  margin-bottom: 20px;
}
.mixing-calculator .mixing-calculator-notes img {
  max-width: 100%;
  min-width: 100%;
}
.mixing-calculator .mixing-calculator-notes ol, .mixing-calculator .mixing-calculator-notes ul {
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
  margin-bottom: 20px;
}
.mixing-calculator .mixing-calculator-notes hr {
  margin-top: 5px;
  margin-bottom: 10px;
}
.mixing-calculator .mixing-calculator-notes h2 {
  margin-bottom: 10px;
  color: #f69521;
  font-family: Teko, sans-serif;
}

@media (max-width: 991px) {
  .calc-heading {
    padding: 15px 0;
  }
  .mixing-calculator {
    padding: 10px !important;
  }
  .mixing-calculator .btn {
    width: 100%;
    margin-bottom: 8px;
  }
  .calc-container {
    grid-template-areas: "step1" "step2" "error" "cat-weight" "total-weight" !important;
  }
  .step-two {
    display: block !important;
  }
  .product-container {
    padding: 15px;
  }
}
.boxes-documents .col-md-2:hover .favorite-doc, .boxes-documents .col-md-2:hover .favorite-article, .boxes-documents .col-xs-12:hover .favorite-doc, .boxes-documents .col-xs-12:hover .favorite-article, .boxes-documents .col-sm-12:hover .favorite-doc, .boxes-documents .col-sm-12:hover .favorite-article {
  visibility: visible;
  opacity: 0.6;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
.boxes-documents .col-md-2 .favorite-doc, .boxes-documents .col-xs-12 .favorite-doc, .boxes-documents .col-sm-12 .favorite-doc {
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 0;
  cursor: pointer;
  width: 29px;
  height: 55px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
.boxes-documents .col-md-2 .favorite-doc.document-highlight, .boxes-documents .col-xs-12 .favorite-doc.document-highlight, .boxes-documents .col-sm-12 .favorite-doc.document-highlight {
  visibility: visible;
  opacity: 1;
  background: url("../img/arrow-bookmark.svg") -138px 0 no-repeat;
  background-size: 166px auto;
  left: 21px;
}
.boxes-documents .col-md-2 .favorite-article, .boxes-documents .col-xs-12 .favorite-article, .boxes-documents .col-sm-12 .favorite-article {
  position: absolute;
  z-index: 3;
  right: 15px;
  top: -30px;
  cursor: pointer;
  width: 29px;
  height: 55px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
.boxes-documents .col-md-2 .favorite-article.article-highlight, .boxes-documents .col-xs-12 .favorite-article.article-highlight, .boxes-documents .col-sm-12 .favorite-article.article-highlight {
  visibility: visible;
  opacity: 1;
  background: url("../img/arrow-bookmark.svg") -138px 0 no-repeat;
  background-size: 166px auto;
  right: 15px;
}
.boxes-documents .shadow-box .favorite-article {
  right: 30px;
  top: -30px;
}
.boxes-documents .document-item {
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
}
.boxes-documents .document-item .favorite-doc-true,
.boxes-documents .document-item .favorite-doc-false {
  transition: color 0.3s;
  margin-top: 0.15rem;
  font-size: 2.5rem;
  cursor: pointer;
  min-width: 4rem;
  flex: 0 1 4rem;
}
.boxes-documents .document-item .favorite-doc-false {
  color: #888888;
}
.boxes-documents .document-item .favorite-doc-false:hover {
  color: #7ED323;
}
.boxes-documents .document-item .favorite-doc-true {
  color: #7ED323;
}
.boxes-documents .document-item > a {
  flex: 0 1 auto;
}
.boxes-documents .document-item + .document-item {
  border-top: 1px solid #EEEEEE;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}
.boxes-documents a {
  position: relative;
  line-height: 1.365;
  display: inline-block;
  color: #222222;
}
.boxes-documents a:hover {
  color: #f69422 !important;
}
.boxes-documents a.mobileLink {
  display: none;
}
.boxes-documents a > strong,
.boxes-documents a > small {
  display: block;
}
.boxes-documents a > small {
  color: #888888;
}
.boxes-documents .documents-collapse-content {
  pointer-events: none;
  padding: 35px 25px;
  text-align: left;
  overflow: hidden;
  max-height: 0;
  display: none;
  float: left;
  height: 0;
}
.boxes-documents .documents-collapse-content.open {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF;
  max-height: 10000000px;
  pointer-events: all;
  display: block;
  height: auto;
}

.search-container .wrap-video h2 {
  text-align: center;
}
.search-container .wrap-video #loader {
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 0;
  background: white;
  border: solid 2px #F5A623;
  border-radius: 60px;
  font-size: 16px;
  color: #F5A623;
  line-height: 120px;
  outline: none;
  font-weight: bold;
  margin: 15px auto 0;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  text-align: center;
  margin-left: 15px;
}
.search-container .wrap-video .boxes-documents {
  margin-bottom: 20px;
}
.search-container .wrap-video .boxes-documents .documentList {
  height: auto;
  padding: 20px 10px 12px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.search-container .wrap-video .boxes-documents .documentList .documentLink {
  min-height: auto;
  margin: 0;
  text-align: left;
}
.search-container .wrap-video .boxes-documents .documentList .documentLink hr {
  margin: 15px 0 10px;
}
.search-container .wrap-video .boxes-documents .documentList .documentLink a {
  color: rgb(23, 136, 228);
}
.search-container .wrap-video .boxes-documents .documentList .documentLink a:hover {
  color: #126cb6 !important;
}
.search-container .wrap-video .boxes-documents .documentList a img {
  width: 75%;
}
.search-container .wrap-video h6 span {
  border: 1px solid;
  padding: 1px 3px;
  line-height: 1.8rem;
  border-radius: 4px;
}
.search-container .wrap-video .videoList .col-xs-12 {
  margin-bottom: 0;
}
.search-container .filter-box {
  width: 380px;
  height: 43px;
  margin: 35px auto 0;
  float: none;
}
.search-container .filter-box li {
  width: 50%;
  display: inline-block;
}
.search-container .filter-box li a {
  display: inline-block;
  width: 100%;
}
.search-container .filter-box li a.disabled {
  pointer-events: none;
  opacity: 0.4;
  background-color: #f7f7f7;
}

.wrap-hero {
  padding-top: 0 !important;
}
.wrap-hero .hero-large {
  box-sizing: border-box;
  float: left;
  width: 100%;
  background: url("/img/CERAKOTE BLACK RAIN HERO.jpg") center center no-repeat;
  background-size: cover;
  height: 375px;
  position: relative;
  color: #ffffff;
}
.wrap-hero .hero-large:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.wrap-hero .hero-large .container {
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.wrap-hero .hero-large .container h1 {
  font-size: 3.6rem;
  color: #ffffff;
  margin: 0 0 10px 0;
}
.wrap-hero .hero-large .container h1 sup {
  top: -0.8em;
  font-size: 50%;
}
.wrap-hero .hero-large .container p {
  font-size: 1.8rem;
  margin-bottom: 40px;
  letter-spacing: 1px;
  padding: 0 16%;
}
.wrap-hero .hero-large .container form {
  background-color: #ffffff;
  opacity: 0.8;
  border-radius: 6px;
}
.wrap-hero .hero-large .container input[type=text] {
  border: 2px solid rgb(235, 235, 235);
  width: 100%;
  height: 55px;
  background: #ffffff;
  padding-left: 50px;
  font-size: 1.8rem;
  border-radius: 6px;
  color: #222;
  font-weight: 600;
}

.strip-knowledge-base {
  box-sizing: border-box;
  float: left;
  width: 100%;
  padding: 55px 0;
}
.strip-knowledge-base .row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.strip-knowledge-base .col-sm-4 + .col-sm-4 {
  border-left: 1px solid rgb(204, 204, 204);
}
.strip-knowledge-base .col-sm-4 {
  text-align: center;
  padding: 0 60px;
}
.strip-knowledge-base .col-sm-4 i {
  font-size: 4.5rem;
}
.strip-knowledge-base .col-sm-4 h2 {
  font-size: 2.4rem;
  margin: 20px 0;
  font-weight: 600;
}
.strip-knowledge-base .col-sm-4 p {
  font-size: 1.8rem;
}
.strip-knowledge-base .col-sm-4 p:last-child {
  margin-bottom: 0;
}

.knowledge-base-bottom {
  padding-top: 20px;
  clear: both;
  background: #e6e8e8;
}
.knowledge-base-bottom .kb-section {
  background-color: #fff;
  margin: 10px;
  border-radius: 6px;
  display: inline-block;
  background: #fff;
  padding: 1.5em;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.knowledge-base-bottom .kb-section a {
  font-size: 16px;
}
.knowledge-base-bottom ul {
  margin-left: 20px;
}
.knowledge-base-bottom ul li {
  padding: 5px 0;
  list-style: decimal;
}
.knowledge-base-bottom h2 {
  font-size: 2.4rem;
  margin-bottom: 10px;
}

ul.article-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  font-weight: 400;
  margin: 0 auto;
  width: 55%;
}
ul.article-list li {
  display: grid;
  text-align: left;
}
ul.article-list a {
  padding: 6px 0;
  display: block;
  width: fit-content;
}
ul.article-list a.see-all {
  text-decoration: underline;
}
ul.article-list .kb-search {
  flex-direction: row;
  padding: 12px 0 12px 0;
  z-index: 1;
}
ul.article-list .kb-search div {
  transition: border-color 0.2s ease-in-out;
  border: 1px solid #ccc;
  background-color: #fff;
  flex-direction: row;
  display: flex;
}
ul.article-list .kb-search div:focus-within {
  border-color: #5C9EAD;
}
ul.article-list .kb-search div input {
  padding-left: 12px;
  flex: 1;
  border: 0;
  font-weight: 400;
}
ul.article-list .kb-search div input:focus {
  outline: none;
}
ul.article-list .kb-search div button {
  align-items: center;
  font-size: 18px;
  color: #F69422;
  display: flex;
  width: 40px;
  background-color: transparent;
  border: 0;
  flex-direction: row;
  border-radius: 4px;
  padding: 0 12px;
  height: 40px;
}
ul.article-list .kb-search div button i {
  margin-right: 6px;
  font-size: 18px;
}

.wrap-hero + footer {
  border-bottom: 1px solid #ffffff;
}

.coating-combo {
  margin-top: 20px;
  padding: 0 0 10px 0;
}
.coating-combo .col-sm-4 {
  margin: 0 !important;
}
.coating-combo .product-in {
  margin: 0;
}
.coating-combo .product-in p {
  margin-bottom: 0;
}
.coating-combo:nth-child(even) {
  background: rgba(204, 204, 204, 0.5);
}
.coating-combo .coating-combo-in {
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.coating-combo .coating-combo-in > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
.coating-combo .coating-combo-in > div.col-md-2 {
  margin-top: 100px;
}
.coating-combo .coating-combo-in > div .flex-wrap {
  width: 100%;
}
.coating-combo .coating-combo-in > div .flex-wrap > div {
  position: relative;
}
.coating-combo .coating-combo-in > div h3, .coating-combo .coating-combo-in > div span {
  font-size: 1.8rem;
  color: #4b4f54;
  margin: 0 0 5px 0;
  display: block;
  line-height: 125%;
}
.coating-combo .coating-combo-in > div .product-box h3, .coating-combo .coating-combo-in > div .product-box span {
  margin: 0;
}
.coating-combo .coating-combo-in > div span {
  margin-bottom: 23px;
  cursor: pointer;
  text-align: center;
}
.coating-combo .coating-combo-in > div span strong {
  font-style: italic;
  font-size: 1.3rem;
}
.coating-combo .coating-combo-in > div span.icon-theme {
  width: 33px;
  height: 33px;
  background: url("../img/popular-themes.png") left -32px no-repeat;
  background-size: 30px;
  margin: 0 auto 15px;
}
.coating-combo .coating-combo-in > div span.icon-blends {
  width: 33px;
  height: 30px;
  background: url("../img/popular-blends.png") left -30px no-repeat;
  background-size: 30px;
  margin: 0 auto 15px;
}
.coating-combo .coating-combo-in > div span.comboMath {
  bottom: 0;
  position: absolute;
  font-size: 30px;
  right: -9px;
}
.coating-combo .coating-combo-in .product-box {
  margin: 20px 10px 10px 10px;
  padding-bottom: 10px;
  height: 210px;
}
.coating-combo .coating-combo-in .product-box.gallery-box a {
  margin-top: 2px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.coating-combo .coating-combo-in .product-box.gallery-box a img {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  flex-shrink: 0;
  object-fit: cover;
}
.coating-combo .coating-combo-in .col-sm-2 {
  width: 20%;
}
.coating-combo .coating-combo-in .col-sm-8 {
  width: 60%;
}

.filter-wrap + h3 {
  clear: both;
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-top: 50px;
}

.coating-combo + .coating-combo {
  margin-top: 0;
}

#galleryReviewModal, #removeAllProductsModal, #applicatorReviewModal, #videoUploadModal {
  --color-cerakote-gray: #3A3A39;
  --color-cerakote-orange: #F69322;
  --input-height: 42px;
  pointer-events: none;
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
}
#galleryReviewModal label, #removeAllProductsModal label, #applicatorReviewModal label, #videoUploadModal label {
  font-weight: normal;
}
#galleryReviewModal .modal-dialog, #removeAllProductsModal .modal-dialog, #applicatorReviewModal .modal-dialog, #videoUploadModal .modal-dialog {
  width: 660px;
}
#galleryReviewModal .modal-content, #removeAllProductsModal .modal-content, #applicatorReviewModal .modal-content, #videoUploadModal .modal-content {
  background-color: white;
  pointer-events: all;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: none;
  color: #333;
  padding: 0;
  border: 0;
}
@media screen and (max-width: 600px) {
  #galleryReviewModal .modal-content, #removeAllProductsModal .modal-content, #applicatorReviewModal .modal-content, #videoUploadModal .modal-content {
    flex-direction: column;
    max-height: 100%;
    display: flex;
  }
}
#galleryReviewModal .modal-header, #removeAllProductsModal .modal-header, #applicatorReviewModal .modal-header, #videoUploadModal .modal-header {
  flex-direction: column;
  position: sticky;
  display: flex;
  z-index: 10;
  padding: 0;
  top: 0;
}
#galleryReviewModal .modal-header-content, #removeAllProductsModal .modal-header-content, #applicatorReviewModal .modal-header-content, #videoUploadModal .modal-header-content {
  background-color: var(--color-cerakote-gray);
  flex-direction: row;
  align-items: center;
  padding: 20px 24px;
  flex-wrap: nowrap;
  display: flex;
  z-index: 1;
}
#galleryReviewModal .modal-header-title, #removeAllProductsModal .modal-header-title, #applicatorReviewModal .modal-header-title, #videoUploadModal .modal-header-title {
  flex-direction: column;
  display: flex;
  flex: 1;
}
#galleryReviewModal .modal-header-title h2, #removeAllProductsModal .modal-header-title h2, #applicatorReviewModal .modal-header-title h2, #videoUploadModal .modal-header-title h2 {
  text-transform: uppercase;
  letter-spacing: 0.125px;
  font-weight: 700;
  font-size: 15px;
  color: #FFF;
  margin: 0;
}
#galleryReviewModal .modal-header-title small, #removeAllProductsModal .modal-header-title small, #applicatorReviewModal .modal-header-title small, #videoUploadModal .modal-header-title small {
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 12px;
  color: #BABABA;
}
#galleryReviewModal .modal-header-close, #removeAllProductsModal .modal-header-close, #applicatorReviewModal .modal-header-close, #videoUploadModal .modal-header-close {
  transition: color 200ms ease-in-out;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 0 8px;
  line-height: 1;
  display: flex;
  outline: none;
  color: #AAA;
  border: 0;
}
#galleryReviewModal .modal-header-close:hover, #galleryReviewModal .modal-header-close:focus-within, #removeAllProductsModal .modal-header-close:hover, #removeAllProductsModal .modal-header-close:focus-within, #applicatorReviewModal .modal-header-close:hover, #applicatorReviewModal .modal-header-close:focus-within, #videoUploadModal .modal-header-close:hover, #videoUploadModal .modal-header-close:focus-within {
  color: #FFF;
}
#galleryReviewModal .modal-body, #removeAllProductsModal .modal-body, #applicatorReviewModal .modal-body, #videoUploadModal .modal-body {
  padding: 24px;
}
#galleryReviewModal .modal-body section, #removeAllProductsModal .modal-body section, #applicatorReviewModal .modal-body section, #videoUploadModal .modal-body section {
  margin-bottom: 0.8rem;
}
#galleryReviewModal .modal-body .input-label, #removeAllProductsModal .modal-body .input-label, #applicatorReviewModal .modal-body .input-label, #videoUploadModal .modal-body .input-label {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #777;
}
#galleryReviewModal .modal-body .file-input, #removeAllProductsModal .modal-body .file-input, #applicatorReviewModal .modal-body .file-input, #videoUploadModal .modal-body .file-input {
  position: absolute;
  visibility: hidden;
}
#galleryReviewModal .modal-body ul.file-list, #removeAllProductsModal .modal-body ul.file-list, #applicatorReviewModal .modal-body ul.file-list, #videoUploadModal .modal-body ul.file-list {
  font-family: arial;
  list-style: none;
  padding: 0;
}
#galleryReviewModal .modal-body ul.file-list li, #removeAllProductsModal .modal-body ul.file-list li, #applicatorReviewModal .modal-body ul.file-list li, #videoUploadModal .modal-body ul.file-list li {
  margin: 10px;
  outline: 1px solid #333;
  padding: 5px;
  border-radius: 3px;
  display: inline-block;
}
#galleryReviewModal .modal-body .form-group, #removeAllProductsModal .modal-body .form-group, #applicatorReviewModal .modal-body .form-group, #videoUploadModal .modal-body .form-group {
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  #galleryReviewModal .modal-body, #removeAllProductsModal .modal-body, #applicatorReviewModal .modal-body, #videoUploadModal .modal-body {
    flex: 1;
  }
}
#galleryReviewModal .modal-footer, #removeAllProductsModal .modal-footer, #applicatorReviewModal .modal-footer, #videoUploadModal .modal-footer {
  box-shadow: 0 -12px 16px 0px white;
  justify-content: space-between;
  border-top: 1px solid #EBEBEB;
  background-color: #FBFBFB;
  flex-direction: row;
  align-items: center;
  padding: 18px 24px;
  position: sticky;
  display: flex;
  z-index: 10;
  bottom: 0;
}
@media screen and (max-width: 600px) {
  #galleryReviewModal .modal-footer, #removeAllProductsModal .modal-footer, #applicatorReviewModal .modal-footer, #videoUploadModal .modal-footer {
    flex-flow: wrap;
  }
  #galleryReviewModal .modal-footer label, #removeAllProductsModal .modal-footer label, #applicatorReviewModal .modal-footer label, #videoUploadModal .modal-footer label {
    flex: auto;
    text-align: left;
  }
}
#galleryReviewModal .modal-footer-actions, #removeAllProductsModal .modal-footer-actions, #applicatorReviewModal .modal-footer-actions, #videoUploadModal .modal-footer-actions {
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  flex: 1;
}
#galleryReviewModal .modal-footer-save, #galleryReviewModal .modal-footer-cancel, #removeAllProductsModal .modal-footer-save, #removeAllProductsModal .modal-footer-cancel, #applicatorReviewModal .modal-footer-save, #applicatorReviewModal .modal-footer-cancel, #videoUploadModal .modal-footer-save, #videoUploadModal .modal-footer-cancel {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  height: 42px;
  border: 0;
}
@media screen and (max-width: 600px) {
  #galleryReviewModal .modal-footer-save, #galleryReviewModal .modal-footer-cancel, #removeAllProductsModal .modal-footer-save, #removeAllProductsModal .modal-footer-cancel, #applicatorReviewModal .modal-footer-save, #applicatorReviewModal .modal-footer-cancel, #videoUploadModal .modal-footer-save, #videoUploadModal .modal-footer-cancel {
    flex: 1;
  }
}
#galleryReviewModal .modal-footer-save[disabled], #galleryReviewModal .modal-footer-cancel[disabled], #removeAllProductsModal .modal-footer-save[disabled], #removeAllProductsModal .modal-footer-cancel[disabled], #applicatorReviewModal .modal-footer-save[disabled], #applicatorReviewModal .modal-footer-cancel[disabled], #videoUploadModal .modal-footer-save[disabled], #videoUploadModal .modal-footer-cancel[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
#galleryReviewModal .modal-footer-save .fa, #galleryReviewModal .modal-footer-cancel .fa, #removeAllProductsModal .modal-footer-save .fa, #removeAllProductsModal .modal-footer-cancel .fa, #applicatorReviewModal .modal-footer-save .fa, #applicatorReviewModal .modal-footer-cancel .fa, #videoUploadModal .modal-footer-save .fa, #videoUploadModal .modal-footer-cancel .fa {
  margin-right: 6px;
  font-size: 18px;
}
#galleryReviewModal .modal-footer-save, #removeAllProductsModal .modal-footer-save, #applicatorReviewModal .modal-footer-save, #videoUploadModal .modal-footer-save {
  background-color: #F69322;
  margin-left: 16px;
  color: #FFF;
}
#galleryReviewModal .video-preview, #removeAllProductsModal .video-preview, #applicatorReviewModal .video-preview, #videoUploadModal .video-preview {
  background-color: #EBEBEB;
  padding-bottom: 56.25%;
  margin-bottom: 25px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
#galleryReviewModal .video-preview-placeholder, #removeAllProductsModal .video-preview-placeholder, #applicatorReviewModal .video-preview-placeholder, #videoUploadModal .video-preview-placeholder {
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  font-size: 60px;
  color: #AAA;
  left: 50%;
  top: 50%;
}
#galleryReviewModal .video-preview iframe, #removeAllProductsModal .video-preview iframe, #applicatorReviewModal .video-preview iframe, #videoUploadModal .video-preview iframe {
  border-radius: 4px;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
}

.modal .modal-dialog {
  margin-top: 50px;
  width: 800px;
}
.modal .modal-content {
  float: left;
  color: #ffffff;
  width: 100%;
  border-radius: 0;
  padding: 20px;
  background: #3a3a39;
  margin-bottom: 50px;
}
.modal .modal-header {
  border: 0;
  padding: 10px 15px 5px 15px;
}
.modal .modal-footer {
  border: 0;
}
.modal h2 {
  text-transform: none;
  font-weight: bold;
  font-size: 2.4rem;
}
.modal input, .modal select, .modal textarea {
  border-radius: 0;
  background: #ffffff !important;
  font-weight: 600;
}
.modal .form-control[disabled], .modal .form-control[readonly], .modal fieldset[disabled] .form-control {
  background-color: #e6e6e6 !important;
  color: #9c9a99;
  opacity: 0.75;
}
.modal #accordionUploader .form-group.keyword-group,
.modal #accordionUploader .form-group.color-group,
.modal #accordionUploader .form-group.story-group,
.modal #accordionEditor .form-group.keyword-group,
.modal #accordionEditor .form-group.color-group,
.modal #accordionEditor .form-group.story-group {
  background: #e6e8e8;
  padding: 10px;
}
.modal #accordionUploader .form-group > a,
.modal #accordionEditor .form-group > a {
  color: #73859d;
  width: 100%;
  display: block;
}
.modal #accordionUploader .form-group > a .hashtag,
.modal #accordionEditor .form-group > a .hashtag {
  background: url("../img/hash.svg") center no-repeat;
  background-size: 14px auto;
  width: 14px;
  height: 14px;
  vertical-align: sub;
  display: inline-block;
}
.modal #accordionUploader .form-group #collapseKeywords,
.modal #accordionUploader .form-group #collapseEditKeywords,
.modal #accordionEditor .form-group #collapseKeywords,
.modal #accordionEditor .form-group #collapseEditKeywords {
  padding-bottom: 15px;
}
.modal #accordionUploader .form-group #collapseKeywords p,
.modal #accordionUploader .form-group #collapseEditKeywords p,
.modal #accordionEditor .form-group #collapseKeywords p,
.modal #accordionEditor .form-group #collapseEditKeywords p {
  margin: 0 0 13px 0;
  color: #73859d;
}
.modal #accordionUploader .form-group #collapseKeywords .col-sm-6,
.modal #accordionUploader .form-group #collapseEditKeywords .col-sm-6,
.modal #accordionEditor .form-group #collapseKeywords .col-sm-6,
.modal #accordionEditor .form-group #collapseEditKeywords .col-sm-6 {
  margin-bottom: 18px;
}
.modal #accordionUploader .form-group #collapseKeywords .col-sm-6 i,
.modal #accordionUploader .form-group #collapseEditKeywords .col-sm-6 i,
.modal #accordionEditor .form-group #collapseKeywords .col-sm-6 i,
.modal #accordionEditor .form-group #collapseEditKeywords .col-sm-6 i {
  position: absolute;
  top: 13px;
  right: 30px;
  pointer-events: none;
  color: #72859d;
}
.modal #accordionUploader .form-group #collapseKeywords .col-sm-12 i,
.modal #accordionUploader .form-group #collapseEditKeywords .col-sm-12 i,
.modal #accordionEditor .form-group #collapseKeywords .col-sm-12 i,
.modal #accordionEditor .form-group #collapseEditKeywords .col-sm-12 i {
  position: absolute;
  top: 13px;
  right: 30px;
  pointer-events: none;
  color: #72859d;
}
.modal #accordionUploader .form-group #collapseColors,
.modal #accordionUploader .form-group #collapseEditColors,
.modal #accordionEditor .form-group #collapseColors,
.modal #accordionEditor .form-group #collapseEditColors {
  padding-bottom: 15px;
}
.modal #accordionUploader .form-group #collapseColors span.colorItem,
.modal #accordionUploader .form-group #collapseEditColors span.colorItem,
.modal #accordionEditor .form-group #collapseColors span.colorItem,
.modal #accordionEditor .form-group #collapseEditColors span.colorItem {
  margin-top: 4px;
  margin-bottom: 4px;
  border: none;
  border-radius: 26px;
  padding: 5px 10px;
}
.modal #accordionUploader .form-group #collapseColors i,
.modal #accordionUploader .form-group #collapseEditColors i,
.modal #accordionEditor .form-group #collapseColors i,
.modal #accordionEditor .form-group #collapseEditColors i {
  position: absolute;
  top: 13px;
  right: 30px;
  pointer-events: none;
  color: #72859d;
}
.modal #accordionUploader .form-group #collapseStory,
.modal #accordionEditor .form-group #collapseStory {
  padding-bottom: 15px;
}
.modal .form-group {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-bottom: 18px;
  position: relative;
}
.modal .form-group .colorItem {
  padding: 1px 6px;
  background: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.modal .form-group .colorItem a {
  cursor: pointer;
  color: #999;
  font-weight: bold;
  font-size: 1.1rem;
}
.modal .form-group .colorItem a:hover {
  color: #333;
}
.modal .form-group .keywordItem {
  padding: 1px 6px;
  background: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.modal .form-group .keywordItem a {
  cursor: pointer;
  color: #393939;
}
.modal .form-group .keywordItem a:hover {
  color: #222222;
}
.modal .btn-orange {
  display: inline-block;
  width: auto;
  font-size: 1.8rem;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff !important;
  border-radius: 4px;
}
.modal .btn-orange:hover {
  background: #ab5f07 !important;
}
.modal .btn-light-green {
  display: inline-block;
  width: auto;
  font-size: 1.8rem;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff !important;
  border-radius: 4px;
}
.modal button.close.close-popup {
  background: #222222;
  opacity: 1;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  top: -46px;
  font-size: 2.8rem;
  left: 54px;
  position: relative;
  line-height: 100%;
  padding: 0;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  outline: none;
  color: #ffffff;
  text-shadow: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.modal button.close.close-popup:hover {
  background: #f69422;
}
.modal .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #ccc 1px;
  outline: 0;
  height: auto;
  min-height: inherit;
  border-radius: 0;
}
.modal .select2-container .select2-search--inline .select2-search__field {
  padding: 8px 7px;
  margin: 0;
  height: 38px;
  width: 100% !important;
}
.modal .select2-container--default .select2-selection--multiple {
  border: 1px solid #ccc;
  border-radius: 0;
  min-height: inherit;
  height: auto;
}
.modal .select2-container--default .select2-selection--multiple .select2-selection__rendered,
.modal .select2-container--default.select2-container--focus .select2-selection--multiple,
.modal .select2-container--default .select2-selection--multiple {
  float: left;
  width: 100%;
}
.modal .select2-container .select2-search--inline {
  width: 100%;
}
.modal .select2-container .select2-selection__choice + .select2-search--inline {
  width: auto;
}
.modal .select2-container--focus.select2-container .select2-selection__choice + .select2-search--inline {
  width: auto;
}
.modal .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 4px;
  margin-bottom: 4px;
  border: none;
  border-radius: 26px;
  padding: 5px 10px;
}

.form-group .keywords-help {
  margin-bottom: 5px;
}
.form-group .keywords-help p {
  margin-bottom: 0;
  font-weight: 600;
}
.form-group .keywords-help p span {
  background: rgb(235, 235, 235);
  padding: 4px 12px 5px 12px;
  border-radius: 15px;
}

.upload-file {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.upload-file .custom-file-upload {
  background: #ffffff;
  padding: 13px 12px 12px 12px;
  cursor: pointer;
  border-radius: 0;
  color: #73859d;
  border: 1px solid #cccccc;
  float: left;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.upload-file .custom-file-upload:hover {
  background: #f7f7f7;
}
.upload-file input[type=file] {
  display: none;
}
.upload-file span#file-name {
  float: left;
  margin: 10px 0 0 0;
  color: rgb(204, 204, 204);
  width: 100%;
  text-align: center;
}
.upload-file img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: inherit;
}

.upload-file-edit {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.upload-file-edit .custom-file-upload-edit {
  background: #ffffff;
  padding: 13px 12px 12px 12px;
  cursor: pointer;
  border-radius: 0;
  color: #73859d;
  border: 1px solid #cccccc;
  float: left;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.upload-file-edit .custom-file-upload-edit:hover {
  background: #f7f7f7;
}
.upload-file-edit input[type=file] {
  display: none;
}
.upload-file-edit span#file-name {
  float: left;
  margin: 10px 0 0 0;
  color: rgb(204, 204, 204);
  width: 100%;
  text-align: center;
}
.upload-file-edit img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: inherit;
}

#modelSuccess .modal-dialog {
  max-width: 475px;
}
#modelSuccess .modal-dialog .modal-header img {
  width: 170px;
}
#modelSuccess .modal-dialog .modal-header button.close {
  margin-top: -2px;
  font-size: 3rem;
  outline: none;
}
#modelSuccess .modal-dialog .modal-body h4 {
  font-size: 3rem;
  color: #000000;
  margin-bottom: 15px;
  line-height: 1.2;
}
#modelSuccess .modal-dialog .modal-body p {
  color: #000000;
  padding-right: 50px;
  line-height: 1.6;
  font-size: 1.6rem;
  margin-bottom: 25px;
}

.laser-optimized-section {
  display: inline-flex;
}
.laser-optimized-section .well {
  padding: 10px;
  margin-bottom: 0;
}
.laser-optimized-section .well .media-heading {
  padding: 0;
}
.laser-optimized-section .well .media-body p {
  margin-bottom: 0;
}
.laser-optimized-section .well .media-body p small {
  font-style: italic;
}

.info-disclaimer {
  color: #1788e4;
  cursor: pointer;
  margin-top: 15px;
  font-weight: 500;
}
.info-disclaimer .info-text {
  padding: 7px 13px;
  border-radius: 4px;
  overflow-y: auto;
  background: #d2d2d2;
  border: 1px solid rgba(187, 187, 187, 0.3098039216);
  color: #131313;
  position: absolute;
  z-index: 10;
  display: none;
  margin-right: 20px;
  font-weight: 500;
}
.info-disclaimer .info-text a {
  color: #1788e4;
}
.info-disclaimer:hover .info-text {
  display: block;
}

.add-to-cart {
  background: #ffffff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  padding: 25px 0;
  position: fixed;
  display: block;
  width: 100%;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.add-to-cart h2 {
  font-family: "league_gothicregular", sans-serif;
  font-weight: normal;
  color: #222222;
  margin-top: 0;
  font-size: 36px;
}
.add-to-cart h2 span {
  color: #f69422;
}
.add-to-cart .col-sm-6 img {
  max-width: 110px;
  margin: 3px 0;
}
.add-to-cart .col-sm-6 p {
  font-size: 1.8rem;
  margin-bottom: 0;
}
.add-to-cart .col-sm-6 p span {
  display: inline;
}
.add-to-cart .col-sm-6 p span em {
  font-style: normal;
  color: #f69422;
  font-weight: 600;
}
.add-to-cart .col-sm-6 p span span {
  font-size: 1.4rem;
}
.add-to-cart .col-sm-6 p span input {
  width: inherit;
  margin: 5px auto;
}
.add-to-cart .col-sm-6 p span #quantityEntered {
  width: 66px;
  display: inline;
}
.add-to-cart .col-sm-6 p > input {
  width: 200px;
  margin: 10px auto;
}
.add-to-cart .col-sm-6 + .col-sm-6 {
  border-left: 1px solid rgb(204, 204, 204);
}
.add-to-cart .col-sm-6 + .col-sm-6 p {
  text-transform: uppercase;
  margin: 30px 17% 35px 17%;
}
.add-to-cart .col-sm-6 + .col-sm-6 .btn {
  padding: 15px 25px 14px 25px;
  margin: 0 5px;
  min-width: 200px;
}
.add-to-cart .container .text-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.add-to-cart .close-cart {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 3.5rem;
  color: #4b4f54;
  background: transparent;
  border: 0;
  line-height: 100%;
  outline: none;
  font-weight: bold;
  padding: 10px;
  z-index: 1055;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.add-to-cart .close-cart:hover {
  color: #126cb6;
}

#vue-home .add-to-cart, #vue-home .show-cart-popup {
  top: 130px;
  z-index: 50;
}

.show-cart-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gallery-full .flex-wrap, .creation-full .flex-wrap {
  display: block;
  text-align: center;
}
.gallery-full .flex-wrap > .col-sm-4, .creation-full .flex-wrap > .col-sm-4,
.gallery-full .flex-wrap > .col-sm-3,
.creation-full .flex-wrap > .col-sm-3 {
  width: 370px;
  float: none;
  display: inline-block;
  padding: 0 6px;
  margin-bottom: 2px;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
  padding: 0;
  width: 358px;
  height: 358px;
  overflow: hidden;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .wrap-img, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .wrap-img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .wrap-img,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .wrap-img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img {
  height: 100%;
  z-index: 0;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
  text-align: left;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: -50%;
  background: #ffffff;
  padding-top: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail h3, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail h3, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail h3,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail h3,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail h3,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail .row,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail .row,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row {
  bottom: 15px;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row .col-sm-4 img, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row .col-sm-4 img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 img,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail .row .col-sm-4 img,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .info-thumbnail .row .col-sm-4 img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 img {
  width: 100%;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .arrow-up, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .arrow-up, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .arrow-up,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .arrow-up,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .arrow-up,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .arrow-up {
  visibility: hidden;
  opacity: 0;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 58px;
  height: 58px;
  margin-left: -29px;
  text-indent: -9999px;
  transition: visibility 0.3s, opacity 0.3s;
  background: url("../img/arrow-bookmark.svg") 0 0 no-repeat;
  background-size: 182px auto;
  transform: rotate(180deg);
  z-index: 2;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box:hover .arrow-up, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box:hover .arrow-up, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box:hover .arrow-up,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box:hover .arrow-up,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box:hover .arrow-up,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box:hover .arrow-up {
  visibility: visible;
  opacity: 1;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .arrow-up.selected, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .arrow-up.selected, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .arrow-up.selected,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .arrow-up.selected,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .arrow-up.selected,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .arrow-up.selected {
  background: url("../img/arrow-bookmark.svg") 0 0 no-repeat;
  background-size: 182px auto;
  visibility: visible !important;
  top: 20px !important;
  transform: rotate(0);
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .bookmark-project,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .bookmark-project,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .bookmark-project,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .bookmark-project {
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 0;
  cursor: pointer;
  width: 29px;
  height: 55px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box:hover .bookmark-project, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box:hover .bookmark-project, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box:hover .bookmark-project,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box:hover .bookmark-project,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box:hover .bookmark-project,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box:hover .bookmark-project {
  visibility: visible;
  opacity: 0.6;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
.gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project.project-highlight, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project.project-highlight, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .bookmark-project.project-highlight,
.gallery-full .flex-wrap > .col-sm-3 .thumbnail-box .bookmark-project.project-highlight,
.creation-full .flex-wrap > .col-sm-3 .thumbnail-box .bookmark-project.project-highlight,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .bookmark-project.project-highlight {
  visibility: visible;
  opacity: 1;
  background: url("../img/arrow-bookmark.svg") -138px 0 no-repeat;
  background-size: 166px auto;
  left: 21px;
}
.gallery-full .flex-wrap > .col-sm-4 .box-on-touch .arrow-up, .creation-full .flex-wrap > .col-sm-4 .box-on-touch .arrow-up,
.gallery-full .flex-wrap > .col-sm-3 .box-on-touch .arrow-up,
.creation-full .flex-wrap > .col-sm-3 .box-on-touch .arrow-up {
  visibility: visible;
  opacity: 1;
}

.gallery-common .flex-wrap > .col-sm-4 {
  width: 33.33333333%;
  float: left;
  padding: 0 15px;
  margin-bottom: 24px;
}
.gallery-common .flex-wrap > .col-sm-4 .thumbnail-box, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box,
.gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
  width: 360px;
  height: 360px;
}

.data-table .table-fill {
  background: white;
  border-radius: 6px;
  border-collapse: separate;
  width: 100%;
  animation: float 5s infinite;
  border: solid 1px #ccc;
}
.data-table .table-fill th {
  color: #222;
  background: #ccc;
  border-bottom: 3px solid #222;
  border-right: 1px solid #ccc;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  text-align: left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}
.data-table .table-fill th:first-child {
  border-top-left-radius: 6px;
}
.data-table .table-fill th:last-child {
  border-top-right-radius: 6px;
  border-right: none;
}
.data-table .table-fill tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #222;
  font-size: 16px;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
}
.data-table .table-fill tr:hover td {
  background: rgba(255, 181, 0, 0.231372549);
  color: #222;
}
.data-table .table-fill tr:first-child {
  border-top: none;
}
.data-table .table-fill tr:nth-child(odd) td {
  background: #ebebeb;
}
.data-table .table-fill tr:nth-child(odd):hover td {
  background: rgba(255, 181, 0, 0.231372549);
}
.data-table .table-fill tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
.data-table .table-fill tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.data-table .table-fill td {
  background: #ffffff;
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #c1c3d1;
}
.data-table .table-fill td:last-child {
  border-right: 0px;
}
.data-table .table-fill th.text-left {
  text-align: left;
}
.data-table .table-fill th.text-center {
  text-align: center;
}
.data-table .table-fill th.text-right {
  text-align: right;
}
.data-table .table-fill td.text-left {
  text-align: left;
}
.data-table .table-fill td.text-center {
  text-align: center;
}
.data-table .table-fill td.text-right {
  text-align: right;
}

.related-creations {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .related-creations {
    flex-direction: row;
  }
}
.related-creations .creations {
  padding: 15px 15px 0 15px;
  margin-top: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

a.related-creation {
  max-width: calc(100% - 18px);
  transition: all 0.3s ease;
  border: 1px solid #ccc;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 13px 13px 0;
  overflow: hidden;
  padding: 0;
  flex: 1;
  display: inline-flex;
  width: inherit;
}
@media screen and (min-width: 568px) {
  a.related-creation {
    max-width: calc(50% - 18px);
    flex: 0 1 50%;
  }
}
@media screen and (min-width: 1031px) {
  a.related-creation {
    max-width: calc(25% - 18px);
    flex: 0 1 25%;
  }
}
a.related-creation .related-creation-thumbnail {
  background-position: center center;
  background-image: var(--thumbnail);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: block;
  height: auto;
  opacity: 1;
  width: 100%;
}
a.related-creation .related-creation-thumbnail::before {
  content: "";
  padding-bottom: 100%;
  display: block;
  width: 100%;
}
a.related-creation .related-creation-cta {
  color: rgb(245, 166, 35);
  font-weight: 600;
  padding: 13px 0;
  display: block;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
}
a.related-creation:hover .related-creation-thumbnail, a.related-creation:focus-within .related-creation-thumbnail {
  opacity: 0.825;
  transform: scale(1.1);
}

.creation-full .product-wrap .flex-wrap > .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 {
  width: 25%;
  min-width: 350px;
  max-width: 460px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
  width: auto;
  height: 460px;
  padding: 0 !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img {
  height: 100% !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box.mix-box .arrow-up,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box.mix-box .arrow-up {
  background: #fff;
  background-size: 182px auto;
  visibility: visible !important;
  opacity: 1;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 0;
  color: #222222;
  top: unset !important;
  text-indent: unset;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  transition: bottom 0.3s;
  bottom: 0;
  width: 85px;
  height: 80px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  left: calc(50% - 15px);
  padding-top: 10px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box.mix-box .arrow-up:hover,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box.mix-box .arrow-up:hover {
  color: #f69422 !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box.mix-box .arrow-up i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box.mix-box .arrow-up i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box.mix-box .arrow-up.selected,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box.mix-box .arrow-up.selected {
  background: #fff;
  background-size: 182px auto;
  visibility: visible !important;
  bottom: 170px;
  opacity: 1;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  z-index: 0;
  color: #222222;
  top: unset !important;
  text-indent: unset;
  transition: bottom 0.3s;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box.mix-box .arrow-up.selected i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box.mix-box .arrow-up.selected i {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
  bottom: -170px;
  height: auto;
  padding-top: 0 !important;
  padding-bottom: 170px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail h3,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail h3 {
  padding: 10px 0 8px 0;
  margin: 0;
  text-align: center;
  background: #3a3a39;
  color: #e6e8e8;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail > a,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail > a {
  width: 100%;
  position: absolute;
  bottom: 0px;
  margin: 0px;
  padding: 5px !important;
  background: #f78e1a;
  color: #fff;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail > a:hover,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail > a:hover {
  color: #fff !important;
  background: #ffa400;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row {
  position: relative;
  bottom: 0;
  margin: 0 !important;
  width: 100% !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row.mix-creation,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row.mix-creation {
  bottom: 30px;
  flex-wrap: unset;
  width: 100% !important;
  white-space: nowrap;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row.mix-creation .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row.mix-creation .col-sm-4 {
  padding: 0 10px;
  height: auto;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row.mix-creation .col-sm-4 strong,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row.mix-creation .col-sm-4 strong {
  display: block;
  width: 100px;
  white-space: initial;
  margin: auto;
  font-size: 1.1rem;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-2 + .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-2 + .col-sm-4 {
  border-left: none !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 {
  padding: 0 10px;
  font-size: 1.25rem;
  position: relative;
  height: 160px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 {
  border: none;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-plus,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-plus {
  position: absolute;
  left: -1.2rem;
  font-size: 2rem;
  bottom: calc(50% - 10px);
  border: 1px solid #dedede;
  border-radius: 50%;
  background: #fff;
  padding: 0 5px;
  line-height: 20px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix {
  position: absolute;
  left: -3.2rem;
  bottom: calc(50% - 10px);
  border-radius: 50%;
  background: #fff;
  padding: 0 5px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix .step-mix,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix .step-mix {
  position: absolute;
  left: -3.2rem;
  bottom: calc(50% - 10px);
  border-radius: 50%;
  background: #fff;
  padding: 0 5px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix .step-mix i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 .step-mix .step-mix i {
  line-height: 45px;
  font-size: 25px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4:nth-child(2),
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4:nth-child(2) {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4.twoColorCreation,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4.twoColorCreation {
  border-right: none !important;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4.creation-details,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4.creation-details {
  padding-top: 30px;
  color: #323232;
  margin: 0;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 i {
  line-height: 60px;
  font-size: 30px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 > div,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 > div {
  display: block;
  min-height: 60px;
  padding-bottom: 5px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 img {
  height: 60px;
  width: auto;
  line-height: 60px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 span,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 + .col-sm-4 span {
  font-size: 1.1rem;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4:nth-child(2),
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4:nth-child(2) {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4:nth-child(4),
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4:nth-child(4) {
  border-left: 1px solid #dedede;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4.creation-details,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4.creation-details {
  padding-top: 30px;
  color: #323232;
  margin: 0;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 i {
  line-height: 60px;
  font-size: 30px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 > div,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 > div {
  display: block;
  min-height: 60px;
  padding-bottom: 5px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 img {
  height: 60px;
  width: auto;
  line-height: 60px;
}
.creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 span,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row .col-sm-4 span {
  font-size: 1.1rem;
}

#colorSwatch p {
  margin-bottom: 0;
}

#oldBrowserModal {
  font-family: "Source Sans Pro", sans-serif;
}
#oldBrowserModal .modal-dialog {
  width: 800px;
}
#oldBrowserModal .modal-dialog .wrap-browser-message {
  background-image: url("/img/No_Pain.jpg?impolicy=full");
  background-size: cover;
  height: 500px;
  width: 800px;
  display: block;
  color: #ffffff;
  padding: 150px 80px;
}
#oldBrowserModal .modal-dialog .wrap-browser-message h2 {
  margin: 0;
  font-weight: normal;
  line-height: 25px;
}
#oldBrowserModal .modal-dialog .wrap-browser-message h2 span {
  text-transform: capitalize;
}
#oldBrowserModal .modal-dialog .wrap-browser-message .browserLinks {
  width: 500px;
}
#oldBrowserModal .modal-dialog .wrap-browser-message .browserLinks a {
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  padding-bottom: 15px;
  padding-right: 25px;
  display: block;
  float: left;
}
#oldBrowserModal .modal-dialog .wrap-browser-message .browserLinks a img {
  height: 50px;
}

.cookiesDisabled {
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: #3d3d3b;
  color: white;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3);
  bottom: 0;
  text-align: center;
  height: 100px;
  padding-top: 15px;
}
.cookiesDisabled p {
  margin: 0;
}

.login-signup, .signup-country {
  font-family: "Source Sans Pro", sans-serif;
}
.login-signup .modal-dialog, .signup-country .modal-dialog {
  width: 550px;
  height: 1000px;
}
.login-signup .modal-content, .signup-country .modal-content {
  background: #3a3a39;
  padding: 30px 40px 40px 40px;
}
.login-signup .btn-orange, .signup-country .btn-orange {
  display: block;
  width: 100%;
}
.login-signup .text-center > img, .signup-country .text-center > img {
  width: 175px;
  max-width: 100%;
}
.login-signup button.close, .signup-country button.close {
  border: 0;
  background: transparent;
  box-shadow: none;
  color: #ffffff;
  top: -30px;
  font-size: 4rem;
  left: 46px;
  opacity: 1;
  text-shadow: 0 1px 0 #0a0b09;
}
.login-signup button.close:hover, .signup-country button.close:hover {
  color: #126cb6;
  background: transparent;
}
.login-signup .modal-body, .signup-country .modal-body {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.login-signup .modal-body h2, .signup-country .modal-body h2, .login-signup .modal-body h3, .signup-country .modal-body h3 {
  float: left;
  font-weight: normal;
  position: relative;
  z-index: 2;
}
.login-signup .modal-body h2, .signup-country .modal-body h2 {
  font-size: 2.6rem;
  margin-top: 0;
}
.login-signup .modal-body h3, .signup-country .modal-body h3 {
  font-size: 2rem;
  text-align: right;
  margin-top: 15px;
  float: right;
}
.login-signup .modal-body .title-group span, .signup-country .modal-body .title-group span {
  display: block;
  font-size: 1.7rem;
  font-weight: bold;
}
.login-signup .modal-body .signUpForNewsletter, .signup-country .modal-body .signUpForNewsletter {
  margin-top: 5px;
}
.login-signup .modal-body .btn, .signup-country .modal-body .btn {
  line-height: 1.6rem;
  font-size: 1.6rem;
  font-weight: normal;
  padding: 13px 20px;
  text-transform: none;
}
.login-signup .modal-body .btn i, .signup-country .modal-body .btn i {
  margin-left: 10px;
  font-size: 1.4rem;
}
.login-signup .modal-body .btn.btn-signup, .signup-country .modal-body .btn.btn-signup {
  line-height: 1.6rem;
  background: transparent;
  border-color: #777;
  color: #fff;
  margin-top: 18px;
}
.login-signup .modal-body .btn.btn-signup span, .signup-country .modal-body .btn.btn-signup span {
  font-weight: bold;
}
.login-signup .modal-body .btn.btn-signup:hover, .signup-country .modal-body .btn.btn-signup:hover {
  color: #fff !important;
}
.login-signup .modal-body .btns-sign-in.mobile-sign-in, .signup-country .modal-body .btns-sign-in.mobile-sign-in {
  display: none;
}
.login-signup .modal-body .btns-sign-in .btn, .signup-country .modal-body .btns-sign-in .btn {
  width: 48%;
  float: left;
}
.login-signup .modal-body .btns-sign-in .btn + .btn, .signup-country .modal-body .btns-sign-in .btn + .btn {
  float: right;
  margin-top: 0;
}
.login-signup .modal-body .btns-sign-in .btn-google:hover, .signup-country .modal-body .btns-sign-in .btn-google:hover {
  background-color: #cf3624;
}
.login-signup .modal-body .btns-sign-in .btn-google:active, .signup-country .modal-body .btns-sign-in .btn-google:active, .login-signup .modal-body .btns-sign-in .btn-google:focus, .signup-country .modal-body .btns-sign-in .btn-google:focus {
  background-color: rgb(221, 75, 57) !important;
}
.login-signup .modal-body input[type=email], .signup-country .modal-body input[type=email], .login-signup .modal-body input[type=username], .signup-country .modal-body input[type=username], .login-signup .modal-body input[type=tel], .signup-country .modal-body input[type=tel], .login-signup .modal-body input[type=password], .signup-country .modal-body input[type=password], .login-signup .modal-body input[type=text], .signup-country .modal-body input[type=text], .login-signup .modal-body select, .signup-country .modal-body select {
  height: 50px;
  font-size: 1.4rem;
  border-radius: 3px;
  font-weight: normal;
}
.login-signup .modal-body .country-code, .signup-country .modal-body .country-code {
  height: 38px;
}
.login-signup .modal-body p, .signup-country .modal-body p {
  margin: 0 25px;
}
.login-signup .modal-body .forgot-password, .signup-country .modal-body .forgot-password {
  text-align: center;
  margin-top: 5px;
}
.login-signup .modal-body .forgot-password a, .signup-country .modal-body .forgot-password a {
  font-size: 1.6rem;
}
.login-signup .divider-sign-in, .signup-country .divider-sign-in {
  display: block;
  margin: 20px 0 20px 0;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.login-signup .divider-sign-in span, .signup-country .divider-sign-in span {
  display: inline-block;
  padding: 0 35px;
  background: #3a3a39;
  position: relative;
}
.login-signup .divider-sign-in:before, .signup-country .divider-sign-in:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgb(235, 235, 235);
  top: 49%;
  left: 0;
  z-index: 0;
}

.wrap-sign-up {
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.signup-country .modal-content {
  padding: 0 20px;
}
.signup-country .signup-company-field {
  height: 38px !important;
  padding-left: 30px;
}
.signup-country .modal-body .form-group #hasCompany {
  height: 34px !important;
}
.signup-country #signupCompany {
  padding-left: 30px;
}

#notificationPopup {
  background-color: transparent !important;
}
#notificationPopup .modal-dialog {
  height: auto !important;
}
#notificationPopup .modal-dialog .modal-content {
  padding: 0px;
  height: auto !important;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
#notificationPopup .modal-dialog .modal-content .modal-header,
#notificationPopup .modal-dialog .modal-content .modal-body,
#notificationPopup .modal-dialog .modal-content .modal-footer {
  padding: 0px;
}

.wrap-business-fields .circle-steps {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}
.wrap-business-fields .circle-steps > span {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  background: rgb(204, 204, 204);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}
.wrap-business-fields .circle-steps > span:before {
  width: 9px;
  height: 9px;
  background: #f69422;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  content: "";
  display: none;
}
.wrap-business-fields .form-group.text-center {
  margin-bottom: 0;
}
.wrap-business-fields .styled-select:before {
  top: 18px;
  pointer-events: none;
}

.fields-step1 {
  display: none;
}
.fields-step1 .circle-steps > span:first-child:before {
  display: block;
}

.fields-step2 {
  display: none;
}
.fields-step2 .circle-steps > span:nth-child(2):before {
  display: block;
}

.fields-step3 {
  display: none;
}
.fields-step3 .circle-steps > span:last-child:before {
  display: block;
}
.fields-step3 p {
  clear: both;
  padding-top: 15px;
}

#login .visit-your-page {
  padding: 60px 15px 15px 15px;
  text-align: center;
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: none;
}
#login .visit-your-page .img-page {
  width: 100px;
  height: 100px;
  margin: 0 0 40px 0;
  border-radius: 50%;
  background: rgb(37, 161, 135);
  display: inline-block;
}
#login .visit-your-page .img-page img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
#login .visit-your-page div + p {
  margin-bottom: 50px;
}

.styled-checkbox {
  position: relative;
  margin: 0 0 10px 10px;
  float: left;
  width: 50%;
}
.styled-checkbox input[type=checkbox]:not(:checked),
.styled-checkbox input[type=checkbox]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
  box-sizing: border-box;
  padding: 0;
}
.styled-checkbox input[type=checkbox] + label {
  position: relative;
  padding-left: 40px;
  padding-top: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 140%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  font-size: 1.6rem;
}
.styled-checkbox input[type=checkbox] + label:before,
.styled-checkbox input[type=checkbox]:not(.filled-in) + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  z-index: 0;
  background: rgb(10, 11, 9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  transition: 0.2s;
}
.styled-checkbox input[type=checkbox] + label:before {
  border-radius: 50%;
}
.styled-checkbox input[type=checkbox]:checked + label:after {
  top: 4px;
  left: 3px;
  width: 10px;
  height: 19px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  background: transparent;
}

.wrap-checkbox {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 16px;
}
.wrap-checkbox label.styled-checkbox2 {
  cursor: pointer;
  float: none;
  width: auto;
  position: relative;
}
.wrap-checkbox label.styled-checkbox2 input[type=checkbox]:not(:checked),
.wrap-checkbox label.styled-checkbox2 input[type=checkbox]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
  width: 0;
  height: 0;
  box-sizing: border-box;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.wrap-checkbox label.styled-checkbox2 .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: transparent;
  background-color: rgb(204, 204, 204);
  border-radius: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.wrap-checkbox label.styled-checkbox2 .lever::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 21px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  left: 0;
  top: -3px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.wrap-checkbox label.styled-checkbox2 input[type=checkbox]:checked + .lever:after {
  left: 20px;
}
.wrap-checkbox label.styled-checkbox2 input[type=checkbox]:checked + .lever {
  background-color: rgba(246, 148, 34, 0.5);
}
.wrap-checkbox label.styled-checkbox2 + em {
  font-style: normal;
  color: #f69422;
  padding-left: 10px;
}

#vue-videos .wrap-hero .breadcrumb {
  padding: 30px 30px 8px;
}
#vue-videos .wrap-hero .videoPlayer {
  width: 100%;
  max-height: 611px;
  height: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#vue-videos .wrap-hero .videoPlayer.open {
  height: 611px;
  margin-bottom: 25px;
}
#vue-videos .wrap-hero .videoPlayer iframe {
  height: 100%;
  width: 100%;
}

#videoPlayerModal {
  padding-right: 0px !important;
}
#videoPlayerModal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
}
#videoPlayerModal .modal-content {
  padding: 0px;
  margin: 0px;
  height: 100%;
  text-align: center;
  background-color: #222;
}
#videoPlayerModal .modal-header {
  display: none;
}
#videoPlayerModal .modal-body {
  padding: 0px;
  top: calc(50% - 350px);
}
#videoPlayerModal .modal-body button.close.close-popup {
  top: -50px;
  right: 10px;
  left: auto;
  position: absolute;
}
#videoPlayerModal .modal-body .save-video {
  opacity: 1;
  height: 38px;
  border-radius: 50%;
  top: -50px;
  left: 10px;
  font-size: 2.8rem;
  float: left;
  position: absolute;
  line-height: 100%;
  color: white;
}
#videoPlayerModal .modal-body .save-video b {
  font-size: 1.8rem;
  padding: 10px;
  font-weight: 400;
}
#videoPlayerModal .modal-footer {
  display: none;
}

.wrap-video .col-sm-9 {
  padding-right: 0;
  border-right: 1px solid rgb(204, 204, 204);
}
.wrap-video h2 {
  margin: 20px 0 10px;
}
.wrap-video .videoList .col-xs-12, .wrap-video .documentList .col-xs-12, .wrap-video .articleList .col-xs-12 {
  text-align: center;
  margin-bottom: 20px;
  min-height: 190px;
}
.wrap-video .videoList .col-xs-12 .vidTitle, .wrap-video .documentList .col-xs-12 .vidTitle, .wrap-video .articleList .col-xs-12 .vidTitle {
  font-weight: 600;
  font-size: 1.3rem;
  width: 100%;
  display: block;
  text-align: left;
  min-height: 40px;
}
.wrap-video .videoList .col-xs-12 .vidDate, .wrap-video .documentList .col-xs-12 .vidDate, .wrap-video .articleList .col-xs-12 .vidDate {
  color: #777;
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  display: block;
}
.wrap-video .videoList a, .wrap-video .documentList a, .wrap-video .articleList a {
  background-color: #e6e8e8;
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}
.wrap-video .videoList a img, .wrap-video .documentList a img, .wrap-video .articleList a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrap-video .videoList a .videoPlay, .wrap-video .documentList a .videoPlay, .wrap-video .articleList a .videoPlay {
  opacity: 0.9;
  font-size: 50px;
  color: #323232;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.wrap-video .videoList a:hover img, .wrap-video .documentList a:hover img, .wrap-video .articleList a:hover img {
  opacity: 0.3;
}
.wrap-video .videoList a:hover .videoPlay, .wrap-video .documentList a:hover .videoPlay, .wrap-video .articleList a:hover .videoPlay {
  opacity: 1;
  color: rgb(254, 0, 0);
}
.wrap-video .videoList button, .wrap-video .documentList button, .wrap-video .articleList button {
  background: none;
  border: none;
  color: rgb(23, 136, 228);
  font-size: 1.2rem;
  width: 100%;
}
.wrap-video .videoList button i, .wrap-video .documentList button i, .wrap-video .articleList button i {
  color: #7ED323;
  font-size: 15px;
}
.wrap-video .videoList button:hover i, .wrap-video .documentList button:hover i, .wrap-video .articleList button:hover i {
  color: #7ED323;
}
.wrap-video .videoList #loader, .wrap-video .documentList #loader, .wrap-video .articleList #loader {
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 0;
  background: white;
  border: solid 2px #F5A623;
  border-radius: 60px;
  font-size: 16px;
  color: #F5A623;
  line-height: 120px;
  outline: none;
  font-weight: bold;
  margin: 15px auto 0;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  text-align: center;
  margin-left: 15px;
}
.wrap-video .videoList.article-details, .wrap-video .documentList.article-details, .wrap-video .articleList.article-details {
  float: left;
}
.wrap-video .videoList.article-details button, .wrap-video .documentList.article-details button, .wrap-video .articleList.article-details button {
  vertical-align: text-top;
  font-size: 1.5rem;
  padding-top: 5px;
}
.wrap-video .articleList {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}
.wrap-video .articleList a {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}
.wrap-video .articleList a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrap-video .articleList a:hover img {
  opacity: 0.3;
}
.wrap-video .articleList a:hover .videoPlay {
  opacity: 1;
  color: rgb(254, 0, 0);
}
.wrap-video .articleList button {
  background: none;
  border: none;
  color: rgb(23, 136, 228);
  font-size: 1.2rem;
  width: 100%;
}
.wrap-video .articleList button i {
  color: #7ED323;
  font-size: 15px;
}
.wrap-video .articleList button:hover i {
  color: #7ED323;
}
.wrap-video .articleList #loader {
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 0;
  background: white;
  border: solid 2px #F5A623;
  border-radius: 60px;
  font-size: 16px;
  color: #F5A623;
  line-height: 120px;
  outline: none;
  font-weight: bold;
  margin: 15px auto 0;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  text-align: center;
}
.wrap-video .article-list li {
  position: relative;
}
.wrap-video .article-list li button {
  width: initial;
  background: none;
  border: 0;
  position: absolute;
  left: -25px;
  top: 5px;
  font-size: 1.4rem;
  color: #f69422;
}

.ul-cta {
  text-decoration: underline;
}

.contact-container .panel-sapcing {
  margin-bottom: 10px;
}
.contact-container .panel-container {
  display: flex;
  flex-wrap: wrap;
}
.contact-container .panel-container .pannel-inner {
  height: 100%;
  position: relative;
  min-height: 200px;
}
.contact-container .panel-container .pannel-inner .panel-body {
  margin-bottom: 15px;
}
.contact-container .panel-container .pannel-inner .text-center {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 15px;
}

.wrap-news {
  margin-top: 35px;
}
.wrap-news .news-box {
  margin-bottom: 25px;
}
.wrap-news .news-content {
  padding-left: 45px;
}
.wrap-news .news-image + .news-content {
  padding-left: 0;
}

#applicator-map-no-results {
  margin-top: 24px;
}

.applicator-map-overlay #applicator-map-sidebar-filters, .applicator-map-overlay #applicator-map-sidebar-results {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  opacity: 0;
}
.applicator-map-overlay #applicator-map-sidebar-filters label, .applicator-map-overlay #applicator-map-sidebar-filters button, .applicator-map-overlay #applicator-map-sidebar-filters a, .applicator-map-overlay #applicator-map-sidebar-results label, .applicator-map-overlay #applicator-map-sidebar-results button, .applicator-map-overlay #applicator-map-sidebar-results a {
  pointer-events: none;
}
@media only screen and (max-width: 1024px) {
  .applicator-map-overlay #applicator-map-sidebar-filters, .applicator-map-overlay #applicator-map-sidebar-results {
    opacity: 1;
    background-color: rgb(255, 255, 255);
  }
}
.applicator-map-overlay #applicator-map-sidebar-filters {
  overflow: hidden !important;
}
.applicator-map-overlay #applicator-map-list {
  height: 100%;
}
.applicator-map-overlay #applicator-map-list .result-placeholder {
  height: 100%;
}
.applicator-map-overlay #applicator-map-list .result-placeholder .bg-image {
  background-image: url("../img/applicator-result-placeholder.jpg");
}
.applicator-map-overlay #applicator-map-view {
  height: 100%;
}
.applicator-map-overlay #applicator-map-view .map-placeholder.landing {
  background-color: none;
}
.applicator-map-overlay #applicator-map-view .map-placeholder {
  height: 100%;
}
.applicator-map-overlay #applicator-map-view .bg-image {
  background-image: url("../img/applicator-map-placeholder.jpg");
  background-repeat: no-repeat;
  transform: translateX(-40%);
  overflow: hidden;
  background-size: cover;
  z-index: -1;
  width: 145vw;
}
.applicator-map-overlay .bg-image {
  filter: blur(4px);
  -webkit-filter: blur(4px);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translate(-50%, -200%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
.applicator-map-overlay .bg-text {
  background-color: rgb(255, 255, 255);
  color: #3a3a39;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
  font-weight: bold;
  border: 2px solid #2196f3;
  position: sticky;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 100%;
  padding: 20px;
  text-align: center;
  max-width: 1200px;
  animation: 1s ease-in 0s 1 slideInFromTop;
  border-radius: 4px;
  min-height: 185px;
}
.applicator-map-overlay .bg-text h1 {
  color: #393939;
  font-size: 5rem;
  font-family: "Teko";
}
.applicator-map-overlay .bg-text p {
  padding-top: 15px;
  color: #2196f3;
  font-size: 2rem;
  border-top: 2px solid;
  margin: 0;
  font-family: "Roboto";
}
.applicator-map-overlay .bg-text p.initial {
  color: #393939;
  font-family: inherit;
  margin: 10px;
  border-top: none;
  padding-top: 0px;
}

.mobile-toggle-map {
  display: none;
}

#about-us {
  background-color: rgb(15, 15, 15);
}
#about-us .breadcrumb {
  color: rgb(221, 221, 221);
}

.mid-inner.applicator .breadcrumb {
  padding: 10px 30px;
}
.mid-inner.applicator .map-how-to {
  text-align: right;
  padding-right: 35px;
}
.mid-inner.applicator .map-how-to .btn-orange {
  background: transparent;
  border-radius: 0;
  border: 1px solid #f69422;
  border-bottom-width: 6px;
  color: #f69422 !important;
  padding: 5px 20px;
}
.mid-inner.applicator .map-how-to .btn-orange:hover {
  background: #f69422;
  color: #fff !important;
}

.noURL {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.noURL:hover span {
  color: rgb(153, 153, 153) !important;
}
.noURL:hover {
  color: #222222 !important;
}

.wrap-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: rgb(37, 161, 135);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px rgb(37, 161, 135);
  }
}
.msgblock {
  float: left;
  width: 100%;
  clear: both;
}
.msgblock.invalidCredentials {
  margin-left: -55px;
  width: calc(100% + 110px);
  padding: 15px 55px;
  background: #f69521;
  border-radius: 0;
}
.msgblock.invalidCredentials i {
  float: left;
  font-size: 40px;
  width: 40px;
}
.msgblock.invalidCredentials span {
  float: left;
  width: calc(100% - 40px);
}

.has-error .form-control {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-success .form-control {
  border-color: #3c763d !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.creation-dropdown {
  float: left;
  width: 350px;
  margin: 35px 0 15px 0;
  position: relative;
}
.creation-dropdown > ul, .creation-dropdown > div {
  list-style: none;
  margin: 0;
  padding: 10px;
  font-size: 2rem;
  font-weight: 500;
  background: rgb(235, 235, 235);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 3px 3px 3px 3px;
}
.creation-dropdown > ul li, .creation-dropdown > div li {
  padding: 10px;
  cursor: pointer;
  color: rgb(153, 153, 153);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.creation-dropdown > ul li:last-child, .creation-dropdown > div li:last-child {
  border-top: 1px solid rgb(204, 204, 204);
  margin-top: 15px;
  color: #222222;
}
.creation-dropdown > ul li span, .creation-dropdown > div li span {
  float: left;
  margin: 5px 10px 0 0;
}
.creation-dropdown > ul li span.icon-creation, .creation-dropdown > div li span.icon-creation {
  width: 23px;
  height: 18px;
  background: url("../img/all-creations.png") left top no-repeat;
}
.creation-dropdown > ul li span.icon-theme, .creation-dropdown > div li span.icon-theme {
  width: 23px;
  height: 23px;
  background: url("../img/popular-themes.png") left top no-repeat;
}
.creation-dropdown > ul li span.icon-blends, .creation-dropdown > div li span.icon-blends {
  width: 23px;
  height: 20px;
  background: url("../img/popular-blends.png") left top no-repeat;
}
.creation-dropdown > ul li span.icon-time, .creation-dropdown > div li span.icon-time {
  width: 23px;
  height: 14px;
  background: url("../img/check.png") left top no-repeat;
  margin-top: 7px;
}
.creation-dropdown > ul li:hover, .creation-dropdown > div li:hover {
  color: #222222;
}
.creation-dropdown > ul li:hover span.icon-creation, .creation-dropdown > div li:hover span.icon-creation {
  background: url("../img/all-creations.png") left -18px no-repeat;
}
.creation-dropdown > ul li:hover span.icon-theme, .creation-dropdown > div li:hover span.icon-theme {
  background: url("../img/popular-themes.png") left -26px no-repeat;
}
.creation-dropdown > ul li:hover span.icon-blends, .creation-dropdown > div li:hover span.icon-blends {
  background: url("../img/popular-blends.png") left -20px no-repeat;
}
.creation-dropdown > ul {
  display: none;
  position: absolute;
  z-index: 11;
  width: 100%;
}
.creation-dropdown > div {
  cursor: pointer;
  padding: 12px 20px 10px 20px;
}
.creation-dropdown > div span {
  float: left;
  margin: 5px 10px 0 0;
}
.creation-dropdown > div span.icon-creation {
  width: 23px;
  height: 18px;
  background: url("../img/all-creations.png") left -18px no-repeat;
}
.creation-dropdown:hover > ul {
  display: block;
  border-radius: 0 0 5px 5px;
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* Firefox */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* Webkit */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* Opera */
.cart-checkout {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.cart-checkout .col-md-9 h2 {
  padding-top: 40px;
  clear: both;
}
.cart-checkout .col-md-9 > div:first-child h2 {
  padding-top: 0;
  margin-top: 0;
}
.cart-checkout .selected-address {
  margin-left: 0;
  margin-right: 0;
  position: relative;
}
.cart-checkout .selected-address .cvv {
  display: inline;
  width: 60px;
  margin-left: 10px;
}
.cart-checkout .address-toggle {
  position: absolute;
  right: -5px;
  bottom: 5px;
  text-align: right;
  cursor: pointer;
  color: rgb(23, 136, 228);
}
.cart-checkout .address-toggle:hover {
  color: #126cb6;
}
.cart-checkout .address-toggle i {
  color: #cccccc;
  font-size: 2.5rem;
  vertical-align: sub;
}
.cart-checkout .same-as-shipping {
  margin-top: 5px;
  margin-bottom: 10px;
}
.cart-checkout .shipping-terms {
  font-size: 1.3rem;
}
.cart-checkout .phone-number {
  width: 252px;
  margin-top: 7px;
}
.cart-checkout .account-info-box {
  padding-bottom: 18px;
}
.cart-checkout .account-info-box input[type=email].form-control,
.cart-checkout .account-info-box input[type=text].form-control,
.cart-checkout .account-info-box select.form-control {
  background: #fff;
}
.cart-checkout .account-info-group {
  margin: 10px 0;
}
.cart-checkout .signup-company-field {
  height: 38px !important;
}
.cart-checkout .checkbox-label {
  font-weight: normal;
}
.cart-checkout .filtering-addresses {
  text-align: center;
  font-size: 20px;
  padding-top: 12px;
  padding-bottom: 15px;
}

.req-text {
  font-size: 1.6rem;
}

.opa {
  opacity: 0.3;
}

.opa-checkout {
  opacity: 0.6;
}

.loading-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: #000;
  overflow-x: hidden;
}
.loading-overlay span {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #fff;
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  text-align: center;
  top: calc(50% - 60px);
}
.loading-overlay span i {
  font-size: 50px;
}

.radio-button {
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
  padding: 3px 3px 2px 3px;
  color: #ffffff;
}

.checkout-check {
  color: rgb(91, 183, 91);
}

.checkout-times,
.error-text {
  color: rgb(218, 79, 73);
}

.block-comment-payment {
  padding-top: 40px;
  clear: both;
}

.checkout-main {
  background-color: #fff;
}
.checkout-main .shadow-box {
  background: #f9f9f9;
}
.checkout-main h2 {
  border-bottom: 1px solid #222222;
}

.cart-sidebar {
  padding: 0px;
}

.order-container {
  width: 50%;
  margin: 0 auto;
}

.submitOrder {
  width: 100%;
}

.wrap-cart {
  margin: 20px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}
.wrap-cart .cart-items, .wrap-cart .cart-summary {
  position: sticky;
  top: 155px;
}
.wrap-cart .blink {
  padding-top: 10px;
  font-size: 1.6rem;
  color: #323232;
  font-weight: 500;
  -moz-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  animation: blink normal 1s infinite ease-in-out;
}
.wrap-cart .cart-content, .wrap-cart .cart-sidebar {
  padding: 20px;
}
.wrap-cart .cart-sidebar .subtotal-price {
  background: #222222;
  color: #ffffff;
  text-align: center;
  padding: 12px 15px;
  margin: 12px 0;
}
.wrap-cart .cart-sidebar .no-items {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  position: absolute;
}
.wrap-cart .cart-sidebar .styled-select {
  float: none;
  display: block;
}
.wrap-cart .cart-sidebar .form-wrap {
  margin-top: 10px;
}
.wrap-cart .cart-sidebar .form-wrap label {
  font-weight: normal;
}
.wrap-cart .cart-sidebar .get-estimate {
  margin: 10px 0;
  text-align: center;
  display: block;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-6 {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-6 > span {
  font-size: 1.4rem;
  display: block;
  position: relative;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-6 > span > p {
  position: absolute;
  margin: 0;
  bottom: 0;
  display: block;
  width: 100%;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-6:first-child {
  padding-right: 3px;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-6:last-child {
  padding-left: 3px;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-12 {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-12 > span {
  font-size: 1.4rem;
  display: block;
  position: relative;
}
.wrap-cart .cart-sidebar .get-estimate .col-xs-12 > span > p {
  position: absolute;
  margin: 0;
  bottom: 0;
  display: block;
  width: 100%;
}
.wrap-cart .cart-sidebar .get-estimate .estimate-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin-bottom: 15px;
}
.wrap-cart .cart-sidebar .get-estimate .estimate-info .shipping-option {
  width: 50%;
  float: left;
  text-align: left;
  color: #222222;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrap-cart .cart-sidebar .get-estimate .estimate-info .shipping-option span {
  font-weight: 600;
}
.wrap-cart .cart-sidebar .get-estimate .estimate-info .shipping-price {
  float: right;
  width: 50%;
  text-align: right;
  font-size: 1.3rem;
  font-weight: 500;
}
.wrap-cart .cart-content {
  padding-top: 25px;
}
.wrap-cart .cart-content > .col-sm-6 {
  padding-right: 60px;
}
.wrap-cart .cart-content > .col-sm-6 p {
  margin-bottom: 5px;
}
.wrap-cart .cart-content > .col-sm-6 + .col-sm-6 {
  border-left: 1px solid rgb(204, 204, 204);
  padding: 0 15px 0 60px;
}
.wrap-cart .cart-content > .col-sm-6 + .col-sm-6 .form-group + .form-group {
  margin-top: 35px;
}
.wrap-cart .cart-content h4 {
  margin-bottom: 20px;
}
.wrap-cart .cart-content label {
  font-weight: 500;
  margin: 0;
}
.wrap-cart .cart-content .cart-content.shipping-address {
  padding: 25px 0 0;
}
.wrap-cart .cart-content .remove-cart-item {
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #4b4f54;
  border-radius: 50%;
  text-align: center;
  padding: 0;
}
.wrap-cart .cart-content .remove-cart-item span {
  line-height: 17px;
  vertical-align: text-top;
  color: #4b4f54;
}
.wrap-cart .cart-content .remove-cart-item:hover {
  border-color: rgb(218, 79, 73);
}
.wrap-cart .cart-content .remove-cart-item:hover span {
  color: rgb(218, 79, 73);
}
.wrap-cart .cart-content.checkout {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.wrap-cart .cart-content.shipping-address h2 {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);
}
.wrap-cart .cart-content.shipping-address h2 + .row {
  margin-bottom: 50px;
}
.wrap-cart .shipping-title {
  margin-top: 0;
  margin-bottom: 15px;
}
.wrap-cart .checkout-sidebar .top-rounded {
  border-radius: 4px 4px 0 0;
}
.wrap-cart .checkout-sidebar .order-section {
  padding: 10px;
}
.wrap-cart .checkout-sidebar .order-section p {
  margin: 5px 0;
}
.wrap-cart .checkout-sidebar .order-section h5 {
  margin: 0 0 10px 0;
}
.wrap-cart .checkout-sidebar .order-section .order-label {
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
  width: 45%;
}
.wrap-cart .checkout-sidebar .order-section .amount {
  font-size: 1.5rem;
  font-weight: 600;
  float: right;
}
.wrap-cart .checkout-sidebar .order-section .total {
  color: #f69422;
  font-weight: 700;
  margin-top: 5px;
}
.wrap-cart .order-note {
  font-size: 12px;
  font-style: italic;
  padding-left: 8px;
}
.wrap-cart h6 {
  font-style: italic;
  background-color: #3a3a39;
  padding: 10px;
  font-size: 14px;
  color: #fff;
}
.wrap-cart .shipping-disclaimer {
  font-size: 1.2rem;
}
.wrap-cart .order-summary-promo-separator {
  margin-top: 12px;
  margin-bottom: 10px;
}
.wrap-cart .side-summary {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.wrap-cart .side-summary div:first-child {
  font-weight: bold;
}
.wrap-cart .wrap-subtotal {
  position: relative;
  margin: 0 0 35px 0;
}

.block-terms {
  position: relative;
  color: #f69422;
  padding-bottom: 10px;
}
.block-terms .info-terms {
  font-size: 1.2rem;
  padding: 7px 13px;
  border-radius: 4px;
  max-height: 125px;
  overflow-y: auto;
  background: #222222;
  color: #ffffff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  margin-top: 5px;
  z-index: 10;
  display: none;
}
.block-terms .link-terms {
  cursor: pointer;
  position: relative;
}
.block-terms .link-terms span {
  font-size: 1.2rem;
  color: #222222;
}
.block-terms:hover .info-terms {
  display: block;
}
.block-terms:before, .block-terms:after {
  bottom: 0px;
  left: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  display: none;
}
.block-terms:before {
  border-color: rgba(34, 34, 34, 0);
  border-bottom-color: #222222;
  border-width: 10px;
  margin-left: -10px;
}
.block-terms:hover:before, .block-terms:hover:after {
  display: block;
}

.help-text {
  text-align: left;
  font-style: normal;
}
.help-text .info-terms {
  min-width: 250px;
  left: -148px;
  line-height: 1.6;
  margin-top: -3px;
}
.help-text:before {
  margin-left: 0;
}

.shipping-options.col-xs-12,
.shipping-options .col-xs-12,
.shipping-options .col-sm-12 {
  padding: 0;
}
.shipping-options.col-xs-12 hr,
.shipping-options .col-xs-12 hr,
.shipping-options .col-sm-12 hr {
  border-color: #d8d8d8;
}
.shipping-options h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.shipping-options p {
  padding: 15px 0 0 0;
  margin: 0;
}
.shipping-options .best-value {
  color: #f99839;
}

.method-wrap {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.method-wrap .method-box {
  width: 150px;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  margin: 0 10px 10px 0;
  cursor: pointer;
}
.method-wrap .method-box .method-info {
  padding: 20px 0px 0px 0px;
  font-weight: bold;
  width: 100%;
}
.method-wrap .method-box .method-info strong {
  display: block;
  font-size: 3rem;
}
.method-wrap .method-box .use-info {
  text-transform: uppercase;
  font-size: 1.4rem;
  background: rgb(153, 153, 153);
  color: #ffffff;
  padding: 8px 6px 7px 6px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.method-wrap .method-box .use-info.selected {
  text-transform: uppercase;
  background: #f69422;
  color: #ffffff;
  font-size: 1.4rem;
  width: 100%;
  display: none;
}
.method-wrap .method-box:first-child {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: center;
  cursor: default;
}
.method-wrap .method-box.method-box-click {
  cursor: pointer !important;
  height: 161px;
  position: relative;
}
.method-wrap .method-box.swatch-box {
  cursor: pointer !important;
  height: 161px;
  position: relative;
}
.method-wrap .swatch-box:first-child {
  display: block !important;
}

.method-box.method-orange {
  border: 1px solid #f69422;
}
.method-box.method-orange span {
  font-size: 1.3rem;
  font-weight: normal;
}
.method-box.method-orange .use-info {
  display: none;
}
.method-box.method-orange .use-info.selected {
  display: block;
  padding: 8px 6px 7px 6px;
}

.table-responsive {
  margin-bottom: 35px;
}
.table-responsive .main-table.table {
  width: 100%;
  margin: 0;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.table-responsive .main-table.table th {
  font-size: 1.6rem;
  background: #222222;
  color: #ffffff;
  font-weight: normal;
  border-right: 1px solid rgb(235, 235, 235);
  vertical-align: middle;
}
.table-responsive .main-table.table th, .table-responsive .main-table.table td {
  padding: 10px;
  text-align: center;
  display: table-cell;
}
.table-responsive .main-table.table th img, .table-responsive .main-table.table td img {
  max-width: 50px;
}
.table-responsive .main-table.table th input[type=text], .table-responsive .main-table.table td input[type=text] {
  max-width: 60px;
  display: inline-block;
  background: #ffffff;
  margin-right: 5px;
  text-align: center;
}
.table-responsive .main-table.table th:first-child, .table-responsive .main-table.table td:first-child {
  text-align: left;
}
.table-responsive .main-table.table th:last-child, .table-responsive .main-table.table td:last-child {
  border-right: 0;
}
.table-responsive .main-table.table td {
  border-right: 1px solid #ffffff !important;
  border-top: 1px solid #ffffff !important;
  vertical-align: middle;
}
.table-responsive .main-table.table td:nth-of-type(2) {
  border-right: 0 !important;
}
.table-responsive .main-table.table td:nth-of-type(3) {
  text-align: left;
  padding-left: 0;
}
.table-responsive .main-table.table td a {
  color: rgb(218, 79, 73);
  font-size: 1.6rem;
}
.table-responsive .main-table.table td a.productURL {
  color: #393939;
}
.table-responsive .main-table.table td a.productURL:hover {
  color: #126cb6;
}
.table-responsive .main-table.table td .stock, .table-responsive .main-table.table td .stock-out {
  color: #f69422;
  font-size: 1.3rem;
}
.table-responsive .main-table.table td .stock-out {
  color: rgb(218, 79, 73);
}
.table-responsive .main-table.table td .kitItem {
  font-size: 1.1rem;
  font-style: italic;
  padding-left: 9px;
  display: block;
}
.table-responsive .main-table.table tr:nth-child(odd) {
  background: #f2f2f2 !important;
}
.table-responsive .main-table.table tr:nth-child(even) {
  background: rgb(235, 235, 235) !important;
}

.main-table.table.table-common {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}
.main-table.table.table-common td {
  padding: 15px;
}
.main-table.table.table-common td:first-child {
  border-right: 1px solid #ffffff !important;
  width: 225px;
}
.main-table.table.table-common td:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}
.main-table.table.table-common td a {
  color: rgb(23, 136, 228);
}

.add-product {
  max-width: 400px;
}

.add-coupon {
  max-width: 400px;
}

/*-- Collection Pages -- */
.collections-bottom {
  padding: 40px 0;
}

.collections {
  background-color: #fff;
  padding: 15px 15px 0 15px;
}
.collections .row {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.collections .row .collection-container {
  margin: 5px;
  flex-grow: 1;
  flex-basis: 48%;
  border: 1px solid #e6e6e6;
  padding: 15px;
}
.collections .row .collection-container .image-container {
  overflow: hidden;
}
.collections .row .collection-container .image-container img {
  width: 100%;
  transition: transform 0.2s;
}
.collections .row .collection-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* -- Common Content Pages styling -- */
.content-page .breadcrumb {
  padding-left: 50px;
}
.content-page h1 {
  text-transform: none;
  color: #222222;
  font-weight: normal;
}
.content-page h1 sup {
  top: -0.8em;
  font-size: 50%;
}
.content-page h1 strong {
  font-weight: 600;
}
.content-page div.col-sm-12 {
  background: #ffffff;
  padding: 30px 50px;
  float: left;
  width: 100%;
}
.content-page p {
  margin: 6px 0 25px 0;
  color: #4b4f54;
  font-weight: 300;
  line-height: 1.8;
  font-size: 1.6rem;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.content-page p span {
  color: #222222;
}
.content-page p a {
  color: #f69422 !important;
}
.content-page p a:hover {
  color: rgba(246, 148, 34, 0.7) !important;
}
.content-page p a strong {
  font-weight: 600;
}
.content-page h2 {
  margin: 15px 0 0 0;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.wrap-testimonials .breadcrumb {
  padding-left: 0;
}
.wrap-testimonials h1 {
  padding-left: 0;
}
.wrap-testimonials h1 sup {
  top: -0.8em;
  font-size: 50%;
}
.wrap-testimonials .testimonial-box {
  box-sizing: border-box;
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 15px 20px 10px 20px;
  margin-bottom: 30px;
  border-radius: 6px;
}
.wrap-testimonials .testimonial-box .blockquote {
  display: inline-block;
  font-size: 1.25rem;
  border-left: 0.5rem solid #3E6B85;
  color: #6c757d;
  font-style: italic;
  line-height: 1.6;
  padding-top: 4.6875rem;
  position: relative;
}
.wrap-testimonials .testimonial-box .blockquote:before {
  font-size: 6.875rem;
  color: #3E6B85;
  content: "“";
  font-family: "Noto Serif", "Georgia", "Times New Roman", "Times", serif;
  font-size: 5.5rem;
  height: 3.75rem;
  left: 0.9375rem;
  line-height: 1;
  max-width: 3.75rem;
  position: absolute;
  text-align: inherit;
  top: 0.9375rem;
  width: 100%;
}
.wrap-testimonials .testimonial-box .blockquote:after {
  content: "";
}
.wrap-testimonials .testimonial-box .blockquote .blockquote-footer {
  font-size: inherit;
  color: #343a40;
  display: flex;
  background: #ffffff;
  font-style: normal;
  padding: 0;
  text-align: left;
}
.wrap-testimonials .testimonial-box .blockquote .blockquote-footer:before {
  content: "— ";
}
.wrap-testimonials .testimonial-box .blockquote .blockquote-footer a {
  display: contents;
}
.wrap-testimonials .testimonial-box .blockquote p {
  margin: 0 0 12px 0;
}

.distributor-top {
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  background: #f69422;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
  margin-bottom: 15px;
  font-size: 36px;
}

.distributor-content h2 {
  text-align: center;
  background: #ddd;
  margin: 5px -10px 0 5px;
  font-family: "league_gothicregular", sans-serif;
  font-size: 3.2rem;
  border-radius: 0;
  font-weight: normal;
  letter-spacing: 0.5px;
  padding: 16px 15px 12px 15px;
  color: #4b4f54;
  font-size: 33px;
  text-transform: none;
}
.distributor-content .distributor-box {
  box-sizing: border-box;
  float: left;
  width: 100%;
  min-height: 165px;
  padding: 20px 10px;
}
.distributor-content .distributor-box h3 {
  font-family: "league_gothicregular", sans-serif;
  font-size: 3.2rem;
  border-radius: 0;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #4b4f54;
  font-size: 33px;
  margin-top: 25px;
}
.distributor-content .distributor-box hr {
  margin: 7px 0 10px;
}
.distributor-content .distributor-box .col-sm-4 {
  text-align: center;
}
.distributor-content .distributor-box .col-sm-4 img {
  width: 100%;
  max-width: 150px;
}
.distributor-content .distributor-box .payment-processed {
  padding: 0;
}
.distributor-content .col-md-6 {
  padding-bottom: 45px;
  float: left;
}
.distributor-content .col-md-6 + .col-md-6 .distributor-box {
  padding-left: 0;
}
.distributor-content .col-md-6 + .col-md-6 h2 {
  background: #ddd;
  margin: 5px 5px 0 -10px;
  border-radius: 0;
  font-size: 33px;
  color: #4b4f54;
  text-transform: none;
}

.form-question {
  display: none;
  margin-top: 25px;
}
.form-question textarea, .form-question input[type=text], .form-question input[type=tel] {
  background: #fafafa;
}
.form-question label {
  margin-right: 35px;
}
.form-question .group-btn .btn {
  text-transform: none;
}
.form-question .group-btn #btnCancel {
  background: transparent;
  color: #393939;
}
.form-question .group-btn #btnCancel:hover {
  color: #222222;
}

/* -- Token Find Applicator -- */
.token-wrap {
  padding: 30px 20px 0 20px;
  margin: 20px 0;
  background: #ffffff;
}
.token-wrap .token-applicator {
  width: 275px;
  float: left;
  border: 1px solid rgb(204, 204, 204);
  padding: 5px;
  position: relative;
  margin: 0 35px 30px 0;
  min-height: 82px;
}
.token-wrap .token-applicator img {
  height: 70px;
  float: right;
  margin-left: 10px;
  max-width: 125px;
}
.token-wrap .token-applicator span {
  padding: 5px 10px 5px 5px;
  display: inherit;
}
.token-wrap .token-applicator .delete-item {
  top: -12px;
  right: -13px;
}
.token-wrap .token-applicator a {
  display: block;
  color: #222222;
  min-height: 68px;
}
.token-wrap .token-applicator a:hover i {
  color: #126cb6;
}
.token-wrap .token-applicator:nth-of-type(1) {
  margin-left: 0;
}
.token-wrap .add-token i {
  float: left;
  color: rgb(204, 204, 204);
  font-size: 6.5rem;
  font-weight: 300;
  width: 90px;
  line-height: 100%;
  text-align: center;
  transition: all 0.3s ease;
}
.token-wrap .heading-applicator {
  margin-top: 10px;
}

.heading-applicator {
  width: 190px;
  float: left;
  margin: 0;
  line-height: 1.3;
}
.heading-applicator span {
  font-size: 4.5rem;
  float: left;
  margin-right: 15px;
  font-family: "league_gothicregular", sans-serif;
  line-height: 100%;
  font-weight: normal;
}

#vue-like-project .heading-applicator {
  width: 100%;
}

.sticky-wrap {
  background: #ffffff;
  -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  padding: 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.sticky-wrap .btn {
  position: absolute;
  left: 50%;
  padding: 13px 75px;
  font-size: 1.8rem;
  font-weight: 500;
  margin-left: -150px;
}

#vue-like-project .sticky-wrap {
  height: 0;
  padding: 0;
  transition: all 0.3s ease;
}
#vue-like-project .sticky-wrap.showStep3 {
  padding: 30px;
  height: inherit;
}

.img-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 160px;
  height: 160px;
  float: left;
  position: relative;
  overflow: hidden;
  margin: 0 15px 15px 0;
}
.img-wrapper .delete-image {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 3px;
  border: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  box-sizing: border-box;
  padding: 2px 0 0 1px;
  color: #fff;
  background: red;
}
.img-wrapper img {
  max-width: 100%;
  height: auto;
}

/* -- Order History -- */
.latest-purchased {
  padding: 15px 25px;
  background: #ffffff;
}
.latest-purchased .row {
  display: flex;
  margin: 0;
}
.latest-purchased .order-history-details {
  display: inline-block;
}
.latest-purchased .order-history-details-btn {
  float: right;
}
.latest-purchased hr {
  margin: 10px 0;
}
.latest-purchased .order-history-container {
  display: flex;
  padding-right: 20px;
  color: #222222;
}
.latest-purchased .order-history-container .order-history-product-image {
  float: left;
  padding-right: 20px;
}
.latest-purchased .order-history-container .order-history-product-image img {
  width: 75px;
  height: auto;
}
.latest-purchased .order-history-container .order-history-product-arrow {
  margin: auto;
  float: right;
  margin-right: 0;
}

/* -- Search box updated style -- */
.search-box .select2-container {
  width: calc(100% - 75px) !important;
  min-height: 50px;
  float: left;
}
.search-box .select2-container > .selection {
  width: 100%;
  height: 100%;
  float: left;
}
.search-box .select2-dropdown {
  border: 1px solid rgb(204, 204, 204);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  margin-top: 8px;
}
.search-box .select2-dropdown:before, .search-box .select2-dropdown:after {
  bottom: 100%;
  left: 5%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.search-box .select2-dropdown:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ccc;
  border-width: 10px;
  margin-left: -10px;
}
.search-box .select2-dropdown:after {
  border-color: rgba(202, 202, 202, 0);
  border-bottom-color: #fff;
  border-width: 9px;
  margin-left: -9px;
}
.search-box .select2-container--default.select2-container--focus .select2-selection--multiple,
.search-box .select2-container--default .select2-selection--multiple {
  border: 1px solid rgb(204, 204, 204);
  background: #ffffff;
  float: left;
  width: 100%;
  min-height: 50px;
  padding: 0 0 0 20px;
  font-size: 1.8rem;
  outline: none;
  border-radius: 25px 0 0 25px !important;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 6px 0;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 0;
  float: right;
  margin-left: 8px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 3px 10px 3px 12px;
  font-size: 1.3rem;
  border-radius: 15px;
}
.search-box .select2-results__option {
  padding: 0;
  background: transparent !important;
}
.search-box .select2-results__option.select2-results__message,
.search-box .select2-results__option.loading-results {
  padding: 10px 10px 10px 20px;
  color: #f69422;
  text-align: left;
}
.search-box .select2-results__option > span {
  padding: 7px 6px 7px 32px;
  display: block;
  text-align: left;
}
.search-box .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #eee !important;
  color: #222222;
}
.search-box .select2-results__option > span.applicator {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.applicator:hover {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.location {
  background: url("../img/location.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.location:hover {
  background: #f69422 url("../img/location_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.bookmarked {
  background: url("../img/location.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.bookmarked:hover {
  background: #f69422 url("../img/location_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.hashtag {
  background: url("../img/hash.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.hashtag:hover {
  background: #f69422 url("../img/hash_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.color_category {
  background: url("../img/color.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.color_category:hover {
  background: #f69422 url("../img/color_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.personal {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.personal:hover {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-results__option > span.owned {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box .select2-results__option > span.owned:hover {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.applicator {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.location {
  background: url("../img/location.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.bookmarked {
  background: url("../img/location.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.hashtag {
  background: url("../img/hash.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.color_category {
  background: url("../img/color.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.personal {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}
.search-box .select2-container--default .select2-selection--multiple .select2-selection__choice span.owned {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}

/* -- Updated Global Search Last -- */
.search-box {
  position: relative;
}
.search-box.gallery-search .global-search > .tokchi-token {
  font-size: 1.3rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 12px;
  border-radius: 15px;
  margin: 0 5px 5px 0;
}
.search-box.gallery-search .global-search > .tokchi-token > div {
  border-bottom: none;
}
.search-box .global-search {
  border: 1px solid #cccccc;
  background: white;
  float: left;
  width: calc(100% - 75px);
  min-height: 51px;
  height: 100%;
  padding: 11px 15px 0 20px;
  font-size: 1.6rem;
  outline: none;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  text-align: left;
  position: relative;
}
.search-box .global-search > .tokchi-token {
  display: inline-block;
}
.search-box .global-search > .tokchi-token.hide-token {
  display: none;
}
.search-box .global-search > .tokchi-token.missing-result > div {
  border: none;
  text-decoration: line-through;
}
.search-box .global-search > .tokchi-token:last-child div {
  margin-right: 5px;
}
.search-box .global-search > .tokchi-token div.applicator {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}
.search-box .global-search > .tokchi-token > div {
  display: inline-block;
  border-bottom: 1px solid #d8d8d8;
}
.search-box .global-search > .tokchi-token > div > span {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  color: #f69422;
}
.search-box .global-search > .tokchi-token > div.location {
  background: url("../img/location.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .global-search > .tokchi-token > div.bookmarked {
  background: url("../img/location.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .global-search > .tokchi-token > div.hashtag {
  background: url("../img/hash.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .global-search > .tokchi-token > div.color_category {
  background: url("../img/color.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 12px;
}
.search-box .global-search > .tokchi-token > div.personal {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}
.search-box .global-search > .tokchi-token > div.owned {
  background: url("../img/applicator.svg") 0 center no-repeat;
  background-size: 10px auto;
  padding-left: 14px;
}
.search-box ul.tokchi-dropdown {
  border: 1px solid rgb(204, 204, 204);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  margin-top: 8px;
  list-style: none;
  width: 100%;
  left: 0 !important;
  background: white;
  z-index: 11;
}
.search-box ul.tokchi-dropdown:before, .search-box ul.tokchi-dropdown:after {
  bottom: 100%;
  left: 5%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.search-box ul.tokchi-dropdown:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ccc;
  border-width: 10px;
  margin-left: -10px;
}
.search-box ul.tokchi-dropdown:after {
  border-color: rgba(202, 202, 202, 0);
  border-bottom-color: #fff;
  border-width: 9px;
  margin-left: -9px;
}
.search-box ul.tokchi-dropdown > li {
  padding: 7px 6px 7px 41px;
  display: block;
  text-align: left;
  cursor: pointer;
}
.search-box ul.tokchi-dropdown > li.color-match {
  padding-left: 10px;
}
.search-box ul.tokchi-dropdown > li span {
  display: block;
  font-size: 1.1rem;
  opacity: 0.6;
}
.search-box ul.tokchi-dropdown .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #eee !important;
  color: #222222;
}
.search-box ul.tokchi-dropdown > li.applicator {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.applicator.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.location {
  background: url("../img/location.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.location.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/location_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.bookmarked {
  background: url("../img/location.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.bookmarked.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/location_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.hashtag {
  background: url("../img/hash.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.hashtag.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/hash_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.hashtag.color-match {
  background: none;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.hashtag.color-match.tokchi-dropdown-item-selected {
  background: #f69422;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.color_category {
  background: url("../img/color.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.color_category.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/color_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.personal {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.personal.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}
.search-box ul.tokchi-dropdown > li.owned {
  background: url("../img/applicator.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.search-box ul.tokchi-dropdown > li.owned.tokchi-dropdown-item-selected {
  background: #f69422 url("../img/applicator_white.svg") 15px center no-repeat;
  background-size: 13px auto;
  color: #ffffff !important;
}

/* -- Gallery Upload Keyword Search -- */
.select2-dropdown li.select2-results__option div {
  padding: 2px 6px 2px 35px;
  display: block;
  text-align: left;
  cursor: pointer;
}
.select2-dropdown li.select2-results__option div.hashtag {
  background: url("../img/hash.svg") 15px center no-repeat;
  background-size: 13px auto;
}
.select2-dropdown li.select2-results__option div span {
  display: inline-block;
  font-size: 1.1rem;
  opacity: 0.6;
}
.select2-dropdown li.select2-results__option.select2-results__option--highlighted div.hashtag {
  background: url("../img/hash_white.svg") 15px center no-repeat;
  background-size: 13px auto;
}

/* -- Gallery Filter Top Searches CSS -- */
.wrap-top-searches {
  margin-top: 15px;
  text-align: center;
  float: left;
  width: 100%;
  display: none;
}
.wrap-top-searches a {
  color: #ffffff;
  display: inline-block;
  margin: 5px 2px;
  padding: 5px 12px;
  background: #393939;
  font-size: 1.3rem;
  border-radius: 20px;
}
.wrap-top-searches.show {
  display: block;
}

.radius-wrap {
  float: left;
  display: none;
}

.searchable .dropdown-menu li a:hover {
  color: #ffffff !important;
}

.quote-modal .modal-body, .quote-modal .modal-footer {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.quote-modal ul.attached-projects {
  margin-left: 18px;
}

.countdown-wrapper + .distributor-content,
.countdown-wrapper + .distributor-top {
  margin-top: 30px;
  border-radius: 0;
}

.distributor-details {
  padding: 35px 35px 15px 35px;
}

[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: "loading...";
}

/* Mid Section CSS ========================================*/
.mid-section {
  padding: 20px 0;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.col {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  min-height: 1px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.styled-select .form-control {
  padding-right: 42px;
}

.product-wrap {
  box-sizing: border-box;
  float: left;
  width: 100%;
  clear: both;
}
.product-wrap h2 {
  margin-bottom: 5px;
}
.product-wrap h2 > a > i {
  font-size: 1.1rem;
  font-weight: 400;
}

.flex-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.flex-wrap.col-xs-4 {
  margin: 20px 0;
}
.flex-wrap.col-sm-4 {
  margin: 20px 0;
}
.flex-wrap.col-sm-3 {
  margin: 20px 0;
}
.flex-wrap.col-sm-5 {
  margin: 20px 0;
}
.flex-wrap.col-sm-6 {
  margin: 20px 0;
}
.flex-wrap.col-sm-9 {
  margin: 20px 0;
}
.flex-wrap .col-sm-4 {
  margin: 10px 0 18px 0;
}
.flex-wrap .shadow-box hr {
  margin: 10px 0;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton .img-placeholder,
.loading-skeleton label,
.loading-skeleton img,
.loading-skeleton span,
.loading-skeleton .form-control {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .img-placeholder::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton img::placeholder,
.loading-skeleton span::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  width: 100%;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton::before {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  background: linear-gradient(90deg, transparent, var(--gradient-color), transparent);
  animation: loading-skeleton 1.5s linear 0.5s infinite;
}

#loader {
  display: block;
  width: 120px;
  height: 120px;
  padding: 0;
  background: white;
  border: solid 2px #F5A623;
  border-radius: 60px;
  font-size: 16px;
  color: #F5A623;
  line-height: 120px;
  outline: none;
  font-weight: bold;
  margin: 15px auto 0;
  transition: all 0.1s ease-out;
  text-align: center;
}
#loader span {
  opacity: 1;
  -webkit-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

.home-no-results {
  color: gray;
  padding-left: 20px;
}

.shop-no-results {
  color: gray;
}

.gallery-no-results {
  color: gray;
}

.color-match-box > .shopProduct {
  display: block;
  height: 100%;
}
.color-match-box > .shopProduct.shopProduct {
  height: 100%;
}
.color-match-box > .shopProduct i {
  margin-right: 10px;
}
.color-match-box > .shopProduct h3,
.color-match-box > .shopProduct p {
  color: #222222 !important;
  height: auto;
  margin: 0;
  transition: opacity 0.3s ease-in, all 0.1s ease-out;
}
.color-match-box > .creation {
  display: block;
  height: 100%;
}
.color-match-box > .creation i {
  margin-right: 10px;
}

.no-results,
.no-more {
  margin: 100px 0;
  display: inline-block;
  color: #ccc;
}
.no-results i,
.no-more i {
  font-size: 40px;
}
.no-results h4,
.no-more h4 {
  text-transform: unset;
  margin-bottom: 20px;
}

.shop-products .collection-info {
  margin-top: 10px;
  background-color: #ffffff;
  padding: 15px;
  display: inline-block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  color: #393939;
}

.product-cards {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 1vmin;
}

.cards-center {
  justify-content: center;
}

.product-card {
  transition: ease-in-out 0.3s;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  border-radius: 4px;
}
.product-card .product-btn {
  width: 100%;
}
.product-card .product-card-btn {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 15%;
  transition: ease-in-out 0.3s;
  z-index: 2;
}
.product-card .product-card-btn a {
  font-size: 1.2rem;
  color: #3E6B85;
}
.product-card .product-card-btn button {
  margin-top: 8px;
  font-size: 1.2rem;
}
.product-card .img-effect {
  transition: ease-in-out 0.2s;
  margin: 0 auto;
  display: grid;
  max-height: 250px;
  height: 175px;
  width: 100%;
  object-fit: contain;
}
.product-card a {
  color: #222222;
}
.product-card:hover {
  box-shadow: 0px 10px 8px -8px rgba(0, 0, 0, 0.1);
}
.product-card:hover .img-effect {
  transform: scale(1.05, 1.05);
  opacity: 0.3;
  z-index: 0;
}
.product-card:hover .product-card-details {
  background-color: #ffffff;
  position: relative;
}
.product-card:hover .product-sku,
.product-card:hover .product-title,
.product-card:hover .product-price,
.product-card:hover .product-creation {
  color: #222222;
}
.product-card:hover .product-card-btn {
  opacity: 1;
}

.product-card-details {
  z-index: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px 12px 12px 12px;
  transition: 0.3s ease-in-out;
  display: inline-block;
  width: 100%;
}
.product-card-details .product-sku {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  float: left;
}
.product-card-details .product-title {
  margin-top: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  text-transform: uppercase;
  height: 34px;
  font-weight: 400;
  font-family: Oswald, "Open Sans", Roboto;
  letter-spacing: 1px;
}
.product-card-details .product-price {
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #222222;
  float: right;
}
.product-card-details .product-creation {
  margin: 10px 0 0 0;
  padding: 3px;
  text-align: center;
  border: 2px solid #393939;
  display: inline-block;
  width: 100%;
}
.product-card-details h3 {
  font-size: 16px;
}

.product-box {
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  width: 177px;
  position: relative;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}
.product-box img {
  margin: 15px 0;
  max-width: 90%;
  max-height: 90px;
  width: auto;
}
.product-box h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: 1.4rem;
}
.product-box > div a:last-child {
  text-transform: uppercase;
  color: #393939;
  display: block;
  padding: 10px 0 10px 0;
  font-weight: 600;
  cursor: pointer;
}
.product-box p {
  margin: 0 0 10px 0;
  font-size: 1.2rem;
}
.product-box > a {
  margin-bottom: 16px;
  display: block;
  height: 100%;
}
.product-box > a.shopProduct {
  flex: 1;
}
.product-box > a i {
  margin-right: 10px;
}
.product-box > a h3,
.product-box > a p {
  color: #222222 !important;
  height: auto;
  margin: 0;
  transition: opacity 0.3s ease-in, all 0.1s ease-out;
}
.product-box > a .hoverShow {
  opacity: 0;
  height: 0;
  display: block;
  transition: all 0.5s ease-in 0.2s, all 0s ease-out;
}
.product-box > a:hover h3,
.product-box > a:hover p {
  color: #f69422 !important;
}
.product-box div:first-child > a {
  display: block;
  height: 100%;
}
.product-box div:first-child > a i {
  margin-right: 10px;
}
.product-box div:first-child > a h3,
.product-box div:first-child > a p {
  color: #222222 !important;
  margin: 0;
  height: auto;
  transition: opacity 0.3s ease-in, all 0.1s ease-out;
}
.product-box div:first-child > a .hoverShow {
  opacity: 0;
  height: 0;
  display: block;
  transition: all 0.5s ease-in 0.2s, all 0s ease-out;
}
.product-box div:first-child > a:hover h3,
.product-box div:first-child > a:hover p {
  color: #f69422 !important;
  opacity: 0;
  height: 0;
  margin: 0;
}
.product-box div:first-child > a:hover .hoverShow {
  opacity: 1;
  height: 54px;
}

.product-box.alternativesWidth {
  width: 177px;
}

#informationModal ul {
  padding-left: 20px;
}

#vue-gallery-details .product-wrap .product-box .addSwatch:hover, #vue-creation-details .product-wrap .product-box .addSwatch:hover {
  color: #f69422 !important;
}
#vue-gallery-details .product-wrap .product-box .addSwatch.disabled, #vue-creation-details .product-wrap .product-box .addSwatch.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.delete-item {
  background: rgb(254, 0, 0);
  color: #ffffff;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
  position: absolute;
  top: -6px;
  right: -6px;
  border: 0;
  line-height: 100%;
  outline: none;
}
.delete-item:before, .delete-item:after {
  width: 2px;
  height: 14px;
  background: #ffffff;
  position: absolute;
  content: "";
  top: 6px;
  left: 12px;
}
.delete-item:before {
  transform: rotate(45deg);
}
.delete-item:after {
  transform: rotate(-45deg);
}

.shadow-box {
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}
.shadow-box.extra-padding {
  padding: 30px;
}
.shadow-box.spacing-top {
  margin-top: 15px;
}
.shadow-box h6 {
  margin-top: 0;
}

.orders .row {
  display: flex;
  margin: 0;
}
.orders .shadow-box table {
  width: 100%;
  margin: 15px 0;
}
.orders .shadow-box table th {
  padding: 15px 0 5px;
}
.orders .shadow-box table img {
  width: 60px;
}
.orders .shadow-box table h5 {
  display: inline-block;
  color: #222222;
}
.orders .shadow-box table .kitItem {
  font-size: 1.1rem;
  font-style: italic;
  padding-left: 9px;
  display: block;
}
.orders .shadow-box table .border-bottom {
  border-bottom: 1px solid #ddd;
}
.orders .shadow-box table .discountAmount {
  padding: 15px 0 5px;
}

#vue-order-print .col-xs-6 {
  font-size: 12px;
}
#vue-order-print .col-xs-6 table {
  display: block;
  padding: 20px;
  border: 2px solid #222;
}
#vue-order-print .col-xs-6 table tbody {
  width: 100%;
  display: block;
}
#vue-order-print .col-xs-6 table tbody tr {
  width: 100%;
  display: block;
}
#vue-order-print .col-xs-6 table tbody tr td {
  width: 49%;
  display: inline-block;
  font-size: 12px;
}
#vue-order-print .col-xs-12 table {
  width: 100%;
}
#vue-order-print .col-xs-12 th {
  background-color: #ddd !important;
}
#vue-order-print .col-xs-12 table,
#vue-order-print .col-xs-12 th {
  border: 1px solid #222;
  padding: 5px 10px;
  font-size: 12px;
}
#vue-order-print .col-xs-12 td {
  padding: 5px 10px;
  font-size: 12px;
}
#vue-order-print .col-xs-12 .table1 {
  margin-bottom: 10px;
}
#vue-order-print .col-xs-12 .table1 tr,
#vue-order-print .col-xs-12 .table1 td {
  border: 1px solid #222;
  padding: 5px 10px;
  font-size: 12px;
}

.thumbnail-box, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
  padding: 7px;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
}
.thumbnail-box h3, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box h3,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box h3 {
  text-transform: none;
  font-size: 2rem;
  margin: 5px 0 8px 0;
}
.thumbnail-box h3 a, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box h3 a,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box h3 a {
  color: #222222;
}
.thumbnail-box h3 .icon-wrap, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box h3 .icon-wrap,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box h3 .icon-wrap {
  float: right;
  margin-top: -5px;
}
.thumbnail-box .wrap-img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img {
  width: 100%;
  height: 245px;
  position: relative;
}
.thumbnail-box .wrap-img a, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img a,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img a {
  display: block;
  width: 100%;
  height: 100%;
}
.thumbnail-box .wrap-img a .projectStatus, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img a .projectStatus,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img a .projectStatus {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8rem;
  text-shadow: 0 0 2px black;
}
.thumbnail-box .wrap-img a .projectStatus.denied, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img a .projectStatus.denied,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img a .projectStatus.denied {
  color: rgb(208, 1, 27);
}
.thumbnail-box .wrap-img a .projectStatus.approved, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img a .projectStatus.approved,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img a .projectStatus.approved {
  color: rgb(37, 161, 135);
}
.thumbnail-box .wrap-img > .icon-delete, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-delete,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-delete {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #ffffff;
  display: inline-block;
  font-size: 2.5rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.thumbnail-box .wrap-img > .icon-delete:hover, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-delete:hover,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-delete:hover {
  color: #126cb6;
}
.thumbnail-box .wrap-img > .icon-star, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-star,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-star {
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-top: 1px solid rgb(204, 204, 204);
  color: #222222;
  font-size: 1.4rem;
  line-height: 3rem;
  cursor: pointer;
  width: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.thumbnail-box .wrap-img > .icon-star i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-star i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-star i {
  text-shadow: 0 0 2px black;
  color: #ffffff;
}
.thumbnail-box .wrap-img > .icon-star:hover i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-star:hover i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-star:hover i {
  color: #126cb6;
}
.thumbnail-box .wrap-img > .icon-pencil, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-pencil,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-pencil {
  position: absolute;
  left: 0px;
  bottom: 31px;
  border-top: 1px solid rgb(204, 204, 204);
  color: #222222;
  font-size: 1.4rem;
  line-height: 3rem;
  cursor: pointer;
  width: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.thumbnail-box .wrap-img > .icon-pencil i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-pencil i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-pencil i {
  text-shadow: 0 0 2px black;
  color: #ffffff;
}
.thumbnail-box .wrap-img > .icon-pencil:hover i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-pencil:hover i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-pencil:hover i {
  color: #126cb6;
}
.thumbnail-box .wrap-img > .icon-comment, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-comment,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-comment {
  position: absolute;
  left: 0px;
  bottom: 62px;
  border-top: 1px solid rgb(204, 204, 204);
  color: #222222;
  font-size: 1.4rem;
  line-height: 3rem;
  cursor: pointer;
  width: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.thumbnail-box .wrap-img > .icon-comment i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-comment i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-comment i {
  text-shadow: 0 0 2px black;
  color: #ffffff;
}
.thumbnail-box .wrap-img > .icon-comment:hover i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img > .icon-comment:hover i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img > .icon-comment:hover i {
  color: #126cb6;
}
.thumbnail-box .wrap-img .icon-disabled, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img .icon-disabled,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img .icon-disabled {
  color: #c4c4c4;
  pointer-events: none;
  opacity: 0.3;
}
.thumbnail-box .wrap-img .icon-disabled:hover, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img .icon-disabled:hover,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img .icon-disabled:hover {
  color: #c4c4c4;
  cursor: default;
}
.thumbnail-box .wrap-img .starred, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img .starred,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img .starred {
  color: #f69422;
}
.thumbnail-box .wrap-img .starred i, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img .starred i,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img .starred i {
  color: #f69422;
}
.thumbnail-box .wrap-img img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img img,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnail-box .info-thumbnail, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
  padding: 10px 15px 55px 15px;
}
.thumbnail-box .info-thumbnail .row, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row {
  position: absolute;
  bottom: 10px;
  margin: 0 15px;
  left: 0;
  width: inherit;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  width: calc(100% - 30px);
}
.thumbnail-box .info-thumbnail .row > div, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row > div,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row > div {
  margin: 0;
}
.thumbnail-box .info-thumbnail p, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail p,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail p {
  margin: 0 0 10px 0;
}
.thumbnail-box .info-thumbnail .col-sm-4, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 {
  margin-top: 5px;
}
.thumbnail-box .info-thumbnail .col-sm-4 a, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4 a,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 a {
  color: #393939;
  display: inline-block;
  padding: 0px;
}
.thumbnail-box .info-thumbnail .col-sm-4 a span, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4 a span,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 a span {
  display: block;
  font-size: 1.1rem;
  color: #999;
  line-height: 100%;
  margin-top: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.thumbnail-box .info-thumbnail .col-sm-4 a:hover span, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4 a:hover span,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 a:hover span {
  color: #126cb6;
}
.thumbnail-box .info-thumbnail .col-sm-4 + .col-sm-4, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4 + .col-sm-4,
.creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 + .col-sm-4 {
  border-left: 1px solid rgb(235, 235, 235);
}

.item-status {
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  position: absolute;
  left: 6px;
  top: 6px;
  color: #fff;
  font-weight: 600;
  cursor: default;
}

.status-green {
  background: rgb(37, 161, 135);
}

.status-yellow {
  background: rgb(245, 166, 35);
}

.status-blue {
  background: rgb(54, 83, 149);
}

.status-red {
  background: rgb(254, 0, 0);
}

/* -- News Box CSS -- */
.news-box img {
  width: 100%;
}
.news-box p {
  line-height: 1.8;
  color: #4b4f54;
}
.news-box h6 {
  margin-top: 0;
}

/* -- Hero styles for homepage marketing banner -- */
#marketing-banner a img {
  width: 100%;
  height: auto;
}

/* -- Hero styles for TV commercial (8-16-2024) -- */
.hero-cta {
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
}

.hero-sub-cta {
  font-size: 2rem;
  margin: 0;
}

#discover {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), url("/img/hero-discover.jpg");
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center center;
}

#launch {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), url("/img/hero-launch.jpg");
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center center;
}

#connect {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), url("/img/hero-connect.jpg");
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 768px) {
  #discover,
  #launch,
  #connect {
    height: 300px;
  }
}
#homepage-video .video-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#homepage-video .video-cta button {
  position: absolute;
  opacity: 0.9;
}
#homepage-video .video-cta img {
  width: 100%;
}

@media (max-width: 767px) {
  #homepage-video .video-cta img {
    background: transparent;
    height: 150px;
  }
}
/* -- Shop Advertisement Banner -- */
.shopBanner > div {
  overflow: hidden;
  display: block;
  margin: 10px 0 0px;
  text-align: center;
  cursor: default;
}
.shopBanner > div span {
  color: #4b4f54;
  font-size: 1.6rem;
}

.marketing-banner {
  float: left;
  width: inherit;
  display: contents;
}
.marketing-banner-img {
  background-image: var(--mobile-image);
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-size: cover;
  height: 275px;
  width: 100%;
  float: left;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
@media screen and (min-width: 767px) {
  .marketing-banner-img {
    background-image: var(--tablet-image);
  }
}
@media screen and (min-width: 991px) {
  .marketing-banner-img {
    background-image: var(--desktop-image);
  }
}

@media (max-width: 991px) {
  .marketing-banner {
    padding: 0 !important;
  }
}
/* -- Search Box CSS -- */
.search-main {
  text-align: center;
  margin-bottom: 15px;
}

.strip-black + .mid-section .search-main {
  margin-top: 25px;
}

.search-box {
  width: 50%;
  display: inline-block;
}
.search-box input[type=text] {
  border: 1px solid rgb(204, 204, 204);
  background: #ffffff;
  float: left;
  width: calc(100% - 75px);
  height: 50px;
  padding: 0 15px 0 20px;
  font-size: 1.8rem;
  outline: none;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.search-box .btn-search,
.search-box .btn-filter {
  background: rgb(204, 204, 204);
  color: #ffffff;
  width: 75px;
  text-align: center;
  float: left;
  border-radius: 0 4px 4px 0;
  border: 0;
  height: 51px;
  font-size: 2.2rem;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.search-box .btn-search:hover, .search-box .btn-search:active, .search-box .btn-search:focus,
.search-box .btn-filter:hover,
.search-box .btn-filter:active,
.search-box .btn-filter:focus {
  background: #f69422;
  color: #ffffff;
}
.search-box .btn-filter {
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  color: #393939;
  width: 50px;
  border: 1px solid rgb(204, 204, 204);
  border-right: 0;
}
.search-box .btn-filter i {
  margin-left: 3px;
}

.search-box-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid #bdbdbd;
  transition: border-color 200ms ease-in-out;
  background-color: #fff;
  border-radius: 4px;
}
.search-box-form:focus-within {
  border-color: #333;
}
.search-box-form .search-box-shop {
  width: auto;
  flex: 1;
  padding: 8px;
  margin: auto;
}
.search-box-form .search-box-shop input {
  font-weight: normal;
  appearance: none;
  float: none;
  background: transparent;
  border: none;
  border-radius: 0;
  min-height: auto;
  width: 100%;
  padding: 5px 5px 5px 0;
  outline: none;
}
.search-box-form .search-box-shop input::placeholder {
  color: currentColor;
}
.search-box-form button {
  background: transparent;
  border: none;
  width: 40px;
}

#filterVisibility.collapse,
.toggle-shop-filters {
  display: none;
}
#filterVisibility.collapse .side-box .collapse,
.toggle-shop-filters .side-box .collapse {
  margin-top: 10px;
}

.mobile-only,
.small-screen {
  display: none;
}

/* Mid Section Inner CSS ===================*/
.mid-inner {
  min-height: -webkit-fill-available;
  background-color: #fafafa;
  margin-bottom: 3%;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.mid-inner > .viewAllBtn {
  color: #cc6909;
  cursor: pointer;
}

.document-page {
  margin-top: 65px;
}

.has-sub-menu .mobile-shop-categories {
  margin-top: 0;
}
.has-sub-menu .mobile-shop-categories h5 {
  position: absolute;
  top: 3px;
  left: 10px;
}
.has-sub-menu .mobile-shop-categories select {
  padding-left: 80px;
}

/* -- Filter CSS -- */
.filter-wrap {
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.filter-box {
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  float: left;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  padding: 0;
}
.filter-box li {
  float: left;
  width: 20%;
  text-align: center;
  list-style: none;
  font-size: 1.8rem;
}
.filter-box li:first-child > a {
  border-radius: 4px 0 0 4px;
}
.filter-box li:last-child > a {
  border-radius: 0 4px 4px 0;
}
.filter-box li button.btn-project-upload {
  color: #4b4f54;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  margin-top: 2px;
}
.filter-box li a {
  color: #4b4f54;
  font-weight: 600;
  display: block;
  padding: 8px 10px;
}
.filter-box li a > img {
  display: inline-block;
  margin-right: 10px;
}
.filter-box li a > span {
  display: inline-block;
  padding-left: 25px;
  line-height: 100%;
}
.filter-box li a > span.icon-newest {
  background: url("../img/clock.png") left 1px no-repeat;
}
.filter-box li a > span.icon-top-search {
  background: url("../img/seo-target.png") left 1px no-repeat;
}
.filter-box li a > span.icon-trending {
  background: url("../img/trending.png") left 0 no-repeat;
  padding-bottom: 1px;
}
.filter-box li a > span.icon-upload-photo {
  background: url("../img/upload-photo.png") left 1px no-repeat;
  padding-bottom: 1px;
}
.filter-box li a:hover, .filter-box li a:active {
  background: #393939;
  color: #ffffff !important;
}
.filter-box li a:hover > span.icon-newest, .filter-box li a:active > span.icon-newest {
  background: url("../img/clock.png") left -16px no-repeat;
}
.filter-box li a:hover > span.icon-top-search, .filter-box li a:active > span.icon-top-search {
  background: url("../img/seo-target.png") left -16px no-repeat;
}
.filter-box li a:hover > span.icon-upload-photo, .filter-box li a:active > span.icon-upload-photo {
  background: url("../img/upload-photo.png") left -16px no-repeat;
}
.filter-box li a:hover > span.icon-trending, .filter-box li a:active > span.icon-trending {
  background: url("../img/trending.png") left -19px no-repeat;
}
.filter-box li.active a > span.icon-newest {
  background: url("../img/clock.png") left -16px no-repeat;
}
.filter-box li.active a > span.icon-top-search {
  background: url("../img/seo-target.png") left -16px no-repeat;
}
.filter-box li.active a > span.icon-trending {
  background: url("../img/trending.png") left -19px no-repeat;
}
.filter-box li.active a > span.icon-upload-photo {
  background: url("../img/upload-photo.png") left -16px no-repeat;
}
.filter-box li + li {
  border-left: 1px solid rgb(204, 204, 204);
}
.filter-box li.active a {
  background: #393939;
  color: #ffffff !important;
}

.filter-options {
  padding: 8px 0 0 0;
  text-align: center;
  clear: both;
}
.filter-options div {
  display: inline-block;
  width: 61px;
}
.filter-options div a {
  display: inline-block;
  margin: 10px 0;
  color: rgb(153, 153, 153);
  font-weight: 600;
  text-align: center;
  position: relative;
}
.filter-options div a:hover, .filter-options div a.active {
  color: #222222 !important;
}
.filter-options div a.active i.fa-check {
  opacity: 1;
}
.filter-options div a img {
  display: inline-block;
  margin-bottom: 8px;
  -webkit-box-shadow: 3px 1px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 1px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 3px 1px 6px rgba(0, 0, 0, 0.5);
}
.filter-options div a span {
  display: block;
}
.filter-options div a i.fa-check {
  color: #ffffff;
  position: absolute;
  top: 7px;
  margin-left: 9px;
  opacity: 0;
  text-shadow: 0px 0px 1px #000000;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.filter-options div a.active i.fa-check {
  opacity: 1;
}
.filter-options div:active {
  color: rgb(153, 153, 153);
}
.filter-options a.color-all img {
  width: 30px;
}

label[for=sortNew],
label[for=sortTrending] {
  font-weight: normal;
}

.color-filters {
  margin: 15px 0;
}
.color-filters span {
  font-size: 1.3em;
  font-weight: 700;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  padding: 3px 0;
}
.color-filters-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 540px;
}
.color-filters-list li {
  flex: 0 1 calc(16.6666666667% - 2px);
  position: relative;
  padding-bottom: 0;
  margin: 0 1px;
  line-height: 1;
  max-width: 65px;
}
.color-filters-list li::before {
  content: attr(aria-label);
  position: absolute;
  z-index: 9;
  pointer-events: none;
  transition: all 150ms 200ms ease-in-out;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translate3d(-50%, 12px, 0);
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  opacity: 0;
}
.color-filters-list li:hover::before {
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
}
.color-filters-list li input[type=checkbox] {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  height: 0;
  border: 1px solid transparent;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  transition: transform 100ms ease-in-out;
}
.color-filters-list li input[type=checkbox][value*=red] {
  background-image: linear-gradient(#B74051, #913340);
  background-color: #B74051;
}
.color-filters-list li input[type=checkbox][value*=orange] {
  background-image: linear-gradient(#F38A1C, #d1700b);
  background-color: #F38A1C;
}
.color-filters-list li input[type=checkbox][value*=yellow] {
  background-image: linear-gradient(#F8EE49, #f6e918);
  background-color: #F8EE49;
}
.color-filters-list li input[type=checkbox][value*=green] {
  background-image: linear-gradient(#1BA00F, #13710b);
  background-color: #1BA00F;
}
.color-filters-list li input[type=checkbox][value*=blue] {
  background-image: linear-gradient(#2456F0, #0e3fd3);
  background-color: #2456F0;
}
.color-filters-list li input[type=checkbox][value*=purple] {
  background-image: linear-gradient(#9E49E9, #861ce4);
  background-color: #9E49E9;
}
.color-filters-list li input[type=checkbox][value*=pink] {
  background-image: linear-gradient(#D929A9, #b01f88);
  background-color: #D929A9;
}
.color-filters-list li input[type=checkbox][value*=black] {
  background-image: linear-gradient(#363636, #1d1d1d);
  background-color: #363636;
}
.color-filters-list li input[type=checkbox][value*=white] {
  background-image: linear-gradient(#F8F8F8, #dfdfdf);
  background-color: #F8F8F8;
}
.color-filters-list li input[type=checkbox][value*=silver] {
  background-image: linear-gradient(#C5C5C5, #acacac);
  background-color: #C5C5C5;
}
.color-filters-list li input[type=checkbox][value*=grey] {
  background-image: linear-gradient(#7B7B7B, #626262);
  background-color: #7B7B7B;
}
.color-filters-list li input[type=checkbox][value*=bronze] {
  background-image: linear-gradient(#F0A755, #ec8f26);
  background-color: #F0A755;
}
.color-filters-list li input[type=checkbox][value*=gold] {
  background-image: linear-gradient(#F5CC3F, #f2bf0f);
  background-color: #F5CC3F;
}
.color-filters-list li input[type=checkbox][value*=tan] {
  background-image: linear-gradient(#BFA371, #ae8c4f);
  background-color: #BFA371;
}
.color-filters-list li input[type=checkbox][value*=brown] {
  background-image: linear-gradient(#625B3F, #433e2b);
  background-color: #625B3F;
}
.color-filters-list li input[type=checkbox][value*=chrome] {
  background-image: linear-gradient(#DCDEDB, #c2c6c0);
  background-color: #DCDEDB;
}
.color-filters-list li input[type=checkbox][value*=white], .color-filters-list li input[type=checkbox][value*=clear] {
  border-color: #cacaca;
}
.color-filters-list li input[type=checkbox][value*=clear] {
  background-image: linear-gradient(rgba(0, 0, 0, 0.125) 25%, transparent 100%);
  background-color: #EEE;
  overflow: hidden;
}
.color-filters-list li input[type=checkbox][value*=clear]::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.125);
  transform-origin: top right;
  transform: rotate(45deg);
  position: absolute;
  height: 150%;
  width: 100%;
  top: 0;
  left: 8px;
}
.color-filters-list li input[type=checkbox]::before {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "FontAwesome";
  display: inline-block;
  font-variant: normal;
  text-rendering: auto;
  font-weight: 900;
  content: "\f00c";
  transition: transform 200ms ease-in-out;
  color: #fff;
  transform: scale(0);
  line-height: 0;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
  margin: -1px 0 0 -6px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.color-filters-list li input[type=checkbox]:hover {
  transform: scale(0.95);
}
.color-filters-list li input[type=checkbox]:checked::before {
  transform: scale(1);
}

.shop-filters .filter-options {
  display: block;
}

.shop-filter-box li {
  width: 16.6666666667%;
  position: relative;
}
.shop-filter-box li span {
  font-size: 1.3rem;
  position: absolute;
  left: 25px;
  bottom: 10px;
  color: #f78f1c;
  font-weight: bold;
  font-style: italic;
}

.filter-options-gallery {
  display: none;
}
.filter-options-gallery.show {
  display: block;
}

/* -- Shop Series Cards -- */
#vue-shop .popular-searches .shadow-box,
#vue-shop .series-card .shadow-box {
  padding: 0 15px;
}
#vue-shop .popular-searches button,
#vue-shop .popular-searches a.btn-orange,
#vue-shop .series-card button,
#vue-shop .series-card a.btn-orange {
  background: transparent;
  border-radius: 0;
  border: 1px solid #f69422;
  border-bottom-width: 6px;
  color: #f69422 !important;
}
#vue-shop .popular-searches button:hover,
#vue-shop .popular-searches a.btn-orange:hover,
#vue-shop .series-card button:hover,
#vue-shop .series-card a.btn-orange:hover {
  background: #f69422;
  color: #fff !important;
}
#vue-shop .popular-searches {
  text-align: center;
  padding: 10px 0 20px 0;
}
#vue-shop .popular-searches h3 {
  font-family: "Teko", sans-serif;
  font-size: 26px;
  line-height: 25px;
  display: block;
  color: #f69422;
  padding-bottom: 15px;
  letter-spacing: 0.1em;
  font-weight: normal;
}
#vue-shop .popular-searches .search-links:first-child {
  margin-left: 12.5%;
}
#vue-shop .popular-searches .search-links a {
  font-family: "Teko", sans-serif;
  font-size: 23px;
  line-height: 20px;
  display: block;
  padding-bottom: 15px;
  color: #393939;
  letter-spacing: 0.05em;
  text-align: center;
  cursor: pointer;
}
#vue-shop .popular-searches .search-links a:hover {
  color: #FD920E !important;
}
#vue-shop .series-card {
  margin-top: 5%;
}
#vue-shop .series-card h1 {
  font-size: 1.6rem;
  padding: 10px 0;
  color: #fff;
  margin: 0;
}
#vue-shop .series-card h2 {
  font-size: 1.5rem;
  display: block;
  border-bottom: 2px solid #f69422;
  margin: 0 auto 10px auto;
  width: 110px;
  padding-top: 10px;
  padding-bottom: 2px;
}
#vue-shop .series-card .series-card-header {
  min-height: 38px;
  text-align: center;
  background: #393939;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#vue-shop .series-card .series-card-content .series-card-key-features {
  min-height: 332px;
  text-align: center;
  background: #FFFFFF;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
}
#vue-shop .series-card .series-card-content .series-card-key-features ul {
  list-style-type: circle;
  padding: 0 20px 20px;
  height: 220px;
}
#vue-shop .series-card .series-card-content .series-card-key-features ul li {
  text-align: left;
  font-size: 1.4rem;
}
#vue-shop .series-card .series-card-content .series-card-image {
  padding: 0;
}
#vue-shop .series-card .series-card-content .series-card-image img {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}
#vue-shop .series-card .series-card-content .series-card-image .med-series-img,
#vue-shop .series-card .series-card-content .series-card-image .sml-series-img,
#vue-shop .series-card .series-card-content .series-card-image .mbl-series-img {
  display: none;
}

/* -- Filtered Products -- */
.sidebar {
  border-radius: 4px;
}
.sidebar h2 {
  margin-left: 30px;
}
.sidebar h3 {
  padding: 0 0 14px 0;
  margin: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #393939;
}
.sidebar h2 + p {
  margin: 0 0 15px 30px;
}
.sidebar .shadow-box {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 26px;
  margin-bottom: 15px;
}
.sidebar .shadow-box h2,
.sidebar .shadow-box h2 + p {
  margin-left: 0;
  text-transform: none;
}
.sidebar .shadow-box .photo-in {
  margin-left: auto;
  margin-right: auto;
}
.sidebar .shadow-box .photo-in label {
  height: inherit;
}
.sidebar .shadow-box address {
  color: rgb(23, 136, 228);
  font-weight: 600;
  margin-bottom: 5px;
}
.sidebar .shadow-box a {
  color: #393939;
  display: inline-block;
  padding: 0 15px;
  margin-top: 10px;
}
.sidebar .shadow-box a span {
  font-weight: 600;
  display: block;
  font-size: 1.3rem;
  color: rgb(153, 153, 153);
  line-height: 100%;
  margin-top: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .shadow-box a i {
  font-size: 1.8rem;
}
.sidebar .shadow-box a:hover span {
  color: #126cb6 !important;
}
.sidebar .shadow-box .my-account-country {
  color: rgb(23, 136, 228);
  margin: 0;
}

.products-filtered {
  float: left;
  width: calc(100% + 30px);
  display: flex;
}
.products-filtered.kits {
  margin-top: 0;
}
.products-filtered > .back-to-top {
  background: none;
  margin: 0;
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 70px;
  height: 70px;
  z-index: 100;
  display: none;
  text-decoration: none;
  color: #f69422;
  background-color: transparent;
  font-size: 70px;
  line-height: 68px;
  padding: 0 5px;
}
.products-filtered > .back-to-top:hover {
  opacity: 0.6;
}
.products-filtered .sidebar {
  width: 19%;
  background-color: #ffffff;
  padding: 15px;
  margin: 0 15px 0 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  height: max-content;
}
.products-filtered .side-box ul .collapse {
  margin-top: 10px;
}

.product-count {
  float: right;
  font-size: 14px;
  color: #7B7B7B;
}

.filter-list-title {
  overflow: auto;
}

.filters:hover {
  color: #F78F1B;
  transition: color 0.2s ease;
}

.filter-selected {
  color: #F78F1B;
  font-weight: 800 !important;
  margin-left: 5px;
  transition: margin-left 0.2s cubic-bezier(1, 0, 1, 1);
}

.clear-filters {
  width: 100%;
  margin-top: 15px;
  text-transform: inherit;
}

#filterOptions ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
}
#filterOptions ul li {
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  font-size: 1.3em;
}
#filterOptions ul li span {
  background: #f2f2f2;
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 10px -10px -8px -10px;
  padding: 10px 14px 12px 16px;
  position: relative;
  color: rgb(153, 153, 153);
  font-weight: 300;
  display: none;
}
#filterOptions ul li span::after {
  bottom: 100%;
  left: 25px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(242, 242, 242, 0);
  border-bottom-color: #f2f2f2;
  border-width: 6px;
  margin-left: -6px;
}
#filterOptions ul li:hover span {
  display: block;
}
#filterOptions ul li i {
  float: right;
  margin-top: 6px;
}
#filterOptions ul li i.fa-plus {
  display: none;
}
#filterOptions ul li i.fa-angle-down {
  display: none;
}
#filterOptions ul li ul {
  position: relative;
  z-index: 2;
  padding: 5px 0;
}
#filterOptions ul li li {
  padding: 3px 0px 3px 10px;
  font-size: 16px;
  font-weight: 500;
}
#filterOptions ul li li div {
  display: inline-block;
}
#filterOptions ul li li div p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: rgb(153, 153, 153);
}
#filterOptions ul li li input {
  margin-left: 20px;
  margin-top: -1px !important;
}
#filterOptions ul li li em {
  float: right;
  background: #ffffff;
  margin-top: 11px;
}
#filterOptions ul li li em.mColorChecks {
  margin-top: 4px;
}
#filterOptions ul li div.collapsed i.fa-angle-up {
  display: none;
}
#filterOptions ul li div.collapsed i.fa-angle-down {
  display: block;
}
#filterOptions ul li.active {
  font-weight: bold;
}
#filterOptions ul li.active i {
  opacity: 1;
}
@media only screen and (min-width: 1031px) {
  #filterOptions {
    display: block !important;
  }
}

.gallery-full > .back-to-top, .creation-full > .back-to-top {
  background: none;
  margin: 0;
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 70px;
  height: 70px;
  z-index: 100;
  display: none;
  text-decoration: none;
  color: #f69422;
  background-color: transparent;
  font-size: 70px;
  line-height: 68px;
  padding: 0 5px;
}
.gallery-full > .back-to-top:hover, .creation-full > .back-to-top:hover {
  opacity: 0.6;
}

.side-box {
  background: #ffffff;
  padding: 10px 15px 15px 15px;
  margin-bottom: 20px;
}

.latest-purchased {
  margin-top: 20px;
}

/* -- Filtered Right Content CSS -- */
.content-right .product-wrap .product-box {
  margin: 10px !important;
  width: 10%;
  min-height: 231px;
  min-width: 190px;
}
.content-right .product-wrap .product-box.swatch-box {
  padding: 5px;
  min-height: 254px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.content-right .product-wrap .product-box.swatch-box .addSwatch:hover {
  color: #f69422 !important;
}
.content-right .product-wrap .product-box.swatch-box .addSwatch.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.content-right .product-wrap .product-box.swatch-box .swatchToCart.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.content-right h4 {
  padding: 0 0 0px 5px;
  margin: 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #393939;
  text-align: left;
  text-transform: capitalize;
}
.content-right h4 .styled-select {
  width: 150px;
  float: none;
  height: 33px;
  display: inline-block;
}
.content-right h4 .styled-select:before {
  top: 2px;
  pointer-events: none;
}
.content-right h4 .styled-select select.form-control {
  margin-top: -6px;
  height: 34px;
}

.product-in {
  background: #ffffff;
  box-sizing: border-box;
  float: left;
  width: 100%;
  padding: 25px 10px 30px 10px;
  text-align: center;
  margin-top: 32px;
}
.product-in h4 {
  text-transform: none;
  margin: 20px 0 0 0;
}
.product-in a {
  color: #393939;
}
.product-in img {
  max-width: 100%;
}

/* Profile Page ========================================*/
.my-page .qualifiedApplication {
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 2px 1px 6px -2px #222;
  background: #3a3a39;
  position: absolute;
  padding: 10px 25px;
  border-radius: 4px;
  color: #222222;
  display: block;
  left: 50%;
  top: 50%;
}
.my-page .qualifiedApplication img {
  margin-left: -15px;
  height: 45px;
}
.my-page .qualifiedApplication span {
  font-size: 17px;
  margin: 0;
  font-weight: 500;
  color: #fff;
  display: inline-block;
}
.my-page .qualifiedApplication:hover {
  color: #f69422 !important;
}

.profile-top {
  margin-top: 30px;
  padding-bottom: 15px;
  position: relative;
}
.profile-top .block-map {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.profile-top .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.profile-top ul li {
  list-style: none;
  padding-top: 5px;
}
.profile-top ul li .checkout-check {
  font-size: 20px;
  vertical-align: middle;
}

.show-all {
  cursor: pointer;
  color: #1788e4;
  text-transform: none;
  font-size: 1.4rem;
  margin-left: 25px;
}

.project-reviews {
  background-color: #fff;
  margin: 15px 0;
}
.project-reviews .col-md-2 {
  padding: 0px;
  padding-right: 15px;
}
.project-reviews .col-md-2 img {
  width: 100%;
}
.project-reviews .col-md-10 {
  padding-left: 0px;
}
.project-reviews .col-md-10 > div {
  margin-top: 15px;
  padding-left: 15px;
}
.project-reviews a {
  color: initial;
}
.project-reviews .stars {
  float: none;
  height: 14px;
  width: 100%;
}
.project-reviews .fa-star {
  float: left;
  padding-right: 4px;
}

.photo-profile {
  position: relative;
  display: inline-block;
}
.photo-profile .photo-in {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 5px;
}
.photo-profile .photo-in img {
  width: 100%;
  height: 101%;
  object-fit: cover;
  margin-top: -1px;
}
.photo-profile .qualified-profile,
.photo-profile .trend-profile,
.photo-profile .distributor-profile,
.photo-profile .yellow-check,
.photo-profile .coming-soon-profile,
.photo-profile .robotics-profile,
.photo-profile .high-volume-profile,
.photo-profile .multi-cam-profile {
  position: absolute;
  bottom: -20px;
  left: 42px;
}
.photo-profile .trend-profile {
  bottom: -5px;
  left: 90px;
  width: 34px;
}
.photo-profile .distributor-profile {
  width: 34px;
  bottom: -5px;
  left: 7px;
}
.photo-profile .qualified-profile {
  width: 34px;
  left: 48px;
}
.photo-profile .robotics-profile {
  width: 34px;
  bottom: 30px;
  left: -15px;
}
.photo-profile .coming-soon-profile {
  bottom: 30px;
  left: 115px;
  width: 34px;
}
.photo-profile .high-volume-profile {
  bottom: 75px;
  left: 115px;
  width: 34px;
}
.photo-profile .multi-cam-profile {
  width: 34px;
  bottom: 75px;
  left: -15px;
}
.photo-profile .yellow-check {
  bottom: 0;
  left: 86px;
}

#vue-my-page .photo-profile .photo-in {
  width: 100%;
  height: auto;
}

.info-profile {
  padding: 0 20px 0px 0px;
}
.info-profile .info-details {
  margin-bottom: 20px;
}
.info-profile h2 {
  text-transform: none;
  font-weight: bold;
  margin-top: 5px;
}
.info-profile h2 a {
  float: right;
}
.info-profile address {
  color: rgb(23, 136, 228);
  font-weight: 600;
  margin-bottom: 10px;
}
.info-profile .aboutUs {
  margin-bottom: 15px;
}
.info-profile .row .col-sm-12 {
  overflow: hidden;
}
.info-profile .row .col-sm-12 > .closePhoneLink {
  position: absolute;
  right: 20px;
  bottom: 4px;
  padding: 6px 10px;
  z-index: 10;
  opacity: 0;
  width: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-profile .row .col-sm-12 > .closePhoneLink.open {
  width: initial;
  opacity: 1;
}
.info-profile .row .col-sm-12 > .phoneLink {
  position: absolute;
  width: 0px;
  background-color: #ddd;
  z-index: 1;
  height: 100%;
  text-align: center;
  padding-top: 10px;
  opacity: 0;
  left: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-profile .row .col-sm-12 > .phoneLink.open {
  width: calc(100% - 30px);
  opacity: 1;
  z-index: 9;
}
.info-profile .col-sm-3 + .col-sm-3 {
  border-left: 1px solid rgb(204, 204, 204);
}
.info-profile .col-sm-3 a {
  color: #393939;
  display: inline-block;
  padding: 0;
  width: 100%;
  height: 100%;
}
.info-profile .col-sm-3 a i {
  font-size: 1.8rem;
}
.info-profile .col-sm-3 a span {
  font-weight: 600;
  display: block;
  font-size: 1.3rem;
  color: rgb(153, 153, 153);
  line-height: 100%;
  margin-top: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-profile .col-sm-3 a.noURL {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.info-profile .col-sm-3 a.noURL:hover span {
  color: rgb(153, 153, 153) !important;
}
.info-profile .col-sm-3 a.noURL:hover {
  color: #222222 !important;
}
.info-profile .col-sm-3 a:hover span {
  color: #126cb6 !important;
}
.info-profile .appInfoContactBtn {
  width: 20% !important;
  padding: 0;
  float: left;
  cursor: pointer;
  z-index: 8;
}

.badges-profile .col-xs-3 {
  font-size: 1.3rem;
  text-align: center;
}
.badges-profile .col-xs-3 img {
  width: 45px;
  display: block;
  margin: auto;
}

.category-profile ul {
  padding-left: 30px;
}

.stars {
  color: #f99839;
  margin: 5px 0;
  position: relative;
}
.stars a {
  color: #f99839 !important;
  margin: 5px 0;
}
.stars > .stars-tooltip {
  position: absolute;
  width: 130px;
  left: -24px;
  top: 42px;
  color: #ffffff;
  display: inline-block;
  font-size: 1.3rem;
  background-color: #555;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  width: 330px;
  top: 30px;
  z-index: 9;
}
.stars > .stars-tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
.stars > .stars-tooltip-center {
  position: absolute;
  width: 130px;
  left: 10px;
  top: 42px;
  color: #ffffff;
  display: inline-block;
  font-size: 1.3rem;
  background-color: #555;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
  width: 330px;
  top: 30px;
  z-index: 9;
}
.stars > .stars-tooltip-center::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
.stars > .stars-tooltip-center::before {
  left: 50%;
}
.stars:hover .stars-tooltip {
  visibility: visible;
  opacity: 1;
}
.stars:hover .stars-tooltip-center {
  visibility: visible;
  opacity: 1;
}

/* Location Page ======================================*/
.location-wrap {
  margin-top: 50px;
  width: 75%;
}
.location-wrap h2 {
  text-transform: none;
  color: #393939;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.location-wrap h3 {
  text-transform: none;
  padding-bottom: 30px;
  color: #393939;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.location-wrap .col-sm-12 h2 {
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px 0;
}
.location-wrap .info-block {
  width: 75%;
  margin: 0 auto;
  text-align: left;
  padding: 0 15px;
}
.location-wrap .info-block p {
  font-size: 22px;
  margin-bottom: 40px;
}
.location-wrap .shadow-box {
  margin-bottom: 30px;
}
.location-wrap .shadow-box svg {
  margin-top: 35px;
  padding: 15px;
}

/* Wishlist Page =======================================*/
.heading-bordered {
  margin-top: 20px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.heading-bordered h2 {
  position: relative;
}
.heading-bordered h2 > span {
  background: #fafafa;
  display: inline-block;
  position: relative;
  z-index: 2;
  padding-right: 15px;
}
.heading-bordered h2:before {
  content: "";
  position: absolute;
  right: 0;
  height: 1px;
  background: rgb(204, 204, 204);
  width: 100%;
  left: 0;
  top: 48%;
  z-index: 1;
}

.heading-bordered + .row .flex-wrap .product-box {
  width: 177px;
  margin-right: 15px;
}

#checkoutAlertModal .modal-dialog {
  width: 400px;
  text-align: center;
}
#checkoutAlertModal .modal-dialog .modal h2 {
  padding-left: 30px;
}
#checkoutAlertModal .modal-dialog h5 {
  line-height: 1.8rem;
}
#checkoutAlertModal .modal-dialog .modal-footer {
  text-align: center;
}
#checkoutAlertModal .modal-dialog .modal-footer button {
  padding: 8px 25px;
}

#qualifiedApplication .modal-title {
  text-align: center;
}
#qualifiedApplication .modal-content {
  padding-bottom: 0;
}
#qualifiedApplication .form-group {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  min-height: 93px;
}
#qualifiedApplication .form-group label {
  padding-top: 9px;
  font-weight: 400;
  display: inline;
  position: absolute;
  bottom: 42px;
}
#qualifiedApplication .form-group .form-control {
  background: #ffffff;
  font-weight: 600;
  height: 42px;
  bottom: 0;
  position: absolute;
}
#qualifiedApplication .sidebar h2 {
  margin-left: 0px;
}
#qualifiedApplication .btn-orange {
  margin-top: 20px;
}

/* Your Account Page ====================================*/
.my-account {
  display: grid;
  padding-top: 20px;
  padding-bottom: 0px;
}
.my-account .sidebar + .content-right .form-group .col-sm-9 > div + div {
  margin-top: 15px;
  margin-bottom: 5px;
}
.my-account .form-group {
  box-sizing: border-box;
  float: left;
  width: 100%;
}
.my-account .form-group > div {
  padding-left: 30px;
}
.my-account .form-group label {
  padding-top: 9px;
  font-weight: 600;
}
.my-account .form-group label span {
  font-size: 1.2rem;
  color: rgb(153, 153, 153);
}
.my-account .form-group .form-control {
  background: #ffffff;
  font-weight: 600;
  height: 42px;
}
.my-account .form-group .form-control.aboutUsSection {
  height: 125px;
}
.my-account .form-group input + a {
  margin: 10px 0 15px 12px;
  display: block;
  font-weight: 600;
}
.my-account .form-group input + .row {
  margin-top: 15px;
}
.my-account #qualifiedApplication .modal-content {
  padding-bottom: 0;
}
.my-account #qualifiedApplication .form-group {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  min-height: 93px;
}
.my-account #qualifiedApplication .form-group label {
  padding-top: 9px;
  font-weight: 400;
  display: inline;
  position: absolute;
  bottom: 42px;
}
.my-account #qualifiedApplication .form-group .form-control {
  background: #ffffff;
  font-weight: 600;
  height: 42px;
  bottom: 0;
  position: absolute;
}
.my-account #qualifiedApplication .sidebar h2 {
  margin-left: 0px;
}
.my-account #qualifiedApplication .btn-orange {
  margin-top: 20px;
}
.my-account .field-add-phone {
  display: none;
}
.my-account.addressSection > div {
  width: 100%;
}
.my-account.addressSection h5 {
  margin: 0 0 5px 0;
  width: 100%;
}
.my-account.addressSection h5 input {
  width: 200px;
  display: inline-block;
  float: right;
  margin-top: -12px;
  margin-right: 15px;
}
.my-account.addressSection h5 a {
  float: right;
  width: 160px;
  text-align: right;
}
.my-account > .payment-options .row label {
  font-weight: 600;
}
.my-account > .payment-options .row .form-group.col-sm-6 {
  width: 50%;
}
.my-account > .payment-options .row .form-group.col-sm-4 {
  width: 33.33333333%;
}
.my-account > .payment-options .row .form-group.col-sm-4 label {
  padding-top: 0;
}
.my-account .remove-payment-opt {
  font-size: 1.9rem;
  color: rgba(255, 0, 0, 0.65);
  position: absolute;
  right: 15px;
  top: 7px;
  text-align: right;
  padding: 0 5px;
  cursor: pointer;
}
.my-account .delete-phone-parent {
  position: relative;
}
.my-account .delete-phone {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.my-account .delete-phone i {
  font-size: 1.9rem;
  color: rgba(255, 0, 0, 0.65);
}
.my-account .primary-phone {
  position: absolute;
  top: 12px;
  right: 40px;
  cursor: pointer;
}
.my-account .primary-phone i {
  font-size: 1.8rem;
}
.my-account .primary-phone i.fa-star {
  color: #f69422;
}

.heading-account h5 a {
  float: right;
}

/* Equipment Page ======================================== */
.wrap-gallery {
  margin-bottom: 25px;
}

.thumbnail-gallery {
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  height: 0;
}
.thumbnail-gallery a {
  display: block;
  position: absolute;
  color: #393939;
  width: 100%;
  height: 100%;
}
.thumbnail-gallery a .thumbnail-info {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 5px 15px 15px 15px;
  z-index: 2;
  background: #ffffff;
  transition: background 0.2s;
  text-align: center;
}
.thumbnail-gallery a .thumbnail-info p {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.thumbnail-gallery a .thumbnail-info h3 {
  font-size: 1.5rem;
}
.thumbnail-gallery a:hover {
  color: #393939 !important;
}
.thumbnail-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnail-gallery:hover {
  z-index: 10;
}
.thumbnail-gallery:hover .thumbnail-info {
  background: rgba(255, 255, 255, 0.8);
  transition: background 0.2s;
}

#loadMore {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border-radius: 50%;
  display: inline-block;
  color: rgb(255, 150, 0);
  font-size: 1.2rem;
  border: 3px solid rgb(255, 150, 0);
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 20px;
}
#loadMore > span {
  display: inline-block;
  margin-top: 50px;
}
#loadMore:hover {
  background: rgb(255, 150, 0);
  color: #ffffff !important;
}

/* Color Match ========================================= */
.shop-search-label {
  padding-left: 25%;
  font-size: 18px;
  line-height: 24px;
  color: #4B4F54;
  font-weight: bold;
  font-family: "Open Sans";
  margin-top: 10px;
}

/* Product Detail ====================================== */
.countdown-wrapper {
  display: table;
  width: 100%;
  line-height: 30px;
  margin: 5px 0;
}
.countdown-wrapper > div {
  display: table-cell;
  line-height: 30px;
}
.countdown-wrapper .stripe-left {
  text-align: right;
}
.countdown-wrapper .stripe-left .gradient {
  display: inline-block;
  width: 100%;
  max-width: 220px;
  height: 3px;
  background: linear-gradient(to right, rgb(242, 242, 242), rgb(255, 151, 0));
}
.countdown-wrapper .stripe-right .gradient {
  display: inline-block;
  width: 100%;
  max-width: 220px;
  height: 3px;
  background: linear-gradient(to left, rgb(242, 242, 242), rgb(255, 151, 0));
}
.countdown-wrapper .middle {
  width: 320px;
}
.countdown-wrapper .countdown {
  width: 100%;
  line-height: 30px;
  z-index: 300;
  text-align: center;
}
.countdown-wrapper .countdown p {
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-family: "league_gothicregular", sans-serif;
  color: white;
  margin: 3px 0px 0px;
  color: #4b4f54;
  text-transform: uppercase;
  font-size: 2.8rem;
}
.countdown-wrapper .countdown p span {
  color: #f69422;
}
.countdown-wrapper .countdown .question {
  color: #f69422;
  display: inline-block;
  margin: 0 0 0 5px;
  font-size: 1.6rem;
  cursor: pointer;
}
.countdown-wrapper .shipping-rates {
  width: 100%;
  line-height: 30px;
  z-index: 300;
  text-align: center;
  padding: 15px;
  background: #fff;
  float: left;
}
.countdown-wrapper .shipping-rates .container-fluid {
  width: fit-content;
}
.countdown-wrapper .shipping-rates h2 {
  font-size: 37px;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  font-family: "league_gothicregular", sans-serif;
  font-weight: normal;
  text-transform: none;
  color: #4b4f54;
  margin-top: 5px;
}
.countdown-wrapper .shipping-rates h6 {
  margin: 5px auto;
  width: fit-content;
}
.countdown-wrapper .shipping-rates p {
  vertical-align: middle;
  line-height: 30px;
  font-family: "league_gothicregular", sans-serif;
  color: #4b4f54;
  text-transform: uppercase;
  font-size: 2.2rem;
  width: fit-content;
  margin: 3px auto;
}
.countdown-wrapper .shipping-rates > div div {
  width: 215px;
  margin: auto;
  text-align: left;
}
.countdown-wrapper .shipping-rates img {
  width: 75px;
  display: block;
  margin: 0 auto;
}

.wrap-details {
  background: #ffffff;
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: relative;
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.info-product-left {
  width: 45%;
}
.info-product-left .resources, .info-product-left .share {
  float: left;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  z-index: 1;
  background: #ffffff;
}
.info-product-left .resources ul, .info-product-left .share ul {
  height: 100%;
}
.info-product-left .resources ul li, .info-product-left .share ul li {
  display: inline-block;
  width: 13%;
}
.info-product-left .resources ul li a, .info-product-left .share ul li a {
  display: block;
  text-align: center;
  padding-top: 30px;
  font-weight: 600;
  color: #393939;
  min-height: 100px;
  height: auto;
}
.info-product-left .resources ul li a:hover, .info-product-left .share ul li a:hover {
  color: #f69422 !important;
}
.info-product-left .resources ul li a[disabled], .info-product-left .share ul li a[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}
.info-product-left .resources ul li a > i, .info-product-left .share ul li a > i {
  font-size: 2rem;
}
.info-product-left .resources ul li a > span, .info-product-left .share ul li a > span {
  display: block;
  line-height: 125%;
  font-size: 1.1rem;
}
.info-product-left .resources ul li a > span em, .info-product-left .share ul li a > span em {
  display: block;
  font-style: normal;
}
.info-product-left #productCarousel, .info-product-left #galleryCarousel, .info-product-left #vue-creation-details #creationCarousel, #vue-creation-details .info-product-left #creationCarousel {
  width: 100%;
}
.info-product-left #productCarousel iframe, .info-product-left #galleryCarousel iframe, .info-product-left #vue-creation-details #creationCarousel iframe, #vue-creation-details .info-product-left #creationCarousel iframe {
  width: 100% !important;
}

.info-product-right {
  float: left;
  width: 55%;
  background: #ffffff;
  padding: 30px 35px 30px 35px;
  position: relative;
}
.info-product-right .info-product-title {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
}
.info-product-right .info-product-title h1 {
  flex: 0 1 auto;
}
.info-product-right .info-product-title .btn-wishlist {
  justify-self: flex-end;
  white-space: nowrap;
  margin-left: 24px;
  flex: 0 1 auto;
}
.info-product-right .product-box .mix-by-weight {
  position: absolute;
  top: 13px;
  left: 120px;
  color: #979797;
  font-size: 1.3rem;
}
.info-product-right h1 {
  text-transform: none;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0px;
  color: inherit;
}
.info-product-right h2,
.info-product-right h3,
.info-product-right h2 > a {
  text-transform: none;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0px;
  line-height: 2.2rem;
}
.info-product-right h3 {
  font-size: 1.4rem;
  margin-top: 20px;
}
.info-product-right h3 span {
  font-weight: normal;
}
.info-product-right h4 {
  font-size: 1.4rem;
  text-align: left;
  margin: 0;
  padding: 15px 20px;
  color: #979797;
}
.info-product-right h4 a {
  display: block;
  float: right;
}
.info-product-right h4 a i {
  font-size: 18px;
  vertical-align: text-bottom;
}
.info-product-right h1 + h3,
.info-product-right h1 + a + h3,
.info-product-right h2 + h3 {
  margin-top: 0;
}
.info-product-right .btn-color-match {
  float: right;
}
.info-product-right .stock, .info-product-right .stock-out, .info-product-right .table-responsive .main-table.table td .stock-out, .table-responsive .main-table.table td .info-product-right .stock-out {
  color: #f69422;
  font-size: 1.6rem;
  margin-bottom: 10px;
  margin-top: 20px;
}
.info-product-right .stock-out {
  color: rgb(218, 79, 73);
}
.info-product-right .stock-notification-input {
  display: inline-block;
  width: 65%;
  margin-top: 5px;
  margin-right: 2px;
}
.info-product-right .stock-notification-submit {
  padding: 7px 20px;
  margin-bottom: 4px;
}
.info-product-right .stock-notification-success {
  padding: 10px;
  margin-bottom: 10px;
}
.info-product-right .stock-notification-error {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.info-product-right table {
  width: 100%;
}
.info-product-right table th {
  background-color: #bdbdbd;
  border: 1px solid #222;
  padding: 7px 15px;
}
.info-product-right table td {
  border: 1px solid #222;
  padding: 7px 15px;
}

.info-product-right-mobile {
  float: left;
  width: 38%;
  background: #fafafa;
  padding: 30px 35px 30px 35px;
  position: relative;
}
.info-product-right-mobile h1 {
  text-transform: none;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0px;
  color: inherit;
}
.info-product-right-mobile h2,
.info-product-right-mobile h3,
.info-product-right-mobile h2 > a {
  text-transform: none;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0px;
  line-height: 2.2rem;
}
.info-product-right-mobile h3 {
  font-size: 1.4rem;
  margin-top: 20px;
}
.info-product-right-mobile h3 span {
  font-weight: normal;
}
.info-product-right-mobile h4 {
  font-size: 1.4rem;
  text-align: left;
  margin: 0;
  padding: 10px 20px;
  color: #979797;
}
.info-product-right-mobile h4 a {
  float: right;
}
.info-product-right-mobile h2 + h3 {
  margin-top: 0;
}
.info-product-right-mobile h1 + h3 {
  margin-top: 0;
}
.info-product-right-mobile .btn-wishlist {
  float: right;
  margin-top: -15px;
}

.add-cart {
  float: left;
  width: 100%;
  position: static;
}
.add-cart input[type=text] {
  background: #ffffff;
  border-radius: 4px;
}
.add-cart input[type=text].field-unit {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,e2e2e2+100 */
  background: #fcfcfc;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fcfcfc 0%, #e2e2e2 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fcfcfc 0%, #e2e2e2 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fcfcfc 0%, #e2e2e2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcfcfc", endColorstr="#e2e2e2", GradientType=0);
  /* IE6-9 */
}
.add-cart .col-sm-12 .col-xs-3 {
  height: 40px;
  position: relative;
}
.add-cart .col-sm-12 .col-xs-3 label {
  position: absolute;
  bottom: 0px;
  margin: 0px;
}
.add-cart .col-sm-12 .col-sm-4 {
  color: rgb(204, 204, 204);
  padding: 0 30px;
  margin: 0px 0 25px 0;
}
.add-cart .col-sm-12 .col-sm-4 strong {
  display: block;
  color: #222222;
}
.add-cart .col-sm-12 .col-sm-4 + .col-sm-4 {
  border-left: 1px solid rgb(204, 204, 204);
}
.add-cart .product-total {
  margin-top: 25px;
}
.add-cart .product-total div > div {
  color: rgb(204, 204, 204);
}
.add-cart .product-total div > div strong {
  display: inline-block;
  color: #222222;
  margin-right: 10px;
  font-size: 1.7rem;
}
.add-cart .productSelect + .select2-container .select2-selection--single {
  height: 36px;
}
.add-cart .productSelect + .select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}

.link-save {
  float: right;
  color: rgb(153, 153, 153);
  font-weight: 600;
  border-bottom: 2px solid rgb(153, 153, 153);
}
.link-save:hover {
  border-bottom: 2px solid #f69422;
}

.main-container h1 {
  font-size: 48px;
}
.main-container h2 {
  font-size: 28px;
}
.main-container h2,
.main-container h3,
.main-container h4,
.main-container h5 {
  text-transform: none;
  color: #3a3a39;
}
.main-container .row-spacing {
  padding: 3px 0;
}
.main-container .jumbotron {
  margin: 0;
  padding: 70px 0;
  background-color: transparent;
}
.main-container .jumbotron .caption-inner p {
  font-size: 22px;
}
.main-container .info {
  margin-top: 20px;
  margin-bottom: 70px;
  padding: 24px;
}
.main-container .info h5 {
  margin: 0;
}
.main-container .banner-cta {
  position: absolute;
  top: 170px;
  width: 100%;
}
.main-container .banner-cta .banner-cta-content {
  width: 55%;
  margin: 0 auto;
  max-width: 1170px;
}
.main-container .banner-cta .banner-cta-content .banner-cta-container {
  width: 55%;
  padding: 25px;
  background-color: rgba(58, 58, 57, 0.8392156863);
  border-radius: 4px;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  max-width: 1170px;
}
.main-container .banner-cta .banner-cta-content .banner-cta-container p {
  font-weight: 200;
  font-size: 22px;
  color: #fff;
}
.main-container .banner-cta .banner-cta-content .banner-cta-container h1 {
  color: #fff;
}
.main-container .img-banner img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.main-container .thumbnail {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  padding: 15px;
  width: 100%;
  display: inline-block;
}
.main-container .thumbnail .icon-img {
  padding: 15px;
}
.main-container .thumbnail .icon-img svg {
  height: 75px;
}
.main-container .thumbnail img {
  padding: 20px;
}
.main-container .thumbnail-height {
  display: flex;
}
.main-container .thumbnail-height .col-md-4 {
  display: flex;
}
.main-container .shipping-block {
  padding: 30px 0;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.main-container .shipping-block .row-height {
  margin: 10px 0 30px 0;
  display: inline-flex;
}
.main-container .shipping-block .row-height .country-icon {
  margin: 0 20px;
}
.main-container .shipping-block .row-height .country-icon svg {
  max-height: 83px;
}
.main-container .shipping-block p {
  margin: 0;
}
.main-container .shipping-block .row a {
  margin: 0 auto;
  width: max-content;
}

.review-form .name-highlight {
  color: #f69422;
}
.review-form h2 {
  text-align: center;
  margin-bottom: 20px;
}
.review-form .review-info-group {
  padding-top: 20px;
}
.review-form .review-info-group .stars a {
  padding-right: 3px;
}
.review-form .row {
  margin: 0 auto 15px;
}
.review-form .fa-star {
  cursor: pointer;
  font-size: 2rem;
}

.collection-list h1,
.creation-detail-top h1 {
  text-transform: none;
  color: #393939;
  margin: 5px 0 10px 0;
}
.collection-list p a,
.creation-detail-top p a {
  padding: 1px 8px;
  margin: 2px 2px 3px 0;
  border-radius: 4px;
  line-height: 20px;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  text-transform: inherit;
}

.stars .star-grey {
  color: rgb(204, 204, 204) !important;
}

.wrap-gallery-details, #vue-creation-details .wrap-creation-details {
  background: transparent;
  box-shadow: none;
  margin-bottom: 10px;
}
.wrap-gallery-details .info-product-right, #vue-creation-details .wrap-creation-details .info-product-right {
  padding: 0;
  margin: 0;
  background: transparent;
  width: 30.5%;
  text-align: left;
  display: grid;
  height: auto;
}
.wrap-gallery-details .info-product-right > a.btn-blue, #vue-creation-details .wrap-creation-details .info-product-right > a.btn-blue {
  width: 100%;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}
.wrap-gallery-details .info-product-right > a.btn-blue span, #vue-creation-details .wrap-creation-details .info-product-right > a.btn-blue span {
  font-size: 16px;
  font-weight: 600;
}
.wrap-gallery-details .info-product-right > a.btn-blue i:first-child, #vue-creation-details .wrap-creation-details .info-product-right > a.btn-blue i:first-child {
  float: left;
  font-size: 30px;
  padding: 15px 10px 0 0;
}
.wrap-gallery-details .info-product-right > a.btn-blue i, #vue-creation-details .wrap-creation-details .info-product-right > a.btn-blue i {
  float: right;
  font-size: 18px;
  margin-top: -10px;
}
.wrap-gallery-details .info-product-right .product-box, #vue-creation-details .wrap-creation-details .info-product-right .product-box {
  padding: 0px 0px 0px 0px !important;
  display: inline-block;
  width: 100%;
  margin-top: 0px;
}
.wrap-gallery-details .info-product-right .product-box .color-details, #vue-creation-details .wrap-creation-details .info-product-right .product-box .color-details {
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.wrap-gallery-details .info-product-right .product-box .applicator-info, #vue-creation-details .wrap-creation-details .info-product-right .product-box .applicator-info {
  padding: 0 20px 20px 20px;
}
.wrap-gallery-details .info-product-right .product-box h2, #vue-creation-details .wrap-creation-details .info-product-right .product-box h2 {
  padding: 5px 0 0;
}
.wrap-gallery-details .info-product-right .product-box h4, #vue-creation-details .wrap-creation-details .info-product-right .product-box h4 {
  margin-top: 15px;
}
.wrap-gallery-details .info-product-right .product-box div > a, #vue-creation-details .wrap-creation-details .info-product-right .product-box div > a {
  border-top: none;
}
.wrap-gallery-details .info-product-right .product-box:hover h4 a, #vue-creation-details .wrap-creation-details .info-product-right .product-box:hover h4 a {
  color: #126cb6 !important;
}
.wrap-gallery-details .info-product-right .product-box table, #vue-creation-details .wrap-creation-details .info-product-right .product-box table {
  margin: 0;
  height: calc(100% - 45px);
}
.wrap-gallery-details .info-product-right .product-box table td, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td {
  vertical-align: middle;
  padding: 4px;
  border: none;
}
.wrap-gallery-details .info-product-right .product-box table td a, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a {
  color: #393939;
}
.wrap-gallery-details .info-product-right .product-box table td a:hover, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a:hover {
  color: #126cb6 !important;
}
.wrap-gallery-details .info-product-right .product-box table td a img, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a img {
  margin: 0;
  max-height: 50px;
  margin-left: 5px;
}
.wrap-gallery-details .info-product-right .product-box table td a h3, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a h3 {
  margin-top: 10px;
}
.wrap-gallery-details .info-product-right .product-box table td a p, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a p {
  margin: 0 0 5px 0;
}
.wrap-gallery-details .info-product-right .product-box table td.seeGallery a, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td.seeGallery a {
  padding: 5px 9px;
  display: block;
  max-width: 110px;
  float: right;
  margin-right: 15px;
  font-size: 13px;
}
.wrap-gallery-details .info-product-right .product-box table td.seeGallery a:hover, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td.seeGallery a:hover {
  color: #ffffff !important;
}
.wrap-gallery-details .info-product-right .shadow-box .photo-in, #vue-creation-details .wrap-creation-details .info-product-right .shadow-box .photo-in {
  margin-left: auto;
  margin-right: auto;
  width: 65px;
  height: 65px;
}
.wrap-gallery-details .info-product-right address, #vue-creation-details .wrap-creation-details .info-product-right address {
  color: rgb(23, 136, 228);
  margin-bottom: 10px;
}
.wrap-gallery-details .info-product-right .applicator-info, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info {
  padding: 20px 20px 25px 20px;
  text-align: center;
}
.wrap-gallery-details .info-product-right .applicator-info div > a img, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info div > a img {
  width: auto;
}
.wrap-gallery-details .info-product-right .applicator-info h4, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info h4 {
  padding: 0;
}
.wrap-gallery-details .info-product-right .applicator-info h2, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info h2 {
  line-height: 1.65rem;
}
.wrap-gallery-details .info-product-right .applicator-info h3, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info h3 {
  margin-top: 15px;
}
.wrap-gallery-details .info-product-right .applicator-info address, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info address {
  margin: 0;
}
.wrap-gallery-details .info-product-right .applicator-info p, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info p {
  margin: 0;
}
.wrap-gallery-details .info-product-right .applicator-info .stars a, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info .stars a {
  color: #3982c4;
  margin: 5px 0;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 {
  overflow: hidden;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12 .col-xs-3, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 .col-xs-3 {
  z-index: 8;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12 > .closePhoneLink, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 > .closePhoneLink {
  position: absolute;
  right: 20px;
  bottom: 3px;
  padding: 8px 10px;
  z-index: 10;
  opacity: 0;
  width: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12 > .closePhoneLink.open, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 > .closePhoneLink.open {
  width: initial;
  opacity: 1;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12 > .phoneLink, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 > .phoneLink {
  position: absolute;
  width: 0px;
  background-color: #ddd;
  z-index: 1;
  height: 100%;
  text-align: center;
  padding-top: 15px;
  opacity: 0;
  left: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.wrap-gallery-details .info-product-right .applicator-info > .row .col-sm-12 > .phoneLink.open, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info > .row .col-sm-12 > .phoneLink.open {
  width: calc(100% - 30px);
  opacity: 1;
  z-index: 9;
}
.wrap-gallery-details .info-product-right .applicator-info a, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a {
  color: #222222;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  height: 100%;
  margin-left: 0;
  cursor: pointer;
}
.wrap-gallery-details .info-product-right .applicator-info a i, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a i {
  font-size: 1.8rem;
  vertical-align: text-bottom;
}
.wrap-gallery-details .info-product-right .applicator-info a span, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a span {
  font-weight: 600;
  display: block;
  font-size: 1rem;
  color: rgb(153, 153, 153);
  line-height: 100%;
  margin-top: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.wrap-gallery-details .info-product-right .applicator-info a:hover span, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a:hover span {
  color: #126cb6 !important;
}
.wrap-gallery-details .info-product-right .applicator-info a.noURL, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a.noURL {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.wrap-gallery-details .info-product-right .applicator-info a.noURL:hover span, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a.noURL:hover span {
  color: rgb(153, 153, 153) !important;
}
.wrap-gallery-details .info-product-right .applicator-info a.noURL:hover, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info a.noURL:hover {
  color: #222222 !important;
}
.wrap-gallery-details .info-product-right .applicator-info .col-sm-4 + .col-sm-4, #vue-creation-details .wrap-creation-details .info-product-right .applicator-info .col-sm-4 + .col-sm-4 {
  border-left: 1px solid rgb(204, 204, 204);
}
.wrap-gallery-details .gallery-media, #vue-creation-details .wrap-creation-details .gallery-media {
  border-radius: 4px 4px 0 0 !important;
}
.wrap-gallery-details .share, #vue-creation-details .wrap-creation-details .share {
  background-color: transparent;
  z-index: 1;
}
.wrap-gallery-details .share ul li, #vue-creation-details .wrap-creation-details .share ul li {
  opacity: 0.6;
  color: #222222;
  background-color: #ffffff;
  border-radius: 4px;
}
.wrap-gallery-details .share ul li#pinBtn a, #vue-creation-details .wrap-creation-details .share ul li#pinBtn a {
  width: 100%;
  height: 100%;
}
.wrap-gallery-details .share ul li#pinBtn i, #vue-creation-details .wrap-creation-details .share ul li#pinBtn i {
  transition: all 0.3s ease;
  color: #222222;
}
.wrap-gallery-details .share ul li#pinBtn:hover i, #vue-creation-details .wrap-creation-details .share ul li#pinBtn:hover i {
  color: #126cb6 !important;
}

.pinned-projects {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
}
.pinned-projects a > img {
  margin: 0;
  border-radius: 4px;
  height: 100%;
  object-fit: cover;
}

.project-tools {
  margin-top: 30px;
}
.project-tools .project-action {
  margin-top: 20px;
}

#galleryCarousel, #vue-creation-details #creationCarousel {
  background: #fff;
  margin-right: 1.5%;
  display: inline-block;
  border-radius: 4px;
}
#galleryCarousel #slider-thumbs, #vue-creation-details #creationCarousel #slider-thumbs {
  width: auto;
  margin: 10px;
}
#galleryCarousel .bookmark-project, #vue-creation-details #creationCarousel .bookmark-project {
  position: absolute;
  z-index: 3;
  right: 20px;
  float: right;
  top: 0;
  cursor: pointer;
  width: 29px;
  height: 55px;
  opacity: 0.4;
  transition: visibility 0.3s, opacity 0.3s;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
#galleryCarousel:hover .bookmark-project, #vue-creation-details #creationCarousel:hover .bookmark-project {
  visibility: visible;
  opacity: 0.6;
  background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
  background-size: 165px auto;
}
#galleryCarousel .bookmark-project.project-highlight, #vue-creation-details #creationCarousel .bookmark-project.project-highlight {
  visibility: visible;
  opacity: 1;
  background: url("../img/arrow-bookmark.svg") -138px 0 no-repeat;
  background-size: 166px auto;
  left: 21px;
}
#galleryCarousel .item, #vue-creation-details #creationCarousel .item {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
#galleryCarousel .item .item-in, #vue-creation-details #creationCarousel .item .item-in {
  position: relative;
  height: 100%;
  width: 100%;
}
#galleryCarousel .item .item-in i + img, #vue-creation-details #creationCarousel .item .item-in i + img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}
#galleryCarousel .item .item-in .fa-search-minus, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus,
#galleryCarousel .item .item-in .fa-search-plus,
#vue-creation-details #creationCarousel .item .item-in .fa-search-plus {
  color: #ffffff;
  font-size: 3rem;
  position: absolute;
  bottom: 25px;
  left: 25px;
  z-index: 999;
  font-weight: normal;
}
#galleryCarousel .item .item-in .fa-search-minus span, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus span,
#galleryCarousel .item .item-in .fa-search-plus span,
#vue-creation-details #creationCarousel .item .item-in .fa-search-plus span {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
  vertical-align: middle;
  padding-left: 5px;
}
#galleryCarousel .item .item-in .fa-search-minus, #vue-creation-details #creationCarousel .item .item-in .fa-search-minus {
  display: none;
}
#galleryCarousel .item .item-in .icon-bookmark, #vue-creation-details #creationCarousel .item .item-in .icon-bookmark {
  position: absolute;
  top: -5px;
  left: 20px;
  z-index: 99;
}
#galleryCarousel .item .item-in .icon-bookmark a, #vue-creation-details #creationCarousel .item .item-in .icon-bookmark a {
  color: #13ae47;
}
#galleryCarousel .item .item-in .icon-bookmark .fa-bookmark, #vue-creation-details #creationCarousel .item .item-in .icon-bookmark .fa-bookmark,
#galleryCarousel .item .item-in .icon-bookmark .fa-star,
#vue-creation-details #creationCarousel .item .item-in .icon-bookmark .fa-star {
  float: left;
}
#galleryCarousel .item .item-in .icon-bookmark .fa-bookmark, #vue-creation-details #creationCarousel .item .item-in .icon-bookmark .fa-bookmark {
  font-size: 5rem;
}
#galleryCarousel .item .item-in .icon-bookmark .fa-star, #vue-creation-details #creationCarousel .item .item-in .icon-bookmark .fa-star {
  color: #ffffff;
  margin: 14px 0 0 -24px;
}
#galleryCarousel .item .item-in.item-in-zoom .fa-search-minus, #vue-creation-details #creationCarousel .item .item-in.item-in-zoom .fa-search-minus {
  display: block;
}
#galleryCarousel .item .item-in.item-in-zoom .fa-search-plus, #vue-creation-details #creationCarousel .item .item-in.item-in-zoom .fa-search-plus {
  display: none;
}

#vue-creation-details .mixology-colors-used-mobile {
  display: none;
}
#vue-creation-details .product-wrap .product-box {
  min-height: 210px;
}
#vue-creation-details .product-wrap .creation-color-step, #vue-creation-details .product-wrap .creation-color-step-mobile {
  position: relative;
  font-size: 1.8rem;
  color: #4b4f54;
  margin: 0 15px 0 0;
  display: block;
  line-height: 125%;
}
#vue-creation-details .product-wrap .creation-color-step strong, #vue-creation-details .product-wrap .creation-color-step-mobile strong {
  font-style: italic;
  font-size: 1.3rem;
}
#vue-creation-details .product-wrap .creation-color-step strong, #vue-creation-details .product-wrap .creation-color-step-mobile strong {
  font-style: italic;
  font-size: 1.3rem;
}
#vue-creation-details .product-wrap .creation-color-step-mobile {
  display: none;
}
#vue-creation-details #creationCarousel {
  margin-left: 0;
  width: 58.5%;
}
#vue-creation-details .creation-share {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  position: relative;
  margin-top: 10px;
}
#vue-creation-details .creation-share ul {
  list-style: none;
}
#vue-creation-details .creation-share ul li {
  text-transform: uppercase;
  color: #222222 !important;
  display: inline-block;
  margin: 10px 8px;
  width: 60px;
  height: 60px;
  background: #ffffff;
}
#vue-creation-details .creation-share ul li#pinBtn {
  position: relative;
  margin-right: 20px;
}
#vue-creation-details .creation-share ul li#pinBtn a {
  opacity: 0;
  width: 100%;
  height: 100%;
}
#vue-creation-details .creation-share ul li#pinBtn i {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-size: 2.5rem;
}
#vue-creation-details .creation-share ul li:first-child {
  margin-left: 0px;
}
#vue-creation-details .creation-share ul li a {
  display: block;
  text-align: center;
  padding: 17px 10px 15px 10px;
  font-weight: 600;
  color: #393939;
}
#vue-creation-details .creation-share ul li a i {
  font-size: 2.5rem;
}
#vue-creation-details .wrap-creation-details {
  min-height: 540px;
  height: 100%;
}
#vue-creation-details .wrap-creation-details .info-product-right {
  width: 40%;
}
#vue-creation-details .wrap-creation-details .info-product-right .creation-custom-panel {
  width: 100%;
}
#vue-creation-details .wrap-creation-details .info-product-right .side-box {
  height: auto;
  margin-bottom: 0;
}
#vue-creation-details .wrap-creation-details .info-product-right .side-box address {
  margin: 0 0 10px 0;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box {
  height: auto;
  min-height: 120px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-all-colors {
  width: 100%;
  margin: 0 0 15px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container {
  padding-bottom: 15px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2,
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3,
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4 {
  padding-left: 10px;
  padding-right: 10px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2:nth-child(2),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(2),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4:nth-child(2) {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2:nth-child(4), #vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2:nth-child(5), #vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2:nth-child(6),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(4),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(5),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(6),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4:nth-child(4),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4:nth-child(5),
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4:nth-child(6) {
  border-left: 1px solid #dedede;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-2:not(:first-child)::before,
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:not(:first-child)::before,
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-4:not(:first-child)::before {
  content: "+";
  font-size: 2rem;
  position: absolute;
  left: -10px;
  bottom: calc(50% - 15px);
  border: 1px solid #dedede;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 19px;
  background-color: #fff;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-step-container {
  text-align: center;
  margin-top: 10px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-step-container .creation-color-step, #vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-step-container .product-wrap .creation-color-step-mobile, #vue-creation-details .product-wrap .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-step-container .creation-color-step-mobile {
  font-size: 1.3rem;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-step-container .creation-color-coat {
  font-size: 1.1rem;
  font-weight: bold;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container {
  padding-bottom: 15px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(2) {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .col-xs-3:nth-child(4) {
  border-left: 1px solid #dedede;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-mix-step-container {
  text-align: center;
  margin-top: 10px;
}
#vue-creation-details .wrap-creation-details .info-product-right .product-box .creation-color-container .creation-color-mix-step-container .step-mix {
  position: absolute;
  left: -2.1rem;
  bottom: calc(50% - 10px);
  border-radius: 50%;
  background: #fff;
  padding: 0 5px;
}
#vue-creation-details .creation-panel-description {
  display: block;
  margin-bottom: 3%;
}
#vue-creation-details .creation-custom-panel {
  display: inline-block;
}

.info-product-right-mobile {
  display: none;
  float: left;
  padding: 0;
  background: transparent;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.info-product-right-mobile .shadow-box .photo-in {
  margin-left: auto;
  margin-right: auto;
}
.info-product-right-mobile address {
  color: rgb(23, 136, 228);
  margin-bottom: 10px;
}
.info-product-right-mobile .shadow-box {
  padding: 5px 20px 25px 20px;
  height: 85%;
}
.info-product-right-mobile .shadow-box .stars a {
  color: #f99839;
  margin: 5px 0;
}
.info-product-right-mobile .shadow-box a {
  color: #222222;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
  height: 100%;
}
.info-product-right-mobile .shadow-box a i {
  font-size: 1.8rem;
}
.info-product-right-mobile .shadow-box a span {
  font-weight: 600;
  display: block;
  font-size: 1rem;
  color: rgb(153, 153, 153);
  line-height: 100%;
  margin-top: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-product-right-mobile .shadow-box a:hover span {
  color: #126cb6 !important;
}
.info-product-right-mobile .shadow-box a.noURL {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.info-product-right-mobile .shadow-box a.noURL:hover span {
  color: rgb(153, 153, 153) !important;
}
.info-product-right-mobile .shadow-box a.noURL:hover {
  color: #222222 !important;
}
.info-product-right-mobile .shadow-box .col-sm-4 + .col-sm-4 {
  border-left: 1px solid rgb(204, 204, 204);
}
.info-product-right-mobile .side-box {
  padding: 20px 20px 25px 20px;
  height: calc(100% - 262px);
  text-align: center;
}
.info-product-right-mobile .side-box h2 {
  line-height: 1.65rem;
}
.info-product-right-mobile .side-box h3 {
  margin-top: 15px;
}
.info-product-right-mobile .side-box address {
  margin: 0;
}
.info-product-right-mobile .side-box p {
  margin: 0;
}
.info-product-right-mobile .side-box .stars a {
  color: #f99839;
  margin: 5px 0;
}
.info-product-right-mobile .side-box > .row .col-sm-12 {
  overflow: hidden;
}
.info-product-right-mobile .side-box > .row .col-sm-12 .col-xs-3 {
  z-index: 8;
}
.info-product-right-mobile .side-box > .row .col-sm-12 > .closePhoneLink {
  position: absolute;
  right: 20px;
  bottom: 3px;
  padding: 8px 10px;
  z-index: 10;
  opacity: 0;
  width: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-product-right-mobile .side-box > .row .col-sm-12 > .closePhoneLink.open {
  width: initial;
  opacity: 1;
}
.info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink {
  position: absolute;
  width: 0;
  background-color: #ddd;
  z-index: 1;
  height: 100%;
  text-align: center;
  padding-top: 15px;
  opacity: 0;
  left: 15px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink.open {
  width: calc(100% - 30px);
  opacity: 1;
  z-index: 9;
}

.heading-bordered.mobile-header {
  display: none;
}

.wrap-gallery-details + #slider-thumbs + .heading-bordered + .product-wrap,
.wrap-creation-details + #slider-thumbs + .heading-bordered + .product-wrap {
  margin-top: 0;
}

.share {
  background: #ffffff;
  box-shadow: none;
  width: 75px;
  height: 100%;
  margin: 0;
  position: absolute;
}
.share ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.share ul li {
  text-transform: uppercase;
  color: #222222 !important;
}
.share ul li a {
  display: block;
  text-align: center;
  padding: 14px 12px;
  font-weight: 600;
  color: #393939;
}
.share ul li span {
  font-size: 1.6rem;
}
.share ul li:hover a {
  background: transparent;
  color: #126cb6 !important;
}
.share ul li:first-child {
  letter-spacing: 1px;
  margin: 10px 8px;
  font-size: 2rem;
  font-family: "league_gothicregular", sans-serif;
  text-align: center;
}
.share ul li + li {
  border-top: 0;
  margin: 10px 8px;
  float: left;
  width: calc(100% - 16px);
  box-sizing: border-box;
  position: relative;
}
.share.articles {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  padding: 10px 50px !important;
  border-radius: 6px;
  border: 1px solid #ddd;
}
.share.articles ul li {
  display: inline-block;
  width: 75px;
}
.share.articles ul li a {
  padding: 17px 10px 15px 10px;
}
.share.articles ul li:last-child a {
  padding: 17px 10px 15px 10px;
}
.share.articles ul li:first-child {
  margin-top: 0px;
  padding-left: 0;
  text-align: left;
}
.share.articles ul li + li {
  width: 75px;
  float: none;
}

.content-page {
  margin-bottom: 3%;
  border-radius: 4px;
}
.content-page .article-body {
  padding: 35px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
.content-page .article-body.ql-editor ol {
  float: left;
}
.content-page .article-body.ql-editor ol :before {
  float: left;
}
.content-page .article-body.ql-editor ul {
  float: left;
}
.content-page .article-body.ql-editor ul :before {
  float: left;
}
.content-page .article-body p {
  margin: 0px;
}
.content-page .article-body img {
  max-height: 300px;
  max-width: -moz-available;
  max-width: -webkit-stretch;
  border-radius: 4px;
}
.content-page .privacy-policy,
.content-page .terms-and-conditions {
  font-size: 16px;
  font-weight: 300;
  color: #494D52;
}
.content-page .privacy-policy p,
.content-page .terms-and-conditions p {
  float: none;
}
.content-page .privacy-policy a,
.content-page .privacy-policy a:visited,
.content-page .privacy-policy a:focus,
.content-page .terms-and-conditions a,
.content-page .terms-and-conditions a:visited,
.content-page .terms-and-conditions a:focus {
  color: #3982c4 !important;
}
.content-page .privacy-policy a:hover,
.content-page .terms-and-conditions a:hover {
  color: rgba(249, 152, 57, 0.7) !important;
}
.content-page .privacy-policy h2,
.content-page .terms-and-conditions h2 {
  float: none;
}
.content-page .privacy-policy h4,
.content-page .terms-and-conditions h4 {
  margin-top: 25px;
  font-size: 1.5rem;
}
.content-page .privacy-policy ol,
.content-page .terms-and-conditions ol {
  margin: 10px 0 20px 25px;
}
.content-page .privacy-policy ol ol,
.content-page .terms-and-conditions ol ol {
  margin-left: 35px;
  list-style-type: lower-alpha;
}
.content-page .privacy-policy ol:not(.policies-tac-toc) li:not(:last-child),
.content-page .terms-and-conditions ol:not(.policies-tac-toc) li:not(:last-child) {
  margin-bottom: 10px;
}
.content-page .privacy-policy ol + h2,
.content-page .terms-and-conditions ol + h2 {
  padding-top: 5px;
}
.content-page .privacy-policy .policies-table thead,
.content-page .terms-and-conditions .policies-table thead {
  background: #ccc;
}
.content-page .privacy-policy .policies-table thead td,
.content-page .terms-and-conditions .policies-table thead td {
  padding: 10px;
  border: 1px solid #ababab;
  vertical-align: top;
  font-weight: 600;
}
.content-page .privacy-policy .policies-table tbody td,
.content-page .terms-and-conditions .policies-table tbody td {
  padding: 10px;
  border: 1px solid #ababab;
  vertical-align: top;
}
.content-page .privacy-policy .policies-table tbody td ul,
.content-page .terms-and-conditions .policies-table tbody td ul {
  padding-left: 20px;
}
.content-page .privacy-policy .policies-table tbody td ul.description-list,
.content-page .terms-and-conditions .policies-table tbody td ul.description-list {
  padding-left: 30px;
}

footer {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  float: left;
  width: 100%;
  font-weight: 600;
  text-align: center;
  background: #e6e8e8;
  overflow: hidden;
  z-index: 10;
}
footer .container-fluid {
  max-width: 100%;
  padding: 0px;
}
footer .sm-links {
  background-color: #393939;
  color: #ffffff;
  padding: 10px;
}
footer .sm-links a {
  color: #ffffff;
}
footer .sm-links a:hover {
  color: #f69422 !important;
}
footer .sm-links div > a {
  display: inline-block;
  font-size: 3rem;
  padding: 0 20px;
}
footer .sm-links span {
  line-height: 43px;
}
footer .footlinks {
  padding: 10px 25px;
}
footer .footlinks h3 {
  text-align: left;
}
footer .footlinks ul {
  list-style: none;
}
footer .footlinks ul li {
  text-align: left;
}
footer .footlinks ul li a {
  color: #393939;
}
footer .footlinks ul li a:hover {
  color: #f69422 !important;
}
footer .footlinks .col-lg-3,
footer .footlinks span {
  text-align: left;
}
footer .footlinks .col-lg-3.mobile-only a:not(.tablet-only),
footer .footlinks span.mobile-only a:not(.tablet-only) {
  text-align: center;
  display: inline-block;
  font-size: 1rem;
}
footer .footlinks .col-lg-3.mobile-only img,
footer .footlinks span.mobile-only img {
  width: 50px;
}
footer .footlinks .form-group {
  margin: 5px 0;
}
footer .footlinks .form-group input {
  max-width: 400px;
}
footer .footlinks .form-group button {
  width: 100%;
  max-width: 400px;
}
footer .copyright {
  padding: 0;
}
footer .copyright .col-lg-3 {
  text-align: left;
}
footer .copyright .col-lg-3 a {
  text-align: center;
  display: inline-block;
  font-size: 0.9rem;
}
footer .copyright .col-lg-3 a img {
  width: 35px;
}
footer .copyright .col-lg-6 {
  text-align: center;
}
footer .copyright .col-lg-6 div {
  font-weight: 600;
  color: #888;
}
footer .copyright div.text-center {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
footer a:hover, footer a:active, footer a:focus {
  color: #f69422;
  text-decoration: none;
}
footer .logo-nic > img {
  max-width: 120px;
}
footer .asterisk {
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
}
footer a.globe:hover > img {
  filter: invert(76%) sepia(27%) saturate(7007%) hue-rotate(346deg) brightness(103%) contrast(93%) !important;
  transition: 0.2s ease;
}

#vue-coatings footer .container-fluid .col-xs-12 {
  background: #e6e8e8;
  z-index: 99;
}
#vue-coatings footer .container-fluid .col-xs-12.sm-links {
  background: #393939;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (max-width: 767px) {
  footer .container-fluid {
    position: absolute;
  }
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}
@media (max-width: 1350px) {
  footer .sm-links a {
    padding: 0 10px;
  }
  footer .sm-links .form-group input {
    font-size: 1.3rem;
    width: 49%;
  }
  footer .sm-links .form-group button {
    padding: 8px;
    font-size: 1.3rem;
    width: 49%;
  }
  footer .copyright .col-lg-3 a img {
    width: 40px;
  }
  footer ul li {
    margin-right: 12px;
    font-size: 1.3rem;
  }
  .banner {
    height: auto;
  }
  /* -- Video -- */
  .wrap-video .col-sm-9 {
    padding-right: 15px;
  }
  #videoPlayerModal iframe {
    width: 95%;
  }
  .shop-container {
    padding: 0 20px;
  }
  .search-main {
    margin-bottom: 20px;
  }
  #filterOptions ul li li {
    padding: 8px 0px 8px 10px;
  }
  .shop-filter-bs-offset {
    width: calc(83% - 130px);
  }
  .main-container .banner-cta .banner-cta-content {
    width: 100%;
  }
  .main-container .banner-cta .banner-cta-content .banner-cta-container {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  footer ul li a {
    padding-top: 0;
  }
  .product-box {
    width: 176px;
  }
  .thumbnail-box .info-thumbnail .col-sm-4 a, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4 a,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 a, .info-profile .col-sm-3 a, .sidebar .shadow-box a {
    padding: 0;
  }
  .banner {
    height: auto;
  }
  .product-in h4 {
    font-size: 1.5rem;
  }
  .info-profile h2 a {
    float: none;
    display: block;
    margin: 5px 0 0 0;
  }
  .heading-bordered + .row .flex-wrap .product-box {
    width: 183px;
  }
  .strip-black h2 {
    font-size: 1.5rem;
  }
  .strip-black h3 {
    font-size: 1.3rem;
  }
  .strip-black ul li {
    padding: 0 28px;
  }
  .add-cart .col-sm-12 .col-md-4 {
    padding: 0 15px;
  }
  .add-cart .col-sm-12 .col-sm-4 {
    padding: 0 15px;
  }
  .slider.slider-horizontal {
    width: calc(100% - 130px);
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
    width: 293px;
    height: 293px;
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box h3, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box h3, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box h3,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box h3 {
    font-size: 1.8rem;
    margin: 0 0 5px 0;
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box p, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box p, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box p,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box p {
    font-size: 1.2rem;
  }
  .banner .item .carousel-caption .caption-inner {
    width: 52%;
  }
  .banner .item:after {
    width: 75%;
  }
  .cart-sidebar .btn-blue {
    font-size: 1.3rem;
    padding: 11px 10px 10px 10px;
  }
  .wrap-cart .cart-content > .col-sm-6 {
    padding-right: 30px;
  }
  .wrap-cart .cart-content > .col-sm-6 + .col-sm-6 {
    padding-left: 30px;
  }
  .token-wrap .token-applicator:nth-of-type(3n+1) {
    margin-left: 0;
  }
  .token-wrap .token-applicator:nth-of-type(1) {
    margin-left: 0;
  }
  /* -- Video -- */
  .wrap-video .col-sm-9 {
    width: 65%;
  }
  .wrap-video .col-sm-3 {
    width: 35%;
  }
  .latest-purchased .product-box + .product-box {
    max-width: 225px;
  }
  .coating-combo .container {
    width: 100%;
  }
  .info-product-left .resources ul li, .info-product-left .share ul li {
    width: 32%;
  }
  .wrap-gallery-details .info-product-right .product-box table td a h3, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a h3,
  .wrap-creation-details .info-product-right .product-box table td a h3 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .wrap-gallery-details .info-product-right .product-box table td a p, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td a p,
  .wrap-creation-details .info-product-right .product-box table td a p {
    margin: 0;
    font-size: 1.1rem;
  }
  .wrap-gallery-details .info-product-right .product-box table td.seeGallery a, #vue-creation-details .wrap-creation-details .info-product-right .product-box table td.seeGallery a,
  .wrap-creation-details .info-product-right .product-box table td.seeGallery a {
    margin-right: 0px;
    max-width: inherit;
  }
  .distributor-content h2 {
    font-size: 28px;
  }
  .distributor-content .col-md-6 + .col-md-6 h2 {
    font-size: 28px;
  }
  .distributor-content .distributor-box h3 {
    font-size: 28px;
  }
  #productCarousel .item iframe, #galleryCarousel .item iframe, #vue-creation-details #creationCarousel .item iframe {
    width: 100%;
  }
  .wrap-video .videoList .col-xs-12 {
    min-height: 305px;
  }
  .wrap-video .videoList .col-xs-12 .vidTitle, .wrap-video .documentList .col-xs-12 .vidTitle {
    min-height: 20px;
  }
  .shop-filter-box li span {
    left: 10px;
  }
  .popular-searches .search-links:first-child {
    margin: 0 !important;
  }
  .popular-searches .search-links {
    font-size: 21px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features {
    max-height: 352px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features ul {
    height: 250px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features ul li {
    font-size: 1.3rem;
  }
  .lrg-series-img {
    display: none;
  }
  .med-series-img {
    display: block !important;
  }
  #vue-cerakote-vs #informationModal .modal-dialog,
  #vue-testing #informationModal .modal-dialog {
    width: 100% !important;
  }
}
@media (max-width: 1070px) {
  .full-screen {
    display: none !important;
  }
  .small-screen {
    display: block !important;
  }
  .mobile-shop-categories {
    margin: 11px 0 3px;
  }
  .mobile-shop-categories .form-control {
    background-color: white;
    font-size: 16px;
    height: 44px;
  }
  .mobile-shop-categories h5 {
    margin: 11px 2px;
  }
}
@media (max-width: 1030px) {
  .sidebar {
    margin-top: 20px;
    border-right: none;
    width: 100%;
  }
  .has-sub-menu .show-cart-popup {
    top: 66px;
  }
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 550px;
  }
  body > .vue-app > header {
    display: none;
  }
  .mobile-head-wrapper, .mobile-nav {
    display: block;
  }
  .hasMessages .show-cart-popup {
    top: 108px;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
  .sm-links .col-sm-6 {
    display: none;
  }
  .footlinks .form-group {
    margin-bottom: 0;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
  .toggle-shop-filters {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
  }
  .toggle-shop-filters .filterBtn {
    display: block;
    background: #f69422;
    padding: 11px 20px 10px 20px;
    font-weight: 600;
    font-size: 14px;
    color: white;
  }
  .toggle-shop-filters div.collapsed i.fa-plus {
    display: inline;
  }
  .toggle-shop-filters i.fa-plus {
    display: none;
  }
  .toggle-shop-filters i {
    display: inline;
  }
  .toggle-shop-filters div.collapsed i.fa-minus {
    display: none;
  }
  #filterVisibility.collapse.in {
    margin-top: 20px;
    display: block;
  }
  .shop-filter-bs-offset {
    width: 100%;
  }
  .sm-links .col-sm-6 {
    display: none;
  }
  .footlinks .form-group {
    margin-bottom: 0;
  }
  .main-container .banner-cta .banner-cta-content {
    padding: 0px;
  }
  .main-container .banner-cta .banner-cta-content .banner-cta-container {
    padding: 20px;
  }
}
@media (max-width: 1025px) {
  .applicator-map-overlay #applicator-map-sidebar-filters, .applicator-map-overlay #applicator-map-sidebar-results {
    filter: none !important;
  }
  .applicator-map-overlay #applicator-map-sidebar-filters a, .applicator-map-overlay #applicator-map-sidebar-results a {
    pointer-events: all;
  }
  .main-container h3 {
    font-size: initial;
  }
}
@media (min-width: 993px) and (max-width: 1199px) {
  .cart-checkout .accordion-head div span .best-rate-span {
    font-size: 1.4rem;
    letter-spacing: 0;
  }
}
@media (max-width: 992px) {
  .info-product-right .info-product-title {
    justify-content: flex-start;
    flex-direction: column;
  }
  .info-product-right .info-product-title .btn-wishlist {
    margin-left: 0;
  }
  footer .sm-links a {
    font-size: 2rem;
    padding: 0 10px 5px 10px;
  }
  footer .sm-links .form-group input {
    width: auto;
  }
  footer .sm-links .form-group button {
    padding: 8px 11px;
    width: auto;
  }
  .modal-header .close {
    text-shadow: none;
    opacity: 1;
    color: #FFFFFF;
    font-size: 15px;
    padding: 10px 0 0 10px;
  }
  footer ul li {
    margin-right: 11px;
  }
  .product-box {
    width: 168px;
  }
  .content-right .product-wrap .product-box {
    width: calc(33.333% - 20px);
  }
  .thumbnail-box .wrap-img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img {
    height: 200px;
  }
  .thumbnail-box .info-thumbnail, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
    padding: 10px 10px 55px 10px;
  }
  .thumbnail-box .info-thumbnail .col-sm-4, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 {
    padding: 0;
  }
  .strip-black {
    padding: 20px 15px;
  }
  .strip-black h3 {
    font-weight: normal;
    font-size: 1.2rem;
  }
  .strip-black h2 {
    font-size: 1.4rem;
  }
  .strip-black h2 i {
    margin-right: 5px;
  }
  .search-box {
    width: 75%;
    margin-top: 25px;
  }
  .filter-box {
    overflow: hidden;
    margin-top: 5px;
  }
  .filter-box li:nth-child(1), .filter-box li:nth-child(2), .filter-box li:nth-child(3) {
    width: 33.33%;
  }
  .filter-box li:nth-child(4), .filter-box li:nth-child(5) {
    width: 50%;
    border-top: 1px solid rgb(204, 204, 204);
  }
  .filter-box li:nth-child(4) {
    border-left: 0;
  }
  .filter-box li:first-child > a, .filter-box li:last-child > a {
    border-radius: 0;
  }
  .mid-inner .breadcrumb {
    padding-bottom: 25px;
  }
  .strip-knowledge-base .col-sm-4 {
    padding: 0 20px;
  }
  .profile-top {
    margin-top: 5px;
  }
  .profile-top .block-map {
    clear: both;
  }
  .info-profile {
    padding-right: 15px;
  }
  .info-profile address {
    margin-bottom: 20px;
  }
  .profile-top div.col-xs-12 {
    float: left !important;
    width: calc(100% - 30px);
    left: 15px !important;
  }
  .profile-top div.col-md-4 {
    padding-top: 20px;
  }
  .modal .modal-dialog {
    width: 600px;
  }
  .my-account .sidebar {
    padding-right: 15px;
  }
  .my-account .sidebar + .content-right {
    padding-left: 15px;
  }
  .my-account .form-group label {
    padding-right: 0;
  }
  .my-account.addressSection h5 input {
    width: 100%;
    margin-top: 18px;
    margin-right: 0;
  }
  .heading-bordered + .row .flex-wrap .product-box {
    width: 168px;
  }
  .shop-filter-box li {
    width: 33.33% !important;
  }
  .shop-filter-box li:nth-child(6) {
    border-top: 1px solid rgb(204, 204, 204);
  }
  .strip-black ul li {
    padding: 0 9px;
  }
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 {
    padding-left: 0;
  }
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 .btn {
    padding: 11px 15px 10px 15px;
    font-size: 1.2rem;
  }
  .wrap-details .info-product-left {
    width: 100%;
  }
  .info-product-left .resources ul li a, .info-product-left .share ul li a {
    min-height: 75px;
  }
  #productCarousel .item img:first-child, #galleryCarousel .item img:first-child, #vue-creation-details #creationCarousel .item img:first-child {
    min-height: 250px;
  }
  .wrap-details .info-product-right {
    width: 100%;
    padding: 35px 15px 10px 20px;
  }
  #productCarousel, #galleryCarousel, #vue-creation-details #creationCarousel {
    width: calc(100% - 100px);
  }
  .wrap-gallery-details, #vue-creation-details .wrap-creation-details {
    height: auto;
  }
  #galleryCarousel, #vue-creation-details #creationCarousel {
    width: calc(100% - 75px);
    margin-right: 0;
    height: 659px;
  }
  #creationCarousel {
    margin-right: 0;
    height: 659px;
    width: 100%;
  }
  .knowledge-base-tabs .nav-tabs li {
    width: 100%;
    margin-bottom: 0px;
  }
  .product-card .product-card-btn {
    display: contents;
    pointer-events: all;
  }
  .product-card .product-card-btn a {
    display: none;
  }
  .product-card .product-card-btn button {
    margin-top: 0;
    background-color: transparent;
    color: inherit !important;
    border: none;
  }
  .wrap-gallery-details .info-product-right, #vue-creation-details .wrap-creation-details .info-product-right,
  .wrap-creation-details .info-product-right {
    width: 100%;
    padding: 35px 0 10px 0;
  }
  .mixology-colors-used-mobile {
    display: block !important;
  }
  .gallery-common .flex-wrap {
    text-align: left;
  }
  .gallery-common .flex-wrap > .col-sm-4 {
    width: 375px;
    float: left;
    padding: 0 15px;
    margin-bottom: 20px;
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box {
    width: 345px;
    height: 345px;
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box h3, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box h3, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box h3,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box h3 {
    font-size: 2rem;
    margin: 5px 0 8px 0;
  }
  .gallery-common .flex-wrap > .col-sm-4 .thumbnail-box p, .gallery-common .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box p, .creation-full .product-wrap .gallery-common .flex-wrap > .col-sm-4 .creation-thumbnail-box p,
  .gallery-common .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box p {
    font-size: 1.4rem;
  }
  .banner .item .carousel-caption {
    left: 0;
    right: 0;
    padding: 0 30px;
    width: 100%;
  }
  .banner .item .carousel-caption .caption-inner {
    width: 65%;
  }
  .banner .item .carousel-caption .caption-inner .promo-item-title {
    font-size: 1.5rem;
  }
  .banner .item:after {
    width: 85%;
  }
  .distributor-content h1 {
    margin-right: 0;
  }
  .distributor-content .col-md-6 + .col-md-6 {
    border-left: 0;
  }
  .distributor-content .col-md-6 + .col-md-6 h1 {
    margin-left: 0;
  }
  .main-table.table {
    min-width: 460px;
  }
  .wrap-cart .col-md-9, .wrap-cart .col-md-3 {
    padding: 0 5px;
  }
  .wrap-cart .cart-content {
    padding: 20px 15px 15px 15px;
  }
  .wrap-cart .cart-content .table-responsive {
    overflow-x: hidden;
  }
  .wrap-cart .cart-content .table-responsive .mobileHide {
    display: none;
  }
  .wrap-cart .cart-content > .col-sm-6 {
    padding: 0 15px 0 0;
  }
  .wrap-cart .cart-content > .col-sm-6 + .col-sm-6 {
    padding: 0 0 0 15px;
  }
  .wrap-cart .cart-content .remove-cart-item span {
    padding-left: 0;
  }
  .token-wrap .token-applicator {
    width: 230px;
  }
  .wrap-creation-details .info-product-right {
    width: 100%;
  }
  .info-product-right-mobile {
    display: block;
  }
  .heading-bordered.mobile-head-wrapper {
    display: block;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .bookmark-project,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .bookmark-project,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .bookmark-project,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box .bookmark-project {
    visibility: visible;
    opacity: 0.6;
    background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
    background-size: 165px auto;
  }
  #galleryCarousel .item .item-in .bookmark-project, #vue-creation-details #creationCarousel .item .item-in .bookmark-project,
  #creationCarousel .item .item-in .bookmark-project {
    visibility: visible;
    opacity: 0.6;
    background: url("../img/arrow-bookmark.svg") -108px 0 no-repeat;
    background-size: 165px auto;
  }
  .radius-wrap {
    display: block;
  }
  .coating-combo .container {
    width: 100%;
  }
  .coating-combo .coating-combo-in > div.col-md-2 {
    margin-top: 25px;
  }
  .coating-combo .coating-combo-in > div span {
    cursor: inherit;
  }
  #qualifiedApplication .form-group {
    min-height: 105px;
  }
  .cart-checkout .accordion-head.cc-option div {
    width: initial;
  }
  .distributor-content h2 {
    font-size: 33px;
    margin: 5px 5px 0 5px;
  }
  .distributor-content .col-md-6 + .col-md-6 h2 {
    font-size: 33px;
    margin: 5px 5px 0 5px;
  }
  .distributor-content .distributor-box h3 {
    font-size: 33px;
  }
  textarea.form-control.commentSection {
    height: 110px;
  }
  .shop-filter-box li span {
    left: 50px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features {
    min-height: 413px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features ul {
    height: 304px;
  }
  .med-series-img {
    display: none !important;
  }
  .sml-series-img {
    display: block !important;
  }
  .product-card-details {
    display: grid;
  }
  .wrap-knowledge-base .knowledge-base .nav-tabs li {
    border: 1px solid #ddd;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .tab-content {
    padding: 20px;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .tab-content .tab-pane .article-list {
    width: auto;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .panel-group .panel .panel-collapse, .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .panel-group .panel .collapse {
    padding: 0;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .panel-group .panel .panel-heading {
    padding: inherit;
    margin-top: 25px;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .panel-group .panel .qa-question .qa-count {
    float: none;
  }
  .wrap-knowledge-base .knowledge-base .knowledge-base-tabs .panel-group .panel .qa-question .qa-date {
    display: none;
  }
  .main-container .thumbnail-height {
    display: block;
  }
  .main-container .banner-cta {
    top: 150px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 760px;
  }
}
@media (max-width: 767px) {
  .news-box img {
    margin: -30px -30px 30px -30px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    max-width: calc(100% + 60px);
    width: calc(100% + 60px);
    padding: 0;
  }
  #vue-shop .series-card .series-card-header {
    margin-top: -30px;
  }
  #vue-shop .series-card .series-card-header,
  #vue-shop .series-card .series-card-content {
    margin-right: -15px;
    margin-left: -15px;
  }
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
    padding-bottom: 55px !important;
  }
  .creation-full .product-wrap .flex-wrap > .col-sm-3 {
    width: 90%;
  }
  .mid-inner.applicator .map-how-to {
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
  }
  .mid-inner.applicator .map-how-to .btn-orange {
    margin-left: 15px;
  }
  h2 > a {
    display: block;
    margin: 5px 0 0 0;
  }
  .show-cart-popup {
    top: 65px;
    overflow: scroll;
    display: block;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 160px;
  }
  .news-box {
    padding-right: 15px;
  }
  .news-box .news-wrap {
    margin: 0;
  }
  .news-box .news-image {
    margin-bottom: 30px;
  }
  .news-box .news-content {
    padding-left: 30px !important;
  }
  .product-box {
    width: 31.2%;
    margin-right: 2%;
  }
  .creation-product-box {
    width: 168px;
    margin-right: 15px;
  }
  .thumbnail-box .wrap-img, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .wrap-img,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .wrap-img {
    height: auto;
  }
  .creation-full .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
    padding: 10px 8px 10px 8px !important;
  }
  .flex-wrap > .col-sm-4 {
    width: 100%;
    margin-bottom: 25px;
  }
  .thumbnail-box .info-thumbnail, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
    padding: 10px 8px 10px 8px;
  }
  .thumbnail-box .info-thumbnail .col-sm-4, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .col-sm-4,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .col-sm-4 {
    width: 33.33333333%;
    float: left;
    padding: 0 10px;
  }
  .thumbnail-box .info-thumbnail .row, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row {
    position: static;
    width: 100%;
    margin: 15px 0 0 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .search-box {
    width: 100%;
  }
  .side-box ul li input[type=checkbox] {
    height: 20px;
    width: 20px;
  }
  .side-box ul li li {
    padding: 10px 10px 10px 10px;
    border-top: 1px solid rgba(151, 151, 151, 0.22) !important;
  }
  .side-box ul li #mGlossLevel {
    margin-top: 10px;
  }
  .modal h2 {
    font-size: 2rem;
  }
  .modal a.close.close-link {
    color: #1788e4;
    opacity: 0.6;
    font-size: 16px;
    line-height: 28px;
  }
  .modal a.close.close-link:hover {
    opacity: 1;
  }
  .banner .item .carousel-caption {
    padding: 0 15px;
  }
  .banner .item .carousel-caption.center {
    width: 100%;
    margin-left: -15px;
  }
  .banner .item .carousel-caption .caption-inner.center {
    padding: 0px 25px 25px;
  }
  .banner .item .carousel-caption .caption-inner .promo-item-title {
    font-size: 1.3rem;
  }
  .banner .item.multiSale img {
    object-position: 0 0;
    transition: all 5s ease;
  }
  .banner .item.multiSale.active img {
    object-position: 100% 0;
  }
  .carousel-caption .caption-inner .captionBtn {
    display: none;
  }
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {
    display: block;
    margin-top: 10px;
  }
  .strip-black {
    padding: 0;
  }
  .strip-black h3 {
    margin: 1px 0 2px 0;
  }
  .strip-black ul li:first-child {
    display: none;
  }
  .strip-black ul li {
    text-align: center !important;
    width: 50%;
    padding: 15px 13px !important;
    box-sizing: border-box;
    float: left;
  }
  .strip-black ul li:nth-child(2), .strip-black li:nth-child(3) {
    border-bottom: 1px solid #666;
  }
  .strip-black ul li:nth-child(2), .strip-black li:nth-child(4) {
    border-right: 1px solid #666;
  }
  .filter-options a {
    margin: 10px;
  }
  .filter-options a span {
    font-size: 1.3rem;
  }
  .filter-box li a {
    padding: 8px 5px;
  }
  .filtered-right > .col-sm-6 {
    width: 33.33333333%;
    padding: 0 8px;
  }
  .product-in h4 {
    font-size: 1.4rem;
  }
  .filtered-right {
    margin-left: -7px;
    margin-right: -7px;
  }
  .product-in {
    margin-top: 16px;
  }
  .strip-knowledge-base .row {
    display: block;
  }
  .strip-knowledge-base {
    padding: 35px 0;
  }
  .strip-knowledge-base .col-sm-4 + .col-sm-4 {
    border-left: 0;
    border-top: 1px solid rgb(204, 204, 204);
    margin-top: 25px;
    padding-top: 25px;
  }
  .strip-knowledge-base .col-sm-4 + .col-sm-4 h2 {
    font-size: 2.2rem;
    margin: 10px 0;
  }
  .wrap-hero .hero-large {
    height: 350px;
  }
  .wrap-hero .hero-large .container input[type=text] {
    height: 50px;
  }
  .wrap-hero .hero-large .container h1 {
    font-size: 3rem;
  }
  .knowledge-base-bottom {
    padding: 45px 0 35px 0;
  }
  .knowledge-base-bottom h1 {
    font-size: 3rem;
  }
  .knowledge-base-bottom .col-sm-4 {
    padding: 0 15px;
  }
  .knowledge-base-bottom .col-sm-12.text-center {
    padding: 0;
  }
  .location-wrap {
    width: 100%;
    margin-top: 15px;
  }
  .location-wrap .col-sm-12 h2 {
    margin-bottom: 25px;
  }
  .location-wrap .col-sm-3 + .col-sm-3, .location-wrap .col-sm-3 {
    border-left: 0;
  }
  .location-wrap .info-block {
    width: 100%;
  }
  .info-profile .col-sm-3 {
    padding: 0 8px;
  }
  .info-profile .col-sm-3 a span {
    font-size: 65%;
  }
  .modal {
    background-color: #fff;
    padding-right: 0 !important;
  }
  .modal .modal-dialog {
    width: 100% !important;
    margin: 0px !important;
  }
  .modal button.close {
    left: 0px !important;
    top: -5px !important;
  }
  .modal .modal-content {
    padding: 10px 5px 10px 5px;
    height: 100vh !important;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #notificationPopup.modal .modal-dialog {
    width: auto !important;
    margin: 100px 50px !important;
  }
  .my-account {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  }
  .my-account .form-group > div {
    padding-left: 15px;
  }
  .my-account .form-group .col-sm-5, .my-account .form-group .col-sm-7, .my-account .form-group .col-sm-8 {
    margin-bottom: 15px;
  }
  .my-account .form-group label {
    padding-top: 0;
    font-weight: 600;
    margin-bottom: 3px;
  }
  .project-reviews .col-md-2 {
    padding-right: 0px;
  }
  .project-reviews .col-md-10 > div {
    padding-left: 30px;
  }
  .wrap-accordion h5.accordion-label {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .accordion-in {
    width: 100%;
  }
  .accordion-in .shadow-box .col-sm-12 {
    clear: both;
  }
  .heading-bordered + .row .flex-wrap .product-box {
    width: 31.2%;
    margin-right: 2%;
  }
  .heading-bordered h2 {
    margin-top: 0;
  }
  #login {
    background-color: #3a3a39;
    padding-right: 0 !important;
  }
  #login .modal-dialog {
    width: 100% !important;
    margin: 0px !important;
    height: 100% !important;
  }
  #login .modal-content {
    padding: 30px 20px 40px 20px;
    height: 100%;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #login button.close {
    left: 30px;
  }
  .coating-combo .coating-combo-in {
    display: block;
    padding-bottom: 25px;
  }
  .coating-combo .coating-combo-in > div {
    display: block;
    width: 100%;
    text-align: center;
  }
  .coating-combo .coating-combo-in > div .flex-wrap > div {
    margin-bottom: 0px !important;
  }
  .coating-combo .coating-combo-in > div span {
    margin-bottom: 25px;
  }
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 {
    padding-left: 15px;
  }
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 .btn {
    padding: 11px 20px 10px 20px;
    font-size: 1.4rem;
  }
  .thumbnail-gallery:hover {
    transform: perspective(644px) rotateY(1deg);
  }
  .wrap-gallery {
    clear: both;
  }
  .add-cart .col-sm-12 .col-xs-4 {
    margin: 10px 0 10px 0;
  }
  .add-cart .form-group > .row > div {
    margin-bottom: 20px;
  }
  .add-cart .product-total {
    margin-top: 0;
  }
  .add-cart .form-group > .row > div.col-xs-4 {
    width: 34%;
  }
  .add-cart .form-group > .row > div.col-xs-8 {
    width: 66%;
  }
  .cart-checkout a.shadow-box + .row {
    float: left;
    width: 100%;
  }
  .cart-checkout .selected-address-name {
    padding-top: 10px;
  }
  .cart-checkout .selected-address-name h3 {
    display: none;
  }
  .cart-checkout .selected-address-info h3 {
    display: none;
  }
  .cart-checkout .selected-address {
    padding-top: 10px;
  }
  .cart-checkout .selected-payment-type h3 {
    display: none;
  }
  .cart-checkout .selected-payment-info h3 {
    display: none;
  }
  .cart-checkout .radio-container {
    padding-right: 10px;
    width: 8.33333333%;
  }
  .cart-checkout .accordion-head {
    padding: 8px 0;
  }
  .cart-checkout .accordion-head .radio-select {
    padding-top: 0;
  }
  .cart-checkout .accordion-head div img {
    margin-left: 10px;
    margin-top: 0;
  }
  .cart-checkout .accordion-head div span {
    margin-top: 0;
  }
  .cart-checkout .accordion-head.cc-option .radio-select {
    padding-top: 10px;
  }
  .cart-checkout .accordion-head.cc-option div img {
    margin-top: 9px;
  }
  .cart-checkout .accordion-head.cc-option div span {
    width: 65%;
  }
  .cart-checkout a.shadow-box + .row .col-sm-12 {
    padding: 15px 14px;
  }
  .cart-checkout .order-container {
    width: auto;
  }
  #productCarousel, #galleryCarousel, #vue-creation-details #creationCarousel, #creationCarousel, .resources, .share {
    width: 100%;
  }
  #productCarousel, #galleryCarousel, #vue-creation-details #creationCarousel {
    height: auto;
  }
  .resources ul, .share ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .info-product-left .resources ul li, .info-product-left .share ul li {
    width: 33.3333333333% !important;
    float: left;
    border-bottom: 0;
  }
  .info-product-left .resources ul li a, .info-product-left .share ul li a {
    min-height: 75px;
  }
  .mid-inner .breadcrumb {
    font-size: 1.4rem;
    padding-bottom: 15px;
  }
  .mid-inner .breadcrumb a.btn-print {
    display: none;
  }
  .knowledge-base-tabs .nav-tabs li {
    font-size: 2rem;
  }
  .block-frequently .thumb-view-gallery, .block-frequently .product-in {
    width: 31.2%;
  }
  .wrap-gallery-details, #vue-creation-details .wrap-creation-details {
    min-height: auto;
    height: auto;
    max-height: inherit;
  }
  .share {
    display: none;
  }
  .gallery-common .flex-wrap {
    text-align: center;
  }
  .gallery-common .flex-wrap > .col-sm-4 {
    float: none;
  }
  .banner .item .carousel-caption .caption-inner {
    width: calc(100% - 15px);
    padding: 25px 10px;
    background-color: unset;
  }
  .banner .item .carousel-caption .caption-inner h1 {
    font-size: 2.6rem;
    margin: 0 0 3px 0;
  }
  .banner .item .carousel-caption .caption-inner p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding: 0;
  }
  .banner .item .carousel-caption .caption-inner a.btn {
    border-width: 1px;
    padding: 6px 15px 5px 15px;
    font-size: 1.2rem;
  }
  .banner .item:after {
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
  .banner .item.no-overlay:after {
    display: none;
  }
  .distributor-content h1 {
    float: left;
    width: 100%;
    font-size: 2.8rem;
  }
  .distributor-content .distributor-box .col-sm-8 {
    text-align: center;
  }
  .distributor-top {
    font-size: 3rem;
  }
  .distributor-details {
    padding: 20px 15px;
  }
  .mobile-toggle-map {
    flex-direction: row;
    align-items: center;
    display: flex;
    margin: 0 5px;
  }
  .mobile-toggle-map > input {
    margin-right: 5px;
  }
  .slider.slider-horizontal {
    width: calc(100% - 176px);
  }
  .cart-sidebar {
    margin-top: 25px;
  }
  .cart-sidebar .btn-blue {
    font-size: 1.4rem;
  }
  .wrap-cart {
    margin-top: 0;
  }
  .wrap-cart > div {
    width: 100%;
  }
  .wrap-cart > div .cart-content > .col-sm-6, .wrap-cart > div .cart-content > .col-sm-6 {
    width: 100%;
    border: 0 !important;
    padding: 0 !important;
  }
  .wrap-cart > div .cart-content > .col-sm-6 + .col-sm-6, .wrap-cart > div .cart-content > .col-sm-6 + .col-sm-6 {
    margin-top: 25px;
  }
  .address-form .styled-select {
    display: block;
    float: none;
  }
  .styled-select {
    margin-bottom: 8px;
  }
  .location-wrap .col-sm-3 {
    width: 100%;
  }
  .content-right .product-wrap .product-box {
    width: calc(50% - 20px);
  }
  .content-right .product-wrap .product-box.swatch-box {
    margin-left: 0;
    margin-right: 2%;
  }
  .coating-combo .coating-combo-in .col-sm-2, .coating-combo .coating-combo-in .col-sm-8 {
    width: 100%;
  }
  .coating-combo .coating-combo-in .product-box {
    margin: 0px 10px 10px 10px;
    width: 168px;
  }
  .coating-combo .coating-combo-in .col-sm-8 {
    padding-right: 0;
    margin-top: 0;
  }
  .token-wrap .token-applicator {
    width: 100%;
    margin-left: 0 !important;
  }
  .token-wrap .heading-applicator {
    margin: 0 0 20px 0;
  }
  .sticky-wrap {
    padding: 20px 15px;
  }
  .sticky-wrap .btn {
    position: static;
    float: right;
    padding: 12px 25px;
    font-size: 1.4rem;
  }
  /* -- Video -- */
  .wrap-video .col-sm-9, .wrap-video .col-sm-3 {
    width: 100%;
    border: 0;
    padding: 0;
  }
  .wrap-video .col-sm-3 {
    margin-top: 55px;
  }
  .radius-wrap {
    display: block;
  }
  .mid-inner.applicator {
    padding-bottom: 0;
  }
  .latest-purchased .flex-wrap {
    width: 100%;
  }
  .latest-purchased .product-box + .product-box {
    max-width: 300px;
  }
  .latest-purchased .btn-right {
    width: 100px;
  }
  .latest-purchased .btn-right a {
    padding: 4px 15px;
    margin-top: 18px;
  }
  #galleryCarousel, #vue-creation-details #creationCarousel,
  #creationCarousel {
    margin-left: 0px;
    max-height: 659px;
  }
  .share {
    position: relative;
    height: auto;
  }
  #galleryCarousel .item .item-in .fa-search-plus, #vue-creation-details #creationCarousel .item .item-in .fa-search-plus,
  #creationCarousel .item .item-in .fa-search-plus {
    display: none;
  }
  td, th {
    padding: 0 5px;
    display: block;
  }
  .orders .shadow-box table .discountAmount {
    padding: 5px 5px 5px;
  }
  .hidePrint {
    display: none;
  }
  .filter-options div {
    width: 55px;
  }
  #qualifiedApplication .form-group {
    min-height: 93px;
  }
  .modal .modal-header {
    padding: 10px 15px 0 15px;
  }
  .modal-header .close {
    text-shadow: none;
    opacity: 1;
    color: #FFFFFF;
    font-size: 15px;
    padding: 10px 0 0 10px;
  }
  .login-signup .modal-body h2, .signup-country .modal-body h2, .login-signup .modal-body h3, .signup-country .modal-body h3 {
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
  #login .modal-body .btns-sign-in .btn + .btn {
    margin-top: 0px;
  }
  .login-signup .divider-sign-in, .signup-country .divider-sign-in {
    margin: 2px 0 10px 0;
  }
  .modal .form-group {
    margin-bottom: 10px;
  }
  .login-signup .modal-body p, .signup-country .modal-body p {
    margin: 0 10px;
  }
  .styled-checkbox {
    margin-bottom: 0;
  }
  .styled-checkbox input[type=checkbox] + label:before, .styled-checkbox input[type=checkbox]:not(.filled-in) + label:after {
    width: 25px;
    height: 25px;
  }
  .styled-checkbox input[type=checkbox]:checked + label:after {
    top: 3px;
    left: 1px;
    width: 10px;
    height: 16px;
  }
  .styled-checkbox input[type=checkbox] + label {
    padding-left: 30px;
    padding-top: 2px;
  }
  #login .modal-body .btns-sign-in {
    display: none;
  }
  #login .modal-body .btns-sign-in.mobile-sign-in {
    display: block;
  }
  #login .modal-body .btns-sign-in.mobile-sign-in i {
    font-size: 2.2rem;
  }
  .distributor-content h2 {
    font-size: 28px;
  }
  .distributor-content .col-md-6 + .col-md-6 h2 {
    font-size: 28px;
  }
  .distributor-content .distributor-box h3 {
    font-size: 28px;
  }
  #productCarousel .item iframe, #galleryCarousel .item iframe, #vue-creation-details #creationCarousel .item iframe {
    width: 100%;
    height: 234px;
  }
  textarea.form-control.commentSection {
    height: 90px;
  }
  .review-form .row .col-md-6 {
    float: left;
    width: 100%;
  }
  .review-form .row .col-md-6:first-child {
    margin: 0 auto 15px;
  }
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 420px;
    margin-bottom: 0px;
  }
  #videoPlayerModal .modal-body .save-video {
    left: 10px !important;
    top: -50px !important;
    display: block;
  }
  #videoPlayerModal .modal-body button.close.close-popup {
    left: 10px !important;
    top: -50px !important;
    display: block;
  }
  .boxes-documents a.mobileLink {
    display: inline-block;
    box-shadow: none !important;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .boxes-documents a.mobileLink .img-responsive {
    display: inline-block;
    height: 100px;
  }
  .boxes-documents a.desktopLink {
    display: none;
  }
  .boxes-documents .open .col-md-2 {
    margin-bottom: 12px !important;
    min-height: auto !important;
  }
  .boxes-documents .open .col-md-2 .favorite-doc {
    visibility: visible;
    opacity: 0.6;
    background: url(/images/arrow-bookmark.svg) -108px 0 no-repeat;
    background-size: 165px auto;
  }
  .content-page .article-body table {
    width: 100% !important;
  }
  .content-page .article-body table td {
    width: 100% !important;
    display: block;
  }
  .search-container .wrap-video > div {
    padding: 0 15px;
  }
  .search-container .wrap-video .videoList .col-xs-12 {
    margin-bottom: 40px;
  }
  .headerBody {
    text-align: left;
  }
  .knowledge-base-tabs .qa-info-q h3 {
    display: block;
  }
  .knowledge-base-tabs .qa-info-q h3 + a {
    float: left;
    margin-top: 0;
  }
  .knowledge-base-tabs .qa-info-q h3 + a + hr {
    margin: 25px 0 0;
  }
  footer .sm-links > .row > div {
    justify-content: space-evenly;
    display: flex;
  }
  footer .sm-links > .row > div > a {
    font-size: 2.5rem;
    padding: 0 15px;
  }
  footer .footlinks .form-group input {
    margin: 15px auto 5px;
  }
  footer .footlinks .col-lg-3 {
    text-align: center;
  }
  footer .footlinks .col-lg-3 h3 {
    text-align: center;
  }
  footer .copyright .col-lg-3 {
    text-align: center;
  }
  footer .footlinks .col-lg-3 .tablet-only {
    display: none;
  }
  footer .footlinks .col-lg-3.mobile-only img {
    margin: auto;
    display: block;
  }
  #vue-shop .popular-searches .row > .row {
    margin-right: 0;
    margin-left: 0;
  }
  #vue-shop .popular-searches .row > .row .search-links:nth-child(1) {
    padding-right: 7.5px;
    padding-left: 15px;
  }
  #vue-shop .popular-searches .row > .row .search-links:nth-child(2) {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
  #vue-shop .popular-searches .row > .row .search-links:nth-child(3) {
    padding-left: 7.5px;
    padding-right: 15px;
  }
  #vue-shop .series-card {
    margin: 25px 0 0 0;
    padding-bottom: 30px;
  }
  #vue-shop .series-card .series-card-content > .row {
    flex-direction: column;
    display: flex;
  }
  #vue-shop .series-card .series-card-content .series-card-image {
    margin-bottom: 0;
    overflow: hidden;
    height: 100px;
  }
  #vue-shop .series-card .series-card-content .series-card-image img {
    height: 100px;
    overflow: hidden;
    margin-left: -368px;
    position: absolute;
    left: 50%;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-bottom: -30px;
    min-height: auto;
    max-height: none;
    padding: 30px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features h2 {
    margin-bottom: 15px;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features ul {
    height: auto;
  }
  #vue-shop .series-card .series-card-content .series-card-key-features ul li {
    font-size: 1.4rem;
  }
  .sml-series-img {
    display: none !important;
  }
  .mbl-series-img {
    display: block !important;
  }
  .main-container .shipping-block .row-height {
    display: block;
  }
  #slider-thumbs ul {
    grid-template-columns: auto auto auto auto;
  }
  .main-container .banner-cta {
    top: 120px;
  }
  .wrap-cart {
    display: inherit;
  }
  .wrap-cart .cart-items, .wrap-cart .cart-summary {
    display: inherit;
    flex-direction: unset;
  }
}
@media (max-width: 650px) {
  .add-to-cart .col-sm-6 {
    width: 100%;
  }
  .add-to-cart .col-sm-6 + .col-sm-6 {
    border-left: none;
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    margin-top: 10px;
  }
  .main-table.table {
    min-width: 260px;
  }
  .main-container .banner-cta .banner-cta-content .banner-cta-container {
    box-shadow: none;
  }
  .main-container .jumbotron {
    padding-top: 20px;
  }
  .main-container .distributor-hero {
    display: none;
  }
  .main-container .img-banner {
    height: 550px;
    background-color: #3a3a39;
  }
  .main-container .img-banner img {
    display: none;
  }
}
@media (max-width: 600px) {
  textarea.form-control.commentSection {
    height: 110px;
  }
  .banner .item .carousel-caption.center {
    height: 100%;
    margin: 0;
  }
  .banner .item .carousel-caption .caption-inner.center {
    padding: 15px 5px;
    height: 100%;
  }
  .carousel-caption .caption-inner .captionBtn {
    display: none;
  }
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {
    display: block;
    width: 50%;
    padding: 5px 60px 5px 5px;
  }
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn br {
    display: none;
  }
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn span {
    width: 100%;
    text-align: right;
    display: inline-block;
    vertical-align: text-top;
    line-height: 11px;
    font-size: 1.4rem;
  }
  .banner .item .carousel-caption .caption-inner p {
    font-size: 1.1rem;
  }
  .banner .item .carousel-caption .caption-inner a.btn {
    float: right;
  }
  .creation-custom-panel,
  .creation-all-colors {
    width: 100%;
    margin-bottom: 10px;
  }
  .main-container .img-banner {
    height: 700px;
  }
}
@media (max-width: 510px) {
  .cookiesDisabled {
    height: 130px;
  }
  .cookiesDisabled h9 {
    font-size: 1.7rem;
  }
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 280px;
  }
  .product-cards {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3%;
  }
  .marketing-banner {
    display: none;
  }
  .products-filtered .sidebar {
    margin: 20px 0 0 0;
    width: auto;
    border-radius: 0;
  }
  .products-filtered {
    display: inline-block;
  }
  .orders .shadow-box table th {
    display: none;
  }
  .orders .order-history-details-btn {
    float: none;
  }
  .orders .row {
    display: inline-block;
  }
  .latest-purchased h2 {
    margin: 0;
  }
  .latest-purchased .order-history-container {
    padding: 0;
  }
  .latest-purchased .order-history-item-details h3 {
    font-size: 14px;
  }
  .latest-purchased .order-history-item-details p {
    margin: 5px 0 0 0;
  }
  .latest-purchased .order-history-product-arrow {
    font-size: 10px;
  }
}
@media (max-width: 479px) {
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {
    padding-right: 30px;
  }
  .shadow-box h3 + p {
    margin-top: 10px;
  }
  .shadow-box.product-box {
    padding: 15px 15px 0 15px !important;
    flex-direction: column;
    display: flex;
  }
  .shadow-box.product-box img {
    margin: 0 0 15px 0;
  }
  .shadow-box.product-box > a {
    flex: 1;
  }
  .shadow-box.product-box > .product-actions {
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 15px;
    flex: 0 1 auto;
    display: flex;
  }
  .shadow-box.product-box > .product-actions > div {
    flex: 0 1 auto;
  }
  .shadow-box.product-box > .product-actions a:last-child {
    position: relative;
    padding: 10px 0;
    margin-top: 0;
  }
  #vue-shop .popular-searches .shadow-box,
  #vue-shop .series-card .shadow-box {
    padding: 30px;
  }
  .product-wrap h2 {
    justify-content: space-between;
    font-size: 1.75rem;
    display: flex;
  }
  .gallery-full .flex-wrap > .col-sm-4, .creation-full .flex-wrap > .col-sm-4 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 15px;
    width: 100%;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box {
    margin-top: 12px;
    height: auto;
    width: 100%;
  }
  .mid-inner .breadcrumb {
    display: none;
  }
  .close-mobile-nav {
    right: 10px;
  }
  .link-cart-mob {
    right: 15px;
  }
  .nav-toggle-mob {
    left: 15px;
  }
  .mobile-nav-left {
    width: 55px;
  }
  .mobile-nav-right {
    width: 100%;
    padding: 25px;
    margin-left: -55px;
  }
  .mobile-nav-right li a {
    font-size: 2.5rem;
    padding: 20px 0 0 0;
  }
  .mobile-nav-footer h2 {
    font-size: 1.8rem;
  }
  .mobile-nav-footer h2 span {
    font-size: 1.3rem;
  }
  footer .sm-links a {
    font-size: 2.2rem;
    padding: 0 7px;
  }
  footer .sm-links .form-group input {
    font-size: 1.3rem;
    width: 49%;
  }
  footer .sm-links .form-group button {
    padding: 8px;
    font-size: 1.3rem;
    width: 49%;
  }
  .product-box, .heading-bordered + .row .flex-wrap .product-box {
    width: 46%;
    margin-right: 4%;
  }
  .strip-black h3 {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 2px 0 0 0;
  }
  .strip-black h2 {
    font-size: 1.2rem;
    font-weight: normal;
  }
  .strip-black h2 i {
    margin-right: 5px;
  }
  .search-box input[type=text] {
    padding: 0 15px 0 15px;
    font-size: 1.6rem;
    width: calc(100% - 100px);
  }
  .search-box .btn-search {
    width: 50px;
  }
  .filter-box li {
    width: 100% !important;
    text-align: left;
    border-left: 0 !important;
  }
  .filter-box li + li {
    border-top: 1px solid rgb(204, 204, 204);
  }
  .filter-box li a {
    padding: 8px 15px;
  }
  .mid-inner {
    padding-top: 25px;
  }
  .filtered-right > .col-sm-6 {
    width: 50%;
  }
  .filter-options a {
    margin: 10px 6px;
  }
  .wrap-hero .hero-large .container p {
    padding: 0;
  }
  .profile-top .col-xs-4, .profile-top .col-xs-8 {
    width: 100%;
    text-align: center;
  }
  .profile-top .col-xs-4 {
    margin-bottom: 20px;
  }
  #login .modal-body .forgot-password {
    clear: both;
    margin-left: 10px;
    float: left;
  }
  #login .modal-content {
    padding: 30px 10px 40px 10px;
  }
  #login button.close {
    left: 20px;
  }
  .styled-checkbox {
    width: 100%;
  }
  .creation-dropdown {
    width: 100%;
  }
  .add-cart .col-sm-12 .col-xs-4 {
    margin: 5px 0 10px 0;
    border: 0 !important;
    padding: 0 15px;
  }
  .wrap-cart .table-responsive .main-table.table td {
    padding: 5px;
    white-space: normal;
  }
  .wrap-cart > div .cart-content {
    padding: 20px 10px;
  }
  .wrap-cart .table-responsive .main-table.table td img {
    max-width: 40px;
  }
  .resources ul li a, .share ul li a {
    font-size: 1.2rem;
    padding: 15px 10px;
    height: 100%;
  }
  .countdown-wrapper .middle {
    width: 230px;
  }
  .countdown-wrapper .countdown p {
    font-size: 2rem;
    margin-top: 0;
  }
  .knowledge-base-tabs .nav-tabs li {
    width: 100%;
  }
  .block-frequently .icon-math {
    width: 22px;
    font-size: 2.5rem;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail {
    padding-top: 10px;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail h3, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail h3, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail h3,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail h3,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail h3,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail h3 {
    font-size: 1.8rem;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail p, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail p, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail p,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail p,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail p,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail p {
    font-size: 1.3rem;
  }
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row, .creation-full .flex-wrap > .col-sm-4 .thumbnail-box .info-thumbnail .row, .creation-full .product-wrap .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row,
  .gallery-full .flex-wrap > .col-sm-4 .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row,
  .creation-full .flex-wrap > .col-sm-4 .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail .row,
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .flex-wrap > .col-sm-4 .creation-thumbnail-box .info-thumbnail .row {
    margin-top: 10px;
  }
  .banner .item .carousel-caption .caption-inner h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .content-right .product-wrap .product-box {
    width: calc(100% - 20px);
  }
  .content-right .product-wrap .product-box.swatch-box {
    margin-right: 4%;
  }
  .coating-combo .coating-combo-in > div .flex-wrap > .product-box {
    margin: 0 4% 15px 0 !important;
  }
  .coating-combo .coating-combo-in .col-sm-8 {
    margin-top: 30px;
  }
  .block-frequently .product-box, .block-frequently .thumb-view-gallery {
    width: 43%;
    margin-right: 1%;
  }
  .sticky-wrap .btn {
    clear: both;
    margin: 25px 0 0 0;
    width: 100%;
    float: left;
  }
  .search-box .select2-container, .search-box .global-search {
    width: calc(100% - 50px) !important;
  }
  .coating-combo .coating-combo-in > div .flex-wrap > div {
    width: 100%;
  }
  .coating-combo .coating-combo-in .product-box {
    width: 148px;
  }
  #vue-gallery .search-box .global-search {
    font-size: 1.3rem;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 25px;
    width: 100% !important;
    padding-right: 35px;
  }
  #vue-gallery .search-box .btn-search {
    position: absolute;
    background-color: transparent;
    color: #ccc;
    right: 0px;
    pointer-events: none;
  }
  .gallery-detail-top, .creation-detail-top h1 {
    font-size: 25px;
  }
  .hideMobile {
    display: none !important;
  }
  #vue-my-page .photo-profile .photo-in {
    width: 80px;
    height: 80px;
  }
  .info-profile .row .col-sm-12 > .phoneLink.open {
    width: calc(100% - 45px);
    left: 30px;
  }
  .latest-purchased .btn-right {
    float: left;
    left: 40px;
  }
  .latest-purchased .product-box {
    margin-right: 2%;
  }
  .latest-purchased .product-box img {
    max-height: 30px;
  }
  .latest-purchased .product-box + .product-box {
    padding: 10px 15px !important;
    max-width: 225px;
    margin-right: 0;
  }
  .latest-purchased {
    padding: 15px 20px;
  }
  .cookiesDisabled h9 {
    font-size: 1.6rem;
  }
  .info-profile {
    padding-left: 15px;
  }
  .info-profile .info-details {
    min-height: 80px;
  }
  .info-profile .aboutUs {
    width: auto;
    margin-left: calc(-25% - 30px);
  }
  .info-profile .row .col-sm-12 {
    margin-left: calc(-25% - 20px);
  }
  textarea.form-control.commentSection {
    height: 130px;
  }
  #vue-creation-details .product-wrap .flex-wrap {
    flex-direction: column;
  }
  #vue-creation-details .product-wrap .creation-product-box {
    min-height: 240px;
    margin: 20px auto 20px;
  }
  #slider-thumbs ul {
    grid-template-columns: auto auto auto;
  }
  .main-container .img-banner {
    height: 800px;
  }
}
@media (max-width: 379px) {
  .banner .item .carousel-caption {
    padding: 0 5px;
  }
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {
    padding-right: 10px;
  }
  #qualifiedApplication .form-group {
    min-height: 105px;
  }
  .wrap-cart .cart-content .table-responsive {
    overflow-x: auto;
  }
  .cookiesDisabled {
    padding-top: 20px;
  }
  .cookiesDisabled h9 {
    font-size: 1.5rem;
  }
  .product-cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .product-card {
    max-width: inherit;
  }
  #slider-thumbs ul {
    grid-template-columns: auto auto;
  }
  .main-container .img-banner {
    height: 900px;
  }
}
@media print {
  header {
    display: none;
  }
  .mobile-head-wrapper {
    display: none;
  }
  .mobile-nav {
    display: none;
  }
  .mid-inner {
    margin-top: 15px;
  }
  footer {
    display: none;
  }
  .countdown-wrapper {
    display: none;
  }
  .breadcrumb {
    display: none;
  }
  /* -- cart -- */
  .wrap-cart .add-product {
    display: none;
  }
  .wrap-cart .cart-sidbar h4 {
    display: none;
  }
  .wrap-cart #calculateShipping {
    display: none;
  }
  .wrap-cart #getEstimate {
    display: none;
  }
  .wrap-cart .block-terms {
    display: none;
  }
  .wrap-cart .wrap-subtotal a {
    display: none;
  }
  .wrap-cart .wrap-subtotal .subtotal-price {
    text-align: left;
  }
  /* -- checkout -- */
  .cart-checkout .col-sm-8 {
    width: 75%;
  }
  .cart-checkout .col-sm-4 {
    width: 25%;
  }
  .cart-checkout .shipping-address.method .method-wrap {
    display: inline-block;
    width: 25%;
    float: left;
    margin: 0;
  }
  .cart-checkout .shipping-address.method p {
    display: inline-block;
    width: 74%;
    margin: 0;
  }
  .cart-checkout .shipping-address.method .method-wrap .method-box {
    display: none;
  }
  .cart-checkout .shipping-address.method .method-wrap .method-box.method-orange {
    display: block;
  }
  .container > .row p {
    display: none;
  }
  .cart-checkout h2 span {
    display: none;
  }
  .cart-checkout .addressSearch {
    display: none;
  }
  .cart-checkout .wrap-accordion #accordionAddress .accordion-head > div.col-sm-5 {
    width: 33.3%;
  }
  .cart-checkout .wrap-accordion #accordionAddress .accordion-head > div.col-sm-7 {
    width: 66.7%;
  }
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box {
    display: none;
  }
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected {
    display: block;
  }
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse {
    display: block;
  }
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse .row > div.col-sm-5 {
    width: 33.3%;
    float: left;
  }
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse .row > div.col-sm-7 {
    width: 66%;
    float: left;
  }
  .cart-checkout .card > a {
    display: none;
  }
  .cart-checkout .card > .row {
    display: none;
  }
  .cart-checkout .addPaymentOption {
    display: none;
  }
  .cart-checkout .paymentOptionForm {
    display: block;
  }
  .cart-checkout .wrap-accordion .paymentshadowbox {
    display: none;
  }
  .cart-checkout .wrap-accordion .paymentshadowbox.selected {
    display: block;
  }
  .cart-checkout .block-comment-payment .form-group p {
    display: none;
  }
  .cart-checkout .block-comment-payment .form-group input {
    display: none;
  }
  .cart-checkout .cart-sidbar .block-terms {
    display: none;
  }
  .alert-success span {
    display: none;
  }
  /* -- shipping (for non logged out users making purchase) -- */
  #vue-shipping .wrap-cart .col-sm-8 {
    width: 74%;
    float: left;
  }
  #vue-shipping .wrap-cart .col-sm-6 {
    width: 50%;
    float: left;
  }
  #vue-shipping .wrap-cart .col-sm-4 {
    width: 25%;
    float: left;
  }
  #vue-shipping .wrap-cart .form-group.checkbox {
    display: none;
  }
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap {
    display: inline-block;
    width: 25%;
    float: left;
    margin: 0;
  }
  #vue-shipping .wrap-cart .shipping-address.method p {
    display: inline-block;
    width: 74%;
    margin: 0;
  }
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap .method-box {
    display: none;
  }
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap .method-box.method-orange {
    display: block;
  }
  #vue-shipping .wrap-cart .submitOrder {
    display: none;
  }
  #vue-shipping .wrap-cart .cart-content.shipping-address h2 + .row {
    margin-bottom: 0;
  }
  /* -- Gallery/Creation Details -- */
  .gallery-detail-top p,
  .creation-detail-top p {
    display: none;
  }
  .wrap-gallery-details .share, #vue-creation-details .wrap-creation-details .share,
  .wrap-creation-details .share {
    display: none;
  }
  .wrap-gallery-details #galleryCarousel, #vue-creation-details .wrap-creation-details #galleryCarousel, .wrap-gallery-details #vue-creation-details #creationCarousel, #vue-creation-details .wrap-gallery-details #creationCarousel, #vue-creation-details .wrap-creation-details #creationCarousel,
  .wrap-creation-details #creationCarousel {
    width: 66%;
  }
  .wrap-gallery-details .info-product-right, #vue-creation-details .wrap-creation-details .info-product-right,
  .wrap-creation-details .info-product-right {
    width: 34%;
  }
  #vue-gallery-details a.btn-blue.mobile-head-wrapper,
  #vue-creation-details a.btn-blue.mobile-head-wrapper {
    display: none;
  }
  #vue-gallery-details a.btn-blue,
  #vue-creation-details a.btn-blue {
    display: none;
  }
  .wrap-gallery-details .info-product-right > h4, #vue-creation-details .wrap-creation-details .info-product-right > h4,
  .wrap-creation-details .info-product-right > h4 {
    display: none;
  }
  .wrap-gallery-details .info-product-right a, #vue-creation-details .wrap-creation-details .info-product-right a,
  .wrap-creation-details .info-product-right a {
    display: none;
  }
  .wrap-gallery-details .info-product-right .shadow-box > .row, #vue-creation-details .wrap-creation-details .info-product-right .shadow-box > .row,
  .wrap-creation-details .info-product-right .shadow-box > .row {
    display: none;
  }
  #vue-gallery-details .info-product-right-mobile .side-box,
  #vue-creation-details .info-product-right-mobile .side-box {
    text-align: left;
  }
  #vue-gallery-details .info-product-right-mobile .shadow-box > .row > div > span,
  #vue-creation-details .info-product-right-mobile .shadow-box > .row > div > span {
    display: none;
  }
  #vue-gallery-details .info-product-right-mobile .shadow-box > .row > div > div,
  #vue-creation-details .info-product-right-mobile .shadow-box > .row > div > div {
    display: none;
  }
  #vue-gallery-details .info-product-right-mobile .side-box .stars,
  #vue-creation-details .info-product-right-mobile .side-box .stars {
    display: none;
  }
  #vue-gallery-details .info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink,
  #vue-creation-details .info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink {
    opacity: 1;
    width: 100px;
    position: relative;
    background: #fff;
  }
  #vue-gallery-details .heading-bordered h2 > span a,
  #vue-creation-details .heading-bordered h2 > span a {
    display: none;
  }
  #vue-gallery-details .product-wrap .product-box,
  #vue-creation-details .product-wrap .product-box {
    width: 100%;
    padding: 5px 0;
    min-height: auto;
  }
  #vue-gallery-details .product-wrap .product-box img,
  #vue-creation-details .product-wrap .product-box img {
    display: inline-block;
    height: 60px;
  }
  #vue-gallery-details .product-wrap .product-box div div,
  #vue-creation-details .product-wrap .product-box div div {
    display: none;
  }
  #vue-gallery-details .product-wrap .product-box div:first-child > a h3, .product-box div:first-child > a p,
  #vue-creation-details .product-wrap .product-box div:first-child > a h3 {
    display: inline;
  }
  /* -- Product Details -- */
  .wrap-details .resources, .wrap-details .share {
    display: none;
  }
  .wrap-details #productCarousel, .wrap-details #galleryCarousel, .wrap-details #vue-creation-details #creationCarousel, #vue-creation-details .wrap-details #creationCarousel {
    width: 62%;
  }
  .wrap-details .info-product-right {
    width: 38%;
  }
  .wrap-details .info-product-right .add-cart .form-group .col-sm-8 {
    display: none;
  }
  .wrap-details .info-product-right .add-cart .form-group > .row > .col-sm-4 {
    display: none;
  }
  .wrap-details .info-product-right .form-group .product-total {
    display: none;
  }
  #vue-shop-details .wrap-knowledge-base {
    display: none;
  }
  /* -- Wishlist & Past Orders (print order history) -- */
  .heading-wishlist {
    display: none;
  }
  .hidePrint {
    display: none;
  }
  .hideNonPrint {
    display: block !important;
  }
  .row.wishlist {
    display: none;
  }
  .row.orders .latest-purchased {
    display: none;
  }
  .row.orders .latest-purchased.print {
    display: block;
    margin: 0;
  }
  .row.orders .latest-purchased .printOnly {
    display: block;
    text-align: left;
  }
  .row.orders .latest-purchased.print .product-box h3 + .printOnly {
    display: block;
  }
  .row.orders > .col-sm-12 {
    padding: 0px;
    margin: 0px;
  }
  .row.orders > .col-sm-12 > .col-sm-6 {
    width: 40% !important;
    padding: 0px;
    margin: 0px;
  }
  .row.orders .col-sm-3 {
    width: 30% !important;
    padding: 0px;
    margin: 0px;
  }
  .row.orders > .col-sm-12 > .col-xs-12 {
    padding: 0px;
    margin: 0px;
  }
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

.fade-enter-active {
  animation: fade 300ms;
}

.fade-leave-active {
  animation: fade 300ms reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-fade-enter-active {
  animation: slide-fade 300ms;
}

.slide-fade-leave-active {
  animation: slide-fade 300ms reverse;
}

@keyframes slide-fade {
  0% {
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.list-item {
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

.list-enter,
.list-leave-to {
  transform: translate3d(0, 10px, 0);
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}

.list-fade-enter,
.list-fade-leave-to {
  opacity: 0;
}

.error-container {
  width: inherit;
  font-family: "Avenir-Next", Helvetica, sans-serif;
  color: #fff;
  z-index: 2;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.error-container a:hover {
  color: #272727 !important;
}
.error-container h1 {
  font-size: 160px;
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  text-shadow: #f69422 5px 5px;
}
.error-container h2 {
  margin-top: 0;
  max-width: 700px;
  font-size: 30px;
}
.error-container p {
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin: 10px 0;
}
.error-container i {
  color: #f69422;
}
.error-container .btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}
.error-container .btn.pill {
  border: 4px solid #fff;
  color: #fff;
}
.error-container .btn.pill:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: #fff;
  z-index: -1;
  transition: 0.2s ease;
}
.error-container .btn.pill:hover {
  color: #272727;
  background: #fff;
  transition: 0.2s ease;
}
.error-container .btn.pill:hover:before {
  width: 100%;
}
.error-container .report-issue {
  color: #fff;
  text-decoration: underline;
}
.error-container .clouds {
  position: absolute;
  left: 17%;
  top: -15%;
  z-index: -1;
}
.error-container .clouds .cloudy {
  transform: scale(-0.78, 0.78);
  display: inline-block;
  text-align: center;
  opacity: 0.2;
  position: relative;
}
.error-container .clouds .cloudy.front {
  left: -20%;
  transform: scale(0.78, 0.88);
  opacity: 1;
  position: relative;
  z-index: 1;
}
.error-container .clouds .cloudy.front .cloud, .error-container .clouds .cloudy.front .shadow {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}
.error-container .clouds .cloudy.right {
  right: 45%;
  transform: scale(-0.68, 0.68);
  opacity: 0.45;
}
.error-container .clouds .cloudy.right .cloud, .error-container .clouds .cloudy.right .shadow {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  animation-direction: alternate-reverse;
}
.error-container .clouds .shadow {
  background: rgba(0, 0, 0, 0.35);
  height: 75px;
  width: 75px;
  border-radius: 50px;
  transform: translate(0, -35px) scale(1.35, 0.25);
  margin: auto;
  -webkit-animation-name: shadow-loop;
  animation-name: shadow-loop;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.error-container .clouds .cloud {
  position: relative;
  text-align: center;
  margin: auto;
  width: 150px;
  -webkit-animation-name: cloud-loop;
  animation-name: cloud-loop;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.error-container .clouds .cloud .base {
  height: 50px;
  width: 150px;
  background: #f69422;
  border-radius: 50px;
  margin: 0;
  font-size: 0;
  line-height: 0;
}
.error-container .clouds .cloud .circle {
  display: inline-block;
  background: #f69422;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin: 0;
  font-size: 0;
  line-height: 0;
  position: relative;
}
.error-container .clouds .cloud .circle.c1 {
  top: 28px;
  left: 15px;
}
.error-container .clouds .cloud .circle.c2 {
  width: 85px;
  height: 85px;
  top: 45px;
  left: -10px;
}
.error-container .clouds .rain {
  display: block;
  text-align: center;
  margin: auto;
  height: 75px;
  width: 80px;
  overflow: hidden;
}
.error-container .clouds .drop {
  display: inline-block;
  background: #f69422;
  height: 25px;
  width: 4px;
  margin: 5px;
  border-radius: 25px;
  opacity: 0.85;
  -webkit-animation-name: drop-fall;
  animation-name: drop-fall;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.error-container .clouds .drop.fast {
  opacity: 0.75;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.error-container .clouds .drop.faster {
  opacity: 0.5;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
}
.error-container .clouds .drop.slow {
  -webkit-animation-duration: 0.85s;
  animation-duration: 0.85s;
}
@-webkit-keyframes shadow-loop {
  0% {
    transform: translate(0, -35px) scale(1.15, 0.25);
  }
  100% {
    transform: translate(0, -35px) scale(1, 0.25);
  }
}
@keyframes shadow-loop {
  0% {
    transform: translate(0, -35px) scale(1.15, 0.25);
  }
  100% {
    transform: translate(0, -35px) scale(1, 0.25);
  }
}
@-webkit-keyframes cloud-loop {
  0% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes cloud-loop {
  0% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes drop-fall {
  0% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, 125px);
    opacity: 0;
  }
}
@keyframes drop-fall {
  0% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, 125px);
    opacity: 0;
  }
}

@media only screen and (max-width: 501px) {
  .error-container {
    width: 100%;
    display: contents;
  }
  .error-container .btn {
    background-color: white;
    width: auto;
  }
  .error-container .btn.pill {
    color: #444444;
    width: 90%;
  }
  .error-container .clouds {
    display: none;
  }
  .error-container h1 {
    font-size: 80px;
  }
  .error-container h2 {
    width: min-content;
  }
  .error-container p {
    font-size: 14px;
  }
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #3E6B85;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
  -webkit-animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.multiselect__spinner:after {
  -webkit-animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  margin: 0 auto;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}

.multiselect__input::-moz-placeholder {
  color: #35495e;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #3E6B85;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon:after {
  content: "×";
  color: #2d4f63;
  font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #365c72;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 41px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.option__small {
  font-size: 60%;
}

.multiselect__option--highlight {
  background: #3E6B85;
  outline: none;
  color: #fff;
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #3E6B85;
  color: #fff;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.multiselect__clear {
  position: absolute;
  right: 40px;
  height: 41px;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
  display: block;
  position: absolute;
  width: 3px;
  height: 16px;
  top: 12px;
  right: 18px;
}

[dir=rtl] .multiselect {
  text-align: right;
}

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px;
}

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px;
}

[dir=rtl] .multiselect__content {
  text-align: right;
}

[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0;
}

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px;
}

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px;
}

@-webkit-keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
.sbx-facebook {
  display: inline-block;
  position: relative;
  width: 450px;
  height: 27px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 13px;
}

.sbx-facebook__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-facebook__input {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0px #CCCCCC;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 62px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-facebook__input-placeholder {
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 0px #CCCCCC;
  background: #FFFFFF;
  padding: 0;
  padding-right: 62px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-facebook__input::-webkit-search-decoration,
.sbx-facebook__input::-webkit-search-cancel-button,
.sbx-facebook__input::-webkit-search-results-button,
.sbx-facebook__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-facebook__input:hover {
  box-shadow: inset 0 0 0 0px #b3b3b3;
}

.sbx-facebook__input:focus,
.sbx-facebook__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 0px #3E82F7;
  background: rgba(255, 255, 255, 0);
}

.sbx-facebook__input::-webkit-input-placeholder {
  color: #AAAAAA;
}

.sbx-facebook__input::-moz-placeholder {
  color: #AAAAAA;
}

.sbx-facebook__input:-ms-input-placeholder {
  color: #AAAAAA;
}

.sbx-facebook__input::placeholder {
  color: #AAAAAA;
}

.sbx-facebook__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #f6f7f8;
  padding: 0;
  width: 35px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-facebook__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-facebook__submit:hover,
.sbx-facebook__submit:active {
  cursor: pointer;
}

.sbx-facebook__submit:focus {
  outline: 0;
}

.sbx-facebook__submit svg {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  fill: #3C5BA2;
}

.sbx-facebook__reset {
  display: none;
  position: absolute;
  top: 3px;
  right: 41px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-facebook__reset:focus {
  outline: 0;
}

.sbx-facebook__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.sbx-facebook__input:valid ~ .sbx-facebook__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/* amazon*/
.sbx-amazon {
  display: inline-block;
  position: relative;
  width: 600px;
  height: 39px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}

.sbx-amazon__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-amazon__input {
  display: inline-block;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #FFFFFF;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 75px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-amazon__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #FFFFFF;
  background: #FFFFFF;
  padding: 0;
  padding-right: 75px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-amazon__input::-webkit-search-decoration,
.sbx-amazon__input::-webkit-search-cancel-button,
.sbx-amazon__input::-webkit-search-results-button,
.sbx-amazon__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-amazon__input:hover {
  box-shadow: inset 0 0 0 1px #e6e6e6;
}

.sbx-amazon__input:focus,
.sbx-amazon__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #FFBF58;
  background: rgba(255, 255, 255, 0);
}

.sbx-amazon__input::-webkit-input-placeholder {
  color: #AAAAAA;
}

.sbx-amazon__input::-moz-placeholder {
  color: #AAAAAA;
}

.sbx-amazon__input:-ms-input-placeholder {
  color: #AAAAAA;
}

.sbx-amazon__input::placeholder {
  color: #AAAAAA;
}

.sbx-amazon__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #ffbf58;
  padding: 0;
  width: 47px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-amazon__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-amazon__submit:hover,
.sbx-amazon__submit:active {
  cursor: pointer;
}

.sbx-amazon__submit:focus {
  outline: 0;
}

.sbx-amazon__submit svg {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  fill: #202F40;
}

.sbx-amazon__reset {
  display: none;
  position: absolute;
  top: 9px;
  right: 54px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-amazon__reset:focus {
  outline: 0;
}

.sbx-amazon__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.sbx-amazon__input:valid ~ .sbx-amazon__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/*  google */
.sbx-google {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 41px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}

.sbx-google__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-google__input {
  display: inline-block;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #CCCCCC;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 77px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-google__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #CCCCCC;
  background: #FFFFFF;
  padding: 0;
  padding-right: 77px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-google__input::-webkit-search-decoration,
.sbx-google__input::-webkit-search-cancel-button,
.sbx-google__input::-webkit-search-results-button,
.sbx-google__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-google__input:hover {
  box-shadow: inset 0 0 0 1px #b3b3b3;
}

.sbx-google__input:focus,
.sbx-google__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #3E82F7;
  background: rgba(255, 255, 255, 0);
}

.sbx-google__input::-webkit-input-placeholder {
  color: #AAAAAA;
}

.sbx-google__input::-moz-placeholder {
  color: #AAAAAA;
}

.sbx-google__input:-ms-input-placeholder {
  color: #AAAAAA;
}

.sbx-google__input::placeholder {
  color: #AAAAAA;
}

.sbx-google__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #3e82f7;
  padding: 0;
  width: 49px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-google__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-google__submit:hover,
.sbx-google__submit:active {
  cursor: pointer;
}

.sbx-google__submit:focus {
  outline: 0;
}

.sbx-google__submit svg {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  fill: #FFFFFF;
}

.sbx-google__reset {
  display: none;
  position: absolute;
  top: 10px;
  right: 56px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-google__reset:focus {
  outline: 0;
}

.sbx-google__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.sbx-google__input:valid ~ .sbx-google__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/* twitter */
.sbx-twitter {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 33px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 12px;
}

.sbx-twitter__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-twitter__input {
  display: inline-block;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 17px;
  box-shadow: inset 0 0 0 1px #E1E8ED;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 52px;
  padding-left: 16px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-twitter__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 17px;
  box-shadow: inset 0 0 0 1px #E1E8ED;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 52px;
  padding-left: 16px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-twitter__input::-webkit-search-decoration,
.sbx-twitter__input::-webkit-search-cancel-button,
.sbx-twitter__input::-webkit-search-results-button,
.sbx-twitter__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-twitter__input:hover {
  box-shadow: inset 0 0 0 1px #c1d0da;
}

.sbx-twitter__input:focus,
.sbx-twitter__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #D6DEE3;
  background: rgba(255, 255, 255, 0);
}

.sbx-twitter__input::-webkit-input-placeholder {
  color: #9AAEB5;
}

.sbx-twitter__input::-moz-placeholder {
  color: #9AAEB5;
}

.sbx-twitter__input:-ms-input-placeholder {
  color: #9AAEB5;
}

.sbx-twitter__input::placeholder {
  color: #9AAEB5;
}

.sbx-twitter__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 16px 16px 0;
  background-color: rgba(62, 130, 247, 0);
  padding: 0;
  width: 33px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-twitter__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-twitter__submit:hover,
.sbx-twitter__submit:active {
  cursor: pointer;
}

.sbx-twitter__submit:focus {
  outline: 0;
}

.sbx-twitter__submit svg {
  width: 13px;
  height: 13px;
  vertical-align: middle;
  fill: #657580;
}

.sbx-twitter__reset {
  display: none;
  position: absolute;
  top: 7px;
  right: 33px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-twitter__reset:focus {
  outline: 0;
}

.sbx-twitter__reset svg {
  display: block;
  margin: 4px;
  width: 11px;
  height: 11px;
}

.sbx-twitter__input:valid ~ .sbx-twitter__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/* spotify */
.sbx-spotify {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 25px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 12px;
}

.sbx-spotify__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-spotify__input {
  display: inline-block;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 13px;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  padding-right: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-spotify__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 13px;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: #FFFFFF;
  padding: 0;
  padding-right: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-spotify__input::-webkit-search-decoration,
.sbx-spotify__input::-webkit-search-cancel-button,
.sbx-spotify__input::-webkit-search-results-button,
.sbx-spotify__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-spotify__input:hover {
  box-shadow: inset 0 0 0 0px #e6e6e6;
}

.sbx-spotify__input:focus,
.sbx-spotify__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 0px #FFFFFF;
  background: rgba(255, 255, 255, 0);
}

.sbx-spotify__input::-webkit-input-placeholder {
  color: #AAAAAA;
}

.sbx-spotify__input::-moz-placeholder {
  color: #AAAAAA;
}

.sbx-spotify__input:-ms-input-placeholder {
  color: #AAAAAA;
}

.sbx-spotify__input::placeholder {
  color: #AAAAAA;
}

.sbx-spotify__submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 12px 0 0 12px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 25px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-spotify__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-spotify__submit:hover,
.sbx-spotify__submit:active {
  cursor: pointer;
}

.sbx-spotify__submit:focus {
  outline: 0;
}

.sbx-spotify__submit svg {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  fill: #222222;
}

.sbx-spotify__reset {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-spotify__reset:focus {
  outline: 0;
}

.sbx-spotify__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.sbx-spotify__input:valid ~ .sbx-spotify__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.vue-instant__suggestions {
  position: absolute;
  left: 0;
  top: 110%;
  margin: 0;
  background-color: #fff;
  border: 1px solid #D3DCE6;
  width: 100%;
  padding: 6px 0;
  z-index: 10;
  border-radius: 2px;
  max-height: 280px;
  box-sizing: border-box;
  overflow: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  margin-top: 3px;
  text-align: left;
}

.vue-instant__suggestions li {
  list-style: none;
  line-height: 36px;
  padding: 0 10px;
  margin: 0;
  cursor: pointer;
  color: #475669;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vue-instant__suggestions li.highlighted__spotify {
  background-color: black;
  color: #fff;
}

.vue-instant__suggestions li.highlighted__twitter {
  background-color: #20a0ff;
  color: #fff;
}

.vue-instant__suggestions li.highlighted__google {
  background-color: #EEEEEE;
  color: black;
}

.vue-instant__suggestions li.highlighted__facebook {
  background-color: #3e5da0;
  color: #fff;
}

.vue-instant__suggestions li.highlighted__amazon {
  background-color: #232F3E;
  color: #fff;
}

.el-input-group__append {
  border: 0px !important;
}

.sbx-custom__input {
  position: absolute;
  left: 0 !important;
  background: rgba(255, 255, 255, 0) !important;
}

.btn-alt-outline, .btn-white-outline, .btn-black-outline, .btn-primary-outline {
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  background-color: transparent;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 0;
  padding: 0 20px;
  display: flex;
  height: 42px;
}
.btn-alt-outline .fa, .btn-white-outline .fa, .btn-black-outline .fa, .btn-primary-outline .fa {
  font-size: 19px;
}
.btn-alt-outline .fa:not(.fa-long-arrow-right), .btn-white-outline .fa:not(.fa-long-arrow-right), .btn-black-outline .fa:not(.fa-long-arrow-right), .btn-primary-outline .fa:not(.fa-long-arrow-right) {
  margin-bottom: -2px;
}
.btn-alt-outline .fa:first-child, .btn-white-outline .fa:first-child, .btn-black-outline .fa:first-child, .btn-primary-outline .fa:first-child {
  margin-right: 8px;
}
.btn-alt-outline .fa:last-child, .btn-white-outline .fa:last-child, .btn-black-outline .fa:last-child, .btn-primary-outline .fa:last-child {
  margin-left: 8px;
}
.btn-alt-outline:hover, .btn-alt-outline:focus-within, .btn-white-outline:hover, .btn-white-outline:focus-within, .btn-black-outline:hover, .btn-black-outline:focus-within, .btn-primary-outline:hover, .btn-primary-outline:focus-within {
  color: white !important;
}
.btn-alt-outline + .btn, .btn-white-outline + .btn, .btn-black-outline + .btn, .btn-primary-outline + .btn {
  margin-top: 15px;
}
.btn-alt-outline {
  border: 3px solid #3E6B85;
  color: #3E6B85;
}
.btn-alt-outline:hover, .btn-alt-outline:focus-within {
  background-color: #3E6B85;
}
.btn-white-outline {
  border: 3px solid white;
  color: white;
}
.btn-white-outline:hover, .btn-white-outline:focus-within {
  background-color: rgba(255, 255, 255, 0.275);
}
.btn-black-outline {
  border: 3px solid #3a3a39;
  color: #3a3a39;
}
.btn-black-outline:hover, .btn-black-outline:focus-within {
  background-color: #3a3a39;
}
.btn-primary-outline {
  border: 3px solid #F69322;
  color: #F69322;
}
.btn-primary-outline:hover, .btn-primary-outline:focus-within {
  background-color: #F69322;
}

#my-page {
  padding-bottom: 50px;
  margin-bottom: 50px;
  position: relative;
}
#my-page p {
  line-height: 24px;
  font-size: 16px;
  color: #7A7A7A;
}
#my-page .load-more {
  transform: translate3d(-50%, 0, 0);
  pointer-events: all;
  position: absolute;
  left: 50%;
  bottom: 0;
}
#my-page-actions {
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 35px;
  display: flex;
}
@media screen and (max-width: 1024px) {
  #my-page-actions {
    border-top: 1px solid #DBDBDB;
    background-color: white;
    flex-wrap: nowrap;
    position: fixed;
    padding: 16px;
    z-index: 101;
    margin: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
#my-page-actions .btn + .btn {
  margin-left: 15px;
  margin-top: 0;
}
@media screen and (max-width: 1024px) {
  #my-page-actions .btn {
    flex: 1;
  }
  #my-page-actions .btn span {
    display: none;
  }
}
#my-page-errors {
  justify-content: center;
  margin-top: 25px;
  display: flex;
}
#my-page .missing-section {
  background-color: #F7F2D2;
  border: 1px solid #F7E199;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  padding: 8px 14px;
  font-weight: 600;
  font-size: 14px;
  color: #555;
}
#my-page .missing-section i {
  display: inline-flex;
  margin-right: 8px;
  font-size: 18px;
}
#my-page #not-certified {
  background-color: rgba(58, 58, 57, 0.365);
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: 700;
  font-size: 15px;
  color: white;
  display: flex;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1024px) {
  #my-page #not-certified {
    background-color: transparent;
    margin-bottom: -42px;
    position: relative;
    padding: 16px;
    width: 100%;
    bottom: auto;
    right: auto;
    left: auto;
    top: auto;
  }
}
#my-page #not-certified-message {
  padding: 10px 24px 10px 4px;
  background-color: #3a3a39;
  letter-spacing: 0.125px;
  margin-bottom: 7.5vw;
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  max-width: 550px;
  display: flex;
}
#my-page #not-certified-message img {
  width: 72px;
}
#my-page #not-certified-message span {
  flex: 1;
}
#my-page #not-certified-message a {
  transition: text-decoration-color 200ms ease-in-out;
  text-decoration-color: transparent !important;
  text-decoration: underline;
  color: #f69422;
}
#my-page #not-certified-message a:hover, #my-page #not-certified-message a:focus-within {
  text-decoration-color: #f69422 !important;
  color: #f69422 !important;
}
#my-page #not-certified-message small {
  text-transform: none;
  font-weight: 500;
  margin-top: 4px;
  font-size: 12px;
  color: #BABABA;
  display: block;
}
@media screen and (max-width: 1024px) {
  #my-page #not-certified-message {
    margin-bottom: 0;
    padding: 16px;
  }
  #my-page #not-certified-message img {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  #my-page #my-page-contact-cta {
    border-top: 1px solid #DBDBDB;
    background-color: white;
    flex-direction: row;
    flex-wrap: nowrap;
    position: fixed;
    display: flex;
    padding: 8px;
    z-index: 100;
    bottom: 0;
    right: 0;
    left: 0;
  }
  #my-page #my-page-contact-cta .btn {
    margin: 8px;
    flex: 1;
  }
}
#my-page-banner {
  background-color: #7D7C7A;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  z-index: 1;
}
#my-page-banner::before {
  content: "";
  box-shadow: inset 0 -250px 150px -100px rgba(0, 0, 0, 0.675);
  background-image: var(--banner-image, none);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  display: block;
  height: 50vmin;
  flex: 1;
}
#my-page-banner.not-certified::before {
  filter: blur(10px);
  min-height: 620px;
  margin: -10px;
}
@media screen and (max-width: 1024px) {
  #my-page-banner {
    position: absolute;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
  #my-page-banner::before {
    box-shadow: inset 0 0 0 100vmax rgba(58, 58, 57, 0.365);
    position: absolute;
    padding: 0;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}
#my-page-details {
  position: relative;
  margin-top: 0;
  width: 100%;
  z-index: 5;
}
@media screen and (max-width: 1024px) {
  #my-page-details {
    overflow: hidden;
  }
}
#my-page-details__inner {
  padding-bottom: 15px;
  flex-direction: row;
  margin-bottom: 75px;
  position: relative;
  margin-top: 75px;
  display: flex;
  z-index: 5;
}
@media screen and (max-width: 1024px) {
  #my-page-details__inner {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 48px 32px;
    margin-bottom: 0;
  }
}
#my-page-details-rating {
  background-color: rgba(0, 0, 0, 0.365);
  backdrop-filter: blur(5px);
  align-items: center;
  flex-direction: row;
  margin-bottom: 25px;
  letter-spacing: 4px;
  border-radius: 3px;
  margin-left: -2px;
  padding: 5px 8px;
  font-size: 20px;
  display: flex;
}
@media screen and (max-width: 1024px) {
  #my-page-details-rating {
    margin-bottom: 18px;
    margin-left: 0;
  }
}
#my-page-details-rating .fa-star,
#my-page-details-rating .fa-star-o {
  color: rgba(255, 255, 255, 0.365);
}
#my-page-details-rating .fa-star.rated,
#my-page-details-rating .fa-star-o.rated {
  color: #F69322;
}
#my-page-details-rating span {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 11px;
  color: white;
}
#my-page-details-certified {
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  bottom: 24px;
  right: 12px;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  #my-page-details-certified {
    bottom: 8px;
    right: 8px;
  }
}
#my-page-details-certified .fa-check {
  font-size: 16px;
  color: white;
  z-index: 1;
}
#my-page-details-certified .fa-certificate {
  position: absolute;
  font-size: 36px;
  color: #F69322;
}
#my-page-details-avatar {
  background-color: #E6E8E8;
  border: 6px solid #E6E8E8;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: -75px;
  align-items: center;
  border-radius: 50%;
  position: relative;
  display: flex;
  height: 200px;
  width: 200px;
  padding: 6px;
}
@media screen and (max-width: 1024px) {
  #my-page-details-avatar {
    margin-bottom: 0;
    height: 150px;
    width: 150px;
  }
}
#my-page-details-avatar img {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
#my-page-details-account {
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 24px;
  display: flex;
  color: #333;
}
#my-page-details-account h1,
#my-page-details-account h3 {
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  color: currentColor;
}
#my-page-details-account h1 {
  letter-spacing: 1.5px;
  text-indent: -3px;
  line-height: 0.75;
  font-weight: 500;
  font-size: 75px;
  margin: 0;
}
#my-page-details-account h3 {
  align-items: flex-end;
  flex-direction: row;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 10px 0 0;
  font-size: 30px;
  display: flex;
}
#my-page-details-account h3 .fa {
  margin-right: 5px;
  font-size: 24px;
}
#my-page-details-account h3 span {
  line-height: 20px;
}
@media screen and (max-width: 1024px) {
  #my-page-details-account {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 24px;
    padding: 0;
  }
  #my-page-details-account h1 {
    line-height: 32px;
    font-size: 36px;
    text-indent: 0;
  }
  #my-page-details-account h3 {
    align-items: flex-start;
    font-size: 18px;
    margin-top: 6px;
  }
  #my-page-details-account h3 .fa {
    font-size: 18px;
  }
  #my-page-details-account h3 span {
    line-height: 21px;
  }
}
#my-page-details-actions {
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
  flex: 1;
  gap: 10px;
}
@media screen and (max-width: 1024px) {
  #my-page-details-actions {
    padding-bottom: 0;
    margin-top: 24px;
  }
}
#my-page-banner ~ #my-page-details__inner {
  margin-top: -160px;
}
@media screen and (max-width: 1024px) {
  #my-page-banner ~ #my-page-details__inner {
    margin-top: 0;
  }
}
#my-page-banner ~ #my-page-details__inner #my-page-details-account {
  color: white;
}
#my-page-nav {
  top: var(--desktop-header-size);
  background-color: #F1F1F1;
  margin-bottom: 25px;
  margin-top: -60px;
  position: sticky;
  z-index: 4;
}
@media screen and (max-width: 1024px) {
  #my-page-nav {
    border-bottom: 1px solid #DBDBDB;
    top: var(--mobile-header-size);
    background-color: white;
    margin-top: 0;
  }
}
#my-page-nav__inner {
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 238px;
  display: flex;
}
@media screen and (max-width: 1024px) {
  #my-page-nav__inner {
    justify-content: space-between;
    padding: 0 16px;
  }
}
#my-page-nav-buttons {
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  flex: 1;
}
#my-page-nav .my-page-nav-link {
  border: 0;
  border-bottom: 3px solid transparent;
  transition: all 200ms ease-in-out;
  background-color: transparent;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  font-weight: 700;
  outline: none;
  display: flex;
  color: #999;
  height: 55px;
  padding: 0;
}
#my-page-nav .my-page-nav-link-counter {
  transition: background-color 200ms ease-in-out;
  text-transform: uppercase;
  background-color: #999;
  letter-spacing: 0.5px;
  border-radius: 3px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
  font-size: 11px;
  padding: 0 8px;
  color: white;
}
@media screen and (max-width: 1024px) {
  #my-page-nav .my-page-nav-link-counter {
    background-color: transparent;
    font-weight: 700;
    color: #999;
  }
}
#my-page-nav .my-page-nav-link .fa {
  margin-right: 8px;
  font-size: 16px;
}
#my-page-nav .my-page-nav-link[aria-selected] {
  border-bottom: 3px solid #F69322;
  color: #333;
}
#my-page-nav .my-page-nav-link[aria-selected] .my-page-nav-link-counter {
  background-color: #333;
  color: white;
}
@media screen and (max-width: 1024px) {
  #my-page-nav .my-page-nav-link[aria-selected] .my-page-nav-link-counter {
    background-color: transparent;
    color: #333;
  }
}
#my-page-nav .my-page-nav-link:not([aria-selected]):hover, #my-page-nav .my-page-nav-link:not([aria-selected]):focus-within {
  color: #333;
}
#my-page-nav .my-page-nav-link:not([aria-selected]):hover .my-page-nav-link-counter, #my-page-nav .my-page-nav-link:not([aria-selected]):focus-within .my-page-nav-link-counter {
  background-color: #333;
}
@media screen and (max-width: 1024px) {
  #my-page-nav .my-page-nav-link:not([aria-selected]):hover .my-page-nav-link-counter, #my-page-nav .my-page-nav-link:not([aria-selected]):focus-within .my-page-nav-link-counter {
    background-color: transparent;
    color: #333;
  }
}
@media screen and (max-width: 1024px) {
  #my-page-nav .my-page-nav-link {
    margin-right: 0;
    padding: 0 16px;
    flex: 1;
  }
  #my-page-nav .my-page-nav-link .fa {
    margin-right: 0;
  }
}
#my-page-review {
  border: 1px solid #DBDBDB;
  background-color: white;
  flex-direction: row;
  margin-bottom: 50px;
  overflow: hidden;
  display: flex;
}
#my-page-review .review-content-section {
  text-align: center;
  width: 100%;
  padding: 3%;
}
#my-page-review .review-content-section button {
  display: inline-block;
}
#my-page-review .review-content-section h2 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 15px;
  margin-top: 0;
}
#my-page-about {
  border: 1px solid #DBDBDB;
  background-color: white;
  flex-direction: row;
  margin-bottom: 50px;
  overflow: hidden;
  display: flex;
}
#my-page-about .about-content-section li {
  width: auto;
}
@media screen and (max-width: 1024px) {
  #my-page-about {
    flex-direction: column;
    margin-top: 16px;
  }
}
#my-page-about-content, #my-page-about-actions {
  flex-direction: column;
  padding: 24px;
  display: flex;
}
#my-page-about h2 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 15px;
  margin-top: 0;
}
#my-page-about p {
  white-space: pre-line;
  margin-top: 0;
}
#my-page-about-content {
  flex: 1;
}
#my-page-about-content .about-content-section {
  flex-direction: column;
  display: flex;
}
#my-page-about-content .about-content-section + .about-content-section {
  margin-top: 36px;
}
@media screen and (max-width: 1024px) {
  #my-page-about-content .about-content-section + .about-content-section {
    margin-top: 24px;
  }
}
#my-page-about-content .featured-items {
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  margin: 0 -10px;
  display: flex;
}
@media screen and (max-width: 1024px) {
  #my-page-about-content .featured-items {
    flex-wrap: wrap;
    margin: -5px;
  }
}
#my-page-about-content .featured-items-card {
  position: relative;
  flex: 0 1 25%;
}
@media screen and (max-width: 1024px) {
  #my-page-about-content .featured-items-card {
    flex: 0 1 50%;
  }
}
#my-page-about-content .featured-items-card-loading {
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 48px;
  color: #FFFFFF;
  display: flex;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
#my-page-about-content .featured-items-card-item {
  border: 1px solid #DBDBDB;
  position: relative;
  display: block;
  margin: 0 10px;
  padding: 7px;
}
@media screen and (max-width: 1024px) {
  #my-page-about-content .featured-items-card-item {
    margin: 5px;
  }
}
#my-page-about-content .featured-items-card-unpin {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  border: none;
  height: 36px;
  width: 36px;
  right: 15px;
  z-index: 10;
  top: 15px;
}
#my-page-about-content .featured-items-card-thumbnail {
  background-image: var(--thumbnail);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100%;
  position: relative;
  transition: none;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 0;
}
#my-page-about-content .featured-items-card-thumbnail::before {
  content: "";
  transition: background-color 200ms ease-in-out;
  background-color: rgba(51, 51, 51, 0);
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
#my-page-about-content .featured-items-card-thumbnail img {
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  max-height: 100%;
  min-width: 100%;
  z-index: 0;
  left: 50%;
  top: 50%;
}
#my-page-about-content .featured-items-card-thumbnail img.videos {
  max-height: 133.333%;
  min-height: 133.333%;
}
#my-page-about-content .featured-items-card-thumbnail .play-icon {
  transition: opacity 200ms ease-in-out;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 62px;
  display: flex;
  color: white;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
}
#my-page-about-content .featured-items-card:hover .featured-items-card-thumbnail .play-icon, #my-page-about-content .featured-items-card:focus-within .featured-items-card-thumbnail .play-icon {
  opacity: 1;
}
#my-page-about-content .featured-items-card:hover .featured-items-card-thumbnail::before, #my-page-about-content .featured-items-card:focus-within .featured-items-card-thumbnail::before {
  background-color: rgba(51, 51, 51, 0.65);
}
#my-page-about-actions {
  border-left: 1px solid #DBDBDB;
  width: 325px;
}
#my-page-about-actions li {
  width: max-content;
}
@media screen and (max-width: 1024px) {
  #my-page-about-actions {
    padding-top: 0;
    border-left: 0;
    width: 100%;
  }
}
#my-page-about-work {
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 -4px;
  display: flex;
}
#my-page-about-work .work-accepted {
  background-color: #EFEFEF;
  text-transform: capitalize;
  border-radius: 100px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  margin: 4px;
}
#my-page-about-map {
  padding-bottom: 70%;
  position: relative;
  margin-top: 25px;
  overflow: hidden;
  cursor: pointer;
  height: 0;
}
@media screen and (max-width: 1024px) {
  #my-page-about-map {
    margin-top: 0;
  }
}
#my-page-about-map .block-map {
  transition: border-color 200ms ease-in-out;
  border: 1px solid #DBDBDB;
  position: absolute;
  padding: 2px;
  height: 100%;
  width: 100%;
}
#my-page-about-map .block-map:hover, #my-page-about-map .block-map:focus-within {
  border-color: #3E6B85;
}
#my-page-about-map .block-map a {
  display: block;
  height: 100%;
}
#my-page-about-map .block-map #gmap-canvas {
  height: 100% !important;
  pointer-events: none;
}
#my-page-about-map .block-map #gmap-canvas button,
#my-page-about-map .block-map #gmap-canvas .gm-fullscreen-control {
  display: none !important;
}
#my-page-about-links {
  flex-direction: column;
  margin-top: 25px;
  list-style: none;
  display: flex;
}
#my-page-about-links > li {
  justify-content: flex-start;
  flex-direction: column;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
}
#my-page-about-links > li a:hover,
#my-page-about-links > li a:focus-within {
  text-decoration: underline;
}
#my-page-about-links > li a + a::before,
#my-page-about-links > li span + span::before {
  content: ",";
  margin-right: 3px;
  margin-left: -3px;
  color: #333;
}
#my-page-about-links > li + li {
  margin-top: 10px;
}
#my-page-about-links .is-certified {
  justify-content: flex-start;
  flex-direction: row;
  white-space: nowrap;
  display: flex;
}
#my-page-about-links .is-certified .certified-badge {
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 3px;
  display: flex;
  z-index: 1;
}
#my-page-about-links .is-certified .certified-badge .fa-check {
  position: absolute;
  font-size: 10px;
  color: white;
  z-index: 1;
}
#my-page-about-links .is-certified .certified-badge .fa-certificate {
  font-size: 22px;
  color: #F69322;
}
#my-page-pinned, #my-page-everything, #my-page-projects, #my-page-reviews, #my-page-videos {
  pointer-events: none;
  position: relative;
  margin-top: 25px;
  column-gap: 25px;
  columns: 3;
}
@media screen and (max-width: 1199px) {
  #my-page-pinned, #my-page-everything, #my-page-projects, #my-page-reviews, #my-page-videos {
    column-count: 2;
  }
}
@media screen and (max-width: 660px) {
  #my-page-pinned, #my-page-everything, #my-page-projects, #my-page-reviews, #my-page-videos {
    margin: 20px 0 0;
    column-count: 1;
  }
}
#my-page-pinned[aria-busy] .grid-card, #my-page-everything[aria-busy] .grid-card, #my-page-projects[aria-busy] .grid-card, #my-page-reviews[aria-busy] .grid-card, #my-page-videos[aria-busy] .grid-card {
  pointer-events: none !important;
  opacity: 0.5 !important;
}
#my-page-pinned .video-card-title, #my-page-everything .video-card-title, #my-page-projects .video-card-title, #my-page-reviews .video-card-title, #my-page-videos .video-card-title {
  flex-direction: row;
  align-items: center;
  display: flex;
}
#my-page-pinned .video-card-title .fa, #my-page-everything .video-card-title .fa, #my-page-projects .video-card-title .fa, #my-page-reviews .video-card-title .fa, #my-page-videos .video-card-title .fa {
  margin-right: 6px;
  font-size: 20px;
  color: red;
}
#my-page-pinned .video-card .play-icon, #my-page-everything .video-card .play-icon, #my-page-projects .video-card .play-icon, #my-page-reviews .video-card .play-icon, #my-page-videos .video-card .play-icon {
  transition: opacity 200ms ease-in-out;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 62px;
  display: flex;
  color: white;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
}
#my-page-pinned .video-card:hover .play-icon, #my-page-pinned .video-card:focus-within .play-icon, #my-page-everything .video-card:hover .play-icon, #my-page-everything .video-card:focus-within .play-icon, #my-page-projects .video-card:hover .play-icon, #my-page-projects .video-card:focus-within .play-icon, #my-page-reviews .video-card:hover .play-icon, #my-page-reviews .video-card:focus-within .play-icon, #my-page-videos .video-card:hover .play-icon, #my-page-videos .video-card:focus-within .play-icon {
  opacity: 1;
}
#my-page-pinned .project-card-controls, #my-page-everything .project-card-controls, #my-page-projects .project-card-controls, #my-page-reviews .project-card-controls, #my-page-videos .project-card-controls {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  position: absolute;
  flex-wrap: nowrap;
  padding: 12px;
  display: flex;
  z-index: 10;
  right: 26px;
  left: 26px;
  top: 26px;
}
#my-page-pinned .project-card-status, #my-page-pinned .project-card-actions, #my-page-everything .project-card-status, #my-page-everything .project-card-actions, #my-page-projects .project-card-status, #my-page-projects .project-card-actions, #my-page-reviews .project-card-status, #my-page-reviews .project-card-actions, #my-page-videos .project-card-status, #my-page-videos .project-card-actions {
  display: flex;
}
#my-page-pinned .project-card .project-review p, #my-page-everything .project-card .project-review p, #my-page-projects .project-card .project-review p, #my-page-reviews .project-card .project-review p, #my-page-videos .project-card .project-review p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
}

.project-review-title {
  flex-direction: column;
  display: flex;
}
.project-review strong {
  text-transform: capitalize;
  font-size: 15px;
}
.project-review blockquote {
  font-size: inherit;
  padding: 0;
  margin: 0;
  border: 0;
}
.project-review blockquote small {
  margin-top: 6px;
  font-size: 11px;
  color: #999999;
}
.project-review blockquote small::before {
  display: none;
}
.project-review blockquote p {
  line-height: 1.625;
  margin-bottom: 0;
  margin-top: 4px;
  color: #666666;
}
.project-review-quote {
  margin: 15px 0 0 0;
}
.project-review-quote > cite {
  font-weight: 600;
  font-size: 13px;
  color: #9A9A9A;
  text-transform: capitalize;
}
.project-review-quote p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 24px;
}
.project-review-rating {
  align-items: center;
  flex-direction: row;
  letter-spacing: 5px;
  font-size: 20px;
  display: inline-flex;
  margin: 0;
}
.project-review-rating .fa-star-o {
  color: #D8D8D8;
}
.project-review-rating .fa-star {
  color: #F69322;
}

.feed-item {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  background-color: white;
  margin-bottom: 25px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.feed-item:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transform: translate3d(0, -10px, 0);
  z-index: 2;
}
.feed-item:empty {
  visibility: hidden;
  display: none;
}
@media screen and (max-width: 1024px) {
  .feed-item {
    margin-bottom: 16px;
  }
  .feed-item:hover {
    transform: translate3d(0, 0, 0);
  }
}

.grid-card {
  transition: opacity 200ms ease-in-out;
  flex-direction: column;
  position: relative;
  display: flex;
  opacity: 1;
  flex: 1;
}
@media screen and (max-width: 1024px) {
  .grid-card {
    width: 100%;
  }
}
.grid-card__inner {
  pointer-events: all;
  transition: none;
  cursor: pointer;
  color: #222222;
  display: block;
}
.grid-card-loading {
  background-color: rgba(0, 0, 0, 0.5);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  font-size: 48px;
  color: #FFFFFF;
  display: flex;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.grid-card-image {
  pointer-events: none;
  position: relative;
  z-index: 0;
  height: 0;
}
.grid-card-image-background {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.grid-card-image-background::before {
  content: "";
  background-image: var(--card-image);
  background-position: center center;
  background-repeat: no-repeat;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-size: cover;
  position: absolute;
  bottom: -1px;
  right: -1px;
  left: -1px;
  top: -1px;
}
.grid-card-image-background.default-image::before {
  background-size: contain;
}
.grid-card-image[data-ratio="1:1"] {
  padding-bottom: 70%;
}
.grid-card-image[data-ratio="16:9"] {
  padding-bottom: 55.1%;
}
.grid-card-author {
  flex-direction: column;
  margin: 15px 0;
  display: flex;
  width: 100%;
}
.grid-card-author strong, .grid-card-author small {
  line-height: 1.25;
  margin: 0;
}
.grid-card-author strong {
  font-size: 16px;
}
.grid-card-author small {
  font-size: 12px;
  margin-top: 4px;
}
.grid-card-type {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: #575757;
}
.grid-card-content {
  padding: 20px 20px 25px;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
}
.grid-card-content h3 {
  line-height: 1.365;
  font-weight: 600;
  font-size: 19px;
  color: #222222;
}
.grid-card-content h3:first-letter {
  text-transform: uppercase;
}
.grid-card-content p {
  margin: 12px 0 0;
}
.grid-card-date {
  align-items: center;
  margin-top: -3px;
  font-weight: 600;
  font-size: 13px;
  color: #9A9A9A;
  display: flex;
}
.grid-card-date > .fa {
  align-items: center;
  font-size: 16px;
  display: flex;
  height: 24px;
  width: 22px;
}
.grid-card-status {
  align-items: flex-start;
  flex-direction: row;
  margin: 10px 0 0 0;
  display: flex;
}
.grid-card-status .status {
  text-transform: uppercase;
  letter-spacing: 0.25px;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.grid-card-status .status .fa {
  margin-right: 4px;
  font-size: 12px;
}
.grid-card-status .status.info {
  background-color: #40CBF9;
}
.grid-card-status .status.pending {
  background-color: #FFBB35;
}
.grid-card-status .status.denied {
  background-color: #FF3F49;
}
.grid-card-status .status.approved {
  background-color: #7ECC58;
}
.grid-card-actions {
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
  flex-direction: row;
  position: absolute;
  flex-wrap: nowrap;
  padding: 0 20px;
  display: flex;
  height: 100%;
  width: 100%;
}
.grid-card-actions > button {
  transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #3E6B85;
  justify-content: center;
  display: inline-flex;
  margin: 0 0 -22px 0;
  pointer-events: all;
  align-items: center;
  border-radius: 100%;
  font-size: 21px;
  cursor: pointer;
  color: white;
  line-height: 1;
  outline: none;
  height: 48px;
  width: 48px;
  z-index: 5;
  padding: 0;
  border: 0;
}
.grid-card-actions > button:hover, .grid-card-actions > button:focus {
  background-color: #4E86A6;
}
.grid-card-actions > button > span {
  font-size: 36px;
}
.grid-card-actions-menu {
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.65);
  transform: translate3d(0, 0, 0);
  background-color: white;
  flex-direction: column;
  pointer-events: all;
  border-radius: 4px;
  position: absolute;
  margin-top: 36px;
  padding: 10px;
  display: flex;
  right: 15px;
  opacity: 1;
  top: 100%;
}
.grid-card-actions-menu[aria-hidden] {
  transform: translate3d(0, 10px, 0);
  pointer-events: none;
  opacity: 0;
}
.grid-card-actions-menu::before {
  content: "";
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  right: 18px;
  top: -10px;
}
.grid-card-actions-menu > button {
  transition: background-color 200ms ease-in-out;
  background-color: transparent;
  justify-content: flex-start;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  color: #555555;
  padding: 0 12px;
  line-height: 1;
  outline: none;
  border: none;
  height: 40px;
}
.grid-card-actions-menu > button .fa {
  margin-right: 6px;
  font-size: 16px;
  width: 16px;
}
.grid-card-actions-menu > button:disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.grid-card-actions-menu > button:not(:disabled):hover, .grid-card-actions-menu > button:not(:disabled):focus-within {
  background-color: #EFEFEF;
}
.grid-card-actions-menu > button.danger {
  color: #FF3F49;
}
.grid-card-actions-menu > button[aria-selected] {
  background-color: #1788e4;
  color: white;
}
.grid-card-actions-menu > button[aria-selected]:hover, .grid-card-actions-menu > button[aria-selected]:focus-within {
  color: white;
}
.grid-card-actions-menu-underlay {
  background-color: rgba(255, 0, 0, 0.1);
  pointer-events: all;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  left: 0;
  top: 0;
}

.my-account .socail-links li {
  text-overflow: ellipsis;
  overflow: hidden;
}
.my-account .account-sidebar {
  padding-right: 15px;
  padding-left: 15px;
}
.my-account .account-sidebar label {
  display: contents;
}
@media screen and (min-width: 1031px) {
  .my-account .account-sidebar {
    padding-right: 60px;
  }
}
.my-account-details {
  padding-top: 10px;
  margin-top: 20px;
  flex: 1;
}
@media screen and (min-width: 1031px) {
  .my-account-details {
    border-left: 1px solid rgba(150, 150, 150, 0.4);
    padding-left: 40px;
  }
}
.my-account-details .my-account:first-of-type {
  padding-top: 0;
}
.my-account-details .my-account:first-of-type .heading-bordered {
  margin-top: 0;
}

.modal-error {
  color: #E71D36;
  margin-bottom: 14px;
  margin-top: 6px;
}
.modal-form {
  flex-direction: column;
}
.modal-form fieldset {
  border: 1px solid #EBEBEB;
  background-color: #FBFBFB;
  flex-direction: column;
  border-radius: 4px;
  padding: 18px;
  display: flex;
  width: 100%;
}
.modal-form fieldset legend {
  margin: 0 0 0 -16px;
  text-align: center;
  font-weight: bold;
  padding: 0 15px;
  font-size: 14px;
  width: auto;
  border: 0;
}
.modal-form fieldset + fieldset {
  margin-top: 25px;
}
.modal-form fieldset .edit-company-address {
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
}
@media screen and (max-width: 600px) {
  .modal-form fieldset .edit-company-address {
    flex-direction: column;
  }
}
.modal-form fieldset .edit-company-address-preview, .modal-form fieldset .edit-company-address-notice {
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;
  flex: 0 1 50%;
  display: flex;
  width: 50%;
}
@media screen and (max-width: 600px) {
  .modal-form fieldset .edit-company-address-preview, .modal-form fieldset .edit-company-address-notice {
    width: 100%;
    flex: 1;
  }
}
.modal-form fieldset .edit-company-address-preview {
  margin-right: 18px;
  text-align: center;
}
.modal-form fieldset .edit-company-address-preview p {
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .modal-form fieldset .edit-company-address-preview {
    order: 2;
  }
}
.modal-form fieldset .edit-company-address-notice {
  background-color: #F7F2D2;
  border: 1px solid #F7E199;
  border-radius: 4px;
}
@media screen and (max-width: 600px) {
  .modal-form fieldset .edit-company-address-notice {
    margin-bottom: 16px;
    order: 1;
  }
}
.modal-form-field {
  flex-direction: row;
  margin-right: -8px;
  margin-left: -8px;
  flex-wrap: nowrap;
  display: flex;
}
.modal-form-field + .modal-form-field {
  margin-top: 16px;
}
@media screen and (max-width: 600px) {
  .modal-form-field {
    flex-wrap: wrap;
  }
  .modal-form-field .modal-form-label {
    width: 100%;
  }
  .modal-form-field .modal-form-label + .modal-form-label {
    margin-top: 16px;
  }
}
.modal-form-checkboxes {
  flex-direction: row;
  margin-bottom: -5px;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}
.modal-form-checkbox {
  text-transform: capitalize;
  flex-direction: row;
  padding-right: 8px;
  flex: 0 1 33.333%;
  flex-wrap: nowrap;
  font-weight: 600;
  display: flex;
}
.modal-form-checkbox input {
  margin-right: 5px;
}
.modal-form-label {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  margin: 0 8px;
  flex: 1;
}
@media screen and (max-width: 600px) {
  .modal-form-label {
    min-width: calc(100% - 16px);
  }
}
.modal-form-label strong {
  margin-bottom: 5px;
}
.modal-form-label small {
  margin-bottom: 12px;
  margin-top: -6px;
  font-weight: 500;
  font-size: 12px;
  color: #888;
}
.modal-form-placeholder {
  line-height: var(--input-height);
  height: var(--input-height);
  position: absolute;
  font-weight: 400;
  padding: 0 15px;
  color: #CACACA;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.modal-form-input {
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: text;
  width: 100%;
}
.modal-form-input:focus-within {
  border-color: #5C9EAD;
}
.modal-form-input[aria-invalid] {
  border-color: #E71D36;
}
.modal-form-input .fa {
  line-height: var(--input-height);
  border-right: 1px solid #CCC;
  width: var(--input-height);
  background-color: #f5f5f5;
  text-align: center;
  font-size: 16px;
  color: #858585;
  z-index: 1;
}
.modal-form-input input,
.modal-form-input select,
.modal-form-input textarea {
  height: var(--input-height);
  padding: 10px 15px;
  font-weight: 500;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  border: 0;
}
.modal-form-input input:not(:placeholder-shown) + .modal-form-placeholder,
.modal-form-input select:not(:placeholder-shown) + .modal-form-placeholder,
.modal-form-input textarea:not(:placeholder-shown) + .modal-form-placeholder {
  display: none;
}
.modal-form-input.select {
  cursor: pointer;
}
.modal-form-input.select select {
  padding-left: 16px;
  margin-left: -1px;
  appearance: none;
  cursor: pointer;
}
.modal-form-input.select::before {
  content: "\f107";
  font: normal normal normal 14px/1 FontAwesome;
  height: var(--input-height);
  width: var(--input-height);
  justify-content: center;
  pointer-events: none;
  align-items: center;
  position: absolute;
  font-size: 20px;
  display: flex;
  right: 0;
}
.modal-form-input textarea {
  max-height: calc(var(--input-height) * 5);
  min-height: calc(var(--input-height) * 3);
  height: calc(var(--input-height) * 3);
  margin-right: -15px;
  line-height: 1.55;
  resize: vertical;
}
.modal-form-input.search-input {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 3px;
}
.modal-form-input.search-input span {
  background-color: #EFEFEF;
  pointer-events: none;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 0 12px;
  height: 32px;
  margin: 2px;
}
.modal-form-input.search-input span a {
  margin: 0 4px 0 -2px;
  pointer-events: all;
  text-align: center;
  line-height: 16px;
  font-size: 16px;
  color: #AAAAAA;
  display: block;
  outline: none;
  height: 16px;
  width: 16px;
  padding: 0;
  border: 0;
}
.modal-form-input.search-input span a:hover, .modal-form-input.search-input span a:focus-within {
  color: red !important;
}
.modal-form-input.search-input input {
  padding-left: 8px;
  height: 32px;
  margin: 2px;
  flex: 1;
}
.modal-form-panel {
  flex-direction: column;
  display: flex;
  width: 100%;
}

#galleryLightBoxModal {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  overflow: hidden;
}
#galleryLightBoxModal.in {
  display: flex !important;
}
#galleryLightBoxModal .modal-dialog {
  margin: 50px;
  flex: 1;
}
#galleryLightBoxModal .modal-content {
  background-color: white;
  pointer-events: all;
  box-shadow: none;
  color: #333;
  padding: 0;
  margin: 0;
  border: 0;
}
#galleryLightBoxModal .modal-header,
#galleryLightBoxModal .modal-footer {
  display: none;
}
#galleryLightBoxModal .modal-body {
  flex-direction: row;
  overflow-y: scroll;
  max-height: 80vh;
  min-height: 80vh;
  display: flex;
  z-index: 1;
  padding: 0;
  flex: 1;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .modal-body {
    flex-direction: column;
    max-height: 100vh;
    margin: 0;
  }
}
#galleryLightBoxModal .project-popup-header {
  width: calc(100% + 380px);
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-header {
    width: 100%;
  }
}
#galleryLightBoxModal .project-popup-content {
  flex-direction: column;
  position: sticky;
  display: flex;
  z-index: 2;
  margin: 0;
  flex: 1;
  top: 0;
}
#galleryLightBoxModal .project-popup-content h2 {
  font-size: calc(10px + 0.5vw);
  text-transform: uppercase;
  padding: 0 48px 0 16px;
  align-items: center;
  line-height: 48px;
  font-weight: 700;
  display: flex;
  color: #333;
  margin: 0;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-content {
    border-bottom: 16px solid white;
    background-color: white;
    margin: 0;
  }
  #galleryLightBoxModal .project-popup-content h2 {
    font-size: calc(11px + 0.5vw);
    padding: 16px 48px 16px 16px;
    line-height: 16px;
  }
}
#galleryLightBoxModal .project-popup-link {
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  line-height: 48px;
  font-size: 13px;
  display: flex;
  bottom: -48px;
  left: 0;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-link {
    display: none;
  }
}
#galleryLightBoxModal .project-popup-link .fa {
  margin-right: 5px;
}
#galleryLightBoxModal .project-popup-link a {
  padding-right: 16px;
}
#galleryLightBoxModal .project-popup-link a:hover, #galleryLightBoxModal .project-popup-link a:focus-within {
  text-decoration: underline;
}
#galleryLightBoxModal .project-popup-title {
  box-shadow: 0 -30px 0 0 white;
  border-top: 2px solid #CCC;
  text-transform: uppercase;
  background-color: white;
  line-height: 1.365;
  position: sticky;
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 15px;
  padding: 10px 0;
  color: #333;
  z-index: 1;
  top: 48px;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-title {
    font-size: calc(11px + 0.5vw);
    box-shadow: none;
  }
}
#galleryLightBoxModal .project-popup-nav {
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 18px;
  outline: none;
  display: flex;
  height: 48px;
  width: 48px;
  z-index: 3;
  border: 0;
}
#galleryLightBoxModal .project-popup-nav.nav-close {
  transition: color 200ms ease-in-out;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 30px;
  padding: 0 8px;
  line-height: 1;
  display: flex;
  outline: none;
  color: #AAA;
  border: 0;
}
#galleryLightBoxModal .project-popup-nav.nav-close:hover, #galleryLightBoxModal .project-popup-nav.nav-close:focus-within {
  color: #F69322;
}
#galleryLightBoxModal .project-popup-nav.nav-right, #galleryLightBoxModal .project-popup-nav.nav-left {
  transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;
  transform: translate3d(0, -50%, 0);
  background-color: #3A3A39;
  color: white;
  opacity: 0;
  top: 50%;
}
#galleryLightBoxModal .project-popup-nav.nav-right:hover, #galleryLightBoxModal .project-popup-nav.nav-right:focus-within, #galleryLightBoxModal .project-popup-nav.nav-left:hover, #galleryLightBoxModal .project-popup-nav.nav-left:focus-within {
  background-color: #F69322;
  opacity: 1;
}
#galleryLightBoxModal .project-popup-nav.nav-right {
  right: 0;
}
#galleryLightBoxModal .project-popup-nav.nav-left {
  left: 0;
}
#galleryLightBoxModal .project-popup-featured-image {
  background-image: var(--featured-image);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #EEE;
  position: relative;
  display: block;
  z-index: 0;
  flex: 1;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-featured-image {
    padding-bottom: 75%;
  }
}
#galleryLightBoxModal .project-popup-featured-image:hover .nav-right,
#galleryLightBoxModal .project-popup-featured-image:hover .nav-left, #galleryLightBoxModal .project-popup-featured-image:focus-within .nav-right,
#galleryLightBoxModal .project-popup-featured-image:focus-within .nav-left {
  opacity: 1;
}
#galleryLightBoxModal .project-popup-sidebar {
  width: 380px;
  flex-direction: column;
  padding: 18px 30px;
  max-height: 100%;
  display: flex;
  height: 100%;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-sidebar {
    padding: 8px 16px 16px;
    width: 100%;
  }
}
#galleryLightBoxModal .project-popup-sidebar::before, #galleryLightBoxModal .project-popup-sidebar::after {
  content: "";
  background-color: white;
  position: sticky;
  height: 48px;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-sidebar::before, #galleryLightBoxModal .project-popup-sidebar::after {
    display: none;
  }
}
#galleryLightBoxModal .project-popup-sidebar::before {
  margin-top: -18px;
  top: 0;
}
#galleryLightBoxModal .project-popup-sidebar::after {
  margin-bottom: -18px;
  margin-top: -30px;
  bottom: 0;
}
#galleryLightBoxModal .project-popup-sidebar p {
  line-height: 24px;
  font-size: 16px;
  color: #7A7A7A;
}
#galleryLightBoxModal .project-popup-sidebar-section {
  flex-direction: column;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #galleryLightBoxModal .project-popup-sidebar-section {
    margin-bottom: 0;
  }
  #galleryLightBoxModal .project-popup-sidebar-section + .project-popup-sidebar-section {
    margin-top: 24px;
  }
}
#galleryLightBoxModal .project-popup-thumbnails {
  pointer-events: none;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  margin: -5px;
}
#galleryLightBoxModal .project-popup-thumbnails li {
  background-image: url("/img/spinner.gif");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
  width: calc(33.333% - 10px);
  background-color: white;
  position: relative;
  margin: 5px;
}
#galleryLightBoxModal .project-popup-thumbnails li::after {
  content: "";
  padding-bottom: 100%;
  display: block;
  width: 100%;
}
#galleryLightBoxModal .project-popup-thumbnails img {
  transition: border-color 200ms ease-in-out;
  border: 2px solid #CCC;
  pointer-events: all;
  position: absolute;
  max-width: 100%;
  cursor: pointer;
  padding: 3px;
  height: 100%;
  width: 100%;
}
#galleryLightBoxModal .project-popup-thumbnails img[aria-selected] {
  border-color: #F69322;
}
#galleryLightBoxModal .project-popup-thumbnails img:hover, #galleryLightBoxModal .project-popup-thumbnails img:focus-within {
  border-color: #F69322;
}
#galleryLightBoxModal .project-popup-colors {
  pointer-events: none;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  margin: -8px;
}
#galleryLightBoxModal .project-popup-colors a {
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  flex-direction: column;
  pointer-events: all;
  align-items: center;
  text-align: center;
  position: relative;
  display: flex;
  padding: 8px;
  opacity: 1;
  width: 25%;
}
#galleryLightBoxModal .project-popup-colors a small {
  transition: color 200ms ease-in-out;
  letter-spacing: 0.25px;
  line-height: 12px;
  font-weight: 600;
  margin-top: 6px;
  font-size: 10px;
  color: #646464;
}
#galleryLightBoxModal .project-popup-colors a:hover, #galleryLightBoxModal .project-popup-colors a:focus-within {
  transform: translate3d(0, -8px, 0);
}
#galleryLightBoxModal .project-popup-colors a:hover small, #galleryLightBoxModal .project-popup-colors a:focus-within small {
  color: #F69322;
}
#galleryLightBoxModal .project-popup-colors img {
  max-width: 100%;
}
#galleryLightBoxModal .project-popup-colors:hover a:not(:hover):not(:focus-within), #galleryLightBoxModal .project-popup-colors:focus-within a:not(:hover):not(:focus-within) {
  opacity: 0.365;
}

#profileEditModal {
  --color-cerakote-gray: #3A3A39;
  --color-cerakote-orange: #F69322;
  --input-height: 42px;
  pointer-events: none;
  overflow: hidden;
}
#profileEditModal .modal-dialog {
  width: 660px;
}
#profileEditModal .modal-content {
  max-height: calc(100vh - 100px);
  background-color: white;
  pointer-events: all;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: none;
  color: #333;
  padding: 0;
  border: 0;
}
@media screen and (max-width: 600px) {
  #profileEditModal .modal-content {
    max-height: 100vh;
  }
}
#profileEditModal .modal-header {
  flex-direction: column;
  position: sticky;
  display: flex;
  z-index: 10;
  padding: 0;
  top: 0;
}
#profileEditModal .modal-header-content {
  background-color: var(--color-cerakote-gray);
  flex-direction: row;
  align-items: center;
  padding: 20px 24px;
  flex-wrap: nowrap;
  display: flex;
  z-index: 1;
}
#profileEditModal .modal-header-title {
  flex-direction: column;
  display: flex;
  flex: 1;
}
#profileEditModal .modal-header-title h2 {
  text-transform: uppercase;
  letter-spacing: 0.125px;
  font-weight: 700;
  font-size: 15px;
  color: #FFF;
  margin: 0;
}
#profileEditModal .modal-header-title small {
  margin-bottom: 0;
  margin-top: 4px;
  font-size: 12px;
  color: #BABABA;
}
#profileEditModal .modal-header-close {
  transition: color 200ms ease-in-out;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 0 8px;
  line-height: 1;
  display: flex;
  outline: none;
  color: #AAA;
  border: 0;
}
#profileEditModal .modal-header-close:hover, #profileEditModal .modal-header-close:focus-within {
  color: #FFF;
}
#profileEditModal .modal-body {
  padding: 0 24px 24px;
}
#profileEditModal .modal-footer {
  box-shadow: 0 -12px 16px 0px white;
  justify-content: space-between;
  border-top: 1px solid #EBEBEB;
  background-color: #FBFBFB;
  flex-direction: row;
  align-items: center;
  padding: 18px 24px;
  position: sticky;
  display: flex;
  z-index: 10;
  bottom: 0;
}
#profileEditModal .modal-footer-actions {
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  flex: 1;
}
#profileEditModal .modal-footer-save, #profileEditModal .modal-footer-cancel {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  height: 42px;
  border: 0;
}
@media screen and (max-width: 600px) {
  #profileEditModal .modal-footer-save, #profileEditModal .modal-footer-cancel {
    flex: 1;
  }
}
#profileEditModal .modal-footer-save[disabled], #profileEditModal .modal-footer-cancel[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
#profileEditModal .modal-footer-save .fa, #profileEditModal .modal-footer-cancel .fa {
  margin-right: 6px;
  font-size: 18px;
}
#profileEditModal .modal-footer-save {
  background-color: #F69322;
  margin-left: 16px;
  color: #FFF;
}
#profileEditModal input[type=file] {
  visibility: hidden;
  display: none;
}
#profileEditModal .my-account {
  flex-direction: column;
  flex-wrap: nowrap;
}
#profileEditModal .edit-profile {
  flex-direction: column;
  display: flex;
}
#profileEditModal .edit-profile-notification {
  transition: transform 200ms ease-in-out;
  transform: translate3d(0, -100%, 0);
  position: absolute;
  z-index: 0;
  top: 100%;
  right: 0;
  left: 0;
  flex: 1;
}
#profileEditModal .edit-profile-notification[aria-expanded] {
  transform: translate3d(0, 0, 0);
  position: sticky;
}
#profileEditModal .edit-profile-notification-warning, #profileEditModal .edit-profile-notification-success, #profileEditModal .edit-profile-notification-error {
  align-items: flex-start;
  flex-direction: row;
  padding: 12px 24px;
  font-weight: 500;
  line-height: 1;
  color: white;
  display: flex;
  height: auto;
}
#profileEditModal .edit-profile-notification-warning .fa, #profileEditModal .edit-profile-notification-success .fa, #profileEditModal .edit-profile-notification-error .fa {
  margin-right: 12px;
  font-size: 18px;
}
#profileEditModal .edit-profile-notification-warning {
  background-color: #F0A202;
}
#profileEditModal .edit-profile-notification-success {
  background-color: #7ECC58;
}
#profileEditModal .edit-profile-notification-error {
  background-color: #F9564F;
}
#profileEditModal .edit-profile-action {
  transition: background-color 200ms ease-in-out;
  box-shadow: 0 0 0 3px white;
  background-color: #3a3a39;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  margin-bottom: 0;
  font-size: 30px;
  cursor: pointer;
  line-height: 0;
  display: flex;
  outline: none;
  color: white;
  height: 42px;
  width: 42px;
  border: 0;
}
#profileEditModal .edit-profile-action i.fa {
  font-size: 16px;
}
#profileEditModal .edit-profile-action:hover, #profileEditModal .edit-profile-action:focus-within {
  background-color: #F69322;
}
#profileEditModal .edit-profile-banner {
  background-image: var(--profile-banner);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #7D7C7A;
  background-size: cover;
  position: relative;
  overflow: hidden;
  margin: 0 -24px;
}
#profileEditModal .edit-profile-banner::before {
  content: "";
  padding-bottom: 40%;
  display: block;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #profileEditModal .edit-profile-banner::before {
    padding-bottom: 72%;
  }
}
#profileEditModal .edit-profile-banner-actions {
  transform: translate3d(-50%, -50%, 0);
  flex-direction: row;
  position: absolute;
  display: flex;
  left: 50%;
  top: 50%;
}
#profileEditModal .edit-profile-banner-actions .edit-profile-action {
  margin: 0 8px;
}
#profileEditModal .edit-profile-nav {
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 16px;
  margin-right: -24px;
  margin-left: -24px;
  padding: 0 24px;
  display: flex;
}
#profileEditModal .edit-profile-nav > small {
  padding: 16px;
  flex: 1;
}
#profileEditModal .edit-profile-tabs {
  flex-direction: row;
  display: flex;
  flex: 1;
}
#profileEditModal .edit-profile-tabs button {
  border-bottom: 3px solid transparent;
  transition: color 200ms ease-in-out;
  background-color: transparent;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  font-weight: 700;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  display: flex;
  outline: none;
  height: 48px;
  color: #999;
  padding: 0;
}
#profileEditModal .edit-profile-tabs button .fa {
  margin-right: 8px;
  font-size: 16px;
}
#profileEditModal .edit-profile-tabs button:not([aria-selected]):hover, #profileEditModal .edit-profile-tabs button:not([aria-selected]):focus-within {
  color: #333;
}
#profileEditModal .edit-profile-tabs button[aria-selected] {
  border-bottom: 3px solid #F69322;
  color: #333;
}
#profileEditModal .edit-profile-avatar {
  background-image: var(--profile-avatar);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #7D7C7A;
  justify-content: flex-end;
  border: 5px solid white;
  background-size: cover;
  align-items: flex-end;
  border-radius: 50%;
  margin-top: -72px;
  display: flex;
  height: 120px;
  width: 120px;
  z-index: 2;
}

#galleryVideoModal {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  overflow: hidden;
}
#galleryVideoModal.in {
  display: flex !important;
}
#galleryVideoModal .modal-dialog {
  margin: 50px;
  flex: 1;
}
#galleryVideoModal .modal-content {
  background-color: white;
  pointer-events: all;
  box-shadow: none;
  color: #333;
  padding: 0;
  margin: 0;
  border: 0;
}
#galleryVideoModal .modal-header,
#galleryVideoModal .modal-footer {
  display: none;
}
#galleryVideoModal .modal-body {
  flex-direction: row;
  overflow-y: scroll;
  max-height: 80vh;
  min-height: 80vh;
  display: flex;
  z-index: 1;
  padding: 0;
  flex: 1;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .modal-body {
    flex-direction: column;
    max-height: 100vh;
    margin: 0;
  }
}
#galleryVideoModal .video-popup-content {
  flex-direction: column;
  margin: 0 0 48px 30px;
  position: sticky;
  display: flex;
  z-index: 2;
  flex: 1;
  top: 0;
}
#galleryVideoModal .video-popup-content h2 {
  font-size: calc(10px + 0.5vw);
  text-transform: uppercase;
  align-items: center;
  line-height: 48px;
  font-weight: 700;
  display: flex;
  color: #333;
  margin: 0;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-content {
    border-bottom: 16px solid white;
    background-color: white;
    margin: 0;
  }
  #galleryVideoModal .video-popup-content h2 {
    font-size: calc(11px + 0.5vw);
    padding: 16px 48px 16px 16px;
    line-height: 16px;
  }
}
#galleryVideoModal .video-popup-link {
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  line-height: 48px;
  font-size: 13px;
  display: flex;
  bottom: -48px;
  left: 0;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-link {
    display: none;
  }
}
#galleryVideoModal .video-popup-link .fa {
  margin-right: 5px;
}
#galleryVideoModal .video-popup-link a {
  padding-right: 16px;
}
#galleryVideoModal .video-popup-link a:hover, #galleryVideoModal .video-popup-link a:focus-within {
  text-decoration: underline;
}
#galleryVideoModal .video-popup-title {
  box-shadow: 0 -30px 0 0 white;
  border-top: 2px solid #CCC;
  text-transform: uppercase;
  background-color: white;
  line-height: 1.365;
  position: sticky;
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 15px;
  padding: 10px 0;
  color: #333;
  z-index: 1;
  top: 48px;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-title {
    font-size: calc(11px + 0.5vw);
    box-shadow: none;
  }
}
#galleryVideoModal .video-popup-nav {
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 18px;
  outline: none;
  display: flex;
  height: 48px;
  width: 48px;
  z-index: 3;
  border: 0;
}
#galleryVideoModal .video-popup-nav.nav-close {
  transition: color 200ms ease-in-out;
  background-color: transparent;
  right: -380px;
  font-size: 20px;
  top: 0;
}
#galleryVideoModal .video-popup-nav.nav-close:hover, #galleryVideoModal .video-popup-nav.nav-close:focus-within {
  color: #F69322;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-nav.nav-close {
    right: 0;
  }
}
#galleryVideoModal .video-popup-nav.nav-right, #galleryVideoModal .video-popup-nav.nav-left {
  transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;
  transform: translate3d(0, -50%, 0);
  background-color: #3A3A39;
  color: white;
  opacity: 0;
  top: 50%;
}
#galleryVideoModal .video-popup-nav.nav-right:hover, #galleryVideoModal .video-popup-nav.nav-right:focus-within, #galleryVideoModal .video-popup-nav.nav-left:hover, #galleryVideoModal .video-popup-nav.nav-left:focus-within {
  background-color: #F69322;
  opacity: 1;
}
#galleryVideoModal .video-popup-nav.nav-right {
  right: 0;
}
#galleryVideoModal .video-popup-nav.nav-left {
  left: 0;
}
#galleryVideoModal .video-popup-player {
  background-color: #EEE;
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 0;
  flex: 1;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-player {
    padding-bottom: 75%;
  }
}
#galleryVideoModal .video-popup-player-wrapper {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
#galleryVideoModal .video-popup-player-wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
#galleryVideoModal .video-popup-sidebar {
  width: 380px;
  flex-direction: column;
  padding: 18px 30px;
  max-height: 100%;
  display: flex;
  height: 100%;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-sidebar {
    padding: 8px 16px 16px;
    width: 100%;
  }
}
#galleryVideoModal .video-popup-sidebar::before, #galleryVideoModal .video-popup-sidebar::after {
  content: "";
  background-color: white;
  position: sticky;
  height: 48px;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-sidebar::before, #galleryVideoModal .video-popup-sidebar::after {
    display: none;
  }
}
#galleryVideoModal .video-popup-sidebar::before {
  margin-top: -18px;
  top: 0;
}
#galleryVideoModal .video-popup-sidebar::after {
  margin-bottom: -18px;
  margin-top: -30px;
  bottom: 0;
}
#galleryVideoModal .video-popup-sidebar-section {
  flex-direction: column;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #galleryVideoModal .video-popup-sidebar-section {
    margin-bottom: 0;
  }
  #galleryVideoModal .video-popup-sidebar-section + .video-popup-sidebar-section {
    margin-top: 24px;
  }
}
#galleryVideoModal .video-popup-thumbnails {
  pointer-events: none;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  margin: -5px;
}
#galleryVideoModal .video-popup-thumbnails li {
  background-image: url("/img/spinner.gif");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
  width: calc(50% - 10px);
  background-color: white;
  position: relative;
  margin: 5px;
}
#galleryVideoModal .video-popup-thumbnails img {
  transition: border-color 200ms ease-in-out;
  border: 2px solid #CCC;
  pointer-events: all;
  max-width: 100%;
  cursor: pointer;
  padding: 3px;
  height: auto;
  width: 100%;
}
#galleryVideoModal .video-popup-thumbnails img[aria-selected] {
  border-color: #F69322;
}
#galleryVideoModal .video-popup-thumbnails img:hover, #galleryVideoModal .video-popup-thumbnails img:focus-within {
  border-color: #F69322;
}
#galleryVideoModal .video-popup-colors {
  pointer-events: none;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  margin: -8px;
}
#galleryVideoModal .video-popup-colors a {
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  flex-direction: column;
  pointer-events: all;
  align-items: center;
  text-align: center;
  position: relative;
  display: flex;
  padding: 8px;
  opacity: 1;
  width: 25%;
}
#galleryVideoModal .video-popup-colors a small {
  transition: color 200ms ease-in-out;
  letter-spacing: 0.25px;
  line-height: 12px;
  font-weight: 600;
  margin-top: 6px;
  font-size: 10px;
  color: #646464;
}
#galleryVideoModal .video-popup-colors a:hover, #galleryVideoModal .video-popup-colors a:focus-within {
  transform: translate3d(0, -8px, 0);
}
#galleryVideoModal .video-popup-colors a:hover small, #galleryVideoModal .video-popup-colors a:focus-within small {
  color: #F69322;
}
#galleryVideoModal .video-popup-colors img {
  max-width: 100%;
}
#galleryVideoModal .video-popup-colors:hover a:not(:hover):not(:focus-within), #galleryVideoModal .video-popup-colors:focus-within a:not(:hover):not(:focus-within) {
  opacity: 0.365;
}