#galleryVideoModal {
  justify-content: center;
  pointer-events: none;
  align-items: center;
  overflow: hidden;

  &.in {
    display: flex !important;
  }

  .modal-dialog {
    margin: 50px;
    flex: 1;
  }

  .modal-content {
    background-color: white;
    pointer-events: all;
    box-shadow: none;
    color: #333;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .modal-header,
  .modal-footer {
    display: none;
  }

  .modal-body {
    flex-direction: row;
    overflow-y: scroll;
    max-height: 80vh;
    min-height: 80vh;
    display: flex;
    z-index: 1;
    padding: 0;
    flex: 1;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      max-height: 100vh;
      margin: 0;
    }

  }

  .video-popup {

    &-content {
      flex-direction: column;
      margin: 0 0 48px 30px;
      position: sticky;
      display: flex;
      z-index: 2;
      flex: 1;
      top: 0;

      h2 {
        font-size: calc(10px + 4 * (100vw / 800));
        text-transform: uppercase;
        align-items: center;
        line-height: 48px;
        font-weight: 700;
        display: flex;
        color: #333;
        margin: 0;
      }

      @media screen and (max-width: 600px) {
        border-bottom: 16px solid white;
        background-color: white;
        margin: 0;

        h2 {
          font-size: calc(11px + 4 * (100vw / 800));
          padding: 16px 48px 16px 16px;
          line-height: 16px;
        }

      }

    }

    &-link {
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      line-height: 48px;
      font-size: 13px;
      display: flex;
      bottom: -48px;
      left: 0;

      @media screen and (max-width: 600px) {
        display: none;
      }

      .fa {
        margin-right: 5px;
      }

      a {
        padding-right: 16px;

        &:hover,
        &:focus-within {
          text-decoration: underline;
        }

      }

    }

    &-title {
      box-shadow: 0 -30px 0 0 white;
      border-top: 2px solid #CCC;
      text-transform: uppercase;
      background-color: white;
      line-height: 1.365;
      position: sticky;
      margin: 0 0 10px;
      font-weight: 700;
      font-size: 15px;
      padding: 10px 0;
      color: #333;
      z-index: 1;
      top: 48px;

      @media screen and (max-width: 600px) {
        font-size: calc(11px + 4 * (100vw / 800));
        box-shadow: none;
      }

    }

    &-nav {
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 18px;
      outline: none;
      display: flex;
      height: 48px;
      width: 48px;
      z-index: 3;
      border: 0;

      &.nav-close {
        transition: color 200ms ease-in-out;
        background-color: transparent;
        right: -$popup-sidebar-width;
        font-size: 20px;
        top: 0;

        &:hover,
        &:focus-within {
          color: #F69322;
        }

        @media screen and (max-width: 600px) {
          right: 0;
        }

      }

      &.nav-right,
      &.nav-left {
        transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;
        transform: translate3d(0, -50%, 0);
        background-color: #3A3A39;
        color: white;
        opacity: 0;
        top: 50%;

        &:hover,
        &:focus-within {
          background-color: #F69322;
          opacity: 1;
        }

      }

      &.nav-right {
        right: 0;
      }

      &.nav-left {
        left: 0;
      }

    }

    &-player {
      background-color: #EEE;
      position: relative;
      overflow: hidden;
      display: block;
      z-index: 0;
      flex: 1;

      @media screen and (max-width: 600px) {
        padding-bottom: 75%;
      }

      &-wrapper {
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;

        iframe {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }

      }

    }

    &-sidebar {
      width: $popup-sidebar-width;
      flex-direction: column;
      padding: 18px 30px;
      max-height: 100%;
      display: flex;
      height: 100%;
      z-index: 1;

      @media screen and (max-width: 600px) {
        padding: 8px 16px 16px;
        width: 100%;
      }

      &::before,
      &::after {
        content: "";
        background-color: white;
        position: sticky;
        height: 48px;
        width: 100%;
        z-index: 2;

        @media screen and (max-width: 600px) {
          display: none;
        }

      }

      &::before {
        margin-top: -18px;
        top: 0;
      }

      &::after {
        margin-bottom: -18px;
        margin-top: -30px;
        bottom: 0;
      }

      &-section {
        flex-direction: column;
        margin-bottom: 30px;
        display: flex;
        width: 100%;

        @media screen and (max-width: 600px) {
          margin-bottom: 0;

          + .video-popup-sidebar-section {
            margin-top: 24px;
          }

        }

      }

    }

    &-thumbnails {
      pointer-events: none;
      flex-direction: row;
      list-style: none;
      flex-wrap: wrap;
      display: flex;
      margin: -5px;

      li {
        background-image: url("/img/spinner.gif");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100px;
        width: calc(50% - 10px);
        background-color: white;
        position: relative;
        margin: 5px;
      }

      img {
        transition: border-color 200ms ease-in-out;
        border: 2px solid #CCC;
        pointer-events: all;
        max-width: 100%;
        cursor: pointer;
        padding: 3px;
        height: auto;
        width: 100%;

        &[aria-selected] {
          border-color: #F69322;
        }

        &:hover,
        &:focus-within {
          border-color: #F69322;
        }

      }

    }

    &-colors {
      pointer-events: none;
      flex-direction: row;
      list-style: none;
      flex-wrap: wrap;
      display: flex;
      margin: -8px;

      a {
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        transform: translate3d(0, 0, 0);
        flex-direction: column;
        pointer-events: all;
        align-items: center;
        text-align: center;
        position: relative;
        display: flex;
        padding: 8px;
        opacity: 1;
        width: 25%;

        small {
          transition: color 200ms ease-in-out;
          letter-spacing: 0.25px;
          line-height: 12px;
          font-weight: 600;
          margin-top: 6px;
          font-size: 10px;
          color: #646464;
        }

        &:hover,
        &:focus-within {
          transform: translate3d(0, -8px, 0);

          small {
            color: #F69322;
          }

        }

      }

      img {
        max-width: 100%;
      }

      &:hover,
      &:focus-within {

        a:not(:hover):not(:focus-within) {
          opacity: 0.365;
        }

      }

    }

  }

}
