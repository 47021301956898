// ==========================================================
// Cloud style credit: https://codepen.io/btzr-io/pen/LWxZga
// ==========================================================

//error page styles

.error-container {
  width: inherit;
  font-family: 'Avenir-Next',Helvetica,sans-serif;
  color: #fff;
  z-index: 2;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  a:hover {
    color: #272727 !important;
  }

  h1 {
    font-size: 160px;
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
    text-shadow: #f69422 5px 5px;
  }

  h2 {
    margin-top: 0;
    max-width: 700px;
    font-size: 30px;
  }

  p {
    text-align: left;
    font-size: 18px;
    width: 100%;
    margin: 10px 0;
  }

  i {
    color: #f69422;
  }

  .btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
  }

  .btn.pill {
    border: 4px solid #fff;
    color: #fff;
  }

  .btn.pill:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #fff;
    z-index: -1;
    transition: 0.2s ease;
  }

  .btn.pill:hover {
    color: #272727;
    background: #fff;
    transition: 0.2s ease;
  }

  .btn.pill:hover:before {
    width: 100%;
  }

  .report-issue {
    color: #fff;
    text-decoration: underline;
  }

  .clouds {
    position: absolute;
    left: 17%;
    top: -15%;
    z-index: -1;

    .cloudy {
      transform: scale(-0.78, 0.78);
      display: inline-block;
      text-align: center;
      opacity: 0.2;
      position: relative;
    }

    .cloudy.front {
      left: -20%;
      transform: scale(0.78, 0.88);
      opacity: 1;
      position: relative;
      z-index: 1;
    }

    .cloudy.front .cloud, .cloudy.front .shadow {
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
    }

    .cloudy.right {
      right: 45%;
      transform: scale(-0.68, 0.68);
      opacity: 0.45;
    }

    .cloudy.right .cloud, .cloudy.right .shadow {
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
      animation-direction: alternate-reverse;
    }

    .shadow {
      background: rgba(0,0,0,0.35);
      height: 75px;
      width: 75px;
      border-radius: 50px;
      transform: translate(0, -35px) scale(1.35, 0.25);
      margin: auto;
      -webkit-animation-name: shadow-loop;
      animation-name: shadow-loop;
      -webkit-animation-duration: 0.7s;
      animation-duration: 0.7s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      animation-timing-function: ease;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
    }

    .cloud {
      position: relative;
      text-align: center;
      margin: auto;
      width: 150px;
      -webkit-animation-name: cloud-loop;
      animation-name: cloud-loop;
      -webkit-animation-duration: 0.7s;
      animation-duration: 0.7s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease;
      animation-timing-function: ease;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
    }

    .cloud .base {
      height: 50px;
      width: 150px;
      background: #f69422;
      border-radius: 50px;
      margin: 0;
      font-size: 0;
      line-height: 0;
    }

    .cloud .circle {
      display: inline-block;
      background: #f69422;
      height: 50px;
      width: 50px;
      border-radius: 50px;
      margin: 0;
      font-size: 0;
      line-height: 0;
      position: relative;
    }

    .cloud .circle.c1 {
      top: 28px;
      left: 15px;
    }

    .cloud .circle.c2 {
      width: 85px;
      height: 85px;
      top: 45px;
      left: -10px;
    }

    .rain {
      display: block;
      text-align: center;
      margin: auto;
      height: 75px;
      width: 80px;
      overflow: hidden;
    }

    .drop {
      display: inline-block;
      background: #f69422;
      height: 25px;
      width: 4px;
      margin: 5px;
      border-radius: 25px;
      opacity: 0.85;
      -webkit-animation-name: drop-fall;
      animation-name: drop-fall;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    .drop.fast {
      opacity: 0.75;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
    }

    .drop.faster {
      opacity: 0.5;
      -webkit-animation-duration: 0.35s;
      animation-duration: 0.35s;
    }

    .drop.slow {
      -webkit-animation-duration: 0.85s;
      animation-duration: 0.85s;
    }

    @-webkit-keyframes shadow-loop {
        0% {
          transform: translate(0, -35px) scale(1.15, 0.25);
        }
        100% {
          transform: translate(0, -35px) scale(1, 0.25);
        }
    }

    @keyframes shadow-loop {
        0% {
          transform: translate(0, -35px) scale(1.15, 0.25);
        }
        100% {
          transform: translate(0, -35px) scale(1, 0.25);
        }
    }

    @-webkit-keyframes cloud-loop {
        0% {
          transform: translate(0, 15px);
        }
        100% {
          transform: translate(0, 0);
        }
    }

    @keyframes cloud-loop {
        0% {
          transform: translate(0, 15px);
        }
        100% {
          transform: translate(0, 0);
        }
    }

    @-webkit-keyframes drop-fall {
        0% {
          transform: translate(0, -25px);
        }
        100% {
          transform: translate(0, 125px);
          opacity: 0;
        }
    }

    @keyframes drop-fall {
        0% {
          transform: translate(0, -25px);
        }
        100% {
          transform: translate(0, 125px);
          opacity: 0;
        }
    }
  }
}

@media only screen and (max-width: 501px) {
  .error-container {
    width: 100%;
    display: contents;

    .btn {
      background-color: white;
      width: auto;
    }

    .btn.pill {
      color: #444444;
      width: 90%;
    }

    .clouds {
      display: none;
    }

    h1 {
      font-size: 80px;
    }

    h2 {
      width: min-content;
    }

    p {
      font-size: 14px;
    }
  }
}