// ==========================================================
// 320 and Up by Phillip
// ==========================================================

/* Typography =================================================== */

// HEADINGS ====================================================
h1, h2, h3, h4, h5, h6, h9 {
    letter-spacing: calc(calc(-50 / 1000) * 1em);
    text-transform: uppercase;
    margin: 15px 0 5px 0;
    font-weight: 600;
	}

h1 { color: $lightBlack; }

h2, h9 {
    font-size: 2rem;
    > a {
		text-transform: none;
		font-size: 1.4rem;
		margin-left: 25px;
		}
	}

h3 {
    font-size: 2rem;
    text-transform: none;
	}

h4 {font-size: 1.6rem;}

h5 {text-transform: none;}

h6 {
	color: $darkenGrey;
	font-size: 1.2rem;
	}

h2 + h5 {margin-top: 0;}


// LINKS ====================================================
a {
    color: $linkColor;
    text-decoration: none;
    @include transition(all .3s ease);
    &:hover {
		text-decoration: none;
		outline: none;
		color: $linkColorHover !important;
		}


    &:active, &:focus {
        text-decoration: none;
        outline: none;
	}
}


// BUTTONS ====================================================
.btn-facebook,
.btn-google,
.btn-blue,
.btn-black,
.btn-grey,
.btn-orange,
.btn-purple,
.btn-light-green {
	font-weight: 600;
	color: $whiteText !important;
	padding: 10px 20px;
	@include transition(all .3s ease);
	}

.btn-blue,
.btn-black,
.btn-orange,
.btn-light-green {
	text-transform: uppercase;
	padding: 11px 20px 10px 20px;
	}

.btn-dark-grey {
  background: $darkenGrey;
  color: $whiteText !important;
}

.btn-light-green {
	background: $lightGreen;
	&:hover {
		background: $darkGreen;
		color: $whiteText !important;
	}
}

.btn-orange {
	background: $orange;
	color: $whiteText !important;
	&:hover {
		background: $orangeLight;
		color: $whiteText !important;
		}
	}

.btn-grey {
	background: $lightenGrey;
	color: $darkenBlack;
	&:hover {
		background: $darkGrey;
		color: $darkenBlack !important;
		}
	}

.btn-black {
	background: $lightBlack;
	&:hover {
		background: $black;
		color: $whiteText !important;
		}
	}

.btn-blue {
	background: $blue;
	&:hover {
		background: $darkenBlue;
		color: $whiteText !important;
		}
	}

.btn-purple {
  background: $purple;
  &:hover {
    background: $darkPurple;
    color: $whiteText !important;
    }
  }

.btn-facebook {
	background: $darkBlue;
	&:hover {
		background: $darkenBlue;
		color: $whiteText !important;
		}
	}

.btn-google {
	background: $darkOrange;
	&:hover {
		background: $orangeLight;
		color: $whiteText !important;
		}
	}

.wrap-btn {
	margin: 40px 0 0px 0;
	}

.wrap-btn .btn + .btn {
	margin-left: 10px;
	}

.btn-bordered {
	display: inline-block;
	padding: 10px 20px;
	@include transition(all .3s ease);
	color: $baseTextColor;
	border: 1px solid $darkBorderColor;
	border-radius: 0;
	font-size: 1.6rem;
	&:hover {border: 1px solid $orange;}
}

.btn-like-project {
	display: block;
	padding: 5px 20px 8px 20px;
	@include transition(all .3s ease);
	color: $white;
	text-transform: uppercase;
	font-size: 2.5rem;
	font-weight: bold;
	border-radius: 4px;
	background: $lightGreen;
	span {
		font-weight: normal;
		display: block;
		font-size: 1.5rem;
	}
	&:hover, &:active, &:focus {
		background: $darkGreen;
		color: #fff !important;
	}
}


// FORM FIELDS ====================================================

input[type="text"].form-control,
input[type="email"].form-control,
input[type="password"].form-control,
input[type="number"].form-control,
input[type="file"].form-control,
input[type="tel"].form-control,
select.form-control,
textarea.form-control {
    box-shadow: none;
    outline: none;
    border: 1px solid $borderColor;
	height: 38px;
	box-shadow: none;
	&:hover, &:active, &:focus {
		outline: none;
		box-shadow: none !important;
	}
	&#variantID {
		background-color: #fff;
		border-radius: 0;
	}
}

textarea.form-control {
	height: 125px;
	resize: none;

  &.commentSection {
    height: 80px;
  }
}

/* -- Select Field Customized -- */
.styled-select {
	position: relative;
	float: left;
	width: 100%;
	}

.styled-select {
  &::before {
    font: normal normal normal 14px/1 FontAwesome;
    pointer-events: none !important;
    align-items: center;
    position: absolute;
    color: #393939;
    display: flex;
    height: 100%;
    right: 15px;
    z-index: 1;
    top: 0;
  }
  &.icon-angle::before {
    content: '\f107';
  }
  &.icon-search::before {
    content: '\f002';
    color: #72859d;
  }
}

.truncated-text {
  --max-lines: 5;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
