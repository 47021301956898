// ==========================================================
// 320 and Up by Phillip Pradeep [ITH]
// ==========================================================

/* Mixins =================================================== */
@mixin text-overflow() { 
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap; 
    }

@mixin fullwidth() {
    box-sizing: border-box;
    float: left;
    width: 100%;
    }

@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
    }

@mixin list-nostyle() {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
}

// .bordered(COLOR, COLOR, COLOR, COLOR);
@mixin bordered($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
    border-top : solid 1px $top-color;
    border-left : solid 1px $left-color;
    border-right : solid 1px $right-color;
    border-bottom : solid 1px $bottom-color; 
    }

// @include rounded(VALUE);
@mixin rounded($radius:4px) {
    -webkit-border-radius : $radius;
    -moz-border-radius : $radius;
    border-radius : $radius; 
    }

// @include border-radius(VALUE,VALUE,VALUE,VALUE);
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
    -webkit-border-top-right-radius : $topright;
    -webkit-border-bottom-right-radius : $bottomright;
    -webkit-border-bottom-left-radius : $bottomleft;
    -webkit-border-top-left-radius : $topleft;
    -moz-border-radius-topright : $topright;
    -moz-border-radius-bottomright : $bottomright;
    -moz-border-radius-bottomleft : $bottomleft;
    -moz-border-radius-topleft : $topleft;
    border-top-right-radius : $topright;
    border-bottom-right-radius : $bottomright;
    border-bottom-left-radius : $bottomleft;
    border-top-left-radius : $topleft;
    -webkit-background-clip : padding-box;
    -moz-background-clip : padding;
    background-clip : padding-box; 
    }

// @include box-shadow(HORIZONTAL VERTICAL BLUR COLOR))
@mixin box-shadow($shadow: 0 1px 3px rgba(0,0,0,.3)) {
    -webkit-box-shadow : $shadow;
    -moz-box-shadow : $shadow;
    box-shadow : $shadow; 
    }

// .drop-shadow(HORIZONTAL, VERTICAL, BLUR, ALPHA);
@mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
    -webkit-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
    -moz-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
    box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha); 
    }

// @include opacity(VALUE);
@mixin opacity($opacity : .5) {
    -webkit-opacity : $opacity;
    -moz-opacity : $opacity;
    opacity : $opacity; 
    }

// .rotate(VALUEdeg);
@mixin rotate($deg) {
    -webkit-transform : rotate($deg);
    -moz-transform : rotate($deg);
    -ms-transform : rotate($deg);
    -o-transform : rotate($deg);
    transform : rotate($deg); 
    }

// .scale(VALUE);
@mixin scale($ratio) {
    -webkit-transform : scale($ratio);
    -moz-transform : scale($ratio);
    -ms-transform : scale($ratio);
    -o-transform : scale($ratio);
    transform : scale($ratio); 
    }

// .skew(VALUE, VALUE);
@mixin skew($x: 0, $y: 0) {
    -webkit-transform : skew($x, $y);
    -moz-transform : skew($x, $y);
    -ms-transform : skew($x, $y);
    -o-transform : skew($x, $y);
    transform : skew($x, $y); 
    }

// @include transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);
@mixin transition($transition) {
    -webkit-transition : $transition;
    -moz-transition : $transition;
    -ms-transition : $transition;
    -o-transition : $transition;
    transition : $transition; 
    }

// .translate(VALUE, VALUE)
@mixin translate($x: 0, $y: 0) {
    -webkit-transform : translate($x, $y);
    -moz-transform : translate($x, $y);
    -ms-transform : translate($x, $y);
    -o-transform : translate($x, $y);
    transform : translate($x, $y); 
    }

@mixin translate3d($x: 0, $y: 0, $z: 0) {
    -webkit-transform : translate($x, $y, $z);
    -moz-transform : translate($x, $y, $z);
    -ms-transform : translate($x, $y, $z);
    -o-transform : translate($x, $y, $z);
    transform : translate($x, $y, $z); 
    }

// 5. ELEMENTS 				==============================

// @include background-alpha(VALUE VALUE);
@mixin background-alpha($color: $white, $alpha: 1) {
    background-color : hsla(hue($color), saturation($color), lightness($color), $alpha); 
    }

// .background-size(VALUE VALUE);
@mixin background-size($size){
    -webkit-background-size : $size;
    -moz-background-size : $size;
    -o-background-size : $size;
    background-size : $size; 
    }

// .background-clip(VALUE); (border-box, padding-box, content-box)
@mixin background-clip($clip) {
    -webkit-background-clip : $clip;
    -moz-background-clip : $clip;
    background-clip : $clip; 
    }

// @include box-sizing(VALUE); (border-box, padding-box, content-box)
@mixin box-sizing($boxsize: border-box) {
    -webkit-box-sizing : $boxsize;
    -moz-box-sizing : $boxsize;
    -ms-box-sizing : $boxsize;
    box-sizing : $boxsize; 
    }

// Chrome Frame
@mixin chromeframe() {
margin : .2em 0;
background : $lightgrey;
color : $black;
padding : .2em 0; }

// Contain floats
@mixin clearfix {
    *zoom : 1;
    &:before,
    &:after {
        display: table;
        content : ""; 
        }
    &:after {
        clear : both; 
        } 
    }

// For image replacement
@mixin hide-text() {
    text-indent : 100%;
    white-space : nowrap;
    overflow : hidden; 
    }

// Hide from visual and speaking browsers
@mixin hidden() {
    display : none !important;
    visibility : hidden; 
    }

// Hide but maintain layout
@mixin invisible() {
    visibility : hidden; 
    }

// @include resize(VALUE) (none, both, horizontal, vertical, inherit)
@mixin resize($direction: both) {
    resize : $direction;
    overflow : auto; 
    }

// .userselect(VALUE) (all, element, none, text)
@mixin user-select($select) {
    -webkit-user-select : $select;
    -moz-user-select : $select;
    -o-user-select : $select;
    user-select : $select; 
    }

// Hidden but available to speaking browsers
@mixin visuallyhidden() {
    overflow : hidden;
    position : absolute;
    clip : rect(0 0 0 0);
    height : 1px;
    width : 1px;
    margin : -1px;
    padding : 0;
    border : 0;
    // Make visuallyhidden focusable with a keyboard
    &.focusable {
        &:active,
        &:focus {
            position : static;
            clip : auto;
            height : auto;
            width : auto;
            margin : 0;
            overflow: visible; 
            } 
        } 
    }

// CUSTOM 
$prefixes: ("-webkit-","-moz-", "");
@mixin selection() {
    @each $prefix in $prefixes {
        ::#{$prefix}selection {
            @content;
        }
    }
}
@mixin element-selection() {
    @each $prefix in $prefixes {
        &::#{$prefix}selection {
            @content;
        }
    }
}
@mixin placeholder() {
    &::-webkit-input-placeholder {@content }
    &:-moz-placeholder {@content }
    &::-moz-placeholder {@content }
    &:-ms-input-placeholder {@content }
}

@mixin prefix($name, $argument) {
    @each $prefix in $prefixes {
        #{$prefix}#{$name} : #{$argument};
    }
}
@mixin tooltip($left, $beforeLeft) {
  position: absolute;
  width: 130px;
  left: $left;
  top: 42px;
  color: $white;
  display: inline-block;
  font-size: 1.3rem;
  background-color: #555;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: $beforeLeft;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
}
