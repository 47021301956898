.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

.fade-enter-active {
  animation: fade 300ms;
}

.fade-leave-active {
  animation: fade 300ms reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.slide-fade-enter-active {
  animation: slide-fade 300ms;
}

.slide-fade-leave-active {
  animation: slide-fade 300ms reverse;
}

@keyframes slide-fade {
  0% {
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}


.list-item {
  transition: transform 300ms ease-in-out,
              opacity 300ms ease-in-out;
}

.list-enter,
.list-leave-to {
  transform: translate3d(0, 10px, 0);
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}


.list-fade-enter,
.list-fade-leave-to {
  opacity: 0;
}
