// ==========================================================
// 320 and Up by Phillip
// ==========================================================

// HEADER
header {
  position: sticky;
  z-index: 100;
  background: $white;
  @include box-shadow();
  width: 100%;
  top: 0;
}

/* Nav Main =============================================== */
header {
  .notificationMessage {
    height:0;
    background-color: #ffb500;

    &.showMessages {
      height:auto;
      padding: 5px;
      transition: all .3s ease;

      a {
        text-align: center;
        display: block;
        font-weight: bold;
        color: $darkBlack;
      }
      span {
        text-align: center;
        display: block;
        font-weight: bold;
        color: $darkBlack;
      }
    }
  }
  nav {
    background: #3a3a39 !important;
    border: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    min-height: inherit !important;
  }
  .navbar-collapse {
    padding: 0;
  }
  .navbar-nav.nav-main {
    text-align: center;
    width: 100%;
    height: 40px;
    border-top: 1px solid #cccccc;

    > li {
      float: none;
      display: inline-block;
      position: relative;

      &.sub-menu-dropdown {
        min-width: 175px;

        &:not(:first-child) {
          border-left: 1px solid #cccccc;
        }
      }

      > a {
        text-transform: uppercase;
        color: $white !important;
        font-family: $navItemFont;
        font-size: 2rem;
        padding: 10px 20px;
        position: relative;

        &:not(.sub-menu-text):hover,
        &:not(.sub-menu-text):active,
        &:not(.sub-menu-text):focus {
          color: $orange !important;
          background: transparent !important;

          &:before {
            content: '';
            position: absolute;
            top: 4px;
            bottom: 24px;
            height: 25px;
            width: 4px;
            -moz-transform: skew(-15deg, 0deg);
            -webkit-transform: skew(-15deg, 0deg);
            -o-transform: skew(-15deg, 0deg);
            -ms-transform: skew(-15deg, 0deg);
            transform: skew(-15deg, 0deg);
            background: $white;
          }

          &:before {
            left: 10px;
          }
        }

        &.sub-menu-text {
          color: $white !important;
          font-family: $openSans;
          font-size: 17px;
          font-weight: bold;
          line-height: 23px;
          text-align: center;
          text-transform: none;
          padding: 8px 18px;
        }
      }
    }

    > li.active > a.sub-menu-text {
      background-color: transparent;
      color: #F78F1B !important;
      box-shadow: inset 0px -4px 0 #F78F1B;
    }

    > li:not(.active) > a.sub-menu-text:hover {
      color: #F78F1B !important;
      box-shadow: inset 0px -4px 0 $white;
    }

    > li.active > a:not(.sub-menu-text) {
      color: #F78F1B !important;
      background-color: transparent;
    }

    > li:hover > a:not(.sub-menu-text) {
      color: $orange !important;
      background: transparent !important;

      &:before {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 24px;
        height: 25px;
        width: 4px;
        -moz-transform: skew(-15deg, 0deg);
        -webkit-transform: skew(-15deg, 0deg);
        -o-transform: skew(-15deg, 0deg);
        -ms-transform: skew(-15deg, 0deg);
        transform: skew(-15deg, 0deg);
        background: $white;
      }

      &:not(.sub-menu-text):before {
        left: 10px;
      }
    }

    .dropdown-menu {
      top: 98%;
      padding: 0px 0;
      margin: 0;
      text-align: center;
      border: 0;
      border-radius: 0;
      background: #3a3a39 !important;
      @include box-shadow(0 8px 16px 0px rgba(0,0,0,.2));

      > li {
        max-width: 425px;
        display: inline-block;
        position: static;
        vertical-align: top;
        font-size: 1.8rem;
        font-family: $openSans;
        font-weight: bold;
        text-transform: uppercase;
        padding: 20px 35px 5px;
        text-align: left;

        > a {
          position: static;
          vertical-align: top;
          font-size: 1.8rem;
          font-family: $openSans;
          font-weight: bold;
          text-transform: uppercase;
          display: block;
          text-align: right;
          padding: 0 0 15px 0;
          color: $white;

          &:hover {
            color: #f69422 !important;
          }

          > img {
            max-width: 100%;
          }

          > span {
            > div {
              float: right;
              font-size: 1.6rem;
              font-weight: normal;
              color: $lightBlack;
              text-transform: none;
            }
          }
        }

        > span {
          display: block;
          padding-bottom: 15px;

          > a {
            float: right;
            font-size: 1.6rem;
            font-weight: normal;
            color: $lightBlack;
            text-transform: none;
          }

          > div {
            float: right;
            font-size: 1.6rem;
            font-weight: normal;
            color: $lightBlack;
            text-transform: none;
          }
        }

        > input + span {
          margin-top: 20px;
          padding-bottom: 10px;
        }

        > input + span + a {
          text-transform: none;
          font-size: 1.6rem;
          text-align: left;
          font-weight: normal;
        }

        > ul {
          list-style: none;
          margin: 0;
          padding: 0;

          &#trendsetters {
            a {
              img {
                border-radius: 50%;
              }
            }
          }

          li {
            text-transform: none;
            font-family: $openSans;
            font-weight: normal;
            font-size: 1.6rem;
            padding-bottom: 6px;
            float: left;
            clear: both;
          }

          a {
            color: $lightBlack;
            display: block;

            img {
              float: left;
              margin: 0 8px 0 0;
              max-width: 30px;
            }

            strong {
              font-weight: normal;
              text-transform: uppercase;
              padding-top: 3px;
            }
          }
        }

        a:hover, a:active, a:focus {
          background: transparent;
          color: $orange;
          text-decoration: none;
        }
      }

      > li + li:before {
        content: '';
        position: absolute;
        width: 1px;
        background: $lightenGrey;
        top: 15px;
        height: calc(100% - 30px);
        bottom: 0;
        margin-left: -35px;
      }
    }
  }

  .phone-top {
    text-align: center;
    position: absolute;
    top: 4px;
    z-index: 11;
    width: 100%;
    font-style: italic;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.phone-top-mob {
  position: static;
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  font-style: italic;
}


/* Header Right Nav ======================================== */
header .navbar-right {
  padding-right: 5px;
  margin: 0;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  i.fa-shopping-cart, .profile-icon {
    font-size: 2rem;
    float: left;
    margin: 0 8px 0 0;
  }
  .profile-icon-small {
    height: 25px;
  }
  > li {
    position: relative !important;
    > a {
      @include fullwidth();
      font-family: $openSans !important;
      font-size: 1.3rem !important;
      padding: 25px 12px 12px 12px !important;
      color: $white !important;
      text-transform: uppercase;
      > span {
        float: left;
        margin-top: 1px;
      }
      &:hover {color: $orange !important;}
    }
    &:nth-of-type(2) {
      border-top: 0;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        background: $darkenGrey;
        right: 0;
        top: 20px;
        bottom: 20px;
        height: 30px;
      }
    }
    &:first-child {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        background: $darkenGrey;
        right: 0;
        top: 20px;
        bottom: 20px;
        height: 30px;
      }
    }
    &:hover > a {color: $orange !important;}
  }
  li.active a {
    color: $orange !important;
    background: transparent !important;
  }
  li:last-child > a {
    padding-left: 0 !important;
    img {margin: 4px 0;}
  }
  .dropdown-menu {
    border: 0;
    @include box-shadow(0 2px 8px rgba(0,0,0,.4));
    width: 300px;
    li {
      font-size: 1.6rem;
      display: block;
      margin: 0 15px;
      font-weight: bold;
      h2 {
        font-size: 1.6rem;
        font-weight: 600;
        padding: 10px 0 0 0;
        line-height: 130%;
        span {
          display: block;
          font-size: 1.3rem;
        }
      }
      h4 {
        font-size: 1.6rem;
        font-weight: 600;
        padding: 10px 0 0 0;
        line-height: 130%;
        span {
          display: block;
          font-size: 1.3rem;
        }
      }
      &:last-child {
        border-top: 0;
        background: $lightenGrey;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      a {
        padding: 8px 10px;
        font-weight: 600;
        &:hover, &:active, &:focus {
          background: transparent;
          color: $orange;
        }
      }

    }
    li + li {
      text-align: center;
      border-top: 1px solid $lightenGrey;
    }
    li:nth-of-type(2) {border-top: 0;}
    &:after, &:before {
      bottom: 100%;
      left: 85%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ddd;
      border-width: 10px;
      margin-left: -10px;
    }
    &:after {
      border-color: rgba(202, 202, 202, 0);
      border-bottom-color: #fff;
      border-width: 9px;
      margin-left: -9px;
    }
    .nav-select-country {
      padding: 0;
    }
  }
}


/* -- Nav Brand/Toggle Button CSS in Responsive -- */
header nav {
  .navbar-header {
    width: 100%;

    .search-main {
      text-align:left;
      margin-top: 13px;

      .search-box {
        width: 400px;

        .global-search {
          min-height: 45px;
        }

        input[type="text"] {
          width: calc(100% - 50px);
          height: 45px;
          font-size: 1.6rem;
        }

        .btn-search {
          width: 50px;
          height: 45px;
        }
      }
    }
  }
  .navbar-brand {
    height: auto;
    margin: 10px 20px;
    padding: 0;
    img {height: 50px;}
  }
  .navbar-toggle {
    border: 3px solid transparent;
    border-color: $white !important;
    border-radius: 0;
    color: $white;
    @include transition(all .3s ease);
    height: 40px;
    padding: 9px 6px;
    &:hover, &:active, &:focus {
      background-color: transparent !important;
    }
    span.icon-bar {
      @include transition(all .3s ease);
      position: relative;
      width: 26px;
      height: 3px
    }
  }
  .navbar-toggle.open-menu {
    span.icon-top {
      transform: rotate(45deg);
      top: 4px
    }
    span.icon-mid {display: none}
    span.icon-bottom {
      transform: rotate(-45deg);
      top: -3px
    }
  }
}

/* Nav Mobile ======================================== */
.mobileNotificationMessage {
  height:0;
  background-color: #ffb500;
  text-align: center;
  font-weight: bold;
  display: block;
  width: 100%;
  top: 0;
  z-index: 1039;

  &.showMessages {
    height: auto;
    padding: 5px;
    transition: all .3s ease;

    a {
      color: $darkBlack;
    }
  }
}
.mobile-head-wrapper {
  position: sticky;
  z-index: 1039;
  display: none;
  top: 0;
}
.mobile-head {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 25px;
  background: #3a3a39;
  @include box-shadow();
  position: relative;
  text-align: center;
  a img {
    display: inline-block;
    width: 128px;
  }
}

.link-cart-mob,
.nav-toggle-mob {
  float: right;
  font-size: 2.5rem;
  position: absolute;
  right: 30px;
  top: 16px;
  color: $white;
  &:hover, &:active, &:focus {color: $orange;}
}

.nav-toggle-mob {
  right: inherit;
  left: 30px;
  color: $white;
}

.mobile-nav {
  @include fullwidth();
  position: fixed;
  z-index: 1040;
  height: 100%;
  top: 0;
  left: -100%;
  @include transition(all .3s ease);
  display: none;
}

.mobile-nav-left {
  width: 75px;
  height: 100%;
  float: left;
  background: rgba(0,0,0,.7);
}

.mobile-nav-right {
  background: #3a3a39;
  width: 100%;
  margin-left: -75px;
  padding: 30px 40px;
  float: left;
  height: 100%;
  overflow-y: auto;
  position: relative;
  button + a {display: inline-block;
    img {
      width: 163px;
    }
  }
  > .mobile-menu {
    width: 100%;
    height: 90%;
    position: relative;

    .main-menu {
      margin-left: 0px;
      @include transition(all .3s ease);

      &.hide-main-menu {
        margin-left:-300px;
      }
    }
    .sub-menu {
      position: absolute;
      top: 0px;
      left: 1100px;
      @include transition(all .3s ease);

      &.show-sub-menu {
        left: 0px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {display: block;}
    a {
      width: 100%;
      text-transform: uppercase;
      color: $white;
      font-family: $leagueGothicRegular;
      font-size: 2.8rem;
      padding: 15px 0 15px 0;
      display: inline-block;
      line-height: 100%;
      letter-spacing: 1px;
      &:hover, &:active, &:focus {
        color: $orange;
      }
    }
  }
}


.mobile-nav-footer {
  @include fullwidth();
  border-top: 1px solid $borderColor;
  text-align: center;
  padding-top: 30px;
  > span {
    float: none;
    display: inline-block;
    margin-right: 0;
    height: auto;
    border-radius: 0;
    width: 64px;
    img {height: auto;}
  }
  h2 {
    display: block;
    color: $white;
    span {
      display: block;
      text-transform: none;
      font-size: 1.5rem;
      margin-top: 3px;
    }
  }
  a {
    margin-top: 10px;
    display: inline-block;
    img {width: 40px;}
  }
}

.close-mobile-nav {
  border: 0;
  background: transparent;
  color: $white;
  right: 30px;
  top: 15px;
  outline: none;
  font-size: 3rem;
  font-weight: 600;
  @include transition(all .3s ease);
  position: absolute;
  &:hover, &:active, &:focus {
    outline: none;
    color: $orange;
  }
}

header nav .link-dealer-mob {display: none}

.cart-count {
  display: inline-block;
  text-align: center;
  vertical-align: text-bottom;
  background-color: $darkenDarkBlack;
  color: $white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  border-radius: 30px;
  margin-left: 5px;
}
