// ==========================================================
// 320 and Up by Phillip
// ==========================================================

// Reset Media Breakpoints according to Design


@media (max-width: 1350px) {
  footer .sm-links a {padding: 0 10px;}
  footer .sm-links .form-group input {font-size: 1.3rem;width: 49%;}
  footer .sm-links .form-group button {padding: 8px; font-size: 1.3rem;width: 49%;}
  footer .copyright .col-lg-3 a img {
    width: 40px;
  }
  footer ul li {margin-right: 12px; font-size: 1.3rem;}
  .banner {height: auto;}
  /* -- Video -- */
  .wrap-video .col-sm-9 {padding-right: 15px;}
  #videoPlayerModal iframe {
    width: 95%;
  }

  .shop-container {
    padding: 0 20px;
  }

  .search-main {
    margin-bottom: 20px;
  }

  #filterOptions ul li li {
    padding: 8px 0px 8px 10px;
  }

  .shop-filter-bs-offset {
    width: calc(83% - 130px);
  }

  .main-container {

    .banner-cta {

      .banner-cta-content {
        width: 100%;

        .banner-cta-container {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  footer ul li a {padding-top: 0;}
  .product-box {width: 176px;}
  .thumbnail-box .info-thumbnail .col-sm-4 a, .info-profile .col-sm-3 a, .sidebar .shadow-box a {padding: 0;}
  .banner {height: auto;}
  .product-in h4 {font-size: 1.5rem;}
  .info-profile h2 a {float: none; display: block; margin: 5px 0 0 0;}
  .heading-bordered+.row .flex-wrap .product-box {width: 183px;}
  .strip-black {h2 {font-size: 1.5rem;} h3 {font-size: 1.3rem;} ul li {padding: 0 28px;}}
  .add-cart .col-sm-12 .col-md-4 {padding: 0 15px;}
  .add-cart .col-sm-12 .col-sm-4 {padding: 0 15px;}
  .slider.slider-horizontal {width: calc(100% - 130px);}
  .gallery-common .flex-wrap>.col-sm-4 .thumbnail-box {width: 293px; height: 293px; h3{font-size: 1.8rem; margin: 0 0 5px 0;} p{font-size: 1.2rem;}}
  .banner .item .carousel-caption .caption-inner {width: 52%;}
  .banner .item:after {width: 75%;}
  .cart-sidebar .btn-blue {font-size: 1.3rem; padding: 11px 10px 10px 10px;}
  .wrap-cart .cart-content {>.col-sm-6{padding-right: 30px;} >.col-sm-6+.col-sm-6 {padding-left: 30px;}}
  .token-wrap .token-applicator:nth-of-type(3n+1) {margin-left: 0;}
  .token-wrap .token-applicator:nth-of-type(1) {margin-left: 0;}
  /* -- Video -- */
  .wrap-video {.col-sm-9 {width: 65%;} .col-sm-3 {width: 35%;}}
  .latest-purchased .product-box+.product-box {max-width: 225px;}
  .coating-combo .container {
    width: 100%;
  }

  .info-product-left .resources ul li {width: 32%;}

  .wrap-gallery-details .info-product-right .product-box table td a h3,
  .wrap-creation-details .info-product-right .product-box table td a h3 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  .wrap-gallery-details .info-product-right .product-box table td a p,
  .wrap-creation-details .info-product-right .product-box table td a p {
    margin: 0;
    font-size: 1.1rem;
  }

  .wrap-gallery-details .info-product-right .product-box table td.seeGallery a,
  .wrap-creation-details .info-product-right .product-box table td.seeGallery a {
    margin-right: 0px;
    max-width: inherit;
  }

  .distributor-content h2 {font-size:28px}
  .distributor-content .col-md-6 + .col-md-6 h2 {font-size:28px}
  .distributor-content .distributor-box h3 {font-size:28px}
  #productCarousel .item iframe {
    width: 100%;
  }
  .wrap-video .videoList .col-xs-12 {
    min-height: 305px;
  }
  .wrap-video .videoList .col-xs-12 .vidTitle, .wrap-video .documentList .col-xs-12 .vidTitle {
    min-height: 20px;
  }
  .shop-filter-box li span {
    left: 10px;
  }

  .popular-searches .search-links:first-child { margin: 0 !important; }
  .popular-searches .search-links { font-size: 21px; }


  #vue-shop {
    .series-card .series-card-content .series-card-key-features {
      max-height: 352px;

      ul {
        height: 250px;

        li {
          font-size: 1.3rem;
        }
      }
    }
  }

  .lrg-series-img { display: none; }
  .med-series-img { display: block !important; }
  #vue-cerakote-vs #informationModal .modal-dialog,
  #vue-testing #informationModal .modal-dialog {width: 100% !important;}
}

@media (max-width: 1070px) {
  .full-screen {display: none !important;}
  .small-screen {display: block !important;}
  .mobile-shop-categories {margin: 11px 0 3px;}
  .mobile-shop-categories .form-control {background-color: white; font-size: 16px; height: 44px;}
  .mobile-shop-categories h5 {margin: 11px 2px;}
}

@media (max-width: 1030px) {
  .sidebar {
    margin-top: 20px;
    border-right: none;
    width: 100%;
  }

  .has-sub-menu .show-cart-popup {top: 66px;}
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 550px;
  }
  body > .vue-app > header {display: none;}
  .mobile-head-wrapper, .mobile-nav {display: block;}
  .hasMessages .show-cart-popup {top: 108px;}
  .desktop-only {display: none !important;}
  .mobile-only {display: block !important;}
  .sm-links .col-sm-6 {
    display: none;
  }

  .footlinks .form-group {
    margin-bottom: 0;
  }

  .desktop-only {display: none !important;}
  .mobile-only {display: block !important;}
  .toggle-shop-filters {display: inline-block; margin-top: 20px; width: 100%;}
  .toggle-shop-filters .filterBtn {display: block; background: $orange; padding: 11px 20px 10px 20px; font-weight: 600; font-size: 14px; color: white;}
  .toggle-shop-filters div.collapsed i.fa-plus {display: inline;}
  .toggle-shop-filters i.fa-plus {display: none;}
  .toggle-shop-filters i {display: inline;}
  .toggle-shop-filters div.collapsed i.fa-minus {display: none;}
  #filterVisibility.collapse.in {margin-top: 20px; display: block;}
  .shop-filter-bs-offset {
    width: 100%;
  }
  .sm-links .col-sm-6 {
    display: none;
  }

  .footlinks .form-group {
    margin-bottom: 0;
  }

  .main-container {

    .banner-cta {

      .banner-cta-content {
        padding: 0px;

        .banner-cta-container {
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: 1025px) {

  .applicator-map-overlay #applicator-map-sidebar-filters, .applicator-map-overlay #applicator-map-sidebar-results {
    filter: none !important;

    a {
      pointer-events: all;
    }
  }

  .main-container {
    h3 {
      font-size: initial;
    }
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .cart-checkout .accordion-head div span .best-rate-span {font-size: 1.4rem; letter-spacing: 0;}
}

@media (max-width: 992px) {
  .info-product-right .info-product-title {
    justify-content: flex-start;
    flex-direction: column;
    .btn-wishlist {
      margin-left: 0;
    }
  }
  footer .sm-links a {font-size: 2.0rem; padding: 0 10px 5px 10px;}
  footer .sm-links .form-group input {width: auto;}
  footer .sm-links .form-group button {padding: 8px 11px; width: auto;}
  .modal-header .close { text-shadow: none; opacity: 1; color: #FFFFFF; font-size: 15px; padding: 10px 0 0 10px; }
  footer ul li {margin-right: 11px;}
  .product-box {width: 168px;}
  .content-right .product-wrap .product-box {width: calc(33.333% - 20px);}
  .thumbnail-box {.wrap-img{height: 200px;} .info-thumbnail{padding: 10px 10px 55px 10px; .col-sm-4 {padding: 0;}}}
  .strip-black {padding: 20px 15px; h3 {font-weight: normal; font-size: 1.2rem;} h2 {font-size: 1.4rem; i {margin-right: 5px;}}}
  .search-box {width: 75%; margin-top: 25px;}
  .filter-box {overflow: hidden; margin-top: 5px;}
  .filter-box li:nth-child(1), .filter-box li:nth-child(2), .filter-box li:nth-child(3) {width: 33.33%;}
  .filter-box li:nth-child(4), .filter-box li:nth-child(5) {width: 50%; border-top: 1px solid $borderColor;}
  .filter-box li:nth-child(4) {border-left: 0;}
  .filter-box li:first-child>a, .filter-box li:last-child>a {border-radius: 0;}
  .mid-inner .breadcrumb {padding-bottom: 25px;}
  .strip-knowledge-base .col-sm-4 {padding: 0 20px;}
  .profile-top {margin-top: 5px; .block-map{clear: both;}}
  .info-profile{padding-right:15px; address {margin-bottom: 20px;}}
  .profile-top div.col-xs-12 {float:left !important; width:calc(100% - 30px); left:15px !important;}
  .profile-top div.col-md-4 {padding-top: 20px;}
  .modal .modal-dialog {width: 600px;}
  .my-account{.sidebar {padding-right: 15px;} .sidebar+.content-right{padding-left: 15px;} .form-group label {padding-right: 0;}}
  .my-account.addressSection {h5 { input {width: 100%; margin-top: 18px; margin-right: 0;}}}
  .heading-bordered+.row .flex-wrap .product-box {width: 168px;}
  .shop-filter-box li {width: 33.33% !important;}
  .shop-filter-box li:nth-child(6) {border-top: 1px solid $borderColor;}
  .strip-black ul li {padding: 0 9px;}
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 {padding-left: 0; .btn {padding: 11px 15px 10px 15px; font-size: 1.2rem;}}
  .wrap-details .info-product-left {width: 100%}
  .info-product-left .resources ul li a {min-height:75px}
  #productCarousel .item img:first-child { min-height: 250px; }
  .wrap-details .info-product-right {width: 100%; padding: 35px 15px 10px 20px;}
  #productCarousel {width: calc(100% - 100px);}

  .wrap-gallery-details,
  .wrap-gallery-details {
    height: auto
  }

  #galleryCarousel {
    width: calc(100% - 75px);
    margin-right: 0;
    height: 659px;
  }

  #creationCarousel {
    margin-right: 0;
    height: 659px;
    width: 100%;
  }

  .knowledge-base-tabs .nav-tabs li {width: 100%; margin-bottom:0px}

  .product-card .product-card-btn { display: contents; pointer-events: all; }
  .product-card .product-card-btn a { display: none; }
  .product-card .product-card-btn button { margin-top: 0; background-color: transparent; color: inherit !important; border: none; }

  .wrap-gallery-details .info-product-right,
  .wrap-creation-details .info-product-right {
    width: 100%;
    padding: 35px 0 10px 0;
  }

  .mixology-colors-used-mobile {display: block !important;}

  .gallery-common .flex-wrap {text-align: left; > .col-sm-4 {width: 375px; float: left; padding: 0 15px; margin-bottom: 20px; .thumbnail-box {width: 345px; height: 345px; h3{font-size: 2rem; margin: 5px 0 8px 0;} p{font-size: 1.4rem;}}}}
  .banner .item .carousel-caption {left: 0; right: 0; padding: 0 30px; width: 100%;}
  .banner .item .carousel-caption .caption-inner {width: 65%;}
  .banner .item .carousel-caption .caption-inner .promo-item-title {font-size: 1.5rem;}
  .banner .item:after {width: 85%;}
  .distributor-content h1 {margin-right: 0;}
  .distributor-content .col-md-6+.col-md-6 {border-left: 0; h1{margin-left: 0;}}
  .main-table.table {min-width: 460px;}
  .wrap-cart .col-md-9, .wrap-cart .col-md-3 {padding: 0 5px;}
  .wrap-cart .cart-content {padding: 20px 15px 15px 15px;}
  .wrap-cart .cart-content .table-responsive {overflow-x: hidden;}
  .wrap-cart .cart-content .table-responsive .mobileHide {display: none}
  .wrap-cart .cart-content {>.col-sm-6{padding: 0 15px 0 0;} >.col-sm-6+.col-sm-6 {padding: 0 0 0 15px;}}
  .wrap-cart .cart-content .remove-cart-item span {padding-left: 0;}
  .token-wrap .token-applicator {width: 230px;}

  .wrap-creation-details .info-product-right {
    width: 100%;
  }

  .info-product-right-mobile {display: block;}
  .heading-bordered.mobile-head-wrapper {display: block}
  .gallery-full .flex-wrap > .col-sm-4 .thumbnail-box .bookmark-project {
    visibility: visible;
    opacity: .6;
    background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
    background-size: 165px auto;
  }
  #galleryCarousel .item .item-in .bookmark-project,
  #creationCarousel .item .item-in .bookmark-project {
    visibility: visible;
    opacity: .6;
    background: url($imgDir + 'arrow-bookmark.svg') -108px 0 no-repeat;
    background-size: 165px auto;
  }
  .radius-wrap {
    display: block;
  }
  .coating-combo .container {
    width: 100%;
  }
  .coating-combo .coating-combo-in > div.col-md-2 {
    margin-top:25px;
  }
  .coating-combo .coating-combo-in > div span {cursor:inherit}
  #qualifiedApplication .form-group {min-height: 105px;}
  .cart-checkout .accordion-head.cc-option div {width: initial;}
  .distributor-content h2 {font-size:33px; margin: 5px 5px 0 5px;}
  .distributor-content .col-md-6 + .col-md-6 h2 {font-size:33px; margin: 5px 5px 0 5px;}
  .distributor-content .distributor-box h3 {font-size:33px}
  textarea.form-control.commentSection {height: 110px;}
  .shop-filter-box li span {
    left: 50px;
  }

  #vue-shop .series-card .series-card-content .series-card-key-features {
    min-height: 413px;

    ul {
      height: 304px;
    }
  }

  .med-series-img { display: none !important; }
  .sml-series-img { display: block !important; }

  .product-card-details {
    display: grid;
  }

  .wrap-knowledge-base {
    .knowledge-base {
      .nav-tabs li {
        border: 1px solid #ddd;
      }

      .knowledge-base-tabs {

        .tab-content {
          padding: 20px;

          .tab-pane {
            .article-list {
              width: auto;
            }
          }
        }

        .panel-group {
          .panel {

            .panel-collapse, .collapse {
              padding: 0;
            }
            .panel-heading {
              padding: inherit;
              margin-top: 25px;
            }
            .qa-question {
              .qa-count {
                float: none;
              }
              .qa-date {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .main-container {

    .thumbnail-height {
      display: block;
    }
  }

  .main-container {

    .banner-cta {
      top: 150px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 760px;
  }
}

@media (max-width: 767px) {

  .news-box img {
    margin: -30px -30px 30px -30px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    max-width: calc(100% + 60px);
    width: calc(100% + 60px);
    padding: 0;
  }

  #vue-shop .series-card .series-card-header {
    margin-top: -30px;
  }
  #vue-shop .series-card .series-card-header,
  #vue-shop .series-card .series-card-content {
    margin-right: -15px;
    margin-left: -15px;
  }
  .creation-full .product-wrap .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {
    padding-bottom: 55px !important;
  }
  .creation-full .product-wrap .flex-wrap > .col-sm-3 {
    width: 90%;
  }
  .mid-inner.applicator .map-how-to {
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
  }
  .mid-inner.applicator .map-how-to .btn-orange {
    margin-left: 15px;
  }
  h2>a {display: block; margin: 5px 0 0 0;}
  .show-cart-popup {top:65px; overflow:scroll; display:block; height:100%; padding-top: 40px; padding-bottom: 160px}
  .news-box {padding-right: 15px;}
  .news-box .news-wrap {margin: 0;}
  .news-box .news-image { margin-bottom: 30px; }
  .news-box .news-content { padding-left: 30px !important; }
  .product-box {width: 31.2%; margin-right: 2%;}
  .creation-product-box {width: 168px;margin-right: 15px;}
  .thumbnail-box .wrap-img {height: auto;}
  .creation-full .flex-wrap > .col-sm-3 .creation-thumbnail-box .info-thumbnail {padding: 10px 8px 10px 8px !important;}
  .flex-wrap > .col-sm-4 {width: 100%; margin-bottom: 25px;}
  .thumbnail-box .info-thumbnail {padding: 10px 8px 10px 8px; .col-sm-4 {width: 33.33333333%; float: left; padding: 0 10px;} .row {position: static; width: 100%; margin: 15px 0 0 0; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-flex-direction: row; flex-direction: row; }}
  .search-box {width: 100%;}
  .side-box ul li input[type="checkbox"] {height: 20px; width: 20px;}
  .side-box ul li li {padding: 10px 10px 10px 10px; border-top: 1px solid rgba(151, 151, 151, 0.22) !important;}
  .side-box ul li #mGlossLevel {margin-top: 10px;}
  .modal h2 {font-size: 2rem;}
  .modal a.close.close-link {color: #1788e4; opacity: .6; font-size: 16px; line-height: 28px;}
  .modal a.close.close-link:hover {opacity: 1}
  .banner .item .carousel-caption {padding: 0 15px;}
  .banner .item .carousel-caption.center {width:100%; margin-left: -15px;}
  .banner .item .carousel-caption .caption-inner.center {padding: 0px 25px 25px;}
  .banner .item .carousel-caption .caption-inner .promo-item-title {font-size: 1.3rem;}
  .banner .item.multiSale img {object-position: 0 0; transition: all 5s ease;}
  .banner .item.multiSale.active img {object-position: 100% 0;}
  .carousel-caption .caption-inner .captionBtn {display:none}
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {display:block; margin-top: 10px;}
  .strip-black {padding: 0; h3 {margin: 1px 0 2px 0;}}
  .strip-black ul li:first-child {display: none;}
  .strip-black ul li {text-align: center !important; width: 50%; padding: 15px 13px !important; box-sizing: border-box; float: left;}
  .strip-black ul li:nth-child(2), .strip-black li:nth-child(3) {border-bottom: 1px solid #666;}
  .strip-black ul li:nth-child(2), .strip-black li:nth-child(4) {border-right: 1px solid #666;}
  .filter-options a {margin: 10px; span{font-size: 1.3rem;}}
  .filter-box li a {padding: 8px 5px;}
  .filtered-right > .col-sm-6 {width: 33.33333333%; padding: 0 8px;}
  .product-in h4 {font-size: 1.4rem;}
  .filtered-right {margin-left: -7px; margin-right: -7px;}
  .product-in {margin-top: 16px;}
  .strip-knowledge-base .row {display: block;}
  .strip-knowledge-base {padding: 35px 0; .col-sm-4+.col-sm-4 {border-left: 0; border-top: 1px solid $borderColor; margin-top: 25px; padding-top: 25px;h2 {font-size: 2.2rem; margin: 10px 0;}}}
  .wrap-hero .hero-large {height: 350px; .container {input[type="text"]{height: 50px;} h1{font-size: 3rem;}}}
  .knowledge-base-bottom {padding: 45px 0 35px 0; h1{font-size: 3rem;} .col-sm-4{padding: 0 15px;} .col-sm-12.text-center{padding: 0;}}
  .location-wrap {width: 100%; margin-top: 15px; .col-sm-12 h2{margin-bottom: 25px;} .col-sm-3+.col-sm-3, .col-sm-3 {border-left: 0;}}
  .location-wrap .info-block {width: 100%;}
  .info-profile .col-sm-3 { padding: 0 8px; a span { font-size: 65%; } }
  .modal {
    background-color: #fff;
    padding-right: 0 !important;

    .modal-dialog {
      width: 100% !important;
      margin: 0px !important;
    }

    button.close {
      left: 0px !important;
      top: -5px !important;
    }

    .modal-content {
      padding: 10px 5px 10px 5px;
      height: 100vh !important;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  #notificationPopup.modal .modal-dialog {
    width: auto !important;
    margin: 100px 50px !important;
  }
  .my-account {display: block; padding-top:0; padding-bottom: 0; .form-group > div {padding-left: 15px;} .form-group {.col-sm-5, .col-sm-7, .col-sm-8 {margin-bottom: 15px;}}}
  .my-account .form-group label {padding-top: 0; font-weight: 600; margin-bottom: 3px;}
  .project-reviews .col-md-2 {padding-right: 0px}
  .project-reviews .col-md-10 > div {padding-left:30px}
  .wrap-accordion h5.accordion-label {width: 100%; margin: 0 0 10px 0;}
  .accordion-in {width: 100%; .shadow-box .col-sm-12{clear: both;}}
  // .heading-account h5 a {float: none; display: block; margin-top: 10px;}
  .heading-bordered+.row .flex-wrap .product-box {width: 31.2%; margin-right: 2%;}
  .heading-bordered h2 {margin-top: 0;}
  #login {background-color: #3a3a39; padding-right: 0 !important; .modal-dialog {width: 100% !important; margin: 0px !important; height: 100% !important}}
  #login {.modal-content {padding: 30px 20px 40px 20px; height:100%;border: none;-webkit-box-shadow: none;box-shadow: none;} button.close {left: 30px;}}
  .coating-combo .coating-combo-in {display: block; padding-bottom: 25px; > div {display: block; width: 100%; text-align: center; .flex-wrap > div {margin-bottom: 0px !important;} span {margin-bottom: 25px;}}}
  .coating-combo .coating-combo-in .col-sm-8 + .col-sm-2 {padding-left: 15px; .btn {padding: 11px 20px 10px 20px; font-size: 1.4rem;}}
  .thumbnail-gallery:hover {transform: perspective(644px) rotateY(1deg);}
  .wrap-gallery {clear: both;}
  .add-cart .col-sm-12 .col-xs-4 {margin: 10px 0 10px 0;}
  .add-cart {.form-group > .row > div {margin-bottom: 20px;} .product-total{margin-top: 0;}}
  .add-cart .form-group > .row > div.col-xs-4 {width: 34%}
  .add-cart .form-group > .row > div.col-xs-8 {width: 66%}
  .cart-checkout a.shadow-box + .row {float: left; width: 100%;}
  .cart-checkout .selected-address-name {padding-top: 10px;}
  .cart-checkout .selected-address-name h3 {display: none;}
  .cart-checkout .selected-address-info h3 {display: none;}
  .cart-checkout .selected-address {padding-top: 10px;}
  .cart-checkout .selected-payment-type h3 {display: none;}
  .cart-checkout .selected-payment-info h3 {display: none;}
  .cart-checkout .radio-container {padding-right: 10px; width: 8.33333333%;}
  .cart-checkout .accordion-head {padding: 8px 0;}
  .cart-checkout .accordion-head .radio-select {padding-top: 0;}
  .cart-checkout .accordion-head div img {margin-left: 10px; margin-top: 0;}
  .cart-checkout .accordion-head div span {margin-top: 0;}
  .cart-checkout .accordion-head.cc-option .radio-select {padding-top: 10px;}
  .cart-checkout .accordion-head.cc-option div img {margin-top: 9px;}
  .cart-checkout .accordion-head.cc-option div span {width: 65%;}
  .cart-checkout a.shadow-box + .row .col-sm-12 {padding: 15px 14px;}
  .cart-checkout .order-container {width: auto;}
  #productCarousel, #galleryCarousel, #creationCarousel, .resources, .share {width: 100%;}
  #productCarousel {height:auto;}
  .resources ul {@include flexbox();}
  .info-product-left .resources ul li {width: 33.333333333333337% !important; float: left; border-bottom: 0;}
  .info-product-left .resources ul li a {min-height:75px}
  .mid-inner .breadcrumb {font-size: 1.4rem; padding-bottom: 15px; a.btn-print {display: none;}}
  .knowledge-base-tabs .nav-tabs li {font-size: 2rem;}
  .block-frequently .thumb-view-gallery, .block-frequently .product-in {width: 31.2%;}

  .wrap-gallery-details,
  .wrap-gallery-details {
    min-height:auto;
    height: auto;
    max-height: inherit;
  }

  .share { display: none; }
  .gallery-common .flex-wrap {text-align: center; >.col-sm-4 {float: none;}}
  .banner .item .carousel-caption .caption-inner {width: calc(100% - 15px); padding: 25px 10px; background-color: unset; h1{font-size: 2.6rem; margin: 0 0 3px 0;} p{font-size: 1.2rem; margin-bottom: 10px; padding: 0;} a.btn{border-width: 1px; padding: 6px 15px 5px 15px; font-size: 1.2rem;}}
  .banner .item:after {width: 100%; background: rgba($black, .75);}
  .banner .item.no-overlay:after { display: none; }
  .distributor-content {h1 {float: left; width: 100%; font-size: 2.8rem;} .distributor-box .col-sm-8{text-align: center;}}
  .distributor-top {font-size: 3rem;}
  .distributor-details {padding: 20px 15px;}

  .mobile-toggle-map {
    flex-direction: row;
    align-items: center;
    display: flex;
    margin: 0 5px;
    > input {
      margin-right: 5px;
    }
  }

  .slider.slider-horizontal {width: calc(100% - 176px);}
  .cart-sidebar {margin-top: 25px; .btn-blue {font-size: 1.4rem;}}
  .wrap-cart {margin-top: 0; > div {width: 100%; .cart-content, .cart-content { > .col-sm-6 {width: 100%; border: 0 !important; padding: 0 !important;} >.col-sm-6+.col-sm-6{margin-top: 25px;}}}}
  .address-form .styled-select {display: block; float: none;}
  .styled-select { margin-bottom: 8px; }
  .location-wrap .col-sm-3 {width: 100%;}
  .content-right .product-wrap .product-box {width: calc(50% - 20px);}
  .content-right .product-wrap .product-box.swatch-box {margin-left: 0; margin-right: 2%;}
  .coating-combo .coating-combo-in {.col-sm-2, .col-sm-8 {width: 100%;} .product-box{margin: 0px 10px 10px 10px; width: 168px;} .col-sm-8{padding-right: 0; margin-top: 0;}}
  .token-wrap .token-applicator {width: 100%; margin-left: 0 !important;}
  .token-wrap .heading-applicator {margin: 0 0 20px 0;}
  .sticky-wrap {padding: 20px 15px; .btn {position: static; float: right; padding: 12px 25px; font-size: 1.4rem;}}
  /* -- Video -- */
  .wrap-video {.col-sm-9, .col-sm-3 {width: 100%; border: 0; padding: 0;} .col-sm-3 {margin-top: 55px;}}
  .radius-wrap {display: block;}
  .mid-inner.applicator {padding-bottom: 0;}
  .latest-purchased .flex-wrap {width:100%}
  .latest-purchased .product-box+.product-box {max-width: 300px;}
  .latest-purchased .btn-right {width: 100px;}
  .latest-purchased .btn-right a {padding: 4px 15px; margin-top: 18px;}

  #galleryCarousel,
  #creationCarousel {
    margin-left: 0px;
    max-height: 659px;
  }

  .share {position: relative;height: auto;}

  #galleryCarousel .item .item-in .fa-search-plus,
  #creationCarousel .item .item-in .fa-search-plus {
    display: none;
  }

  td, th {padding:0 5px; display: block;}
  .orders .shadow-box table .discountAmount {padding: 5px 5px 5px;}
  .hidePrint {display: none;}

  .filter-options div {width:55px}
  #qualifiedApplication .form-group {min-height: 93px;}
  .modal .modal-header {padding: 10px 15px 0 15px;}
  .modal-header .close { text-shadow: none; opacity: 1; color: #FFFFFF; font-size: 15px; padding: 10px 0 0 10px; }
  .login-signup .modal-body h2, .login-signup .modal-body h3 {width: 100%; margin-top: 15px; text-align: center; }
  #login .modal-body .btns-sign-in .btn + .btn {margin-top: 0px;}
  .login-signup .divider-sign-in {margin: 2px 0 10px 0;}
  .modal .form-group {margin-bottom: 10px;}
  .login-signup .modal-body p {margin: 0 10px;}
  .styled-checkbox {margin-bottom: 0;}
  .styled-checkbox input[type="checkbox"] + label:before, .styled-checkbox input[type="checkbox"]:not(.filled-in) + label:after {width:25px; height:25px;}
  .styled-checkbox input[type="checkbox"]:checked + label:after {top: 3px;left: 1px;width: 10px;height: 16px;}
  .styled-checkbox input[type="checkbox"] + label {padding-left: 30px;padding-top: 2px;}
  #login .modal-body .btns-sign-in {display:none}
  #login .modal-body .btns-sign-in.mobile-sign-in {display:block}
  #login .modal-body .btns-sign-in.mobile-sign-in i {font-size: 2.2rem;}
  .distributor-content h2 {font-size:28px}
  .distributor-content .col-md-6 + .col-md-6 h2 {font-size:28px}
  .distributor-content .distributor-box h3 {font-size:28px}
  #productCarousel .item iframe {
    width: 100%;
    height: 234px;
  }
  textarea.form-control.commentSection {height: 90px;}
  .review-form .row .col-md-6 {
    float: left;
    width: 100%;
  }
  .review-form .row .col-md-6:first-child {
    margin: 0 auto 15px;
  }
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 420px;
    margin-bottom: 0px;
  }
  #videoPlayerModal .modal-body .save-video {
    left: 10px !important;
    top: -50px !important;
    display: block;
  }
  #videoPlayerModal .modal-body button.close.close-popup {
    left: 10px !important;
    top: -50px !important;
    display: block;
  }
  .boxes-documents a.mobileLink {
    display: inline-block;
    box-shadow: none !important;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .boxes-documents a.mobileLink .img-responsive {
    display: inline-block;
    height: 100px;
  }
  .boxes-documents a.desktopLink {
    display: none;
  }
  .boxes-documents .open .col-md-2 {
    margin-bottom: 12px !important;
    min-height: auto !important;
  }
  .boxes-documents .open .col-md-2 .favorite-doc {
    visibility: visible;
    opacity: .6;
    background: url(/images/arrow-bookmark.svg) -108px 0 no-repeat;
    background-size: 165px auto;
  }
  .content-page .article-body table {
    width:100% !important;
  }
  .content-page .article-body table td {
    width:100% !important;
    display: block;
  }
  .search-container .wrap-video > div {
    padding: 0 15px;
  }
  .search-container .wrap-video .videoList .col-xs-12 {
    margin-bottom: 40px
  }

  .headerBody {
    text-align: left;
  }

  .knowledge-base-tabs .qa-info-q h3 {
    display: block;

    + a {
      float: left;
      margin-top: 0;

      + hr {
        margin: 25px 0 0;
      }
    }
  }

  footer .sm-links > .row > div {
    justify-content: space-evenly;
    display: flex;
    > a {
      font-size: 2.5rem;
      padding: 0 15px;
    }
  }

  footer .footlinks .form-group input {
    margin: 15px auto 5px;
  }

  footer .footlinks .col-lg-3 {
    text-align:center;
  }

  footer .footlinks .col-lg-3 h3 {
    text-align:center;
  }

  footer .copyright .col-lg-3 {
    text-align:center;
  }

  footer .footlinks .col-lg-3 .tablet-only {
    display: none;
  }

  footer .footlinks .col-lg-3.mobile-only img {
    margin: auto;
    display: block;
  }

  #vue-shop .popular-searches {
    .row > .row {
      margin-right: 0;
      margin-left: 0;
      .search-links {
        &:nth-child(1) {
          padding-right: 7.5px;
          padding-left: 15px;
        }
        &:nth-child(2) {
          padding-right: 7.5px;
          padding-left: 7.5px;
        }
        &:nth-child(3) {
          padding-left: 7.5px;
          padding-right: 15px;
        }
      }
    }
  }

  #vue-shop .series-card {
    margin: 25px 0 0 0;
    padding-bottom: 30px;

    .series-card-content {
      > .row {
        flex-direction: column;
        display: flex;
      }
      .series-card-image {
        margin-bottom: 0;
        overflow: hidden;
        height: 100px;

        img {
          height: 100px;
          overflow: hidden;
          margin-left: -368px;
          position: absolute;
          left: 50%;
        }
      }

      .series-card-key-features {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-bottom: -30px;
        min-height: auto;
        max-height: none;
        padding: 30px;

        h2 {
          margin-bottom: 15px;
        }

        ul {
          height: auto;

          li {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .sml-series-img { display: none !important; }
  .mbl-series-img { display: block !important; }

  .main-container {
    .shipping-block {

      .row-height {
        display: block;
      }
    }
  }

  #slider-thumbs ul { grid-template-columns: auto auto auto auto; }

  .main-container {

    .banner-cta {
      top: 120px;
    }
  }

  .wrap-cart {
    display: inherit;

    .cart-items, .cart-summary {
      display: inherit;
      flex-direction: unset;
    }
  }
}

@media (max-width: 650px) {
  .add-to-cart .col-sm-6 {width:100%}
  .add-to-cart .col-sm-6 + .col-sm-6 {
    border-left: none;
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    margin-top: 10px;
  }

  .main-table.table {min-width: 260px;}

  .main-container {

    .banner-cta {

      .banner-cta-content {

        .banner-cta-container {
          box-shadow: none;
        }
      }
    }

    .jumbotron {
      padding-top: 20px;
    }

    .distributor-hero {
      display: none;
    }

    .img-banner {
      height: 550px;
      background-color: #3a3a39;

      img {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  textarea.form-control.commentSection {height: 110px;}
  .banner .item .carousel-caption.center {height:100%; margin: 0;}
  .banner .item .carousel-caption .caption-inner.center {padding: 15px 5px; height: 100%;}
  .carousel-caption .caption-inner .captionBtn {display:none}
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {display:block; width: 50%; padding: 5px 60px 5px 5px;}
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn br {display:none;}
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn span {width:100%; text-align:right; display: inline-block; vertical-align: text-top; line-height: 11px; font-size: 1.4rem;}
  .banner .item .carousel-caption .caption-inner p {font-size: 1.1rem;}
  .banner .item .carousel-caption .caption-inner a.btn {float: right;}

  .creation-custom-panel,
  .creation-all-colors {
    width: 100%;
    margin-bottom: 10px;
  }

  .main-container {

    .img-banner {
      height: 700px;
    }
  }
}

@media (max-width: 510px) {
  .cookiesDisabled {height: 130px;}
  .cookiesDisabled h9 {font-size: 1.7rem}
  #vue-videos .wrap-hero .videoPlayer.open {
    height: 280px;
  }

  .product-cards {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 3%;
  }

  .marketing-banner {
    display: none;
  }

  .products-filtered {
    .sidebar {
      margin: 20px 0 0 0;
      width: auto;
      border-radius: 0;
    }
  }

  .products-filtered {
    display: inline-block;
  }

  .orders .shadow-box table th {
    display: none;
  }

  .orders .order-history-details-btn {
    float: none;
  }

  .orders .row {
    display: inline-block;
  }

  .latest-purchased {
    h2 {
      margin: 0;
    }

    .order-history-container {
      padding: 0;
    }

    .order-history-item-details {
      h3 {
        font-size: 14px;
      }
      p {
        margin: 5px 0 0 0;
      }
    }

    .order-history-product-arrow {
      font-size: 10px;
    }
  }
}

@media (max-width: 479px) {
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {padding-right:30px}
  .shadow-box {
    h3 + p {
      margin-top: 10px;
    }
    &.product-box {
      padding: 15px 15px 0 15px !important;
      flex-direction: column;
      display: flex;
      img {
        margin: 0 0 15px 0;
      }
      > a {
        flex: 1;
      }
      > .product-actions {
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 15px;
        flex: 0 1 auto;
        display: flex;
        > div {
          flex: 0 1 auto;
        }
        a {
          &:last-child {
            position: relative;
            padding: 10px 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  #vue-shop {
    .popular-searches,
    .series-card {
      .shadow-box {
        padding: 30px;
      }
    }
  }

  .product-wrap {
    h2 {
      justify-content: space-between;
      font-size: 1.75rem;
      display: flex;
    }
  }
  .gallery-full .flex-wrap > .col-sm-4 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 15px;
    width: 100%;
    .thumbnail-box {
      margin-top: 12px;
      height: auto;
      width: 100%;
    }
  }
  .mid-inner .breadcrumb {display:none}
  .close-mobile-nav {right: 10px;}
  .link-cart-mob {right: 15px;}
  .nav-toggle-mob {left: 15px;}
  .mobile-nav-left {width: 55px;}
  .mobile-nav-right {width: 100%; padding: 25px; margin-left: -55px; li a {font-size: 2.5rem; padding: 20px 0 0 0;}}
  .mobile-nav-footer h2 {font-size: 1.8rem; span {font-size: 1.3rem;}}
  footer .sm-links a {font-size: 2.2rem; padding: 0 7px;}
  footer .sm-links .form-group input {font-size: 1.3rem;width: 49%;}
  footer .sm-links .form-group button {padding: 8px; font-size: 1.3rem;width: 49%;}
  .product-box, .heading-bordered+.row .flex-wrap .product-box {width: 46%; margin-right: 4%;}
  .strip-black h3 {font-size: 1.1rem; font-weight: normal; margin: 2px 0 0 0;}
  .strip-black h2 {font-size: 1.2rem; font-weight: normal; i {margin-right: 5px;}}
  .search-box input[type="text"] {padding: 0 15px 0 15px; font-size: 1.6rem; width: calc(100% - 100px);}
  .search-box .btn-search {width: 50px;}

  .filter-box li {width: 100% !important; text-align: left; border-left: 0 !important;}
  .filter-box li + li {border-top: 1px solid $borderColor;}
  .filter-box li a {padding: 8px 15px;}
  .mid-inner {padding-top: 25px;}
  .filtered-right > .col-sm-6 {width: 50%;}
  .filter-options a {margin: 10px 6px;}
  .wrap-hero .hero-large .container p {padding: 0;}
  .profile-top {.col-xs-4, .col-xs-8{width: 100%; text-align: center;} .col-xs-4{margin-bottom: 20px;}}
  #login {.modal-body {.forgot-password {clear: both; margin-left: 10px; float: left;}}}
  #login {.modal-content {padding: 30px 10px 40px 10px;} button.close {left: 20px;}}
  .styled-checkbox {width: 100%;}
  .creation-dropdown {width: 100%;}
  .add-cart .col-sm-12 .col-xs-4 {margin: 5px 0 10px 0; border: 0 !important; padding: 0 15px;}
  .wrap-cart .table-responsive .main-table.table td {padding: 5px; white-space: normal;}
  .wrap-cart > div .cart-content {padding: 20px 10px;}
  .wrap-cart .table-responsive .main-table.table td img {max-width: 40px;}
  .resources ul li a, .share ul li a {font-size: 1.2rem; padding: 15px 10px; height: 100%;}
  .countdown-wrapper {.middle {width: 230px;} .countdown p {font-size: 2rem; margin-top: 0;}}
  .knowledge-base-tabs .nav-tabs li {width: 100%;}
  .block-frequently .icon-math {width: 22px; font-size: 2.5rem;}
  .gallery-full .flex-wrap>.col-sm-4 .thumbnail-box .info-thumbnail {padding-top: 10px; h3 {font-size: 1.8rem;} p {font-size: 1.3rem;} .row {margin-top: 10px;}}
  .banner .item .carousel-caption .caption-inner h1 {font-size: 1.8rem; margin-bottom: 10px;}
  .content-right .product-wrap .product-box { width: calc(100% - 20px); }
  .content-right .product-wrap .product-box.swatch-box {margin-right: 4%;}
  .coating-combo .coating-combo-in {> div .flex-wrap > .product-box {margin: 0 4% 15px 0 !important;} .col-sm-8 {margin-top: 30px;}}
  .block-frequently {.product-box, .thumb-view-gallery {width: 43%; margin-right: 1%;}}
  .sticky-wrap .btn {clear: both; margin: 25px 0 0 0; width: 100%; float: left;}
  .search-box .select2-container, .search-box .global-search {width: calc(100% - 50px) !important;}
  .coating-combo .coating-combo-in > div .flex-wrap > div {width: 100%;}
  .coating-combo .coating-combo-in .product-box {width: 148px;}
  #vue-gallery .search-box .global-search {font-size: 1.3rem;padding-top: 10px;padding-bottom:5px;border-radius: 25px;width: 100% !important;padding-right: 35px;}
  #vue-gallery .search-box .btn-search {position:absolute; background-color: transparent; color: #ccc; right:0px; pointer-events:none;}
  .gallery-detail-top, .creation-detail-top h1 {font-size: 25px;}
  .hideMobile {display:none !important;}
  #vue-my-page .photo-profile .photo-in {width: 80px;height: 80px;}
  .info-profile .row .col-sm-12 > .phoneLink.open {width: calc(100% - 45px); left: 30px;}
  .latest-purchased .btn-right {float: left;left: 40px;}
  .latest-purchased .product-box {margin-right: 2%;}
  .latest-purchased .product-box img {max-height:30px}
  .latest-purchased .product-box + .product-box {padding: 10px 15px !important; max-width: 225px; margin-right: 0;}
  .latest-purchased {padding: 15px 20px;}
  .cookiesDisabled h9 {font-size: 1.6rem}
  .info-profile { padding-left: 15px; }
  .info-profile .info-details { min-height: 80px; }
  .info-profile .aboutUs { width: auto; margin-left: calc(-25% - 30px); }
  .info-profile .row .col-sm-12 { margin-left: calc(-25% - 20px); }
  textarea.form-control.commentSection {height: 130px;}

  #vue-creation-details {
    .product-wrap {
      .flex-wrap {
        flex-direction: column;
      }

      .creation-product-box {
        min-height: 240px;
        margin: 20px auto 20px;
      }
    }
  }

  #slider-thumbs ul { grid-template-columns: auto auto auto; }

  .main-container {

    .img-banner {
      height: 800px;
    }
  }
}

@media (max-width: 379px) {
  .banner .item .carousel-caption {padding: 0 5px;}
  .banner .item .carousel-caption .caption-inner .mobile-captionBtn {padding-right:10px}
  #qualifiedApplication .form-group {min-height: 105px;}
  .wrap-cart .cart-content .table-responsive {overflow-x:auto}
  .cookiesDisabled {padding-top: 20px;}
  .cookiesDisabled h9 {font-size: 1.5rem}

  .product-cards {
    grid-template-columns: repeat(1, 1fr);;
  }

  .product-card {
    max-width: inherit;
  }

  #slider-thumbs ul { grid-template-columns: auto auto; }

  .main-container {

    .img-banner {
      height: 900px;
    }
  }
}


@media print {
  header {display:none}
  .mobile-head-wrapper {display:none}
  .mobile-nav {display:none}
  .mid-inner {margin-top:15px}
  footer {display:none}
  .countdown-wrapper {display:none}
  .breadcrumb {display:none}

  /* -- cart -- */
  .wrap-cart .add-product {display:none}
  .wrap-cart .cart-sidbar h4 {display:none}
  .wrap-cart #calculateShipping {display:none}
  .wrap-cart #getEstimate {display:none}
  .wrap-cart .block-terms {display:none}
  .wrap-cart .wrap-subtotal a {display:none}
  .wrap-cart .wrap-subtotal .subtotal-price {text-align:left}

  /* -- checkout -- */
  .cart-checkout .col-sm-8 {width:75%}
  .cart-checkout .col-sm-4 {width:25%}
  .cart-checkout .shipping-address.method .method-wrap {display:inline-block; width:25%;float:left;margin: 0;}
  .cart-checkout .shipping-address.method p {display:inline-block; width:74%;margin:0}
  .cart-checkout .shipping-address.method .method-wrap .method-box {display:none}
  .cart-checkout .shipping-address.method .method-wrap .method-box.method-orange {display:block}
  .container > .row p {display:none}
  .cart-checkout h2 span {display:none}
  .cart-checkout .addressSearch {display:none}
  .cart-checkout .wrap-accordion #accordionAddress .accordion-head > div.col-sm-5 {width:33.3%}
  .cart-checkout .wrap-accordion #accordionAddress .accordion-head > div.col-sm-7 {width:66.7%}
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box {display:none}
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected {display:block}
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse {display:block}
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse .row > div.col-sm-5 {width:33.3%; float:left}
  .cart-checkout .wrap-accordion #accordionAddress .shadow-box.selected .collapse .row > div.col-sm-7 {width:66%; float:left}
  .cart-checkout .card > a {display:none}
  .cart-checkout .card > .row {display:none}
  .cart-checkout .addPaymentOption {display:none}
  .cart-checkout .paymentOptionForm {display:block;}
  .cart-checkout .wrap-accordion .paymentshadowbox {display:none}
  .cart-checkout .wrap-accordion .paymentshadowbox.selected {display:block}
  .cart-checkout .block-comment-payment .form-group p {display:none}
  .cart-checkout .block-comment-payment .form-group input {display:none}
  .cart-checkout .cart-sidbar .block-terms {display:none}
  .alert-success span {display:none}

  /* -- shipping (for non logged out users making purchase) -- */
  #vue-shipping .wrap-cart .col-sm-8 {width:74%; float:left}
  #vue-shipping .wrap-cart .col-sm-6 {width:50%; float:left}
  #vue-shipping .wrap-cart .col-sm-4 {width:25%; float:left}
  #vue-shipping .wrap-cart .form-group.checkbox {display:none}
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap {display:inline-block; width:25%;float:left;margin: 0;}
  #vue-shipping .wrap-cart .shipping-address.method p {display:inline-block; width:74%;margin:0}
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap .method-box {display:none}
  #vue-shipping .wrap-cart .shipping-address.method .method-wrap .method-box.method-orange {display:block}
  #vue-shipping .wrap-cart .submitOrder {display:none}
  #vue-shipping .wrap-cart .cart-content.shipping-address h2 + .row {margin-bottom:0}

  /* -- Gallery/Creation Details -- */
  .gallery-detail-top p,
  .creation-detail-top p {
    display: none;
  }

  .wrap-gallery-details .share,
  .wrap-creation-details .share {
    display: none;
  }

  .wrap-gallery-details #galleryCarousel,
  .wrap-creation-details #creationCarousel {
    width: 66%;
  }

  .wrap-gallery-details .info-product-right,
  .wrap-creation-details .info-product-right {
    width: 34%;
  }

  #vue-gallery-details a.btn-blue.mobile-head-wrapper,
  #vue-creation-details a.btn-blue.mobile-head-wrapper {
    display: none;
  }

  #vue-gallery-details a.btn-blue,
  #vue-creation-details a.btn-blue {
    display: none;
  }

  .wrap-gallery-details .info-product-right > h4,
  .wrap-creation-details .info-product-right > h4 {
    display: none;
  }

  .wrap-gallery-details .info-product-right a,
  .wrap-creation-details .info-product-right a {
    display: none;
  }

  .wrap-gallery-details .info-product-right .shadow-box > .row,
  .wrap-creation-details .info-product-right .shadow-box > .row {
    display: none;
  }

  #vue-gallery-details .info-product-right-mobile .side-box,
  #vue-creation-details .info-product-right-mobile .side-box {
    text-align:left;
  }

  #vue-gallery-details .info-product-right-mobile .shadow-box > .row > div > span,
  #vue-creation-details .info-product-right-mobile .shadow-box > .row > div > span {
    display: none;
  }

  #vue-gallery-details .info-product-right-mobile .shadow-box > .row > div > div,
  #vue-creation-details .info-product-right-mobile .shadow-box > .row > div > div {
    display: none;
  }

  #vue-gallery-details .info-product-right-mobile .side-box .stars,
  #vue-creation-details .info-product-right-mobile .side-box .stars {
    display: none;
  }

  #vue-gallery-details .info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink,
  #vue-creation-details .info-product-right-mobile .side-box > .row .col-sm-12 > .phoneLink {
    opacity: 1;
    width: 100px;
    position: relative;
    background: #fff;
  }

  #vue-gallery-details .heading-bordered h2 > span a,
  #vue-creation-details .heading-bordered h2 > span a {
    display: none;
  }

  #vue-gallery-details .product-wrap .product-box,
  #vue-creation-details .product-wrap .product-box {
    width: 100%;
    padding: 5px 0;
    min-height: auto;
  }

  #vue-gallery-details .product-wrap .product-box img,
  #vue-creation-details .product-wrap .product-box img {
    display: inline-block;
    height: 60px;
  }

  #vue-gallery-details .product-wrap .product-box div div,
  #vue-creation-details .product-wrap .product-box div div {
    display: none;
  }

  #vue-gallery-details .product-wrap .product-box div:first-child > a h3, .product-box div:first-child > a p,
  #vue-creation-details .product-wrap .product-box div:first-child > a h3, .product-box div:first-child > a p {
    display: inline;
  }

  /* -- Product Details -- */
  .wrap-details .resources {display:none}
  .wrap-details #productCarousel {width:62%}
  .wrap-details .info-product-right {width:38%}
  .wrap-details .info-product-right .add-cart .form-group .col-sm-8 {display:none}
  .wrap-details .info-product-right .add-cart .form-group > .row > .col-sm-4 {display:none}
  .wrap-details .info-product-right .form-group .product-total {display:none}
  #vue-shop-details .wrap-knowledge-base {display:none}

  /* -- Wishlist & Past Orders (print order history) -- */
  .heading-wishlist {display:none}
  .hidePrint {display:none}
  .hideNonPrint {display:block !important}
  .row.wishlist {display:none}
  .row.orders .latest-purchased {display:none}
  .row.orders .latest-purchased.print {display:block; margin:0}
  .row.orders .latest-purchased .printOnly {display:block; text-align:left}
  .row.orders .latest-purchased.print .product-box h3 + .printOnly {display:block}
  .row.orders > .col-sm-12 {padding: 0px; margin: 0px;}
  .row.orders > .col-sm-12 > .col-sm-6 {width: 40% !important; padding: 0px; margin: 0px;}
  .row.orders .col-sm-3 {width: 30% !important; padding: 0px; margin: 0px;}
  .row.orders > .col-sm-12 > .col-xs-12 {padding: 0px; margin: 0px;}
}

