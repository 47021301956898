#profileEditModal {

  --color-cerakote-gray: #3A3A39;
  --color-cerakote-orange: #F69322;

  --input-height: 42px;

  pointer-events: none;
  overflow: hidden;

  .modal-dialog {
    width: 660px;
  }

  .modal-content {
    max-height: calc(100vh - 100px);
    background-color: white;
    pointer-events: all;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: none;
    color: #333;
    padding: 0;
    border: 0;

    @media screen and (max-width: 600px) {
      max-height: 100vh;
    }

  }

  .modal-header {
    flex-direction: column;
    position: sticky;
    display: flex;
    z-index: 10;
    padding: 0;
    top: 0;

    &-content {
      background-color: var(--color-cerakote-gray);
      flex-direction: row;
      align-items: center;
      padding: 20px 24px;
      flex-wrap: nowrap;
      display: flex;
      z-index: 1;
    }

    &-title {
      flex-direction: column;
      display: flex;
      flex: 1;

      h2 {
        text-transform: uppercase;
        letter-spacing: 0.125px;
        font-weight: 700;
        font-size: 15px;
        color: #FFF;
        margin: 0;
      }

      small {
        margin-bottom: 0;
        margin-top: 4px;
        font-size: 12px;
        color: #BABABA;
      }

    }

    &-close {
      transition: color 200ms ease-in-out;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      padding: 0 8px;
      line-height: 1;
      display: flex;
      outline: none;
      color: #AAA;
      border: 0;

      &:hover,
      &:focus-within {
        color: #FFF;
      }

    }

  }

  .modal-body {
    padding: 0 24px 24px;
  }

  .modal-footer {
    box-shadow: 0 -12px 16px 0px white;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB;
    background-color: #FBFBFB;
    flex-direction: row;
    align-items: center;
    padding: 18px 24px;
    position: sticky;
    display: flex;
    z-index: 10;
    bottom: 0;

    &-actions {
      justify-content: flex-end;
      flex-direction: row;
      flex-wrap: nowrap;
      display: flex;
      flex: 1;
    }

    &-save,
    &-cancel {
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      padding: 0 20px;
      display: flex;
      height: 42px;
      border: 0;

      @media screen and (max-width: 600px) {
        flex: 1;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.65;
      }

      .fa {
        margin-right: 6px;
        font-size: 18px;
      }

    }

    &-save {
      background-color: #F69322;
      margin-left: 16px;
      color: #FFF;
    }

  }

  input[type=file] {
    visibility: hidden;
    display: none;
  }

  .my-account {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .edit-profile {
    flex-direction: column;
    display: flex;

    &-notification {
      transition: transform 200ms ease-in-out;
      transform: translate3d(0, -100%, 0);
      position: absolute;
      z-index: 0;
      top: 100%;
      right: 0;
      left: 0;
      flex: 1;

      &[aria-expanded] {
        transform: translate3d(0, 0, 0);
        position: sticky;
      }

      &-warning,
      &-success,
      &-error {
        align-items: flex-start;
        flex-direction: row;
        padding: 12px 24px;
        font-weight: 500;
        line-height: 1;
        color: white;
        display: flex;
        height: auto;

        .fa {
          margin-right: 12px;
          font-size: 18px;
        }

      }

      &-warning {
        background-color: #F0A202;
      }

      &-success {
        background-color: #7ECC58;
      }

      &-error {
        background-color: #F9564F;
      }

    }

    &-action {
      transition: background-color 200ms ease-in-out;
      box-shadow: 0 0 0 3px white;
      background-color: #3a3a39;
      justify-content: center;
      border-radius: 100%;
      align-items: center;
      margin-bottom: 0;
      font-size: 30px;
      cursor: pointer;
      line-height: 0;
      display: flex;
      outline: none;
      color: white;
      height: 42px;
      width: 42px;
      border: 0;

      i.fa {
        font-size: 16px;
      }

      &:hover,
      &:focus-within {
        background-color: #F69322;
      }

    }

    &-banner {
      background-image: var(--profile-banner);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #7D7C7A;
      background-size: cover;
      position: relative;
      overflow: hidden;
      margin: 0 -24px;

      &::before {
        content: "";
        padding-bottom: 40%;
        display: block;
        width: 100%;

        @media screen and (max-width: 600px) {
          padding-bottom: 72%;
        }

      }

      &-actions {
        transform: translate3d(-50%, -50%, 0);
        flex-direction: row;
        position: absolute;
        display: flex;
        left: 50%;
        top: 50%;

        .edit-profile-action {
          margin: 0 8px;
        }

      }

    }

    &-nav {
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 16px;
      margin-right: -24px;
      margin-left: -24px;
      padding: 0 24px;
      display: flex;

      > small {
        padding: 16px;
        flex: 1;
      }

    }

    &-tabs {
      flex-direction: row;
      display: flex;
      flex: 1;

      button {
        border-bottom: 3px solid transparent;
        transition: color 200ms ease-in-out;
        background-color: transparent;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        font-weight: 700;
        border-right: 0;
        border-left: 0;
        border-top: 0;
        display: flex;
        outline: none;
        height: 48px;
        color: #999;
        padding: 0;

        .fa {
          margin-right: 8px;
          font-size: 16px;
        }

        &:not([aria-selected]):hover,
        &:not([aria-selected]):focus-within {
          color: #333;
        }

        &[aria-selected] {
          border-bottom: 3px solid #F69322;
          color: #333;
        }

      }

    }

    &-avatar {
      background-image: var(--profile-avatar);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #7D7C7A;
      justify-content: flex-end;
      border: 5px solid white;
      background-size: cover;
      align-items: flex-end;
      border-radius: 50%;
      margin-top: -72px;
      display: flex;
      height: 120px;
      width: 120px;
      z-index: 2;
    }

  }

}
