// ==========================================================
// 320 and Up by Phillip
// ==========================================================

/* Elements =================================================== */
p {margin: 5px 0 20px 0;}

ul, ol {
	// margin: 0;
	// padding: 0;
	}

p + ul, p + ol {
	margin: 0px 0 20px 25px;
	}

.ul-no-styled {
	list-style: none;
	margin: 0;
	padding: 0;
	}

select + i.fa-angle-down {
	pointer-events: none !important;
}

.btn-block {
  margin-bottom: 5px;
  + .btn-block {
    margin-top: 0;
  }
}

.btn.phone-number {

  .phone-number-cta,
  .phone-number-display {
    transition: opacity 200ms ease-in-out;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .phone-number-cta {
    opacity: 1;
  }

  .phone-number-display {
    position: absolute;
    opacity: 0;
  }

  &:hover,
  &:focus-within {

    .phone-number-cta {
      opacity: 0;
    }

    .phone-number-display {
      opacity: 1;
    }

  }

}
