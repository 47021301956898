.my-account {

  .socail-links li {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .account-sidebar {
    padding-right: 15px;
    padding-left: 15px;

    label {
      display: contents;
    }

    @media screen and (min-width: 1031px) {
      padding-right: 60px;
    }

  }

  &-details {
    padding-top: 10px;
    margin-top: 20px;
    flex: 1;

    @media screen and (min-width: 1031px) {
      border-left: 1px solid rgba(150, 150, 150, 0.4);
      padding-left: 40px;
    }

    .my-account:first-of-type {
      padding-top: 0;

      .heading-bordered {
        margin-top: 0;
      }

    }

  }

}
