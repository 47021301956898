$popup-sidebar-width: 380px;

.btn {

  &-alt-outline,
  &-white-outline,
  &-black-outline,
  &-primary-outline {
    transition: background-color 200ms ease-in-out,
    color 200ms ease-in-out;
    background-color: transparent;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    border-radius: 0;
    padding: 0 20px;
    display: flex;
    height: 42px;

    .fa {
      font-size: 19px;

      &:not(.fa-long-arrow-right) {
        margin-bottom: -2px;
      }

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }

    }

    &:hover,
    &:focus-within {
      color: white !important;
    }

    + .btn {
      margin-top: 15px;
    }

  }

  &-alt-outline {
    border: 3px solid #3E6B85;
    color: #3E6B85;

    &:hover,
    &:focus-within {
      background-color: #3E6B85;
    }

  }

  &-white-outline {
    border: 3px solid white;
    color: white;

    &:hover,
    &:focus-within {
      background-color: rgba(white, 0.275);
    }

  }

  &-black-outline {
    border: 3px solid #3a3a39;
    color: #3a3a39;

    &:hover,
    &:focus-within {
      background-color: #3a3a39;
    }

  }

  &-primary-outline {
    border: 3px solid #F69322;
    color: #F69322;

    &:hover,
    &:focus-within {
      background-color: #F69322;
    }

  }

}

#my-page {
  padding-bottom: 50px;
  margin-bottom: 50px;
  position: relative;

  p {
    line-height: 24px;
    font-size: 16px;
    color: #7A7A7A;
  }

  .load-more {
    transform: translate3d(-50%, 0, 0);
    pointer-events: all;
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  &-actions {
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 35px;
    display: flex;

    @media screen and (max-width: 1024px) {
      border-top: 1px solid #DBDBDB;
      background-color: white;
      flex-wrap: nowrap;
      position: fixed;
      padding: 16px;
      z-index: 101;
      margin: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .btn {

      + .btn {
        margin-left: 15px;
        margin-top: 0;
      }

      @media screen and (max-width: 1024px) {
        flex: 1;

        span {
          display: none;
        }

      }

    }

  }

  &-errors {
    justify-content: center;
    margin-top: 25px;
    display: flex;
  }

  .missing-section {
    background-color: #F7F2D2;
    border: 1px solid #F7E199;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 8px 14px;
    font-weight: 600;
    font-size: 14px;
    color: #555;

    i {
      display: inline-flex;
      margin-right: 8px;
      font-size: 18px;
    }

  }

  #not-certified {
    background-color: rgba(#3a3a39, 0.365);
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-weight: 700;
    font-size: 15px;
    color: white;
    display: flex;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    @media screen and (max-width: 1024px) {
      background-color: transparent;
      margin-bottom: -42px;
      position: relative;
      padding: 16px;
      width: 100%;
      bottom: auto;
      right: auto;
      left: auto;
      top: auto;
    }

    &-message {
      padding: 10px 24px 10px 4px;
      background-color: #3a3a39;
      letter-spacing: 0.125px;
      margin-bottom: 7.5vw;
      align-items: center;
      flex-direction: row;
      border-radius: 5px;
      max-width: 550px;
      display: flex;

      img {
        width: 72px;
      }

      span {
        flex: 1;
      }

      a {
        transition: text-decoration-color 200ms ease-in-out;
        text-decoration-color: transparent !important;
        text-decoration: underline;
        color: #f69422;

        &:hover,
        &:focus-within {
          text-decoration-color: #f69422 !important;
          color: #f69422 !important;
        }

      }

      small {
        text-transform: none;
        font-weight: 500;
        margin-top: 4px;
        font-size: 12px;
        color: #BABABA;
        display: block;
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 0;
        padding: 16px;

        img {
          display: none;
        }

      }

    }

  }

  @media screen and (max-width: 1024px) {

    #my-page-contact-cta {
      border-top: 1px solid #DBDBDB;
      background-color: white;
      flex-direction: row;
      flex-wrap: nowrap;
      position: fixed;
      display: flex;
      padding: 8px;
      z-index: 100;
      bottom: 0;
      right: 0;
      left: 0;

      .btn {
        margin: 8px;
        flex: 1;
      }

    }

  }

  &-banner {
    background-color: #7D7C7A;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    z-index: 1;

    &::before {
      content: "";
      box-shadow: inset 0 -250px 150px -100px rgba(black, 0.675);
      background-image: var(--banner-image, none);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 600px;
      display: block;
      height: 50vmin;
      flex: 1;
    }

    &.not-certified::before {
      filter: blur(10px);
      min-height: 620px;
      margin: -10px;
    }

    @media screen and (max-width: 1024px) {
      position: absolute;
      height: auto;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;

      &::before {
        box-shadow: inset 0 0 0 100vmax rgba(#3A3A39, 0.365);
        position: absolute;
        padding: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
      }

    }

  }

  &-details {
    position: relative;
    margin-top: 0;
    width: 100%;
    z-index: 5;

    @media screen and (max-width: 1024px) {
      overflow: hidden;
    }

    &__inner {
      padding-bottom: 15px;
      flex-direction: row;
      margin-bottom: 75px;
      position: relative;
      margin-top: 75px;
      display: flex;
      z-index: 5;

      @media screen and (max-width: 1024px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 48px 32px;
        margin-bottom: 0;
      }

    }

    &-rating {
      background-color: rgba(black, 0.365);
      backdrop-filter: blur(5px);
      align-items: center;
      flex-direction: row;
      margin-bottom: 25px;
      letter-spacing: 4px;
      border-radius: 3px;
      margin-left: -2px;
      padding: 5px 8px;
      font-size: 20px;
      display: flex;

      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
        margin-left: 0;
      }

      .fa-star,
      .fa-star-o {
        color: rgba(white, 0.365);

        &.rated {
          color: #F69322;
        }

      }

      span {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 5px;
        font-size: 11px;
        color: white;
      }

    }

    &-certified {
      justify-content: center;
      align-items: center;
      position: absolute;
      display: flex;
      bottom: 24px;
      right: 12px;
      z-index: 1;

      @media screen and (max-width: 1024px) {
        bottom: 8px;
        right: 8px;
      }

      .fa-check {
        font-size: 16px;
        color: white;
        z-index: 1;
      }

      .fa-certificate {
        position: absolute;
        font-size: 36px;
        color: #F69322;
      }

    }

    &-avatar {
      background-color: #E6E8E8;
      border: 6px solid #E6E8E8;
      justify-content: center;
      flex-direction: column;
      box-sizing: border-box;
      margin-bottom: -75px;
      align-items: center;
      border-radius: 50%;
      position: relative;
      display: flex;
      height: 200px;
      width: 200px;
      padding: 6px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 0;
        height: 150px;
        width: 150px;
      }

      img {
        position: absolute;
        border-radius: 50%;
        height: 100%;
        width: 100%;
      }

    }

    &-account {
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 24px;
      display: flex;
      color: #333;

      h1,
      h3 {
        font-family: "Teko", sans-serif;
        text-transform: uppercase;
        color: currentColor;
      }

      h1 {
        letter-spacing: 1.5px;
        text-indent: -3px;
        line-height: 0.75;
        font-weight: 500;
        font-size: 75px;
        margin: 0;
      }

      h3 {
        align-items: flex-end;
        flex-direction: row;
        letter-spacing: 1px;
        font-weight: 400;
        margin: 10px 0 0;
        font-size: 30px;
        display: flex;

        .fa {
          margin-right: 5px;
          font-size: 24px;
        }

        span {
          line-height: 20px;
        }

      }

      @media screen and (max-width: 1024px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 24px;
        padding: 0;

        h1 {
          line-height: 32px;
          font-size: 36px;
          text-indent: 0;
        }

        h3 {
          align-items: flex-start;
          font-size: 18px;
          margin-top: 6px;

          .fa {
            font-size: 18px;
          }

          span {
            line-height: 21px;
          }

        }

      }

    }

    &-actions {
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      display: flex;
      flex: 1;
      gap: 10px;

      @media screen and (max-width: 1024px) {
        padding-bottom: 0;
        margin-top: 24px;
      }

    }

  }

  &-banner {

    ~ #my-page-details__inner {
      margin-top: -160px;

      @media screen and (max-width: 1024px) {
        margin-top: 0;
      }

      #my-page-details-account {
        color: white;
      }

    }

  }

  &-nav {
    top: var(--desktop-header-size);
    background-color: #F1F1F1;
    margin-bottom: 25px;
    margin-top: -60px;
    position: sticky;
    z-index: 4;

    @media screen and (max-width: 1024px) {
      border-bottom: 1px solid #DBDBDB;
      top: var(--mobile-header-size);
      background-color: white;
      margin-top: 0;
    }

    &__inner {
      justify-content: flex-start;
      flex-direction: row;
      padding-left: 238px;
      display: flex;

      @media screen and (max-width: 1024px) {
        justify-content: space-between;
        padding: 0 16px;
      }

    }

    &-buttons {
      justify-content: flex-start;
      flex-direction: row;
      display: flex;
      flex: 1;
    }

    .my-page-nav-link {
      border: 0;
      border-bottom: 3px solid transparent;
      transition: all 200ms ease-in-out;
      background-color: transparent;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      margin-right: 40px;
      font-weight: 700;
      outline: none;
      display: flex;
      color: #999;
      height: 55px;
      padding: 0;

      &-counter {
        transition: background-color 200ms ease-in-out;
        text-transform: uppercase;
        background-color: #999;
        letter-spacing: 0.5px;
        border-radius: 3px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 5px;
        font-size: 11px;
        padding: 0 8px;
        color: white;

        @media screen and (max-width: 1024px) {
          background-color: transparent;
          font-weight: 700;
          color: #999;
        }

      }

      .fa {
        margin-right: 8px;
        font-size: 16px;
      }

      &[aria-selected] {
        border-bottom: 3px solid #F69322;
        color: #333;

        .my-page-nav-link-counter {
          background-color: #333;
          color: white;

          @media screen and (max-width: 1024px) {
            background-color: transparent;
            color: #333;
          }

        }

      }

      &:not([aria-selected]) {

        &:hover,
        &:focus-within {
          color: #333;

          .my-page-nav-link-counter {
            background-color: #333;

            @media screen and (max-width: 1024px) {
              background-color: transparent;
              color: #333;
            }

          }

        }

      }

      @media screen and (max-width: 1024px) {
        margin-right: 0;
        padding: 0 16px;
        flex: 1;

        .fa {
          margin-right: 0;
        }

      }

    }

  }

  &-review {
    border: 1px solid #DBDBDB;
    background-color: white;
    flex-direction: row;
    margin-bottom: 50px;
    overflow: hidden;
    display: flex;

    .review-content-section {
      text-align: center;
      width: 100%;
      padding: 3%;

      button {
        display: inline-block;
      }

      h2 {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 15px;
        margin-top: 0;
      }
    }
  }

  &-about {
    border: 1px solid #DBDBDB;
    background-color: white;
    flex-direction: row;
    margin-bottom: 50px;
    overflow: hidden;
    display: flex;

    .about-content-section li {
      width: auto;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 16px;
    }

    &-content,
    &-actions {
      flex-direction: column;
      padding: 24px;
      display: flex;
    }

    h2 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 15px;
      margin-top: 0;
    }

    p {
      white-space: pre-line;
      margin-top: 0;
    }

    &-content {
      flex: 1;

      .about-content-section {
        flex-direction: column;
        display: flex;

        + .about-content-section {
          margin-top: 36px;

          @media screen and (max-width: 1024px) {
            margin-top: 24px;
          }

        }

      }

      .featured-items {
        flex-direction: row;
        position: relative;
        flex-wrap: nowrap;
        margin: 0 -10px;
        display: flex;

        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
          margin: -5px;
        }

        &-card {
          position: relative;
          flex: 0 1 25%;

          @media screen and (max-width: 1024px) {
            flex: 0 1 50%;
          }

          &-loading {
            background-color: rgba(black, 0.5);
            justify-content: center;
            align-items: center;
            position: absolute;
            font-size: 48px;
            color: #FFFFFF;
            display: flex;
            z-index: 1;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
          }

          &-item {
            border: 1px solid #DBDBDB;
            position: relative;
            display: block;
            margin: 0 10px;
            padding: 7px;

            @media screen and (max-width: 1024px) {
              margin: 5px;
            }

          }

          &-unpin {
            background-color: transparent;
            justify-content: center;
            align-items: center;
            position: absolute;
            color: #FFFFFF;
            font-size: 16px;
            cursor: pointer;
            display: flex;
            border: none;
            height: 36px;
            width: 36px;
            right: 15px;
            z-index: 10;
            top: 15px;
          }

          &-thumbnail {
            background-image: var(--thumbnail);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            padding-bottom: 100%;
            position: relative;
            transition: none;
            overflow: hidden;
            display: block;
            width: 100%;
            height: 0;

            &::before {
              content: '';
              transition: background-color 200ms ease-in-out;
              background-color: rgba(#333333, 0);
              position: absolute;
              z-index: 1;
              bottom: 0;
              right: 0;
              left: 0;
              top: 0;
            }

            img {
              transform: translate3d(-50%, -50%, 0);
              position: absolute;
              max-height: 100%;
              min-width: 100%;
              z-index: 0;
              left: 50%;
              top: 50%;

              &.videos {
                max-height: 133.333%;
                min-height: 133.333%;
              }

            }

            .play-icon {
              transition: opacity 200ms ease-in-out;
              justify-content: center;
              align-items: center;
              position: absolute;
              font-size: 62px;
              display: flex;
              color: white;
              height: 100%;
              width: 100%;
              z-index: 2;
              opacity: 0;
            }


          }

          &:hover,
          &:focus-within {

            .featured-items-card-thumbnail {

              .play-icon {
                opacity: 1;
              }

              &::before {
                background-color: rgba(#333333, 0.65);
              }

            }

          }

        }

      }

    }

    &-actions {
      border-left: 1px solid #DBDBDB;
      width: 325px;

      li {
        width: max-content;
      }

      @media screen and (max-width: 1024px) {
        padding-top: 0;
        border-left: 0;
        width: 100%;
      }

    }

    &-work {
      flex-direction: row;
      list-style: none;
      flex-wrap: wrap;
      margin: 0 -4px;
      display: flex;

      .work-accepted {
        background-color: #EFEFEF;
        text-transform: capitalize;
        border-radius: 100px;
        padding: 8px 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        margin: 4px;
      }

    }

    &-map {
      padding-bottom: 70%;
      position: relative;
      margin-top: 25px;
      overflow: hidden;
      cursor: pointer;
      height: 0;

      @media screen and (max-width: 1024px) {
        margin-top: 0;
      }

      .block-map {
        transition: border-color 200ms ease-in-out;
        border: 1px solid #DBDBDB;
        position: absolute;
        padding: 2px;
        height: 100%;
        width: 100%;

        &:hover,
        &:focus-within {
          border-color: #3E6B85;
        }

        a {
          display: block;
          height: 100%;
        }

        #gmap-canvas {
          height: 100% !important;
          pointer-events: none;

          button,
          .gm-fullscreen-control {
            display: none !important;
          }

        }

      }

    }

    &-links {
      flex-direction: column;
      margin-top: 25px;
      list-style: none;
      display: flex;

      > li {
        justify-content: flex-start;
        flex-direction: column;
        white-space: pre-wrap;
        word-wrap: break-word;
        display: flex;

        a:hover,
        a:focus-within {
          text-decoration: underline;
        }

        a + a::before,
        span + span::before {
          content: ',';
          margin-right: 3px;
          margin-left: -3px;
          color: #333;
        }

        + li {
          margin-top: 10px;
        }

      }

      .is-certified {
        justify-content: flex-start;
        flex-direction: row;
        white-space: nowrap;
        display: flex;

        .certified-badge {
          justify-content: center;
          align-items: center;
          position: relative;
          margin-right: 3px;
          display: flex;
          z-index: 1;

          .fa-check {
            position: absolute;
            font-size: 10px;
            color: white;
            z-index: 1;
          }

          .fa-certificate {
            font-size: 22px;
            color: #F69322;
          }

        }

      }

    }

  }

  &-pinned,
  &-everything,
  &-projects,
  &-reviews,
  &-videos {
    pointer-events: none;
    position: relative;
    margin-top: 25px;
    column-gap: 25px;
    columns: 3;

    @media screen and (max-width: 1199px) {
      column-count: 2;
    }

    @media screen and (max-width: 660px) {
      margin: 20px 0 0;
      column-count: 1;
    }

    &[aria-busy] {

      .grid-card {
        pointer-events: none !important;
        opacity: 0.5 !important;
      }

    }

    .video-card {

      &-title {
        flex-direction: row;
        align-items: center;
        display: flex;

        .fa {
          margin-right: 6px;
          font-size: 20px;
          color: red;
        }

      }

      .play-icon {
        transition: opacity 200ms ease-in-out;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 62px;
        display: flex;
        color: white;
        height: 100%;
        width: 100%;
        z-index: 2;
        opacity: 0;
      }

      &:hover,
      &:focus-within {

        .play-icon {
          opacity: 1;
        }

      }

    }

    .project-card {

      &-controls {
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        position: absolute;
        flex-wrap: nowrap;
        padding: 12px;
        display: flex;
        z-index: 10;
        right: 26px;
        left: 26px;
        top: 26px;
      }

      &-status,
      &-actions {
        display: flex;
      }

      .project-review p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
      }

    }

    // &:hover,
    // &:focus-within {
    //   .grid-card {
    //     &:not(:hover):not(:focus-within) {
    //       opacity: 0.5;
    //     }
    //   }
    // }

  }

}

.project-review {

  &-title {
    flex-direction: column;
    display: flex;
  }

  strong {
    text-transform: capitalize;
    font-size: 15px;
  }

  blockquote {
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: 0;

    small {
      margin-top: 6px;
      font-size: 11px;
      color: #999999;

      &::before {
        display: none;
      }

    }

    p {
      line-height: 1.625;
      margin-bottom: 0;
      margin-top: 4px;
      color: #666666;
    }

  }

  &-quote {
    margin: 15px 0 0 0;

    > cite {
      font-weight: 600;
      font-size: 13px;
      color: #9A9A9A;
      text-transform: capitalize;
    }

    p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 24px;
    }

  }

  &-rating {
    align-items: center;
    flex-direction: row;
    letter-spacing: 5px;
    font-size: 20px;
    display: inline-flex;
    margin: 0;

    .fa-star-o {
      color: #D8D8D8;
    }

    .fa-star {
      color: #F69322;
    }

  }

}

.feed-item {
  box-shadow: 0 1px 2px 0 rgba(black, 0.15);
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  background-color: white;
  margin-bottom: 25px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  z-index: 1;

  &:hover {
    box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(black, 0.23);
    transform: translate3d(0, -10px, 0);
    z-index: 2;
  }

  &:empty {
    visibility: hidden;
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;

    &:hover {
      transform: translate3d(0, 0, 0);
    }

  }

}

.grid-card {
  transition: opacity 200ms ease-in-out;
  flex-direction: column;
  position: relative;
  display: flex;
  opacity: 1;
  flex: 1;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  &__inner {
    pointer-events: all;
    transition: none;
    cursor: pointer;
    color: #222222;
    display: block;
  }

  &-loading {
    background-color: rgba(black, 0.5);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
    font-size: 48px;
    color: #FFFFFF;
    display: flex;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &-image {
    pointer-events: none;
    position: relative;
    z-index: 0;
    height: 0;

    &-background {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      position: absolute;
      overflow: hidden;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;

      &::before {
        content: "";
        background-image: var(--card-image);
        background-position: center center;
        background-repeat: no-repeat;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background-size: cover;
        position: absolute;
        bottom: -1px;
        right: -1px;
        left: -1px;
        top: -1px;
      }

      &.default-image::before {
        background-size: contain;
      }

    }

    &[data-ratio="1:1"] {
      padding-bottom: 70%;
    }

    &[data-ratio="16:9"] {
      padding-bottom: 55.10%;
    }

  }

  &-author {
    flex-direction: column;
    margin: 15px 0;
    display: flex;
    width: 100%;

    strong, small {
      line-height: 1.25;
      margin: 0;
    }

    strong {
      font-size: 16px;
    }

    small {
      font-size: 12px;
      margin-top: 4px;
    }

  }

  &-type {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #575757;
  }

  &-content {
    padding: 20px 20px 25px;
    align-items: flex-start;
    flex-direction: column;
    display: flex;

    h3 {
      line-height: 1.365;
      font-weight: 600;
      font-size: 19px;
      color: #222222;

      &:first-letter {
        text-transform: uppercase;
      }

    }

    p {
      margin: 12px 0 0;
    }

  }

  &-date {
    align-items: center;
    margin-top: -3px;
    font-weight: 600;
    font-size: 13px;
    color: #9A9A9A;
    display: flex;

    > .fa {
      align-items: center;
      font-size: 16px;
      display: flex;
      height: 24px;
      width: 22px;
    }

  }

  &-status {
    align-items: flex-start;
    flex-direction: row;
    margin: 10px 0 0 0;
    display: flex;

    .status {
      text-transform: uppercase;
      letter-spacing: 0.25px;
      border-radius: 100px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 5px 0 0;
      padding: 4px 10px;
      font-weight: 600;
      font-size: 10px;
      color: white;

      .fa {
        margin-right: 4px;
        font-size: 12px;
      }

      &.info {
        background-color: #40CBF9;
      }

      &.pending {
        background-color: #FFBB35;
      }

      &.denied {
        background-color: #FF3F49;
      }

      &.approved {
        background-color: #7ECC58;
      }

    }

  }

  &-actions {
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
    flex-direction: row;
    position: absolute;
    flex-wrap: nowrap;
    padding: 0 20px;
    display: flex;
    height: 100%;
    width: 100%;

    > button {
      transition: transform 200ms ease-in-out, background-color 200ms ease-in-out;
      box-shadow: 0 2px 6px rgba(black, 0.3);
      background-color: #3E6B85;
      justify-content: center;
      display: inline-flex;
      margin: 0 0 -22px 0;
      pointer-events: all;
      align-items: center;
      border-radius: 100%;
      font-size: 21px;
      cursor: pointer;
      color: white;
      line-height: 1;
      outline: none;
      height: 48px;
      width: 48px;
      z-index: 5;
      padding: 0;
      border: 0;

      &:hover,
      &:focus {
        background-color: #4E86A6;
      }

      > span {
        font-size: 36px;
      }

    }

    &-menu {
      transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
      box-shadow: 0 0 12px -4px rgba(black, 0.65);
      transform: translate3d(0, 0, 0);
      background-color: white;
      flex-direction: column;
      pointer-events: all;
      border-radius: 4px;
      position: absolute;
      margin-top: 36px;
      padding: 10px;
      display: flex;
      right: 15px;
      opacity: 1;
      top: 100%;

      &[aria-hidden] {
        transform: translate3d(0, 10px, 0);
        pointer-events: none;
        opacity: 0;
      }

      &::before {
        content: "";
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        right: 18px;
        top: -10px;
      }

      > button {
        transition: background-color 200ms ease-in-out;
        background-color: transparent;
        justify-content: flex-start;
        display: inline-flex;
        white-space: nowrap;
        align-items: center;
        border-radius: 4px;
        font-weight: 600;
        color: #555555;
        padding: 0 12px;
        line-height: 1;
        outline: none;
        border: none;
        height: 40px;

        .fa {
          margin-right: 6px;
          font-size: 16px;
          width: 16px;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.25;
        }

        &:not(:disabled) {
          &:hover,
          &:focus-within {
            background-color: #EFEFEF;
          }
        }

        &.danger {
          color: #FF3F49;
        }

        &[aria-selected] {
          background-color: #1788e4;
          color: white;
          &:hover,
          &:focus-within {
            color: white;
          }
        }

      }

      &-underlay {
        background-color: rgba(red, 0.1);
        pointer-events: all;
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: -1;
        left: 0;
        top: 0;
      }

    }

  }

}
