// ==========================================================
// 320 and Up by Phillip Pradeep [ITH]
// ==========================================================

//footer
footer {
  font-family: $openSans;
  @include fullwidth();
  font-weight: 600;
  text-align: center;
  background: #e6e8e8;
  overflow: hidden;
  z-index: 10;
  .container-fluid {
    max-width: 100%;
    padding: 0px;
  }

  .sm-links {
    background-color: $lightBlack;
    color: $white;
    padding: 10px;

    a {
      color: $white;

      &:hover {
        color: #f69422 !important;
      }
    }

    div {
      > a {
        display: inline-block;
        font-size: 3rem;
        padding: 0 20px;
      }
    }

    span {
      line-height: 43px;
    }
  }
  .footlinks {
    padding: 10px 25px;

    h3 {
      text-align:left;
    }

    ul {
      list-style: none;

      li {
        text-align:left;

        a {
          color: $lightBlack;

          &:hover {
            color: #f69422 !important;
          }
        }
      }
    }

    .col-lg-3,
    span {
      text-align:left;

      &.mobile-only {
        a:not(.tablet-only) {
          text-align: center;
          display: inline-block;
          font-size: 1rem;
        }

        img {
          width:50px;
        }
      }
    }

    .form-group {
      margin: 5px 0;
      input {
        max-width: 400px;
      }
      button {
        width: 100%;
        max-width: 400px;
      }
    }
  }
  .copyright {
    padding: 0;

    .col-lg-3 {
      text-align:left;

      a {
        text-align: center;
        display: inline-block;
        font-size: .9rem;

        img {
          width:35px;
        }
      }
    }

    .col-lg-6 {
      text-align:center;

      div {
        font-weight: 600;
        color: #888
      }
    }

    div.text-center {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  a:hover, a:active, a:focus {
    color: $orange;
    text-decoration: none;
  }
  .logo-nic > img {
    max-width: 120px;
  }
  .asterisk {
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
  }
  a.globe:hover > img {
    filter: invert(76%) sepia(27%) saturate(7007%) hue-rotate(346deg) brightness(103%) contrast(93%) !important; 
    transition: 0.2s ease;
  }
}
#vue-coatings footer .container-fluid .col-xs-12 {
  background: #e6e8e8;
  z-index: 99;

  &.sm-links {
    background: #393939;
  }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (max-width: 767px) {
  footer {
    .container-fluid {
      position: absolute;
    }
  }
}

@media (min-width: 768px) {
  .col-sm-5ths {
      width: 20%;
      float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
      width: 20%;
      float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
      width: 20%;
      float: left;
  }
}
