// ==========================================================
// 320 and Up
// ==========================================================

/* Variables =================================================== */

// COLORS
$black 				: #000000;
$darkBlack 			: #222222;
$darkenBlack 		: #323232;
$lightBlack 		: #393939;
$lightenBlack		: #4b4f54;
$white				: #ffffff;
$lighterGrey 		: #fafafa;
$lightGrey 		    : #f2f2f2;
$lightenGrey		: rgb(235,235,235);
$darkGrey			: rgb(204,204,204);
$darkenGrey			: rgb(153,153,153);
$green				: rgb(37,161,135);
$lightGreen			: #13ae47;
$darkGreen			: darken($lightGreen, 5%);
$yellow				: rgb(245,166,35);
$yellowDark			: rgb(255,150,0);
$red              	: rgb(254,0,0);
$darkRed            : rgb(208,1,27);
$orange             : #f69422;
$orangeLight		: darken($orange, 20%);
$darkOrange			: rgb(221,75,57);
$blue				: rgb(23,136,228);
$darkBlue			: rgb(54,83,149);
$darkenBlue			: rgb(35,82,124);
$color--green		: #7ac142;
$curve				: cubic-bezier(0.650, 0.000, 0.450, 1.000);
$darkenDarkBlack	: rgb(10,11,9);
$purple       : #8a2be2;
$darkPurple   : #4b0082;

// ROOT 					==============================
$baseTextColor 		: $darkBlack;
$lightTextColor 	: $darkenGrey;
$whiteText      	: $white;
$borderColor 		: $darkGrey;
$lightBorderColor 	: $lightenGrey;
$darkBorderColor 	: $darkenGrey;
$bgFormField    	: $white;

// TYPOGRAPHY 			==============================
@font-face {
  font-family: 'HelveticaNeueLTStdBdIt';
  src: url('../fonts/HelveticaNeueLTStdBdIt.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HelveticaNeueLTStdBdIt.woff') format('woff'),
       url('../fonts/HelveticaNeueLTStdBdIt.ttf') format('truetype'),
       url('../fonts/HelveticaNeueLTStdBdIt.svg#HelveticaNeueLTStdBdIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans Regular";
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.ttf") format('truetype'),
       url("../fonts/OpenSans-Regular.woff") format('woff'),
       url("../fonts/OpenSans-Regular.woff2") format('woff2');
}

@font-face {
  font-family: "OpenSans Bold";
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.ttf") format('truetype'),
       url("../fonts/OpenSans-Bold.woff") format('woff'),
       url("../fonts/OpenSans-Bold.woff2") format('woff2');
}

@font-face {
  font-family: "OpenSans ExtraBold";
  font-weight: 900;
  src: url("../fonts/OpenSans-ExtraBold.ttf") format('truetype'),
       url("../fonts/OpenSans-ExtraBold.woff") format('woff'),
       url("../fonts/OpenSans-ExtraBold.woff2") format('woff2');
}

$openSans: "Open Sans", sans-serif;

$OpenSansRegular: "OpenSans Regular", sans-serif;
$OpenSansExtraBold: "OpenSans ExtraBold", sans-serif;

$sourceSansPro: "Source Sans Pro", sans-serif;
$leagueGothicRegular: "league_gothicregular", sans-serif;
$arial: "Arial", sans-serif;

$navItemFont  : 'HelveticaNeueLTStdBdIt';

//$basefont : "Open Sans", sans-serif;
$baseFont       	: $openSans;
$baseFontSize   	: 1.4rem;
$baseLineHeight 	: 1.45;
$baseFontWeight 	: 500;

// Links
$linkColor 		    : $blue;
$linkColorHover 	: darken($linkColor, 10);
$linkColorVisited   : darken($linkColorHover, 10);
$linkColorFocus 	: darken($linkColorVisited, 10);

// colour palettes
$alertColor 	: rgb(252,248,227);
$errorColor 	: rgb(218,79,73);
$infoColor 		: rgb(217,237,247);
$inversColor 	: rgb(65,65,65);
$successColor 	: rgb(91,183,91);
$warningColor 	: #faa732;

// 4. TEXTURE 				==============================
// alert
$alertBorderColor	: darken($alertColor, 20%);
$alertBorderWidth 	: 1px;
$alertBorderStyle 	: solid;
$alertText			: darken($alertColor, 60%);

// alert-error
$alertErrorColor 	: lighten($errorColor, 30%);
$alertErrorBorder  	: lighten($errorColor, 20%);
$alertErrorText 	: $errorColor;

// alert-info
$alertInfoColor 	: $infoColor;
$alertInfoBorder  	: lighten($infoColor, 20%);
$alertInfoText 		: darken($infoColor, 50%);

// alert-inverse
$alertInverseColor 	: $inversColor;
$alertInverseBorder : $black;
$alertInverseText 	: $white;

// alert-success
$alertSuccessColor 	: lighten($successColor, 30%);
$alertSuccessBorder : lighten($successColor, 20%);
$alertSuccessText 	: darken($successColor, 20%);

// alert-warning
$alertWarningColor 	: lighten($warningColor, 30%);
$alertWarningBorder : lighten($warningColor, 20%);
$alertWarningText 	: darken($warningColor, 20%);

// buttons
$buttonBackground 	: $yellow;
$buttonBorderColor 	: darken($buttonBackground, 10%);
$buttonColor 		: $white;
$buttonBorderWidth	: 1px;
$buttonBorderStyle	: solid;

// Tables
$tableBackground 	: transparent;
$tableStripe 		: $lightGrey;
$tableBorder 		: $lightGrey;
$tableBorderWidth	: 1px;
$tableBorderStyle	: solid;

// Forms
$inputBackground 	: $white;
$inputBorder 		: $lightGrey;
$inputBorderWidth	: 1px;
$inputBorderStyle	: solid;
$inputHover 		: $lightGrey;
$inputFocus 		: $lightGrey;
$placeholderColor 	: $lightGrey;

// Radio buttons
$radioSelected : $blue;
$radioDefault : $white;

// IMG PATH
$imgDir: '../img/';
